import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

declare let ga: Function;

@Component({
    selector: "app-painel",
    templateUrl: "./painel.component.html",
    styleUrls: ["./painel.component.css"],
})
export class PainelComponent implements OnInit {
    constructor(
        public router: Router
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                ga("set", "page", event.urlAfterRedirects);
                ga("set", "anonymizeIp", true);
                ga("send", "pageview");
            }
        });
    }

    ngOnInit(): void {
    }

    
}
