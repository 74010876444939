import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormularioService, AlertModalComponent } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { SuspensaoContribuicaoModel } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';

@Component({
    selector: 'app-suspensao-contribuicao',
    templateUrl: './suspensao-contribuicao.component.html',
    styleUrls: ['./suspensao-contribuicao.component.css']
})
export class SuspensaoContribuicaoComponent implements OnInit {
    suspensaoContribuicao: SuspensaoContribuicaoModel;
    suspensaoContribuicaoForm: FormGroup;
    isLoading: boolean = false;
    ptBR = LOCALE_PT_BR;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    urlArquivoSeSuite: string;
    msgs: any[];
    yearRange: string;
    uploadedFiles: any[] = [];
    isSimulado = sessionStorage.getItem('isSimulado');

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private fb: FormBuilder
    ) {
        this.yearRange = '1900:' + (new Date()).getFullYear();
    }

    ngOnInit() {
        this.suspensaoContribuicaoForm = this.fb.group({
            dataSuspensaoContrato: null
        });
    }

    ngOnDestroy(): void {
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar(suspensaoContribuicao: SuspensaoContribuicaoModel) {
        this.isLoading = true;
        this.suspensaoContribuicao = suspensaoContribuicao;
        this.authService.getRegFibra()
            .subscribe(regFibra => {
                let formData = new FormData();
                formData.append('DataSuspensaoContrato', suspensaoContribuicao.dataSuspensaoContrato.toLocaleDateString('pt-BR'));

                if (this.fileUpload.hasFiles()) {
                    this.fileUpload.files.forEach((file, index) => {
                        formData.append('anexo_' + index, file);
                    });

                    this.formularioService.gerarFormSuspensaoContribuicao(regFibra, formData)
                        .subscribe(urlArquivoSeSuite => {
                            this.isLoading = false;
                            this.urlArquivoSeSuite = urlArquivoSeSuite;
                            this.alertModalComponent.show('Solicitação enviada com sucesso!', true);
                            if (this.isSimulado === 'true') {
                                window.open(urlArquivoSeSuite, '_blank');
                            }
                            this.reset();
                        }, () => {
                            this.isLoading = false;
                            this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                            this.reset();
                        });
                }
            }, () => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                this.reset();
            });
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        this.twoFactorAuth.show();
    }

     /**
     * Lida com o sucesso da autenticação de dois fatores.
     * @param twoFactorAuthToken 2fa token.
     */
      on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar(this.suspensaoContribuicaoForm.value);
    }
}
