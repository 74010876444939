import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService, TipoBloqueioConst } from '@fibra/fibra-shared-lib';
import { SaldoEmprestimo } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-saldo-emprestimo',
    templateUrl: './saldo-emprestimo.component.html',
    styleUrls: ['./saldo-emprestimo.component.css']
})

export class SaldoEmprestimoComponent implements OnInit {
    isLoading = true;
    saldoEmprestimo: SaldoEmprestimo;
    saldoEmprestimoConcessao: SaldoEmprestimo;
    dataCarencia: Date = new Date();
    mostrarAcompanhamento = true;
    mensagemDeErro: string;
    bloquearSaldoEmprestimo: boolean;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {  
            this.isLoading = true;
            this.infoSaldoEmprestimo();
    }

    private infoSaldoEmprestimo() {
        forkJoin([this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldo(true),this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldoConcessao(true)])
        .subscribe(([saldo,saldoConcessao]) => {
            this.saldoEmprestimo = saldo;
            this.saldoEmprestimoConcessao = saldoConcessao;
            this.saldoEmprestimo.valorMaximoLiquido = this.saldoEmprestimoConcessao.valorMaximoLiquido < 0 ? 0 : this.saldoEmprestimoConcessao.valorMaximoLiquido;
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquearSaldoEmprestimo = true;
        this.isLoading = false;
    }
}
