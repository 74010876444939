import { Component, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';

import { GestaoFinanceiraEmprestimoServiceExtratoGetLiberacoesResponseItem } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-liberacoes-card',
    styleUrls: ['./liberacoes-card.component.css'],
    templateUrl: './liberacoes-card.component.html'
})
export class LiberacoesCardComponent implements OnInit {
    exibirTodos = false;
    isLoading = true;
    todasAsLiberacoesMenosAs3MostradasPrimeiro: GestaoFinanceiraEmprestimoServiceExtratoGetLiberacoesResponseItem[] = [];
    tresLiberacoesMostradasPrimeiro: GestaoFinanceiraEmprestimoServiceExtratoGetLiberacoesResponseItem[] = [];
    total = 0;

    get textoBotao() {
        if (this.exibirTodos) {
            return 'Ocultar';
        }

        return 'Exibir Todos';
    }

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        // this.gestaoFinanceiraEmprestimoService.Extrato_GetLiberacoes().subscribe(liberacoes => {
        //     this.todasAsLiberacoesMenosAs3MostradasPrimeiro = liberacoes;

        //     this.total =
        //         this.todasAsLiberacoesMenosAs3MostradasPrimeiro
        //             .map(liberacao => Number(liberacao.valor))
        //             .reduce((previous, current) => Number(previous || 0) + Number(current));

        //     this.tresLiberacoesMostradasPrimeiro = this.todasAsLiberacoesMenosAs3MostradasPrimeiro.slice(0, 3);
        //     this.todasAsLiberacoesMenosAs3MostradasPrimeiro.splice(0, 3);
        //     this.isLoading = false;
        // });
    }
}
