import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from '../header/header.module';
import { RodapeModule } from '../rodape/rodape.module';

//primeng
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
//import { CurrencyMaskModule } from 'ngx-currency-mask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';

import {
    PainelBdComponent,
    AcompanhamentoCardComponent,
    AcompanhamentoEmprestimoComponent,
    AcompanhamentoGerencialComponent,
    AtuariasComponent,
    AutopatrocinioComponent,
    BeneficioComponent,
    BpdComponent,
    CalendarioPagamentosCardComponent,
    CarenciasComponent,
    ComoFazerComponent,
    ConfirmacaoEmprestimoComponent,
    ContribuicaoComponent,
    ContribuicaoExtratoCardComponent,
    //dados cadastrais
    CadastroComponent,
    AlterarSenhaComponent,
    DadosParaDepositoCardComponent,
    DemonstrativoPagamentoCardComponent,
    DemonstrativoPagamentoComponent,
    DependentesComponent,
    DesignadosSeguroDeVidaTabComponent,
    TelaInssComponent,

    DownloadContratoCardComponent,
    EmprestimoConfirmadoComponent,
    EmprestimoNegadoComponent,
    ExtratoAtasComponent,
    ExtratoCardComponent,
    ExtratoSidebarComponent,
    FichaFinanceiraComponent,
    InicioComponent,
    InformacoesCardComponent,
    InformacoesAdicionaisEmprestimoComponent,
    InformeRendimentoComponent,
    LiberacoesCardComponent,
    MorteBeneficiarioComponent,
    NormasComponent,
    PedidoEmprestimoComponent,
    PensaoComponent,
	BeneficioAbonoAnualComponent,
    PerfilComunicacaoComponent,
    PerfilComunicacaoFormComponent,
    PerfilComunicacaoInformacoesComplementaresComponent,
    PortabilidadeComponent,
    PrevisaoAposentadoriaComponent,
    ReclusaoComponent,
    RegulamentoComponent,
    ResgateComponent,
    SaldoEmprestimoComponent,
    SeguroVidaComponent,
    SimuladorEmprestimoComponent,
    SituacaoCardComponent,
    TelaContribuicaoComponent,
    TelaFibraNumerosComponent,
    TelaPrevisaoAposentadoriaComponent,
    TelaTvfibraComponent,
    TempoEspecialComponent,
    TempoIdadeComponent,
    TempoInvalidezComponent,
    TempoServicoComponent,
    TelaPpdvComponent,
    TemporarioMorteComponent,
    //VestingComponent,
    SaldoIrComponent,
	//fibra numeros
    PlanosCardComponent,
    ReajusteBeneficioCardComponent,
    //informacoes complementares
    TextoInformacoesComplementaresComponent,
    //inicio
    PrevisaoAposentadoriaBpsComponent,
    //extrato bps
    ExtratoBpsComponent,
    ExtratoBpsCardComponent,
    //participantes e beneficiarios
    ParticipantesBeneficiariosComponent,
    RentabilidadeComponent,
    RentabilidadeCardComponent
} from '.';

import { AutoCompleteLogradouroComponent } from './dados-cadastrais/cadastro/auto-complete-logradouro/auto-complete-logradouro.component';
import { AlteracaoPercentualCardComponent } from './emprestimo/acompanhamento-emprestimo/alteracao-percentual-card/alteracao-percentual-card.component';
import { InfoPgtoExtraComponent } from './emprestimo/acompanhamento-emprestimo/info-pgto-extra/info-pgto-extra.component';
import { BloqueioCarteiraEmprestimoComponent } from './emprestimo/bloqueio-carteira-emprestimo/bloqueio-carteira-emprestimo.component';
import { ConfirmacaoTokenComponent } from './emprestimo/confirmacao-emprestimo/confirmacao-token/confirmacao-token.component';
import { PagamentosEmprestimoComponent } from './emprestimo/pagamentos-emprestimo/pagamentos-emprestimo.component';
import { AmortizacaoCardComponent } from './emprestimo/pagamentos-emprestimo/amortizacao-card/amortizacao-card.component';
import { CarenciaCardComponent } from './emprestimo/pagamentos-emprestimo/carencia-card/carencia-card.component';
import { ContratoCardComponent } from './emprestimo/acompanhamento-emprestimo/contrato-card/contrato-card.component';
import { BaixarAppComponent } from './baixar-app/baixar-app.component';
import { TimeLineComponent } from './emprestimo/acompanhamento-emprestimo/time-line/time-line.component';
import { PlanoContinuidadeDeNegocioComponent } from './plano-continuidade-de-negocio/plano-continuidade-de-negocio.component';
import { ModalTokenComponent } from './emprestimo/acompanhamento-emprestimo/alteracao-percentual-card/modal-token';
import { IdentificacaoPlanoComponent } from './identificacao-plano/identificacao-plano.component';
import { SolicitacaoDescontoFolhaComponent } from './familia-itaipu/solicitacao-desconto-folha/solicitacao-desconto-folha.component';
import { InscricaoBeneficiarioComponent } from './solicitacoes/inscricao-beneficiario/inscricao-beneficiario.component';
import { DeclaracaoDependenteIrComponent } from './solicitacoes/declaracao-dependente-ir/declaracao-dependente-ir.component';
import { BeneficioAuxilioFuneralComponent } from './solicitacoes/beneficio-auxilio-funeral/beneficio-auxilio-funeral.component';
import { SuplementacaoAposentadoriaComponent } from './solicitacoes/suplementacao-aposentadoria/suplementacao-aposentadoria.component';
import { SuspensaoContribuicaoComponent } from './solicitacoes/suspensao-contribuicao/suspensao-contribuicao.component';
import { NomeacaoDesignadoComponent } from './solicitacoes/nomeacao-designado/nomeacao-designado.component';
import { ContratoAntecipacaoBeneficioComponent } from './solicitacoes/contrato-antecipacao-beneficio/contrato-antecipacao-beneficio.component';
import { DeclaracaoTempoServicoComponent } from './solicitacoes/declaracao-tempo-servico/declaracao-tempo-servico.component';
import { ExclusaoBeneficiarioComponent } from './solicitacoes/exclusao-beneficiario/exclusao-beneficiario.component';

import { CertificadoInscricaoComponent } from './certificado-inscricao/certificado-inscricao.component';
import { SuplementacaoPensaoComponent } from './solicitacoes/suplementacao-pensao/suplementacao-pensao.component';

import { ProrrogacaoAdiantamentoParcialComponent } from './solicitacoes/prorrogacao-adiantamento-parcial/prorrogacao-adiantamento-parcial.component';
import { DocSaldamentoPlanoCDComponent } from './doc-saldamento-plano-cd/doc-saldamento-plano-cd.component';

import { DashboardBdRoutingModule } from './painel-bd-routing.module';
import { SharedModule } from '@fibra/fibra-shared-lib';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
    AuthGuardService, RoleGuardService,
    AssistidoGuardService,
    AtivoGuardService, FibraGuardService
} from '@fibra/fibra-shared-lib';

import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { SpinnerModule } from 'primeng/spinner';
import { ComponentsModule } from '../../components/components.module';
import { SignWidgetComponent } from '../../components/clicksign/sign-widget/sign-widget.component';

@NgModule({
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ComponentsModule,
        RodapeModule,
        HeaderModule,
        BrowserAnimationsModule,
        BrowserModule,
        // CarouselModule,
        ChartModule,
        AutoCompleteModule,
        CheckboxModule,
        //CurrencyMaskModule,
        DashboardBdRoutingModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        NgxLoadingModule,
        NgxMaskModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        RadioButtonModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        SharedModule,
        SidebarModule,
        SliderModule,
        TableModule,
        PaginatorModule,
        TooltipModule,
        OverlayPanelModule,
        CalendarModule,
        InputMaskModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        FileUploadModule,
        CardModule,
        SpinnerModule
    ],
    declarations: [
        PainelBdComponent,
        AcompanhamentoCardComponent,
        AcompanhamentoEmprestimoComponent,
        AcompanhamentoGerencialComponent,
        AtuariasComponent,
        AutopatrocinioComponent,
        BeneficioComponent,
        BpdComponent,
        CalendarioPagamentosCardComponent,
        CarenciasComponent,
        ComoFazerComponent,
        ConfirmacaoEmprestimoComponent,
        ContribuicaoComponent,
        ContribuicaoExtratoCardComponent,
        SolicitacaoDescontoFolhaComponent,
        InscricaoBeneficiarioComponent,
        DeclaracaoDependenteIrComponent,
        BeneficioAuxilioFuneralComponent,
        SuplementacaoAposentadoriaComponent,
        SuspensaoContribuicaoComponent,
        NomeacaoDesignadoComponent,
        ContratoAntecipacaoBeneficioComponent,
        DeclaracaoTempoServicoComponent,
        ExclusaoBeneficiarioComponent,
        //dados cadastrais
        CadastroComponent,
        AlterarSenhaComponent,
        DadosParaDepositoCardComponent,
        DemonstrativoPagamentoCardComponent,
        DemonstrativoPagamentoComponent,
        DependentesComponent,
        DesignadosSeguroDeVidaTabComponent,
        TelaInssComponent,

        DownloadContratoCardComponent,
        EmprestimoConfirmadoComponent,
        EmprestimoNegadoComponent,
        ExtratoAtasComponent,
        ExtratoCardComponent,
        ExtratoSidebarComponent,
        FichaFinanceiraComponent,
        InicioComponent,
        InformacoesCardComponent,
        InformacoesAdicionaisEmprestimoComponent,
        InformeRendimentoComponent,
        InfoPgtoExtraComponent,
        LiberacoesCardComponent,
        MorteBeneficiarioComponent,
        NormasComponent,
        PedidoEmprestimoComponent,
        PensaoComponent,
		BeneficioAbonoAnualComponent,
        PerfilComunicacaoComponent,
        PerfilComunicacaoFormComponent,
        PerfilComunicacaoInformacoesComplementaresComponent,
        PortabilidadeComponent,
        PrevisaoAposentadoriaComponent,
        ReclusaoComponent,
        RegulamentoComponent,
        ResgateComponent,
        SaldoEmprestimoComponent,
        SeguroVidaComponent,
        SimuladorEmprestimoComponent,
        SituacaoCardComponent,
        TelaContribuicaoComponent,
        TelaFibraNumerosComponent,
        TelaPrevisaoAposentadoriaComponent,
        TelaTvfibraComponent,
        TelaPpdvComponent,
        TempoEspecialComponent,
        TempoIdadeComponent,
        TempoInvalidezComponent,
        TempoServicoComponent,
        TemporarioMorteComponent,
        TimeLineComponent,
        //VestingComponent,
        BloqueioCarteiraEmprestimoComponent,
        InformacoesAdicionaisEmprestimoComponent,
        ConfirmacaoTokenComponent,
        ModalTokenComponent,
        PagamentosEmprestimoComponent,
        AmortizacaoCardComponent,
        CarenciaCardComponent,
        AutoCompleteLogradouroComponent,
        BaixarAppComponent,
        ContratoCardComponent,
        SaldoIrComponent,
        AlteracaoPercentualCardComponent,
        PlanoContinuidadeDeNegocioComponent,
        IdentificacaoPlanoComponent,
        CertificadoInscricaoComponent,
        SuplementacaoPensaoComponent,
        ProrrogacaoAdiantamentoParcialComponent,
        DocSaldamentoPlanoCDComponent,
        PlanosCardComponent,
        ReajusteBeneficioCardComponent,
        PrevisaoAposentadoriaBpsComponent,

        //extrato bps
        ExtratoBpsComponent,
        ExtratoBpsCardComponent,
        //participantes e beneficiarios
        ParticipantesBeneficiariosComponent,
        //informacoes complementares
        TextoInformacoesComplementaresComponent,
        RentabilidadeComponent,
        RentabilidadeCardComponent
    ],
    providers: [
        AuthGuardService,
        RoleGuardService,
        AtivoGuardService,
        FibraGuardService,
        AssistidoGuardService,
        MessageService
    ]
})
export class PainelBdModule { }
