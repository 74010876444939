import { Component, OnInit, Input } from '@angular/core';
import { FibraNumerosModel, GestaoPlanoBiService } from '@fibra/fibra-shared-lib';
import { formatCurrency } from '@angular/common';

@Component({
    selector: 'app-tela-fibra-numeros',
    templateUrl: './tela-fibra-numeros.component.html',
    styleUrls: ['./tela-fibra-numeros.component.css']
})
export class TelaFibraNumerosComponent implements OnInit {
    @Input() flag: boolean;

    dataBar: any;
    dataEfetivo: any;
    dataFixa: any;
    dataOutros: any;
    dataTotal: any;
    dataVariavel: any;
    optionsWithCurrency: any;
    options: any;
    isLoading = true;
    pontomedio = 0;
    fibraNumerosModel: FibraNumerosModel;
    totalDeParticipantes: number;
    mensagemDeErro: string;
    bloquear: boolean;
    posicao: string;
    posicaoEquilibrioPerc: string;

    constructor(private gestaoPlanoBiService: GestaoPlanoBiService) { }

    ngOnInit() {
        this.carregarFibraNumeros();
        this.getOption();
        this.getOptionsWithCurrency();
    }

    getOption() {
        this.options = {
            responsive: false,
            maintainAspectRatio: true,
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        let val = data['datasets'][0]['data'][tooltipItem['index']];

                        val = formatCurrency(val, 'pt-BR', 'R$');

                        return val;
                    },
                }
            },
            hover: {
                animationDuration: 0
            },
            title: {
                display: false
            }

        };
    }

    getOptionsWithCurrency() {
        this.optionsWithCurrency = {
            responsive: false,
            maintainAspectRatio: true,
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        let val = data['datasets'][0]['data'][tooltipItem['index']];
                        val = ' ' + val;
                        return val;
                    },
                }
            },
            hover: {
                animationDuration: 0
            },
            title: {
                display: false
            }

        };
    }

    private carregarFibraNumeros() {
        this.gestaoPlanoBiService.BI_GetFibraNumeros().subscribe(fibraNumeros => {
            this.isLoading = true;
            this.fibraNumerosModel = fibraNumeros;
            this.dataTotal = {
                labels: ['Renda fixa', 'Renda variável', 'Outros'],
                datasets: [
                    {
                        data: [
                            this.fibraNumerosModel.rendaFixa,
                            this.fibraNumerosModel.rendaVariavel,
                            this.fibraNumerosModel.outros
                        ],
                        backgroundColor: [
                            '#e4ba2d',
                            '#6ba481',
                            '#0d74bf'
                        ],
                        hoverBackgroundColor: [
                            '#e4ba2d',
                            '#6ba481',
                            '#0d74bf'
                        ],
                    }
                ]
            };

            this.dataFixa = {
                labels: ['Renda fixa', 'Renda variável', 'Outros'],
                datasets: [
                    {
                        data: [
                            this.fibraNumerosModel.rendaFixa,
                            this.fibraNumerosModel.rendaVariavel,
                            this.fibraNumerosModel.outros
                        ],
                        backgroundColor: [
                            '#e4ba2d',
                            '#bebebe',
                            '#bebebe'
                        ],
                        hoverBackgroundColor: [
                            '#e4ba2d',
                            '#bebebe',
                            '#bebebe'
                        ]
                    }
                ]
            };

            this.dataVariavel = {
                labels: ['Renda fixa', 'Renda variável', 'Outros'],
                datasets: [
                    {
                        data: [
                            this.fibraNumerosModel.rendaFixa,
                            this.fibraNumerosModel.rendaVariavel,
                            this.fibraNumerosModel.outros
                        ],
                        backgroundColor: [
                            '#bebebe',
                            '#6ba481',
                            '#bebebe'
                        ],
                        hoverBackgroundColor: [
                            '#bebebe',
                            '#6ba481',
                            '#bebebe'
                        ]
                    }
                ]
            };

            this.dataOutros = {
                labels: ['Renda fixa', 'Renda variável', 'Outros'],
                datasets: [
                    {
                        data: [
                            this.fibraNumerosModel.rendaFixa,
                            this.fibraNumerosModel.rendaVariavel,
                            this.fibraNumerosModel.outros
                        ],
                        backgroundColor: [
                            '#bebebe',
                            '#bebebe',
                            '#0d74bf'
                        ],
                        hoverBackgroundColor: [
                            '#bebebe',
                            '#bebebe',
                            '#0d74bf'
                        ]
                    }
                ]
            };

            this.dataBar = {
                labels: ['a', 'a', 'a', 'a'],
                datasets: [
                    {
                        label: 'Min',
                        backgroundColor: '#b71b0c',
                        data: [3],
                    },
                    {
                        backgroundColor: '#ff7c6d',
                        data: [15]
                    },
                    {
                        backgroundColor: '#6ac7ff',
                        data: [15]
                    },
                    {
                        label: 'Max',
                        backgroundColor: '#007ab8',
                        data: [3]
                    }
                ]
            };

            this.dataEfetivo = {
                labels: ['Aposentados', 'Pensionistas', 'Ativos'],
                datasets: [
                    {
                        data: [
                            this.fibraNumerosModel.qtdAponsentados,
                            this.fibraNumerosModel.qtdPensionistas,
                            this.fibraNumerosModel.qtdParticipantes
                        ],
                        backgroundColor: [
                            '#e4ba2d',
                            '#6ba481',
                            '#0d74bf'
                        ]
                    }
                ]
            };
            this.totalDeParticipantes = this.fibraNumerosModel.qtdAponsentados
                + this.fibraNumerosModel.qtdParticipantes + this.fibraNumerosModel.qtdPensionistas;
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
            ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }

    calcularValorRange(): number {
        if (!this.fibraNumerosModel || this.fibraNumerosModel.zonaEquilibrio === null) {
            return 50; 
        }

        const zonaEquilibrioMin = this.fibraNumerosModel.zonaEquilibrioMin || 0;
        const zonaEquilibrioMax = this.fibraNumerosModel.zonaEquilibrioMax || 0;
        const zonaEquilibrio = this.fibraNumerosModel.zonaEquilibrio || 0;

        const rangeCalculado = ((zonaEquilibrio - zonaEquilibrioMin) / (zonaEquilibrioMax - zonaEquilibrioMin)) * 100;

        return Math.max(0, Math.min(rangeCalculado, 100)) + 15;
    }

}

