import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { DadosCadastraisService, HEADERS } from "@fibra/fibra-shared-lib";
import { AuthService } from "@fibra/fibra-shared-lib";
import { Observable, of } from "rxjs"
import { tap } from "rxjs/operators"

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService,
        private dadosCadastraisService: DadosCadastraisService) {
    }

    private cache: Map<string, HttpResponse<any>> = new Map();
    private expires: Map<string, Date> = new Map();

    // 300000ms = 5min
    // 1800000ms = 30min
    cachePath: Array<{ path: string, expiresTimeMs: number }> = [
        { path: 'Bloqueio/GetBloqueioSimulacaoEmprestimo', expiresTimeMs: 300000 },
        { path: 'PercentuaisApos/GetPrevisoesApos', expiresTimeMs: 300000 },
        { path: 'PercentuaisApos/GetCarenciaApos', expiresTimeMs: 300000 },
        { path: 'TempoDeServico/GetInss', expiresTimeMs: 300000 },
        { path: 'Contrato/GetSituacaoAtual', expiresTimeMs: 300000 },
        { path: 'Contrato/GetValoresSimulacaoEmprestimo', expiresTimeMs: 300000 },
        { path: 'Download/GetListSeDocs', expiresTimeMs: 300000 },
        { path: 'Contrato/GetPagamentos', expiresTimeMs: 300000 },
        { path: 'emprestimo/Contrato', expiresTimeMs: 300000 },
        { path: 'Extrato/ItensExtratoPorContrato', expiresTimeMs: 300000 },
        { path: 'Contrato/GetHistoricoSituacaoContratoPendente', expiresTimeMs: 300000 },
        { path: 'Download/GetExtratoBps', expiresTimeMs: 300000 },
        { path: 'api/v1/pessoas-politicamente-expostas', expiresTimeMs: 1800000 },
        
        //#region Não utilizar cache nesses endpoint
        // { path: 'CadastroSinqia/GetBeneficiarios', expiresTimeMs: 300000 },
        // { path: 'CadastroSinqia/GetDesignados', expiresTimeMs: 300000 },
        //#endregion
    ];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let cachePath = this.cachePath.find(x => req.url.includes(x.path));
        let isAuthenticated = this.authService.isAuthenticated;
        let dadosCad = this.dadosCadastraisService.getCadastroGeralLocal();
        let idAdesao = '';
        if (req.headers.has(HEADERS.ADESAO_ID)) {
            idAdesao = req.headers.get(HEADERS.ADESAO_ID);
        }

        if (!cachePath || req.method !== "GET" || !isAuthenticated || dadosCad == null) {
            return next.handle(req);
        }

        let cacheId = `${req.url}-${dadosCad.id}-${dadosCad.cpf}-${idAdesao}`;

        let expired = false;
        if (this.expires.has(cacheId)) {
            let expires = this.expires.get(cacheId) as Date;
            expired = expires < new Date();
        }

        if (req.headers.get("reset") || expired) {
            this.cache.delete(cacheId)
        }
        const cachedResponse = this.cache.get(cacheId)
        if (cachedResponse) {
            return of(cachedResponse.clone())
        } else {
            return next.handle(req).pipe(
                tap(stateEvent => {
                    if (stateEvent instanceof HttpResponse) {
                        this.cache.set(cacheId, stateEvent.clone());
                        let expiresDate = new Date();
                        let expiresTimeMs = cachePath.expiresTimeMs;
                        expiresDate.setMilliseconds(expiresDate.getMilliseconds() + expiresTimeMs);
                        this.expires.set(cacheId, expiresDate);
                    }
                })
            );
        }
    }
}