export class GraphUserProfile {
    businessPhones: any[];
    displayName: string;
    givenName?: any;
    jobTitle: string;
    mail: string;
    mobilePhone?: any;
    officeLocation?: any;
    preferredLanguage?: any;
    surname?: any;
    userPrincipalName: string;
    id: string;
}



