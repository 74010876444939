export const environment = {
    production: false,

    API_LOGIN: 'https://apihom.fundacaoitaipu.com.br/hom/login',
    API_GESTAO_PLANO_CADASTRO: 'https://apihom.fundacaoitaipu.com.br/hom/cadastro',
    API_EMPRESTIMO: 'https://apihom.fundacaoitaipu.com.br/hom/emprestimo/',
    API_GESTAO_PLANO_BENEFICIO: 'https://apihom.fundacaoitaipu.com.br/hom/beneficio',
    API_CONTRIBUICAO: 'https://apihom.fundacaoitaipu.com.br/hom/contribuicao',
    API_GESTAO_PLANO_BI: 'https://apihom.fundacaoitaipu.com.br/hom/bi',
    API_GESTAO_RELACIONAMENTO_COMUNICACAO: 'https://apihom.fundacaoitaipu.com.br/hom/comunicacao',
    API_DOCUMENTOS: 'https://apihom.fundacaoitaipu.com.br/hom/documentos',
    URL_DO_AMBIENTE: 'https://apihom.fundacaoitaipu.com.br',
    URL_AUTENTICACAOAD: 'https://apihom.fundacaoitaipu.com.br/hom/login/GetAdUser',
    URL_API_TV_FIBRA: 'https://www.fundacaoitaipu.com.br/app/tv',
    API_LOG: 'https://apihom.fundacaoitaipu.com.br/hom/admin',
    BASE_URL_CONTRIBUICAO_DEFINIDA: 'https://apihom.fundacaoitaipu.com.br/hom/previdencia/cd',
    KEY_CLIENT_ID: '4ce66a42-d836-41e3-b88e-baf2af9d5298',
    MSAL_AUTHORITY: 'https://login.microsoftonline.com',
    KEY_TENANT_ID: '6f1486b7-7393-4f00-baf1-6718c254b2e6',
    COOKIE_DOMAIN: 'https://hom-aa.fundacaoitaipu.com.br',

    CLICK_SIGN_CONFIG: {
        endpoint: 'https://sandbox.clicksign.com',
        origin: 'https://hom-aa.fundacaoitaipu.com.br',
    },
    GOOGLE_SETTINGS: {
        recaptchaSiteKey: '6LfbyTYqAAAAAG0IZTqI2NaCbFspHTVTl-YcfNsz'
    }
};

