export class GestaoPlanoBeneficioDemostrativoPagamento {
    tipoConcessao: string;
    itensPagamentos: ItensPagamento[];
}

export interface ItensPagamento {
    dataReferencia: Date;
    dataPagamento: Date;
    valorBruto: number;
    valorLiquido: number;
    tipoFolha: string;
}
