import { BeneficiarioDto } from "./beneficiario.dto";
import { ModeloContribuicaoDto } from "./modelo-contribuicao.dto";
import { ContaBancariaDto } from "./conta-bancaria.dto";
import { PessoaDto } from "./pessoa.dto";

export class AdesaoDto {
    id?: number;
    concordoTermo?: boolean;
    contribuicaoBasica?: number;
    valorPortabilidade?: number;
    //tipoRegimeTributario?: number;
    tipoAdesao?: number;
    pessoa?: PessoaDto;
    contaBancaria?: ContaBancariaDto;
    modeloContribuicao?: ModeloContribuicaoDto;
    beneficiarios?: BeneficiarioDto[];

}

