import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AdesaoService } from '@fibra/fibra-shared-lib';
import { Observable } from 'rxjs';

/**
 * Verifica se a adesão do contexto está condizente com o contexto da URL
 */
@Injectable({
    providedIn: 'root'
})
export class AdesaoContextoGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private adesaoService: AdesaoService) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Define your criteria to skip the validation
        const shouldSkipValidation = this.checkSkipCriteria(route);
        if (shouldSkipValidation) {
            return true;
        }

        let contextoAdesaoDefinido = this.adesaoService.verificarAdesaoContextoUrl(state.url);
        if (!contextoAdesaoDefinido) {
            this.router.navigate(['/painel'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }

    private checkSkipCriteria(route: ActivatedRouteSnapshot): boolean {
        return route.queryParams['bypass-rule'] == '1';
    }
}
