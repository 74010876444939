import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { HttpHeaderService } from '@fibra/fibra-shared-lib';

@Injectable()
export class AdesaoContextoInterceptor implements HttpInterceptor {

    constructor(private httpHeaderService: HttpHeaderService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        let headers: HttpHeaders = request.headers;

        headers = this.httpHeaderService.addHeadersAdesao(headers);
        request = req.clone({
            headers
        });

        return next.handle(request);
    }
}