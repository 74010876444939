import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { SideMenuConfig } from '../side-menu/side-menu-config';
import { SideMenuItem } from '../../models';

@Component({
    selector: 'app-breadcrumb',
    styleUrls: ['./breadcrumbs.component.css'],
    templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent {
    breadcrumbs: string[] = [];
    urlAtiva: string;

    constructor(router: Router) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.breadcrumbs = this.construirBreadcrumbs();
            }
        });
    }

    private construirBreadcrumbs() {
        this.urlAtiva = location.pathname.replace('/painel/', '');
        return this.obterCaminhoDestaTelaPeloMenuLateral(SideMenuConfig);
    }

    private obterCaminhoDestaTelaPeloMenuLateral(menu: SideMenuItem[]) {
        let item: SideMenuItem;
        let caminho: string[];

        for (let i = 0; i < menu.length; i++) {
            item = menu[i];

            if (item.RouterLink && item.RouterLink === this.urlAtiva) {
                return [item.Name];
            }

            if (item.Items && item.Items.length > 0) {
                caminho = this.obterCaminhoDestaTelaPeloMenuLateral(item.Items);

                if (caminho.length > 0) {
                    return [item.Name, ...caminho];
                }
            }
        }

        return [];
    }
}
