export function removerAcentos(texto) {
    return texto
        .replace(/[ÁÀÂÃÄ]/g, 'A')
        .replace(/[áàâãä]/g, 'a')
        .replace(/[ÉÈÊË]/g, 'E')
        .replace(/[éèêë]/g, 'e')
        .replace(/[ÍÌÎÏ]/g, 'I')
        .replace(/[íìîï]/g, 'i')
        .replace(/[ÓÒÔÕÖ]/g, 'O')
        .replace(/[óòôõö]/g, 'o')
        .replace(/[ÚÙÛÜ]/g, 'U')
        .replace(/[úùûü]/g, 'u')
        .replace(/[Ç]/g, 'C')
        .replace(/[ç]/g, 'c')
        .replace(/[Ñ]/g, 'N')
        .replace(/[ñ]/g, 'n');
}
