import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-previsao-aposentadoria-bps',
    styleUrls: ['./previsao-aposentadoria-bps.component.css'],
    templateUrl: './previsao-aposentadoria-bps.component.html'
})
export class PrevisaoAposentadoriaBpsComponent implements OnInit {
    isLoading = true;
    
    ngOnInit() {
        this.isLoading = false;
    }
}
