import * as CryptoJS from 'crypto-js';

/**
 * Base Storage service for Storage management.
 */
export abstract class BaseStorageService {
    private APP_DATA_SECRET_KEY = '94872225-2275-40eb-8b55-6575913dd43b';
    protected storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
    }

    encrypt(data: string) {
        let dataEncrypted = CryptoJS.AES.encrypt(data, this.APP_DATA_SECRET_KEY);
        return dataEncrypted.toString();
    }

    decrypt(data: string): string {
        let dataDecrypted = CryptoJS.AES.decrypt(data, this.APP_DATA_SECRET_KEY);
        return dataDecrypted.toString(CryptoJS.enc.Utf8);
    }

    set(key: string, value: string): boolean {
        if (this.storage) {
            this.storage.setItem(key, value);
            return true;
        }
        return false;
    }

    setSecure(key: string, value: string) {
        let data = this.encrypt(value);
        this.set(key, data);
    }

    setAnyAsJsonSecure(key: string, value: any) {
        let valueJson = JSON.stringify(value);
        this.setSecure(key, valueJson);
    }

    getSecure(key: string): string | null {
        let data = this.get(key);
        let dataDecrypted: string | null = null;
        if (data != null) {
            dataDecrypted = this.decrypt(data);
        }
        return dataDecrypted;
    }

    getSecureParse<TData>(key: string): TData | null {
        let data = this.get(key);
        if (data != null) {
            let dataDecrypted = this.decrypt(data);
            return JSON.parse(dataDecrypted) as TData;
        }
        return null;
    }

    get(key: string): string | null {
        if (this.storage) {
            let dataString = this.storage.getItem(key) as string;
            return dataString;
        }
        return null;
    }

    getParse<TData>(key: string): TData | null {
        if (this.storage) {
            let dataString = this.storage.getItem(key) as string;
            if (dataString) {
                return JSON.parse(dataString) as TData;
            }
            return null;
        }
        return null;
    }

    remove(key: string): boolean {
        if (this.storage) {
            this.storage.removeItem(key);
            return true;
        }
        return false;
    }

    any(key: string): boolean {
        return this.storage && this.storage.getItem(key) != null;
    }

    clear(): boolean {
        if (this.storage) {
            this.storage.clear();
            return true;
        }
        return false;
    }
}