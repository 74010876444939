import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-factor-auth-sms',
  templateUrl: './two-factor-auth-sms.component.html',
  styleUrls: ['./two-factor-auth-sms.component.css']
})
export class TwoFactorAuthSmsComponent implements OnInit {
  @Input() phoneNumber: string | null = null;

  constructor() { }

  ngOnInit() {
  }

}
