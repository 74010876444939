export const TipoBeneficio = {
    supAposentadoriaTempoContribuicao: 1,
    supAposentadoriaTempoContribuicaoDesvinculadoINSS: 2,
    supAposentadoriaIdade: 3,
    supAposentadoriaEspecial: 4,
    supAposentadoriaInvalidez: 5,
    supPensao: 6,
    beneficioEspecialTemporarioMorte: 7,
    supPensaoConversao: 8,
    auxilioReclusao: 9
}