import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header.component";
import { InputMaskModule } from 'primeng/inputmask';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		HeaderComponent
	],
	imports: [
		CommonModule,
		InputMaskModule,
		NgxMaskModule
	],
	exports:[
		HeaderComponent
	]
})
export class HeaderModule{}