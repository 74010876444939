import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-alterar-senha-interno-cd',
    templateUrl: './alterar-senha.component.html'
})
export class AlterarSenhaComponent implements OnInit {

    constructor() { }

    ngOnInit() { }
}
