import { Component, OnInit } from '@angular/core';
import {
    AdesaoService,
    AuthService,
    DadosCadastraisService,
    ESituacaoParticipante,
    GestaoPlanoBeneficioService,
    GestaoPlanoCadastroService,
} from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-inicio-cd',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.css']
})
export class InicioCdComponent implements OnInit {
    ativo: boolean = false;
    papel: string[] = [];
    showEmprestimo = true;
    showSimulador = true;
    showBeneficio = true;
    showFibraNumeros = true;

    constructor(
        private authService: AuthService, private adesaoService: AdesaoService
    ) { }

    ngOnInit() {
        let adesao = this.adesaoService.getAdesaoContexto();
        this.ativo = this.adesaoService.validarSituacao(ESituacaoParticipante.Ativo, adesao.tipoSituacao);
    }

    beneficioHidden () {
        this.showBeneficio = false;
        console.log("beneficio hidden");
    }
}
