import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CD_ROUTES } from './painel-cd.routes';

@NgModule({
    imports: [
        RouterModule.forChild(CD_ROUTES)
    ],
    exports: [RouterModule]
})
export class DashboardCdRoutingModule { }
