export const TELEFONE = {
    ERROR_MESSAGE: 'O Telefone deve conter 10 números',
    LENGTH_MAX_MASK: 14,
    LENGTH_MIN_MASK: 10,
    MASK: '(00) 0000-0000'
};

export const CELULAR = {
    ERROR_MESSAGE: 'O Celular deve conter 11 números',
    LENGTH_MAX_MASK: 16,
    LENGTH_MIN_MASK: 10,
    MASK: '(00) 00000-0000'
};

export const TELEFONE_DDI = {
    ERROR_MESSAGE: 'O Telefone deve conter 12 números',
    LENGTH_MAX_MASK: 17,
    LENGTH_MIN_MASK: 12,
    MASK: '+00 (00) 0000-0000'
};

export const CELULAR_DDI = {
    ERROR_MESSAGE: 'O Celular deve conter 13 números',
    LENGTH_MAX_MASK: 19,
    LENGTH_MIN_MASK: 13,
    MASK: '+00 (00) 0-0000-0000'
};

export const CEP = {
    ERROR_MESSAGE: 'CEP invalido!',
    REGEX: '',
    LENGTH: 8,
    MASK: '00000-000'
};
