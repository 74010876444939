import { Component, OnInit } from '@angular/core';
import { LinksIntegracao, ArquivosService, NomeArquivos } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-plano-continuidade-de-negocio',
  templateUrl: './plano-continuidade-de-negocio.component.html',
  styleUrls: ['./plano-continuidade-de-negocio.component.css']
})
export class PlanoContinuidadeDeNegocioComponent implements OnInit {

    readonly OpenExternalURL = LinksIntegracao;
    readonly NomeArquivo = NomeArquivos.GUIA_ACESSO_REMOTO;
    readonly Nome = 'Guia para acesso remoto';
    isLoading = false;
    regFibra: number;
    isModalVisible = false;
    errorMessage: string;

    constructor(private arquivosService: ArquivosService) { }

      ngOnInit() {}

      openExternalURL(link: string) {
          if (link) {
           window.open(link, '_brank');
          }
      }

      handleError(error: any, mensagem: string) {
          console.log(error);
          this.isLoading = false;
          this.isModalVisible = true;
          this.errorMessage = mensagem ? mensagem : 'Houve um erro em sua requisição. Por favor contate o suporte.';
      }

      downloadFile(nomeArquivo: string, nomeSecao: string) {
        this.arquivosService.getArquivoByNome(nomeArquivo, () => {
        }, this);
    }
}
