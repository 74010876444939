import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icone-sucesso-ou-erro',
    styles: [`
        i {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    `],
    template: `
        <i *ngIf="!sucesso"
            class="fa fa-exclamation-triangle text-danger"></i>
        <i *ngIf="sucesso"
            class="fa fa-thumbs-o-up text-success"></i>
    `
})
export class IconeSucessoOuErroComponent {
    @Input() sucesso: boolean;
}
