import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DadosCadastraisSinqiaDto } from '../models/sinqia-api/dados-cadastrais-sinqia.dto';
import { BasicTokenData, GestaoPlanoCadastroServiceCadastrosGetResponse } from '../models';
import { ResumoAdesaoDto } from '../models/sinqia-api/resumo-adesao.dto';
import { TwoFactorAuthTokenModel } from '../models/fibra-identity/two-factor-auth-token.model';
import { DadosCadastraisService } from '../services/dados-cadastrais.service';
import { AuthCdService } from './contribuicao-definida/auth-cd.service';
import { STORAGE_KEYS } from '../constants';

@Injectable()
export class SessionInitService {
    _isTermAccepted: boolean;
    existeRoleAprovarDesaprovar: string;
    twoFactorAuthKey: string = '2fa_token';

    constructor(private router: Router,
        private dadosCadastraisService: DadosCadastraisService,
        private authCdService: AuthCdService) { }

    public get plano() {
        var paths = this.router.url;
        if (paths.indexOf('/painel/plano-cd') == 0)
            return 'cd';
        if (paths.indexOf('/painel/plano-cd') == 0)
            return 'bd';
        return '';
    }

    sessionInit(tokenData: BasicTokenData, carregarDadosCadastrais: boolean = true) {
        return new Promise((resolve, reject) => {
            this.existeRoleAprovarDesaprovar = !tokenData.existePendenciaAprovacao ? "false"
                : tokenData.existePendenciaAprovacao.toString();

            sessionStorage.setItem('accessToken', `Bearer ${tokenData.access_token}`);
            sessionStorage.setItem('tokenExpirationDate', tokenData.expires);
            sessionStorage.setItem('existePendenciaAprovacao', this.existeRoleAprovarDesaprovar);

            let tokenCdPromise = this.authCdService.getHandshakeToken(tokenData.access_token)
            let promises = [tokenCdPromise];
            if (carregarDadosCadastrais) {
                let dadosCadPromise = this.dadosCadastraisService.carregarDadosCadastrais(tokenData.access_token);
                promises.push(dadosCadPromise);
                let dadosPerfilPromise = this.dadosCadastraisService.carregarDadosDoPerfil(tokenData.access_token);
                promises.push(dadosPerfilPromise);
            }

            Promise.all(promises)
                .then(results => {
                    const success = !results.includes(false);
                    resolve(success);
                })
                .catch(error => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    sessionInitPerfil(data: any) {
        sessionStorage.setItem(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL, data);
    }

    sessionPerfil(): {
        usuario: string,
        anotacoes: Array<{ chave: string, nota: string, criadoEm: string }>
    } {
        var sessionItem = this.dadosCadastraisService.obterPerfil();
        if (sessionItem == null)
            return {
                usuario: '',
                anotacoes: []
            };
        return sessionItem;
    }

    encerrarSessionPerfil() {
        return sessionStorage.remove(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL);
    }

    sessionInitIntegracao(tokenData: BasicTokenData) {
        sessionStorage.setItem('accessTokenIntegracao', `Bearer ${tokenData.access_token}`);
    }

    sessionInitDadosCadastrais(dadosCadastrais: GestaoPlanoCadastroServiceCadastrosGetResponse) {
        sessionStorage.setItem('dadosCadastrais', JSON.stringify(dadosCadastrais));
    }

    sessionGetDadosCadastrais(): GestaoPlanoCadastroServiceCadastrosGetResponse {
        const dadosCadastrais = sessionStorage.getItem('dadosCadastrais');
        return JSON.parse(dadosCadastrais);
    }

    sessionInitTermAccepted(hasTerm: boolean) {
        sessionStorage.setItem('ADERIU_TERMO_ADESAO', JSON.stringify(hasTerm));
    }

    sessionGetTermAccepted(): boolean {
        const hasTerm = sessionStorage.getItem('ADERIU_TERMO_ADESAO');
        return JSON.parse(hasTerm);
    }

    sessionInitLoginDuplo(loginDuplo: boolean) {
        sessionStorage.setItem('LOGIN_DUPLO', JSON.stringify(loginDuplo));
    }

    sessionGetLoginDuplo(): boolean {
        const loginDuplo = sessionStorage.getItem('LOGIN_DUPLO');
        return JSON.parse(loginDuplo);
    }

    sessionInitDadosCadastraisSinqia(dadosCadastrais: DadosCadastraisSinqiaDto) {
        sessionStorage.setItem('dadosCadastraisSinqia', JSON.stringify(dadosCadastrais));
    }

    sessionGetDadosCadastraisSinqia(): DadosCadastraisSinqiaDto {
        const dadosCadastrais = sessionStorage.getItem('dadosCadastraisSinqia');
        return JSON.parse(dadosCadastrais);
    }

    sessionInitAdesao(adesao: ResumoAdesaoDto) {
        sessionStorage.setItem('adesao', JSON.stringify(adesao));
    }

    sessionGetAdesao(): ResumoAdesaoDto {
        const adesao = sessionStorage.getItem('adesao');
        return JSON.parse(adesao);
    }

    sessionRemove(key: string) {
        sessionStorage.removeItem(key);
    }

    sessionSaveTwoFactorAuthToken(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        let token = JSON.stringify(twoFactorAuthToken);
        sessionStorage.setItem(btoa(this.twoFactorAuthKey), btoa(token));
    }

    sessionGetTwoFactorAuthToken() {
        let tokenJson = sessionStorage.getItem(btoa(this.twoFactorAuthKey));
        if (tokenJson) {
            let token = JSON.parse(atob(tokenJson)) as TwoFactorAuthTokenModel;
            return token;
        }
        return null;
    }

    get isTermAccepted(): boolean {
        return this._isTermAccepted;
    }

    set isTermAccepted(isTermAccepted: boolean) {
        this._isTermAccepted = isTermAccepted;
    }

}
