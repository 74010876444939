export interface SimulacaoDTO {

    id?: string,

    email: string,
    celular: string,

    contribuicao?: number,
    rendaDesejada?: number,

    aporteInicial: number,
    tempoContribuicao: number,
    taxaJuros: number,
    duracao: number

    tipoSimulacao: TipoSimulacao,


    rendaMensal:number,
    idadeAtual: number,
    idadeAposentadoria: number,

    saldoAcumulado: number,
    beneficioMensal: number, //NÂO ENTENDI

    beneficioDataInicio: Date,
    beneficioRequerido: number,

    valorPortabilidade: number,
    saldoInicial: number,

}

export enum TipoSimulacao {
    Contribuicao = 1,
    RendaDesejada = 2
  }