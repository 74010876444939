import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { GestaoPlanoCadastroService } from '@fibra/fibra-shared-lib';
import { GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem } from '@fibra/fibra-shared-lib';
import { inserirZeroAEsquerda } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-acompanhamento-gerencial',
    templateUrl: './acompanhamento-gerencial.component.html',
    styleUrls: ['./acompanhamento-gerencial.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AcompanhamentoGerencialComponent implements OnInit {
    exibirModalPrevisaoAposentadoria = false;
    filtroPercentual = 0;
    inserirZeroAEsquerda = inserirZeroAEsquerda;
    isLoading = true;
    ordenacao = 0;
    percentuais = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    percentualEstimadoSuplementacaoList: GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem[] = [];
    DataSourceTable: GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem[] = [];
    percentualEstimadoSuplementacaoSelecionado: GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem;
    mensagemDeErro: string;
    bloquear: boolean;


    constructor(private gestaoPlanoCadastroService: GestaoPlanoCadastroService) { }
//carrega a lista
    ngOnInit() {
        this.carregarLista();
    }

    carregarLista() {
        this.isLoading = true;
        this.percentualEstimadoSuplementacaoList = [];

        this.gestaoPlanoCadastroService.Cadastros_ConsultaPlanilhaDir(
            this.ordenacao,
            this.filtroPercentual
        ).subscribe(data => {
            this.percentualEstimadoSuplementacaoList = data;
            this.DataSourceTable = data;
            this.isLoading = false;
        }, () => {
            this.informarErroDeCarregamento();
        });
    }

    visualizarPrevisaoDeAposentadoria(
        percentualEstimadoSuplementacao: GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem
    ) {
        this.percentualEstimadoSuplementacaoSelecionado = percentualEstimadoSuplementacao;
        this.exibirModalPrevisaoAposentadoria = true;
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }
}
