export default class SimuladorCd {
	erro = false;
	//fibra
	teto = 0;
	taxaRealAnual = 0;
	taxaRealMensal = 0;
	taxaSalarioCrescimentoMedio = 0;
	listaTaxasCrescimentoSalarial = [];
	idadeLimite = 0;
	faixas = [];
	urf = 0;
	saldoPortabilidade = 0;

	//user
	carencia = 0;
	salario = 0;
	saldoAtual = 0;
	idade = 0;
	carenciaLimite = 0;

	//calulados
	baseFaixas = [];
	contrMensalPatrocinadora = 0;
	contrMensalParticipante = 0;
	contrMensal = 0;
	contrPatrocinadoraTaxaZero = 0;
	contrParticipanteTaxaZero = 0;
	numeroAportesMensais = 0;
	valorEstimadoContribuicaoFuturas = 0;
	saldoAtualProjetado = 0;
	montanteTotalAcumulado = 0;
	tempoRendaFinanceira = 0;
	rendaFinanceiraAnuidade = 0;
	beneficioRendaFinanceira = 0;
	valorPortabilidade = 0;
	valorPortabilidadeParc = false;
    beneficioRendaFinanceiraCd = 0;


	constructor(carencia: any,salario: any,saldo: any,idade: any,carenciaLimite: any, beneficioRendaFinanceiraCd: any) {
		this.carencia = parseInt(carencia);
		this.salario = parseFloat(salario);
		this.saldoAtual = parseFloat(saldo);
		this.idade = parseInt(idade);
		this.carenciaLimite = parseInt(carenciaLimite);
        this.beneficioRendaFinanceiraCd = beneficioRendaFinanceiraCd;
	}

    setSalario(salario) {
        this.salario = parseFloat(salario);
    }

    setCarencia(carencia) {
        this.carencia = parseInt(carencia);
    }

    setSaldo(saldo) {
		this.saldoAtual = parseFloat(saldo);
    }

    setIdade(idade) {
		this.idade = parseInt(idade);
    }

    setCarenciaLimite(carenciaLimite) {
		this.carenciaLimite = parseInt(carenciaLimite);
    }

    setBeneficioRendaFinanceiraCd(beneficioRendaFinanceiraCd) {
        this.beneficioRendaFinanceiraCd = beneficioRendaFinanceiraCd;
    }

	//formula valor futuro
	getPv(ValorAporte: number, Taxa: number, Meses: number) {


		Taxa = Taxa / 100 ;

		if(Taxa === 0)
			return ValorAporte;

		let a = (ValorAporte*(Math.pow(1+Taxa,Meses)-1));
		let b = (Taxa*(Math.pow(1+Taxa,Meses)));
		let c = (1 + Taxa);

		return (a/b)*c;
	}

	setTetoFibra(value:any) {
		this.teto = parseFloat(value);
	}
	setTaxaRealAnual(value:any) {
		this.taxaRealAnual = parseFloat(value);
	}
	setTaxaRealMensal() {
		var valor = 0;

		valor = Math.pow(1+this.taxaRealAnual/100,1/12)-1;
		this.taxaRealMensal = valor * 100;
	}
	setTaxaSalarioCrescimentoMedio(values: any) {
		this.listaTaxasCrescimentoSalarial = values;
	}
	setIdadeLimite(value: any) {
		this.idadeLimite = parseInt(value);
	}
	setFaixas(values: any){
		this.faixas = values;
	}
	setFaixa(values: []){
		this.faixas.push(values);
	}
	setSaldoPortabilidade(value: number = 0){
		this.saldoPortabilidade = value;
	}
	setURF(value: number = 0){
		this.urf = value;
	}

	//taxa de crescimento salarial
	getTaxaCrescimentoSalarial(){
		var taxaIdadeAtual = 0,
			taxaIdadeCarencia = 0,
			valor = 0;

		taxaIdadeAtual = parseFloat(this.listaTaxasCrescimentoSalarial[this.idade].replace(",","."))/100;
		taxaIdadeCarencia = parseFloat(this.listaTaxasCrescimentoSalarial[this.idade + this.carencia].replace(",","."))/100;
		valor = taxaIdadeCarencia / taxaIdadeAtual;
		valor = ((Math.pow(valor, (1/this.carencia) )) -1) *100;
		this.taxaSalarioCrescimentoMedio = valor = valor;

		return valor;
	}

	//base de incidência
	getBaseFaixa1(){
		var valor = 0;

		if(this.faixas.length>0){
			if(this.salario > this.faixas[0].valor){
				valor = this.faixas[0].valor
			}else{
				valor = this.salario
			}
		}
		this.baseFaixas[0] = valor;
		return valor;
	}
	getBaseFaixa2(){
		var valor = 0;

		if(this.faixas.length>0){
			if(this.salario > this.faixas[1].valor){
				valor = this.faixas[1].valor - this.faixas[0].valor
			}else{
				valor = this.salario - this.faixas[0].valor
			}
		}
		this.baseFaixas[1] = valor;
		return valor;
	}
	getBaseFaixa3(){
		var valor = 0;

		if(this.faixas.length>0){
			if(this.salario > this.faixas[1].valor){
				valor = this.salario - this.faixas[1].valor
			}else{
				valor = this.salario - this.getBaseFaixa2() - this.getBaseFaixa1()
			}
		}
		this.baseFaixas[2] = valor;
		return valor;
	}
	//valor contribuicao
	getContribuicaoMensalPatrocinadora(){
		var valor = 0,
			a = 0,b =0, c = 0;

		if(this.faixas.length>0){

			a = (this.faixas[0].percentual/100) * this.getBaseFaixa1();
			b = (this.faixas[1].percentual/100) * this.getBaseFaixa2();
			c = (this.faixas[2].percentual/100) * this.getBaseFaixa3();

			valor = a + b + c;

		}
		this.contrMensalPatrocinadora = valor;
		return valor;
	}
	getContribuicaoMensalParticipante(){
		var valor = 0,
			a = 0,b =0, c = 0;

		if(this.faixas.length>0){

			a = (this.faixas[0].percentual/100) * this.getBaseFaixa1();
			b = (this.faixas[1].percentual/100) * this.getBaseFaixa2();
			c = (this.faixas[2].percentual/100) * this.getBaseFaixa3();

			valor = a + b + c;

		}
		this.contrMensalParticipante = valor;
		return valor;
	}

	getContribuicaoMensalParticipanteTaxaZero(){
		var valor = 0,
			a = 0,b =0, c = 0;

		if(this.faixas.length>0){

			a = (this.faixas[0].percentual/100) * this.getBaseFaixa1();
			b = (this.faixas[1].percentual/100) * this.getBaseFaixa2();
			c = (this.faixas[2].percentual/100) * this.getBaseFaixa3();

			valor = a + b + c;

		}
		this.contrMensalParticipante = valor;
		return valor;
	}

	getContribuicaoMensal(){
		var valor = 0;

		valor = this.contrMensalPatrocinadora + this.contrMensalParticipante;
		this.contrMensal = valor
		return valor;
	}

	//aportes até aposentadoria
	getNumeroAportes(){
		var valor = 0;

		valor = 12 * this.carencia;
		this.numeroAportesMensais = valor;

		return valor
	}

	//valor estimado de contri. futuras
	getValorEstimadoContribuicaoFuturas(){
		var a = 0,b = 0,c = 0,d = 0,e = 0,f = 0,g = 0,h = 0,i = 0,
			TaxaMensal = this.taxaRealMensal/100,
			TaxaAnual = this.taxaRealAnual/100,
			TaxaCrescimentoSalarial = this.taxaSalarioCrescimentoMedio/100,
			TaxaCrescimentoSalarialMes = Math.pow(1 + TaxaCrescimentoSalarial,1/12) - 1,
			valor = 0;

		if(this.contrMensal != undefined){
			a = Math.pow((1 + TaxaMensal),this.numeroAportesMensais); //índice juros mensal
			b = Math.pow((1 + (TaxaCrescimentoSalarialMes)),this.numeroAportesMensais);	// índice de crescimento salarial mensal
			c = (TaxaMensal - TaxaCrescimentoSalarialMes); // Fator juro e crescimento mensal
			d = Math.pow((1 + TaxaAnual),this.carencia); // Índice juro anual
			e = Math.pow((1 + TaxaCrescimentoSalarial),this.carencia); // Índice crescimento salarial anual
			f = (TaxaAnual) - (TaxaCrescimentoSalarial); // Fator juro e crescimento anual
			//valor = ((((a * b) -1 ) / c) * (d / e) * f + (g - h) / i);
			valor = (((a-b)/c)+((d-e)/f));
			valor = valor * this.contrMensal;

			this.valorEstimadoContribuicaoFuturas = valor;
		}

		return valor;
	}
	getContribuicaoParticipanteTaxaZero(){
		var TaxaAnual = 0,
			valor = 0;

		this.contrParticipanteTaxaZero = valor = (Math.pow((1 + TaxaAnual),this.carencia) - Math.pow((1 + this.taxaSalarioCrescimentoMedio/100),this.carencia)) / ( TaxaAnual - this.taxaSalarioCrescimentoMedio/100) * this.contrMensalParticipante * 13;
		return valor;
	}
	getContribuicaoPatrocinadoraTaxaZero(){
		var TaxaAnual = 0,
			valor = 0;

		this.contrPatrocinadoraTaxaZero = valor = (Math.pow((1 + TaxaAnual),this.carencia) - Math.pow((1 + this.taxaSalarioCrescimentoMedio/100),this.carencia)) / ( TaxaAnual - this.taxaSalarioCrescimentoMedio/100) * this.contrMensalPatrocinadora * 13;
		return valor;
	}

	getSaldoAtualProjetado(){
		var valor = 0;

		valor = this.saldoAtual * Math.pow((1 + this.taxaRealMensal/100),this.carencia*12);
		this.saldoAtualProjetado = valor;

		return valor;
	}

	getMontanteTotalAcumulado(){
		var valor = 0;

		valor = this.saldoAtualProjetado + this.valorEstimadoContribuicaoFuturas;
		this.montanteTotalAcumulado = valor;
		return valor;
	}

	getTempoRendaFinanceira(){
		var valor = this.idadeLimite - (this.idade+this.carencia);

		if(valor < 25)
			valor = 25;

		this.tempoRendaFinanceira = valor;
		return valor;
	}

	getRendaFinanceiraAnuidade(){
		var meses = 0,
			valor = 0;

		meses = this.tempoRendaFinanceira*12;
		valor = this.getPv(1,this.taxaRealMensal,meses) + this.getPv(1,this.taxaRealAnual,this.tempoRendaFinanceira)
		this.rendaFinanceiraAnuidade = valor;

		return valor;
	}

	getBeneficioRendaFinanceira(){
        if(this.beneficioRendaFinanceiraCd && this.beneficioRendaFinanceiraCd > 0 && this.beneficioRendaFinanceira == 0)
        {
            this.beneficioRendaFinanceira = valor = this.beneficioRendaFinanceiraCd;
            return valor;
        }
		var meses = 0,
			valor = 0;

		this.beneficioRendaFinanceira = valor = this.montanteTotalAcumulado/this.rendaFinanceiraAnuidade;

		return valor;
	}

	portabilidade(){
		var carencia = this.carencia,
			valor = 0, valorParcelado = 0;


		if(this.saldoPortabilidade === 0) {
            this.valorPortabilidade = 0;
			return valor;
        }

		valor = this.saldoPortabilidade * Math.pow((1 + this.taxaRealMensal/100),carencia*12);
		valorParcelado = valor/60;

		if(valorParcelado < (this.urf*(10/100))){
			this.valorPortabilidadeParc = false;
			this.valorPortabilidade = valor;
		}else{
			this.valorPortabilidadeParc = true;
			this.valorPortabilidade = valorParcelado;
		}

		return this.valorPortabilidade;
	}

	calcular =  () => {
        this.erro = false;
		if(this.carenciaLimite <= this.carencia){
			//calcula a faixa base
			this.getTaxaCrescimentoSalarial();
			this.getBaseFaixa1();
			this.getBaseFaixa2();
			this.getBaseFaixa3();
			//número de aportes mensais
			this.getNumeroAportes();

			this.getContribuicaoMensalPatrocinadora();
			this.getContribuicaoMensalParticipante();
			this.getContribuicaoMensal();

			this.getValorEstimadoContribuicaoFuturas();
			this.getSaldoAtualProjetado();
			this.getMontanteTotalAcumulado();

			this.getContribuicaoParticipanteTaxaZero();
			this.getContribuicaoPatrocinadoraTaxaZero()

			this.getTempoRendaFinanceira();
			this.getRendaFinanceiraAnuidade();
			this.getBeneficioRendaFinanceira();

			this.portabilidade();
		}else{
			this.erro = true;
		}

	}
}
