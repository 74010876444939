export enum TipoSeDocumento {
    NormaPessoalEmprestimo = 1,
    EstatutoFibra = 2,
    INPCJuros = 3,
    LaminaBD = 4,
    SaldamentoBD = 5,
    RegulamentoCD = 6,
    SinteseSaldamentoNovoPlano = 7,
    PoliticaIvestimentoPGA = 8,
    PoliticaIvestimentoPBD = 9,
    RegulamentoInvestimentos = 10,
    HipotesesAtuarias = 11,
    DemonstrativoAtuarial = 12,
    NotaTecnicaAtuarial = 13,
    LaminaCD = 14,
    SinteseSaldamentoNovoPlanoAtivos = 15,
    SeguDeVida = 16,
    MultiplicadorSalarial = 17,
    RegulamentoPlanoBD = 18,
    RegulamentoRiscoCompliance = 19,
    MinutaRegulamentoCD = 20,
    PoliticaIvestimentoPCD = 21,
    SinteseSaldamentoAtivoAssistidos = 22,
    SinteseSaldamentoAssistido = 23
}