import { ContribuicaoSaldo } from './../domain/gestaofinanceira/emprestimo/models/contribuicao-saldo';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from './http-client.service';

import { Service } from './service';
import {
    GestaoPlanoContribuicaoServiceContribuicaoSaldoResponse,
    ExtratoContribuicao
} from '../models';
import { Observable } from 'rxjs';
import { ContribuicaoDto } from '../models/sinqia-api/contribuicao.dto';
import { ETipoPlano, ETokenApi } from '../enums';

@Injectable()
export class GestaoPlanoContribuicaoService extends Service {
    private url: string;
    private httpclienteService : HttpClientService;

    constructor(http: HttpClient, @Inject('environment') private environment,private httpClientService: HttpClientService) {
        super(http);
        this.url = this.environment.API_CONTRIBUICAO;
        this.httpClientService = httpClientService;
    }

    public Contribuicao_Saldo(): Observable<GestaoPlanoContribuicaoServiceContribuicaoSaldoResponse> {
        const url = `${this.url}/Saldo/SaldoContribuicao`;
        return this.cachedGet<GestaoPlanoContribuicaoServiceContribuicaoSaldoResponse>(url);
    }

    public Contribuicao_Get(): Observable<ContribuicaoSaldo> {
        const url = `${this.url}/Saldo/GetContribuicao`;
        return this.cachedGet<ContribuicaoSaldo>(url);
    }

    public Extrato_ExtratoContribuicaoConsolidado(): Observable<ExtratoContribuicao[]> {
        const dataAtual = new Date();
        const ano = dataAtual.getFullYear();
        const mes = dataAtual.getMonth();
        const url = `${this.url}/Extrato/ExtratoContribuicaoConsolidado?ano=${ano}&mes=${mes}&tipo=1`;
        return this.cachedGet<ExtratoContribuicao[]>(url);
    }

    public Extrato_ExtratoContribuicaoPorAno(ano: number): Observable<ContribuicaoDto> {
        const url = `${this.url}/Extrato/ExtratoContribuicaoPorAno?ano=${ano}`;
        return this.httpClientService.get<ContribuicaoDto>(url, ETokenApi.TokenApiBd);
    }
    
    obterExtratoContribuicaoPorAno(ano) {      
        console.log("To aqui obterExtratoContribuicaoPorAno");
        const url = `${this.url}/Extrato/ExtratoContribuicaoPorAno?ano=${ano}`;
        return this.httpClientService.get<ContribuicaoDto>(url, ETokenApi.TokenApiBd);
    }

    public Saldo_Contrib_Anterior(): Observable<number> {
        const url = `${this.url}/Saldo/SaldoContribAnterior`;
        return this.cachedGet<number>(url);
    }

    public ExtratoContribuicaoPdf(ano: number, callback: Function, thisArg: any) {
        const url = `${this.url}/Extrato/ExtratoContribuicaoPdf?ano=${ano}`;
        return this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }
}
