import { GestaoPlanoCadastroService, LogradouroResponse } from '@fibra/fibra-shared-lib';
import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-auto-complete-logradouro',
    templateUrl: './auto-complete-logradouro.component.html',
    styleUrls: ['./auto-complete-logradouro.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AutoCompleteLogradouroComponent implements OnInit {
    @Input() recebeDescricaoLogradouro: string;
    @Output() respostaCodigoLogradouro = new EventEmitter();

    logradouroResponse: LogradouroResponse;
    tipoLogradouro: string;

    objLogradouro: any[] = [];

    filteredLogradouroSingle: any[];

    constructor(private gestaoPlanoCadastroService: GestaoPlanoCadastroService) {
        this.logradouroResponse = <LogradouroResponse>{};
    }

    ngOnInit() {
        this.tipoLogradouro = this.recebeDescricaoLogradouro;
        GestaoPlanoCadastroService.emitirDescricaoLogradouro.subscribe(
            descricao => (this.tipoLogradouro = descricao)
        );
    }

    filterLogradouroSingle(event) {
        const query = event.query;
        this.gestaoPlanoCadastroService.Cadastros_GetLogradouro().subscribe(logradouro => {
            this.filteredLogradouroSingle = this.filterLogradouro(query, logradouro);
        });
    }

    filterLogradouro(query, logradouroR: any[]): any[] {
        const filtered: any[] = [];
        for (let i = 0; i < logradouroR.length; i++) {
            this.logradouroResponse = logradouroR[i];
            if (this.logradouroResponse.descLogradouro.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(this.transform(this.logradouroResponse.descLogradouro));
                this.objLogradouro.push(this.logradouroResponse);
            }
        }
        return filtered;
    }

    onBlur(event) {
        let logradouro = event.target.value;
        if (logradouro) {
            for (let i = 0; i < this.objLogradouro.length; i++) {
                const objLogradouro = this.objLogradouro[i];
                if (objLogradouro.descLogradouro.toLowerCase() === logradouro.toLowerCase()) {
                    logradouro = objLogradouro.descLogradouro;
                    this.respostaCodigoLogradouro.emit(this.transform(logradouro));
                    return;
                }
            }
        } else {
            this.respostaCodigoLogradouro.emit(this.transform(logradouro));
        }
    }

    transform(value:string): string {
        value = value.toLowerCase();
        let first = value.substring(0,1).toUpperCase();
        return first + value.substring(1);
    }
}
