<div *ngIf="checarVisibilidade()"
     class="app-lightbox-backdrop d-flex flex-column justify-content-center align-items-center"
     (click)="closeDialog()">
    <div class="app-lightbox-body"
         (click)="$event.stopPropagation()">
        <div class="app-lightbox-aspect-ratio">
            <div class="app-lightbox-content">
                <i class="fa fa-close"
                   title="Fechar"
                   (click)="closeDialog()"></i>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
