export class IndiceFinanceiro {
    id: number;
    nome: string | null;
    codigo: string | null;
    periodicidade: string | null;
    unidadePadrao: string | null;
    tipoDivulgacao: string | null;
    usadoBenchMark: boolean | null;
    codigoBovespa: string | null;
    codigoPrevic: string | null;
    itemIndiceFinanceiro: any[] = [];
}