import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
    AuthService,
    GestaoPlanoBeneficioService,
    GestaoPlanoBeneficioServiceLancamentoMesContraChequeResponseItem,
    GestaoPlanoLancamentoBeneficio,
    ESituacaoParticipante,
    AdesaoService
} from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-beneficio',
    templateUrl: './beneficio.component.html'
})
export class BeneficioComponent implements OnInit {

    lancamentos: GestaoPlanoBeneficioServiceLancamentoMesContraChequeResponseItem[] = [];
    lancamentoBeneficio: GestaoPlanoLancamentoBeneficio;

    role: string[] = [];
    valorLiquido: string;
    mensagemDeErro: string;

    isLoading = true;
    show = false;
    bloquear: boolean;

    constructor(
        private gestaoPlanoBeneficiosService: GestaoPlanoBeneficioService,
        private adesaoService: AdesaoService,
        private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.init();
    }

    init() {
        let adesao = this.adesaoService.getAdesaoContexto();
        let ehAssistido = this.adesaoService.validarSituacao(ESituacaoParticipante.Assistido, adesao.tipoSituacao);

        if (ehAssistido) {
            this.show = true;
            this.gestaoPlanoBeneficiosService.Lancamento_GetBeneficio().subscribe(data => {
                this.lancamentoBeneficio = data;
                if (this.lancamentoBeneficio.valorLiquido === 0) {
                    this.show = false;
                }
                this.isLoading = false;
            }, httpError => {
                if (httpError.status === 400) {
                    this.show = false;
                    this.isLoading = false;
                    return;
                }
                this.informarErroDeCarregamento();
            });
        }
    }

    baixarContracheque() {
        this.isLoading = true;
        if (this.lancamentoBeneficio) {
            const dataReferencia = this.datePipe.transform(this.lancamentoBeneficio.ultimoPagamento, 'yyyy-MM-dd');
            this.gestaoPlanoBeneficioService.Beneficios_SegundaViaDemonstrativo(dataReferencia
                , () => {
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                    this.informarErroDeCarregamento();
                });
        } else {
            this.isLoading = false;
            this.informarErroDeCarregamento();
        }
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
            ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }
}
