import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-two-factor-auth-email',
  templateUrl: './two-factor-auth-email.component.html',
  styleUrls: ['./two-factor-auth-email.component.css']
})
export class TwoFactorAuthEmailComponent implements OnInit {
  @Input() email: string | null = null;

  constructor() { }

  ngOnInit() {
  }
}
