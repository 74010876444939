import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-plano-cd',
  templateUrl: './painel-cd.component.html',
  styleUrls: ["./painel-cd.component.css"]
})
export class PainelCdComponent implements OnInit {

  ngOnInit(): void {
  }

}
