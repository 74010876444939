import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CELULAR, CPF, CustomValidators, ETipoDependente, RelacaoDependenciaService } from '@fibra/fibra-shared-lib';
import { MessageService } from 'primeng/api';
import { DependenteModel } from '../../models/dependente.model';
import { EAcaoForm } from '../../enums/acao-form.enum';
import moment from 'moment';
import { ValidatorExtention } from '../../validators/validator-extension';

@Component({
    selector: 'app-novo-dependente',
    templateUrl: './novo-dependente.component.html',
    styleUrls: ['./novo-dependente.component.css']
})
export class NovoDependenteComponent implements OnInit {
    @Input() visible: boolean = false;
    @Input() tipoDependente: ETipoDependente;
    @Input() acaoForm: EAcaoForm = EAcaoForm.Adicionar;

    @Output() onHide: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSalvarDependente: EventEmitter<DependenteModel> = new EventEmitter<DependenteModel>();
    @Output() onAtualizarDependente: EventEmitter<DependenteModel> = new EventEmitter<DependenteModel>();

    readonly CPF = CPF;
    readonly CELULAR = CELULAR;
    eTipoDependente = ETipoDependente;
    eAcaoForm = EAcaoForm;

    get headerTitle() {
        return this.tipoDependente == ETipoDependente.Beneficiario ? 'Cadastro de beneficiário' : 'Cadastro de designados';
    }

    formDependente: FormGroup;

    formControlFriendlyNames = {
        tipoDependente: 'Tipo de Dependente',
        nome: 'Nome',
        cpf: 'CPF',
        dataNascimento: 'Data de Nascimento',
        grauParentesco: 'Grau de Parentesco',
        email: 'E-mail',
        sexo: 'Sexo',
        telefone: 'Telefone',
        percentualParticipacao: 'Percentual Designado',
        cep: 'CEP',
        logradouro: 'Logradouro',
        numero: 'Número',
        bairro: 'Bairro',
        cidade: 'Cidade',
        uf: 'UF',
    };

    relacaoDependenciaLista: { id: string; descricao: string; }[] = [];

    constructor(private formBuilder: FormBuilder, private messageService: MessageService,
        private relacaoDependenciaService: RelacaoDependenciaService) {
    }

    ngOnInit(): void {
        this.relacaoDependenciaLista = this.relacaoDependenciaService.getRelacaoDependencia();
        let relacaoDependeniaPermitidos = this.relacaoDependenciaLista.map(x => x.id.toString());
        let grauParentescoControl = this.tipoDependente == ETipoDependente.Beneficiario ?
            this.formBuilder.control("0", [Validators.required, CustomValidators.allowedValuesValidator(relacaoDependeniaPermitidos)]) : this.formBuilder.control("0");

        this.formDependente = this.formBuilder.group({
            codigo: this.formBuilder.control(null, []),
            nome: this.formBuilder.control(null, [Validators.required]),
            cpf: this.formBuilder.control(null, [Validators.required]),
            dataNascimento: this.formBuilder.control(null, [Validators.required, ValidatorExtention.dateValidator(['DD/MM/YYYY', 'DDMMYYYY'])]),
            grauParentesco: grauParentescoControl,
            email: this.formBuilder.control(null, []),
            percentualParticipacao: this.formBuilder.control(0, []),
            sexo: this.formBuilder.control('0', [Validators.required, CustomValidators.allowedValuesValidator(['M', 'F'])]),
            telefone: this.formBuilder.control('', [Validators.required])
        });
    }

    salvarDependente(dependente: DependenteModel, acaoForm: EAcaoForm) {
        let percentualValido = this.tipoDependente == ETipoDependente.Designado
            ? dependente.percentualParticipacao != null && dependente.percentualParticipacao >= 0 && dependente.percentualParticipacao <= 100 : true;

        if (this.tipoDependente == ETipoDependente.Designado)
            dependente.grauParentesco = "0";

        if (this.formDependente.valid && percentualValido) {
            if (acaoForm == EAcaoForm.Adicionar) {
                dependente.codigo = crypto.randomUUID();
                this.onSalvarDependente.emit(dependente);
            }
            else if (acaoForm == EAcaoForm.Atualizar)
                this.onAtualizarDependente.emit(dependente);

            this.ocultarModal();
        } else {
            this.messageService.clear();
            this.messageService.add({
                key: this.tipoDependente,
                severity: 'warn',
                summary: 'Campos obrigatórios',
                detail: `É necessário preencher os campos obrigatórios.`
            });
        }
    }

    mostrarModal() {
        this.visible = true;
    }

    ocultarModal() {
        this.limparFormulario();
        this.visible = false;
    }

    limparFormulario() {
        Object.keys(this.formDependente.value).forEach(key => {
            let defaultValue = null;
            if (key == 'tipoDependente')
                defaultValue = '0';
            if (['percentualParticipacao', 'grauParentesco', 'sexo'].includes(key))
                defaultValue = 0;
            this.formDependente.get(key).patchValue(defaultValue);
        });
    }

    adicionarDependente() {
        this.acaoForm = EAcaoForm.Adicionar;
        this.mostrarModal();
    }

    editarDependente(dependente: DependenteModel) {
        this.acaoForm = EAcaoForm.Atualizar;
        Object.keys(dependente).forEach(key => {
            let value = dependente[key];
            if (key == 'dataNascimento')
                value = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');

            this.formDependente.get(key)?.patchValue(value);
        });
        this.mostrarModal();
    }
}
