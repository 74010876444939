import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'app-alterar-senha-interno-bd',
    templateUrl: './alterar-senha.component.html'
})
export class AlterarSenhaComponent implements OnInit {
    ngOnInit(): void {
    }

}
