import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { GestaoFinanceiraEmprestimoService } from '../services/gestao-financeira-emprestimo.service';
import { TipoBloqueioConst } from '../constants/tipo-bloqueio-const';

@Injectable()
export class RoleGuardService implements CanActivate {
    papeisUsuario: string[] = [];

    constructor(private router: Router, private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    canActivate() {
        // this.gestaoFinanceiraEmprestimoService.Emprestimo_GetBloqueio().subscribe(dataBloqueio => {
        //     if (dataBloqueio &&
        //         (dataBloqueio.idTipoBloqueio === TipoBloqueioConst.EMPRESTIMO_ANDAMENTO)) {
        //             this.router.navigate(['/404']);
        //             return false;
        //     }
        // });
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratoAtivo(true).subscribe(contrato => {
            if (!contrato) {
                this.router.navigate(['/404']);
                return false;
            }
        });
        return true;
    }
}
