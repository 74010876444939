export class DesignadoModel {
    id: number;
    nome: string;
    cpf: string;
    percentual: number;
    idDesignado: number;
    nomeDesignado: string;
    cpfDesignado: string;
    dataNascimento: Date | null;
    sexo: string | null;
    telefone: string | null;
    email: string | null;
}