import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-carencias',
    styleUrls: ['./carencias.component.css'],
    templateUrl: './carencias.component.html'
})
export class CarenciasComponent implements OnInit {

    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;

    constructor() { }

    ngOnInit() {
        this.isLoading = false;
    }

}
