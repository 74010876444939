import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CPF, ETipoDependente, FileUploadModel, RelacaoDependenciaService } from '@fibra/fibra-shared-lib';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import * as _ from "lodash";
import { DependenteModel } from '../../models/dependente.model';
import { NovoDependenteComponent } from '../novo-dependente/novo-dependente.component';

@Component({
    selector: 'app-dependente',
    templateUrl: './dependente.component.html',
    styleUrls: ['./dependente.component.css']
})
export class DependenteComponent implements OnInit {
    @Input() dependentes: Array<DependenteModel> = [];
    @Input() tipoDependente: ETipoDependente;
    @Input() tituloGrid: string;
    @Input() isLoading: boolean = false;
    @Input() enableResetButton: boolean = true;
    @Input() readOnly: boolean = false;
    @Input() disableAttachment: boolean = false;
    @Output() onSalvarDependente: EventEmitter<DependenteModel> = new EventEmitter<DependenteModel>();
    @Output() onAtualizarDependente: EventEmitter<DependenteModel> = new EventEmitter<DependenteModel>();
    @Output() onRemoveDependente: EventEmitter<DependenteModel> = new EventEmitter<DependenteModel>();
    @Output() onResetarDadosClick: EventEmitter<any> = new EventEmitter<any>();

    //#region File Upload
    @Output() onFileUpload = new EventEmitter<{ dependente: DependenteModel, file: FileUploadModel }>();
    @Output() onFileRemoved = new EventEmitter<{ dependente: DependenteModel, documentId: string }>();
    //#endregion
    @ViewChild('novoDependente') novoDependente: NovoDependenteComponent;

    eTipoDependente = ETipoDependente;
    readonly CPF = CPF;
    dependenteModal: boolean = false;
    date: moment.Moment;

    nomeAmigavelGridDependente = {
        'Beneficiario': 'Beneficiário',
        'Designado': ETipoDependente.Designado
    };
    relacaoDependenciaLista: { id: string; descricao: string; }[] = [];

    // Dependente anterior a edição
    dependenteAnterior: DependenteModel;

    get totalPercentualDesignado() {
        let designados = this.dependentes.filter(x => x.tipoDependente == ETipoDependente.Designado);
        return _.sumBy(designados, desig => desig.percentualParticipacao);
    }

    get mensagemPercentual() {
        let messagem = '';
        let designados = this.dependentes.filter(x => x.tipoDependente == ETipoDependente.Designado);
        if (designados.length > 0) {
            let totalPercentual = this.totalPercentualDesignado;
            if (totalPercentual < 100) {
                messagem = `(Falta ${100 - totalPercentual}% para completar)`;
            }
            else if (totalPercentual > 100) {
                messagem = `(Ultrapassou o limite, reduzir ${totalPercentual - 100}%)`
            }
        }
        return messagem;
    }

    get tooltipMessagem() {
        return _.some(this.dependentes, { valido: false }) ? 'Existem dados inválido' : null;
    }

    get dependentsWitoutDocuments() {
        return _.filter(this.dependentes, x => x.tipoDependente == ETipoDependente.Beneficiario
            && x.existente == false && _.isEmpty(x.documentos));
    }

    constructor(private confirmationService: ConfirmationService,
        private relacaoDependenciaService: RelacaoDependenciaService) {
    }

    ngOnInit(): void {
        this.date = moment();
        this.relacaoDependenciaLista = this.relacaoDependenciaService.getRelacaoDependencia();
    }

    salvarDependente(novoDependente: any) {
        let dataNasc = novoDependente.dataNascimento as string;
        let dataNascimento = this.getDataNascimento(dataNasc);
        if (dataNascimento == null) {
            console.error('Data de nascimento inválida!')
            return;
        }

        let dependente = new DependenteModel();
        dependente.codigo = novoDependente.codigo;
        dependente.tipoDependente = this.tipoDependente;
        dependente.nome = novoDependente.nome;
        dependente.cpf = novoDependente.cpf;
        dependente.email = novoDependente.email;
        dependente.dataNascimento = new Date(dataNascimento.toDate());
        dependente.grauParentesco = novoDependente.grauParentesco;
        dependente.percentualParticipacao = novoDependente.percentualParticipacao;
        dependente.sexo = novoDependente.sexo;
        dependente.telefone = novoDependente.telefone;
        dependente.existente = false;

        this.onSalvarDependente.emit(dependente);
    }

    private getDataNascimento(dataNasc: string) {
        let formatos = ['DDMMYYYY', 'DD/MM/YYYY'];
        for (let formato of formatos) {
            let dataNascimento = moment(dataNasc, formato, true);
            if (dataNascimento.isValid())
                return dataNascimento;
        }
        return null;
    }

    atualizarDependente(novoDependente: any) {
        let dataNasc = novoDependente.dataNascimento as string;
        let dataNascimento = this.getDataNascimento(dataNasc);
        if (dataNascimento == null) {
            console.error('Data de nascimento inválida!')
            return;
        }

        let dependente = new DependenteModel();
        dependente.codigo = novoDependente.codigo;
        dependente.tipoDependente = this.tipoDependente;
        dependente.nome = novoDependente.nome;
        dependente.cpf = novoDependente.cpf;
        dependente.email = novoDependente.email;
        dependente.dataNascimento = new Date(dataNascimento.toDate());
        dependente.grauParentesco = novoDependente.grauParentesco;
        dependente.percentualParticipacao = novoDependente.percentualParticipacao;
        dependente.sexo = novoDependente.sexo;
        dependente.telefone = novoDependente.telefone;
        dependente.existente = this.dependenteAnterior.existente;
        dependente.documentos.push(...this.dependenteAnterior.documentos);

        this.onAtualizarDependente.emit(dependente);
    }

    removerDependente(dependente: DependenteModel) {
        let messagem = `Tem certeza que deseja excluir ${dependente.nome}?`;

        this.confirmationService.confirm({
            key: this.tipoDependente,
            message: messagem,
            accept: () => {
                this.onRemoveDependente.emit(dependente);
            }
        });
    }

    adicionarDependente() {
        this.novoDependente.adicionarDependente();
    }

    ocultarModal() {
        this.dependenteModal = false;
    }

    resetarDados(ev: any) {
        this.confirmationService.confirm({
            key: this.tipoDependente,
            message: "Essa ação irá redefinir os dados para o estado inicial, tem certeza que deseja continuar?",
            accept: () => {
                this.onResetarDadosClick.emit(ev);
            }
        });
    }

    editarDependente(dependente: DependenteModel) {
        this.dependenteAnterior = dependente;
        this.novoDependente.editarDependente(dependente);
    }

    getDescricaoGrauParentesco(id: string) {
        return this.relacaoDependenciaLista.find(x => x.id == id)?.descricao;
    }
}
