import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import {
    AuthService,
    GestaoFinanceiraEmprestimoService
} from '@fibra/fibra-shared-lib';
import {
    GestaoFinanceiraEmprestimoServiceSaldoGetInformacoesAdicionaisSaldoEmprestimoResponse
} from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-informacoes-adicionais-emprestimo',
  templateUrl: './informacoes-adicionais-emprestimo.component.html',
  styleUrls: ['./informacoes-adicionais-emprestimo.component.css']
})
export class InformacoesAdicionaisEmprestimoComponent implements OnInit {

    informacoesAdicionais: GestaoFinanceiraEmprestimoServiceSaldoGetInformacoesAdicionaisSaldoEmprestimoResponse;
    error = false;
    isLoading = false;

    constructor() { }

    ngOnInit() { }
}
