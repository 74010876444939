import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CustomValidators, ETipoDependente, FileUploadModel, RELACAO_DEPENDENCIA } from "@fibra/fibra-shared-lib";


export class DependenteModel {
    tipoDependente: ETipoDependente | null = null;
    codigo?: string | null = null;
    nome?: string | null = null;
    cpf?: string | null = null;
    dataNascimento?: Date;
    grauParentesco?: string | null = null;;
    percentualParticipacao?: number;
    email?: string | null = null;
    sexo?: string | null;
    telefone?: string | null;
    documentos: FileUploadModel[] = [];
    existente?: boolean | null = null;

    get valido() {
        return this.formDependente.valid;
    }

    get formDependente() {
        let grauParentescoControl = this.tipoDependente == ETipoDependente.Beneficiario ?
            new FormControl("0", [Validators.required])
            : new FormControl("0");

        return new FormGroup({
            nome: new FormControl(this.nome, [Validators.required]),
            cpf: new FormControl(this.cpf, [Validators.required]),
            dataNascimento: new FormControl(this.dataNascimento, [Validators.required]),
            grauParentesco: grauParentescoControl,
            percentualParticipacao: new FormControl(this.percentualParticipacao, []),
            sexo: new FormControl(this.sexo, [Validators.required, CustomValidators.allowedValuesValidator(['M', 'F'])]),
            telefone: new FormControl(this.telefone, [Validators.required])
        });
    }
}