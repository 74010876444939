import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ArquivosService, GestaoPlanoBeneficioService, NomeArquivos } from '@fibra/fibra-shared-lib';
import { AutoPatrocinioDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/auto-patrocinio.dto';



@Component({
    selector: 'app-tela-ppdv',
    templateUrl: './tela-ppdv.component.html',
    styleUrls: ['./tela-ppdv.component.css']
})
export class TelaPpdvComponent implements OnInit {
    valoresAutopatrocinio?: AutoPatrocinioDto;
    isLoading = false;
    nomeArquivo: string = NomeArquivos.PPDV;

    constructor(private arqService: ArquivosService,
        private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService) { }

    ngOnInit() {
    }

    public async getAutoPatrocinio() {
        const keyAutoPatroc = 'carregando_auto_patrocinio';
        try {
            this.valoresAutopatrocinio = JSON.parse(sessionStorage.getItem("AUTO_PATROCINIO"));
            if (!this.valoresAutopatrocinio && !sessionStorage.getItem(keyAutoPatroc)) {
                this.isLoading = true;
                sessionStorage.setItem(keyAutoPatroc, 'true');
                await this.gestaoPlanoBeneficioService
                    .Beneficios_GetAutoPatrocinio()
                    .subscribe(
                        (autopatrocinio) => {
                            this.valoresAutopatrocinio = autopatrocinio;
                            sessionStorage.setItem(
                                "AUTO_PATROCINIO",
                                JSON.stringify(autopatrocinio)
                            );
                            this.isLoading = false;
                            sessionStorage.removeItem(keyAutoPatroc);
                        },
                        (error) => {
                            this.isLoading = false;
                            console.log(error);
                            sessionStorage.removeItem(keyAutoPatroc);
                        }
                    );
            }
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            this.isLoading = false;
            sessionStorage.removeItem(keyAutoPatroc);
            throw e;
        }
    }

    DownloadArquivo() {
        this.isLoading = true;
        this.arqService.getArquivoByNome(this.nomeArquivo, () => { this.isLoading = false; }, this);
    }
}
