import ptBr from '@angular/common/locales/pt';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID,NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { PainelModule } from './painel/painel.module';
import { PainelCdModule } from './painel/cd/painel-cd.module';
import { PainelBdModule } from './painel/bd/painel-bd.module';
import { LoginModule } from './login/login.module';
import { ROUTES } from './app.routes';
import { SharedModule } from '@fibra/fibra-shared-lib';
import { environment } from '../environments/environment';
import { ClipboardModule } from 'ngx-clipboard';
import { TermoAdesaoModule } from './termo-adesao';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { NgxMaskModule } from 'ngx-mask';
import { ServidorIndisponivelComponent } from './servidor-indisponivel/servidor-indisponivel.component';
import { httpInterceptorProviders } from './http-interceptors/http-interceptors';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from './components/components.module';
import { SignWidgetComponent } from './components/clicksign/sign-widget/sign-widget.component';
import { MockModule } from './mocks/mocks.module';


const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.COOKIE_DOMAIN// or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },

    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '#42505f',
            text: '#ffffff'
        },
        button: {
            background: '#007bff',
            text: '#ffffff'
        }
    },
    type: "opt-out",
    content: {
        message: 'Este site usa cookies para garantir que você obtenha a melhor experiência.',
        deny: 'Fechar',
        allow: "Aceitar",
        link: 'Saiba mais em nossa declaração de privacidade',
        href: 'https://www.fundacaoitaipu.com.br/politica-de-privacidade',
        policy: '',

    }
};


@NgModule({
    declarations: [
        AppComponent,
        ServidorIndisponivelComponent
    ],
    imports: [
        BrowserModule,
        PainelModule,
        PainelCdModule,
        PainelBdModule,
        ComponentsModule,
        TermoAdesaoModule,
        LoginModule,
        ClipboardModule,
        RouterModule.forRoot(ROUTES, {scrollPositionRestoration: 'enabled'}),
        SharedModule.forRoot(environment),
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot(),
        MockModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

registerLocaleData(ptBr);
