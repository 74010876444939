import * as gestaoPlanoBeneficioDemostrativoPagamentoModel from '@fibra/fibra-shared-lib';
import { Component, OnInit } from '@angular/core';
import { GestaoPlanoBeneficioDemostrativoPagamento, ItensPagamento, GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-demonstrativo-pagamento-card',
    templateUrl: './demonstrativo-pagamento-card.component.html'
})
export class DemonstrativoPagamentoCardComponent implements  OnInit {

    show = true;
    isLoading = true;
    bloquearDemostrativo: boolean;
    mensagemDeErro: string;
    dataReferencia: Date;
    demostrativoPagamentos: GestaoPlanoBeneficioDemostrativoPagamento[];
    ultimoItemPagamento: ItensPagamento;
    itensPagamento: ItensPagamento[] = [];
    anos: number[];
    meses: number[];
    mesSelecionado: number;
    anoSelecionado: number;

    nomeDosMeses = [
        null,
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];

    constructor(private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService,
                private datePipe: DatePipe) { }

    ngOnInit() {
        this.getAnoContraCheque();
    }

    getAnoContraCheque()
    {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService.Lancamento_AnoContraCheque().subscribe(dadosAnos => {
            this.anos = dadosAnos;

            if(this.anos && this.anos.length > 0)
            {
                this.anoSelecionado = this.anos.slice(-1)[0];
                this.getMesesContraCheque(this.anoSelecionado);
            }

        }, httpError => {
            if(httpError.status === 400)
            {
                this.show = false;
                this.isLoading = false;
                return;
            }
            this.informarErroDeCarregamento();
        });
    }

    getMesesContraCheque(ano: any) {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService.Lancamento_MesContraCheque(ano).subscribe(dadosMeses => {
            this.meses = dadosMeses;

            if(this.meses && this.meses.length > 0)
            {
                this.mesSelecionado = this.meses.slice(-1)[0];
                this.getDemostrativo();
            }

        }, () => this.informarErroDeCarregamento());
    }

    getDemostrativo() {
        this.isLoading = true;

        this.gestaoPlanoBeneficioService.Lancamento_DemostrativoPagamento(this.anoSelecionado, this.mesSelecionado).subscribe(demostrativoPag => {
            this.demostrativoPagamentos = demostrativoPag;

            if (this.demostrativoPagamentos.length == 0)
            {
                this.isLoading = false;
                this.show = false;
                return;
            };

            this.itensPagamento = [];
            this.demostrativoPagamentos.forEach(dados => {
                this.itensPagamento.push(...dados.itensPagamentos);
            });


            if (this.itensPagamento.length == 0)
            {
                this.isLoading = false;
                this.show = false;
                return;
            };

            this.isLoading = false;

        }, () => this.informarDemostrativo());
    }

    formatNomeMes(mes: number): string
    {
        return this.nomeDosMeses[mes];
    }

    baixarContracheque(data: any) {
        this.isLoading = true;
        const dataRef = this.datePipe.transform(new Date(data), 'yyyy-MM-dd');
        console.log("dataRef: ", dataRef);
        this.gestaoPlanoBeneficioService.Beneficios_SegundaViaDemonstrativo(dataRef, () => {
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private informarDemostrativo() {
        this.mensagemDeErro =
            'Não existe demostrativo de pagamento para o periodo.';
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private bloquearSimuladorEInformarTerminoDeCarregamento() {
        this.bloquearDemostrativo = true;
        this.isLoading = false;
    }
}
