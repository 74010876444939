export class Categoria {
    cod_categoria?: number;
    nome_categoria?: string;
    videos?: any[];

    constructor(schema: {
        cod_categoria?: number,
        nome_categoria?: string,
        videos?: any[]
    }) {
        this.cod_categoria = schema.cod_categoria;
        this.nome_categoria = schema.nome_categoria;
        this.videos = schema.videos;
    }
}
