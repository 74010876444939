import { Component, OnInit } from '@angular/core';
import { GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-ficha-financeira',
    templateUrl: './ficha-financeira.component.html',
    styleUrls: ['./ficha-financeira.component.css']
})
export class FichaFinanceiraComponent implements OnInit {

    isLoading = false;

    show = true;

    bloquearInfo: boolean;

    mensagemDeErro: string;

    ano: number = 0;

    anos: number[];

    constructor(private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService) { }

    ngOnInit() {
        this.getAnos();
    }

    getAnos()
    {
        this.isLoading = true
        this.gestaoPlanoBeneficioService.Lancamento_AnoContraCheque().subscribe(dadosAnos => {
            this.anos = dadosAnos;
            if(this.anos && this.anos.length === 0)
            {
                this.show = false;
            }
            this.isLoading = false;
        }, httpError => {
            if(httpError.status === 400)
            {
                this.show = false;
                this.isLoading = false;
                return;
            }
            this.informarErroDeCarregamento();
        });
    }

    baixarBaseIR(ano: number) {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService.Beneficio_CalculoBaseIR(ano, () => {
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    baixarFicha(ano: number) {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService.Beneficio_FichaFinanceira(ano, () => {
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquear();
    }

    private bloquear() {
        this.bloquearInfo = true;
        this.isLoading = false;
    }
}
