import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import * as _ from "lodash";
import { FileUploadModel, UploadClientService } from '@fibra/fibra-shared-lib';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Input() visible: boolean = false;
    @Input() uploadedFiles: FileUploadModel[] = [];
    @Output() onFileUpload: EventEmitter<FileUploadModel> = new EventEmitter<FileUploadModel>();
    @Output() onFileRemoved: EventEmitter<string> = new EventEmitter<string>();
    @Output() onHide = new EventEmitter();
    isLoading = false;
    private readonly path = "temp";
    errorMessages: Array<any> = [];
    successMessages: Array<any> = [];
    componentId = crypto.randomUUID();

    constructor(private uploadClientService: UploadClientService,
        private confirmationService: ConfirmationService,) { }

    ngOnInit(): void {
        this.visible = false;
        this.errorMessages = [];
        this.successMessages = [];
    }

    hideModal() {
        this.visible = false;
        this.errorMessages = [];
        this.successMessages = [];
        this.onHide.emit();
    }

    reset() {
        this.fileUpload.clear();
        this.uploadedFiles = [];
        this.errorMessages = [];
        this.successMessages = [];
    }

    uploadHandler(filesObj: { files: File[] }) {
        this.errorMessages = [];
        this.successMessages = [];
        this.setIsLoading(true);
        filesObj?.files?.forEach(file => {
            this.uploadClientService.upload(this.path, file.name, file, (percentage) => { })
                .then(result => {
                    this.errorMessages = [];
                    this.successMessages = [];

                    let fileUploadModel = new FileUploadModel();
                    fileUploadModel.id = result.id;
                    fileUploadModel.file = file;
                    fileUploadModel.name = file.name;
                    fileUploadModel.pathBlob = result.path;
                    fileUploadModel.fileNameBlob = result.filename;
                    fileUploadModel.isUploaded = true;

                    this.uploadedFiles.push(fileUploadModel);
                    this.fileUpload.clear();

                    this.onFileUpload.emit(fileUploadModel);
                    setTimeout(() => {
                        this.successMessages = [];
                    }, 3000);
                    this.setIsLoading(false);
                }).catch(error => {
                    console.error(error);
                    let messagem = "Erro ao enviar o arquivo: Se o arquivo contém caracteres especiais considere renomear o arquivo.";
                    this.errorMessages = [{ severity: 'error', summary: '', detail: messagem }];
                    this.setIsLoading(false);
                });
        });
    }

    removeFile(id: string) {
        this.confirmationService.confirm({
            key: this.componentId,
            message: "Tem certeza que deseja excluir o arquivo?",
            accept: () => {
                if (_.some(this.uploadedFiles, { id: id })) {
                    _.remove(this.uploadedFiles, { id: id });
                    this.onFileRemoved.emit(id);
                }
            }
        });
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        this.fileUpload.disabled = isLoading;
    }
}
