import { Component, OnInit } from '@angular/core';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { TipoPagamento, Boleto, CobrancaGerarBoleto,
         GestaoFinanceiraEmprestimoService,
         PagamentoEmprestimo, DUVIDA_LIGUE, limparCodigoBarra, BloqueioSimulador, CodBloqueioConst
} from '@fibra/fibra-shared-lib';
import { ClipboardService } from 'ngx-clipboard';

@Component({
    selector: 'app-pagamentos-emprestimo',
    templateUrl: './pagamentos-emprestimo.component.html',
    styleUrls: ['./pagamentos-emprestimo.component.css']
})
export class PagamentosEmprestimoComponent implements OnInit {

    erro = false;
    habilitarValor = false;
    chkTotalInadimplencia = false;
    chkResiduoTotalParcela = false;
    trocarIconeCodigo = false;
    trocarIconeImpressao = false;
    isModalVisible = false;
    agoraNao = true;


    isLoading: boolean;
    exibirAposPagamento: boolean;
    exibirPagamento: boolean;
    exibirAposAmortizacao: boolean;
    inadimplente = false;
    residuo = false;

    saldoDisponivelAposPagamento: number;
    saldoDisponivelAposAmortizacao: number;

    boleto: Boleto;
    cobranca: CobrancaGerarBoleto;
    currencyMaskConfig: CurrencyMaskConfig;
    pagamentoEmprestimo: PagamentoEmprestimo;
    erroSimulador: BloqueioSimulador;

    valorPagamento = null;

    readonly DUVIDA_LIGUE = DUVIDA_LIGUE;
    mensagemDeErro = 'Geração de boletos indisponível no momento. Por favor tente novamente mais tarde. Dúvidas ligue 0800 041 4404.';

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService, private clipboardService: ClipboardService) {

        this.currencyMaskConfig = {
            align: 'left',
            allowNegative: false,
            decimal: ',',
            precision: 2,
            prefix: '',
            suffix: '',
            thousands: '.'
        };
    }

    ngOnInit() {
        this.inadimplente = false;
        this.residuo = false;
        this.valorPagamento = null;
        this.habilitarValor = false;
        this.isLoading = true;
        this.exibirPagamento = true;
        this.getPagamento();
    }

    getBloqueio()
    {
        if(!this.pagamentoEmprestimo.segundaTela)
        {
            this.gestaoFinanceiraEmprestimoService.Emprestimo_GetBloqueioSimulacaoEmprestimo().subscribe(data => {
                this.erroSimulador = data;
                if (this.erroSimulador && (this.erroSimulador.codigo === CodBloqueioConst.COD_BOLETO_PENDENTE)) {
                    this.bloquearCard(this.erroSimulador.mensagem);
                     return;
                }
                this.isLoading = false;
            }, () => this.erroDeCarregamento());
        } else { this.isLoading = false; }
    }

    private bloquearCard(mensagemErro: string) {
        this.mensagemDeErro = mensagemErro;
        this.isLoading = false;
        this.erro = true;
    }

    private erroDeCarregamento() {
        this.isLoading = false;
        this.erro = true;
    }

    fecharModal() {
        this.isModalVisible = false;
        this.agoraNao = false;
    }

    tentarNovamente() {
        this.isLoading = true;
        this.erro = false;
        this.isModalVisible = false;
        this.agoraNao = false;
        this.ngOnInit();
    }

    mostrarModal(error: any) {
        console.log(error);
        this.isLoading = false;
        this.erro = true;
        this.isModalVisible = true;
        this.agoraNao = true;
    }

    getPagamento() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetPagamento().subscribe(pagamento => {
            this.pagamentoEmprestimo = pagamento;
            if (this.pagamentoEmprestimo)
            {
                this.getBloqueio();
            }
            this.exibirPagamento = this.pagamentoEmprestimo.pagamento;
        }, () => this.erroDeCarregamento());
    }

    calculaResiduoInadimplencia() {
        this.valorPagamento = 0;

        if (this.inadimplente) {
            this.valorPagamento =  this.pagamentoEmprestimo.totalInadimplencia;
            this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.INADIMPLENCIA;
        }

        if (this.residuo) {
            this.valorPagamento = this.pagamentoEmprestimo.totalResidual;
            this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.RESIDUAL_PRESTACAO;
        }

        if (this.residuo && this.inadimplente) {
            this.valorPagamento = this.pagamentoEmprestimo.totalInadimplencia + this.pagamentoEmprestimo.totalResidual;
            this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.SALDO_RESIDUAL_INADIMPLENTE;
        }
    }

    selecionarQuitacao() {
        this.habilitarValor = false;
        this.valorPagamento = this.pagamentoEmprestimo.valorMaximoPermitido;
        this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.QUITACAO;
        this.saldoDisponivelAposPagamento = this.pagamentoEmprestimo.saldoAposPgQuitacao;

        this.exibirAposPagamento = true;
        this.exibirAposAmortizacao = false;
    }

    selecionarCarencia() {
        this.habilitarValor = false;
        this.valorPagamento = this.pagamentoEmprestimo.saldoCarencia;
        this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.CARENCIA;
        this.saldoDisponivelAposPagamento = this.pagamentoEmprestimo.saldoAposPgCarencia;

        this.exibirAposPagamento = true;
        this.exibirAposAmortizacao = false;
    }

    selecionarAmortizacao() {
        this.habilitarValor = true;
        this.valorPagamento = null;
        this.pagamentoEmprestimo.tipoPagamento = TipoPagamento.AMORTIZACAO;

        this.exibirAposPagamento = false;
        this.exibirAposAmortizacao = true;
        this.calculaValorAposAmortizacao();
    }

    calculaValorAposAmortizacao(): number {
        if (this.valorPagamento > 0 && this.pagamentoEmprestimo.valorMaximoPermitido >= this.valorPagamento) {
            this.saldoDisponivelAposAmortizacao = this.pagamentoEmprestimo.limite + this.valorPagamento;
            return this.saldoDisponivelAposAmortizacao < 0 ? 0 : this.saldoDisponivelAposAmortizacao;
        } else {
            return (this.saldoDisponivelAposAmortizacao =
                this.pagamentoEmprestimo.limite < 0 ? 0 : this.pagamentoEmprestimo.limite);
        }
    }

    obterBoleto() {
        if (this.valorPagamento > 0) {
            this.isLoading = true;
            this.pagamentoEmprestimo.valorPagamento = this.valorPagamento;
            try {
                this.gestaoFinanceiraEmprestimoService.Emprestimo_ObterBoleto(this.pagamentoEmprestimo).subscribe(dados => {
                    this.boleto = dados;
                    if (this.boleto) {
                        this.pagamentoEmprestimo.segundaTela = true;
                        this.pagamentoEmprestimo.idCobranca = this.boleto.id;
                        this.pagamentoEmprestimo.cobrancaPendente = true;
                        this.pagamentoEmprestimo.linhaDigitavel = this.boleto.linhaDigitavel;
                        this.isLoading = false;
                    } else {
                        this.erroDeCarregamento();
                    }
                }, (error) => this.mostrarModal(error));
            } catch {
                this.erroDeCarregamento();
            }
        }
    }

    copiarCodigoBarra() {
       this.trocarIconeCodigo = true;
       this.trocarIconeImpressao = false;
       this.clipboardService.copyFromContent(
           limparCodigoBarra(this.pagamentoEmprestimo.linhaDigitavel)
       );
    }

    imprimirBoleto() {
        this.trocarIconeCodigo = false;
        this.trocarIconeImpressao = true;
        if (this.pagamentoEmprestimo.cobrancaPendente) {
            this.isLoading = true;
            this.gestaoFinanceiraEmprestimoService.Emprestimo_BoletoPDF(
                this.pagamentoEmprestimo.idCobranca, () => { this.isLoading = false; },
                () => {
                    this.erroDeCarregamento()
            }, this );
        }
    }
}
