import { AdesaoSimplificado } from "./adesao-simplificado";
import { DadosFibraDto } from "./dados-fibra.dto";
import { MigracaoModel } from "./migracao.model";

export class ResumoAdesaoDto {

    participanteNome : string;
    cpf: string;
    idAdesao: number;
    idPlano: number;
    planoNome: string;
    matricula: string;
    patrocinadorNome: string;
    idade: number;
    idSituacao: number;
    situacaoParticipante: string;
    tipoSituacao: string;
    dependenteNome: string;
    tutorNome: string;
    dataInscricao: Date;
    isDependenteBeneficiario: boolean;
    isTutor: boolean;
    listaAdesoes: AdesaoSimplificado[];
    nomeSecundario: string;
    nomePrincipal: string;
    textoExibicao: string;
    dadosFibra: DadosFibraDto;
    migracoes: Array<MigracaoModel> = [];
    regimeTributario: string;
}
