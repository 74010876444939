import { Component, OnInit, ViewChild } from '@angular/core';
import { CurrencyMaskConfig } from 'ng2-currency-mask';

import {
    GestaoFinanceiraEmprestimoService,
    SimulacaoEmprestimo,
    SimulacaoValores,
    SaldoDevedor,
    ERRO_CRITICO_DE_CARREGAMENTO,
    Contrato,
    TermoAdesaoService,
    BloqueioSimulador,
    TipoEmp,
    ErroEmprestimo,
    TipoBloqueioConst,
    SignDataModel,
    AlertModalComponent,
    DadosCadastraisService
} from '@fibra/fibra-shared-lib';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';
import { ContratoProntoParaAssinar } from '@fibra/fibra-shared-lib/lib/domain/gestaofinanceira/emprestimo/models/contrato-pronto-para-assinar';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';

@Component({
    selector: 'app-simulador-emprestimo',
    styleUrls: ['./simulador-emprestimo.component.css'],
    templateUrl: './simulador-emprestimo.component.html'
})
export class SimuladorEmprestimoComponent implements OnInit {
    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;

    mostrarSignWidget: boolean = false;
    exibirDialogEmpFim: boolean = false;
    readonly VALOR_MINIMO_EMPRESTIMO = 0;
    readonly TIPO_EMPRESTIMO_PLUS = TipoEmp.EMP_PLUS;
    readonly AMBIENTE_SIMULADO = 'true';
    readonly MENSAGEM_ERRO = ErroEmprestimo;

    bloquearSimulador: boolean;
    clienteTentouSimularEmprestimo = false;
    currencyMaskConfig: CurrencyMaskConfig;
    exibirModalDeConfirmacaoDeEmprestimo = false;
    exibirModalDeEmprestimoConfirmado = false;
    exibirModalDeEmprestimoNegado: boolean;
    exibirModalBloqueioCarteira = false;

    exibirLinkInformacoesAdicionais = false;
    isLoading = true;
   // isModalidadePlus = true;
    mensagemDeErro: string;
    permitirSelecaoDeModalidade = false;
    planoDeParcelamentoEscolhido: SimulacaoValores;
    planosDeParcelamentoDisponiveis: SimulacaoValores[] = [];
    valorContratoEmprestimoSimulado = 0;
    valorEmprestimoSimulado: number = null;//this.VALOR_MINIMO_EMPRESTIMO;
    simulacoesEmprestimo: SimulacaoEmprestimo[] = [];
    simulacaoTradicional: SimulacaoEmprestimo;
    simulacaoPlus: SimulacaoEmprestimo;
    saldoDevedor: SaldoDevedor;
    valorMaximoPermitido: number;
    inputSlid = document.getElementById('valorEmprestimoSimulado');
    emprestimoPendente: boolean;

    novoValorEmp = true;
    clickBtnPercEmp = false;
    msgBloqueio: string;
    seguro: number;
    contrato: Contrato;
    iof: number;

    erroSimulador: BloqueioSimulador;
    dadosCadastrais: DadosCadastraisSinqiaDto;

    get valorEmprestimoSimuladoIsValid() {
        return this.valorEmprestimoSimulado > this.VALOR_MINIMO_EMPRESTIMO && this.valorEmprestimoSimulado <= this.valorMaximoPermitido;
    }

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
                private dadosCadastraisService: DadosCadastraisService,
                private termoAdesaoService: TermoAdesaoService) {
        this.currencyMaskConfig = {
            align: 'left',
            allowNegative: false,
            decimal: ',',
            precision: 2,
            prefix: '',
            suffix: '',
            thousands: '.'
        };
    }

    async ngOnInit() {

        this.dadosCadastrais = await this.dadosCadastraisService.getCadastroGeral(true).toPromise();

        this.isLoading = true;
        let token: any;
        do {
            token = sessionStorage.getItem('accessToken');
            if(token)
            {
                if (!this.verificarAmbienteSimulado()) { this.verficarTemTermoAdesao(); }
            }
        } while (!token);
    }

    verificarTemBloqueio() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetBloqueioSimulacaoEmprestimo().subscribe(data => {
            this.erroSimulador = data;
            if (this.erroSimulador) {
                this.emprestimoPendente = this.erroSimulador.codigo === TipoBloqueioConst.PENDENCIA ? true : false;
                this.bloquearCardSimulacao(this.erroSimulador.mensagem);
                 return;
            } else {
                this.pegarValoresSimulacaoEmprestimo();
            }
        }, () => this.informarErroDeCarregamento());
    }

    verificarAmbienteSimulado(): boolean {
        if (sessionStorage.getItem('isSimulado') === this.AMBIENTE_SIMULADO) {
             this.informarAmbienteSimulado(this.MENSAGEM_ERRO.AMBIENTE_SIMULADO); return true;
        }
        return false;
    }

    verficarTemTermoAdesao() {
        this.termoAdesaoService.isTermAccepted(true).subscribe( termoAceito => {
            if (!termoAceito) {
                this.informarTermoNaoAssinado(this.MENSAGEM_ERRO.TERMO_NAO_ASSINADO); return;
            } else {
                this.verificarTemBloqueio();
            }
        });

    }

    pegarValoresSimulacaoEmprestimo() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetValoresSimulacaoEmprestimo().subscribe( dataValorSimulacao => {
            this.simulacoesEmprestimo = dataValorSimulacao;

            if (this.simulacoesEmprestimo) {
                this.permitirSelecaoDeModalidade = this.simulacoesEmprestimo.length > 1;
                this.simulacaoPlus = this.simulacoesEmprestimo.filter( v => v.tipoEmpId === this.TIPO_EMPRESTIMO_PLUS)[0];
                this.valorMaximoPermitido = this.simulacaoPlus.valorMaximoLiquido;
                this.isLoading = false;
            } else {
                this.informarErroDeCarregamento();
                return;
            }
        }, () => this.informarErroDeCarregamento());
    }

    alteraValorEmp() {
        this.novoValorEmp = true;
        this.clickBtnPercEmp = false;
    }

    obterPlanosDeEmprestimo() {
        this.isLoading = true;
        this.novoValorEmp = false;
        this.clickBtnPercEmp = true;
        this.planoDeParcelamentoEscolhido = undefined;
        this.planosDeParcelamentoDisponiveis = [];

        this.simulacaoPlus.valorSolicitado = this.valorEmprestimoSimulado;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_SimularEmprestimo(this.simulacaoPlus)
        .subscribe(data => {
            if (data && data.simulacao && data.simulacao.length > 0) {
                this.planosDeParcelamentoDisponiveis = data.simulacao.sort(this.ordenarPorQuantidadeDecrescenteDePercentual);
            }
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    solicitarEmprestimo() {
        this.clienteTentouSimularEmprestimo = true;

        if (this.valorEmprestimoSimuladoIsValid) {
            this.exibirModalDeConfirmacaoDeEmprestimo = true;
        }
    }

    emprestimoSolicitado(contratoParaAssinar: ContratoProntoParaAssinar) {
        this.mostrarSignWidget = true;
        setTimeout(() => {
            this.signWidget.carregarWidget({
                signKey: contratoParaAssinar.idDaRequisicaoDaAssinatura,
                idDocumento: contratoParaAssinar.idDoDocumento
            });
        }, 1000);
    }

    depoisDeConfirmarEmprestimo(signData: SignDataModel) {
        if(signData == null) {
            window.location.reload();
            return;
        }

        this.gestaoFinanceiraEmprestimoService.EmprestimoAssinado(0, signData.idDocumento).subscribe((o) => {
            this.alertModalComponent.show(
                "Seu contrato de empréstimo foi assinado e está em processamento.\n" +
                `Após a conclusão do processo de assinatura será enviado um e-mail para ${this.dadosCadastrais.contato.email} com seu contrato assinado em anexo.\n` +
                "Caso não encontre o e-mail em sua Caixa de Entrada, verifique sua caixa de Span e/ou Lixo Eletrônico.", true, false, true, true, true
            );
        });
    }

    assinaturaFinalizada() {
        this.exibirModalDeConfirmacaoDeEmprestimo = false;
        this.mostrarSignWidget = false;
        window.location.reload();
    }

    private ordenarPorQuantidadeDecrescenteDePercentual(a: SimulacaoValores, b: SimulacaoValores) {
        return a.percentual < b.percentual ? 1 : a.percentual > b.percentual ? -1 : 0;
    }

    private bloquearSimuladorEInformarTerminoDeCarregamento() {
        this.bloquearSimulador = true;
        this.isLoading = false;
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = ERRO_CRITICO_DE_CARREGAMENTO;
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private informarTermoNaoAssinado(mensagemErro: string) {
        this.mensagemDeErro = mensagemErro;
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private informarAmbienteSimulado(mensagemErro: string) {
        this.mensagemDeErro = mensagemErro;
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private bloquearCardSimulacao(mensagemErro: string) {
        this.mensagemDeErro = mensagemErro;
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    obterValores() {
        this.seguro = this.planoDeParcelamentoEscolhido.taxasAntecipadas.find( x => x.descricao === 'Seguro').valor;
        this.iof = this.planoDeParcelamentoEscolhido.iOFTotal;
    }

    valorTotal(event) {
         // tslint:disable-next-line:radix
        if (event.value === parseInt (this.valorMaximoPermitido.toString())) {
            this.valorEmprestimoSimulado = this.valorMaximoPermitido;
        }
    }
}
