import { Component } from '@angular/core';

import { AdesaoService, ArquivosService, TipoSeDocumento, TipoSituacaoParticipante } from '@fibra/fibra-shared-lib';
import { NomeArquivos } from '@fibra/fibra-shared-lib';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';

@Component({
    selector: 'app-doc-saldamento-plano-cd',
    templateUrl: './doc-saldamento-plano-cd.component.html',
    styleUrls: ['./doc-saldamento-plano-cd.component.css']
})

export class DocSaldamentoPlanoCDComponent {
    SinteseSaldamentoAssistido : number = TipoSeDocumento.SinteseSaldamentoAssistido;
    SinteseSaldamentoNovoPlanoAtivos : number = TipoSeDocumento.SinteseSaldamentoNovoPlanoAtivos;
    SinteseSaldamentoAtivoAssistidos : number = TipoSeDocumento.SinteseSaldamentoAtivoAssistidos;
    fileNamesCD : string[] = [];
    fileNamesBD : string[] = [];
    fileNamesSintese: string[] = [];
    isLoading = true;
    resumoAdesao: ResumoAdesaoDto;

    constructor(private arquivosService: ArquivosService,
                private adesaoService: AdesaoService,) { }

    ngOnInit() {

        this.isLoading = true;

        this.resumoAdesao = this.adesaoService.getAdesaoContexto();

        this.arquivosService.getListSeDocs(TipoSeDocumento.SinteseSaldamentoAtivoAssistidos).subscribe(files =>
            {

                this.fileNamesBD = files;
            },
            (err) => {
                this.isLoading = false;
            }
        );

        if( this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.ATIVO || this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.AUTOPATR_PARCIAL || this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.AUTOPATR_TOTAL)
        {
            this.arquivosService.getListSeDocs(TipoSeDocumento.SinteseSaldamentoNovoPlanoAtivos).subscribe(files =>
                {
                    this.fileNamesCD = files;
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
        }
        else{

            this.arquivosService.getListSeDocs(TipoSeDocumento.SinteseSaldamentoAssistido).subscribe(files =>
                {
                    this.fileNamesSintese = files;
                    this.isLoading = false;
                },
                (err) => {
                    this.isLoading = false;
                }
            );
        }
    }

    AfterViewInit(){
        this.isLoading = false;
    }

    downloadFile(idDoc: string, fileName: string) {
        this.isLoading = true;
        this.arquivosService.getSeDocsByName(idDoc,fileName);
        setTimeout(() => {
            this.isLoading = false;
          }, 2000);
    }
}
