import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../storage.service';
import { ResumoAdesaoDto } from '../../models/sinqia-api/resumo-adesao.dto';
import { STORAGE_KEYS } from '../../constants';


@Injectable()
export class AuthCdService {
    url: any;

    constructor(
        @Inject('environment') private environment,
        private storageService: StorageService,
        private httpClient: HttpClient) {
        this.url = this.environment.API_LOGIN;
    }

    getHandshakeToken(accessTokenBd: string) {
        return new Promise<boolean>((resolve, reject) => {
            let urlBaseCd = this.environment.API_LOGIN
            let endpointHandshakeToken = `${urlBaseCd}/api/Account/singlesignon/previdenciacd`;
            let httpHeader = new HttpHeaders().set('Authorization', `Bearer ${accessTokenBd}`);
            this.httpClient.get<any>(endpointHandshakeToken, {
                headers: httpHeader
            }).subscribe(token => {
                this.SalvarHandshakeToken(token.accessToken);
                resolve(true);
            }, error => {
                reject(error);
            });
        });
    }

    SalvarHandshakeToken(token: string) {
        this.storageService.setSecure(STORAGE_KEYS.HANDSHAKE_TOKEN, token);
    }
}
