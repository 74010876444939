import { ParticipantePendenteAprovacaoResponse } from "./participante-pendente-aprovacao-response.model";

export class AdesaoParticipantePendenteAprovacaoResponse {
    nomeParticipante: string;
    cpfParticipante: string;
    idAdesao: number;
    idTipoAdesao: number;
    idPlano: number;
    participantesPendentesAprovacao: Array<ParticipantePendenteAprovacaoResponse>;
    participantesDescontoAprovado: Array<ParticipantePendenteAprovacaoResponse>;
}
