import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, SessionInitService, CPF, DadosCadastraisService, AuthServiceLoginResponse, AuthCdService } from '@fibra/fibra-shared-lib';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-entrar',
    templateUrl: './entrar.component.html',
    styleUrls: ['./entrar.component.css']
})
export class EntrarComponent implements OnInit {
    readonly CPF = CPF;

    formulario: FormGroup;
    isErrorDialogVisible = false;
    isLoading = false;
    errorMessage: string;
    submitAttempted = false;
    returnUrl: string;
    naoCadastrado = false;
    grecaptcha: any = (window as any).grecaptcha;

    private readonly mensagemErro = 'Algo deu errado. Por favor tente novamente mais tarde.';

    get isAndroidDevice() {
        return navigator.userAgent.match(/Android/i);
    }

    get isAppleDevice() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }

    constructor(
        @Inject('environment') private environment,
        private router: Router,
        private authService: AuthService,
        private sessionInitService: SessionInitService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService
    ) {
        this.formulario = new FormGroup({
            username: new FormControl('', [
                Validators.required,
                Validators.minLength(CPF.LENGTH_WITHOUT_MASK),
                Validators.maxLength(CPF.LENGTH_WITHOUT_MASK)
            ]),
            password: new FormControl('', [Validators.required])
        });
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] ?
            this.route.snapshot.queryParams['returnUrl'] : '/login';
    }

    get username() {
        return this.formulario.get('username');
    }

    get password() {
        return this.formulario.get('password');
    }

    validar() {
        this.submitAttempted = true;

        if (this.formulario.valid) {
            this.doLogin();
        }
    }

    private doLogin() {
        this.isLoading = true;
        this.grecaptcha.ready(() => {
            this.grecaptcha.execute(this.environment.GOOGLE_SETTINGS.recaptchaSiteKey, { action: 'aa_login' })
            .then((token: string) => {
                this.authService.login(
                    this.username.value,
                    this.username.value,
                    this.password.value,
                    '',
                    token
                ).subscribe(tokenData => {
                    this.sessionInitService.sessionInit(tokenData)
                        .then(success => {
                            if (success) {
                                this.redirecionarLogin();
                            }
                        })
                        .catch(error => {
                            console.error(error);
                        });
                }, (error) => {
                    this.handleError(error);
                });
            });
        });
    }

    private redirecionarLoginDuplo() {
        this.router.navigate([
            '/login/selecionar-perfil',
            this.username.value,
            this.password.value,
            this.returnUrl
        ], { skipLocationChange: true });
    }

    private redirecionarLogin() {
        if (this.returnUrl.includes('/login')) {
            this.router.navigate(['/painel']);
        } else {
            this.router.navigateByUrl(decodeURIComponent(this.returnUrl));
        }
    }

    private handleError(error) {
        console.error(error);
        let message = this.mensagemErro;

        if (error != null && !(error === undefined)) {
            const mensagemErro = error.error.error_description;
            switch (error.status) {
                case 400: {
                    if (error.error.error === "password_expired") {
                        let cpfNumers = this.username.value.replace(/\D/g, '');

                        this.confirmationService.confirm({
                            message: 'Sua senha expirou! Você será redirecionado para alterar sua senha.',
                            accept: () => {
                                this.router.navigate([`/alterar-senha/${cpfNumers}`]);
                            },
                            rejectVisible: false,
                            acceptLabel: "OK"
                        });
                    } else {
                        message = mensagemErro ? mensagemErro : 'Serviço de autenticação não encontrado';
                        this.showErrorMessage(message);
                    }
                    break;
                }
                case 404: {
                    message = 'Serviço de autenticação não encontrado';
                    this.showErrorMessage(message);
                    break;
                }
            }
        }
        this.isLoading = false;
    }

    private showErrorMessage(message: string) {
        this.isLoading = false;
        this.naoCadastrado = false;
        this.errorMessage = message;
        if (this.errorMessage && this.errorMessage.includes('Senha não cadastrada.')) {
            this.naoCadastrado = true;
        }
        this.isErrorDialogVisible = true;
    }
}
