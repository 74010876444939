import { CadastroGeral } from './../models/cadastro-geral.model';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Service } from './service';
import {
    GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem,
    GestaoPlanoCadastroServiceCadastrosGetCadastroEmailResponseItem,
    GestaoPlanoCadastroServiceCadastrosGetGrupoDataResponse,
    GestaoPlanoCadastroServiceCadastrosGetInfoCadastraisResponse,
    GestaoPlanoCadastroServiceCadastrosGetResponse,
    GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse,
    GestaoPlanoCadastroServiceDependenteGetDependenteBenefResponseItem,
    GestaoPlanoCadastroServiceDependenteGetDesignadoResponseItem,
    GestaoPlanoCadastroServiceTelefonesFilterResponse,
    GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem,
    GestaoPlanoCadastroTelefonesGetTelefoneSMSResponse,
    GestaoPlanoCadastroServiceCadastrosGetAposentadosMesResponse,
    LogradouroResponse,
    DadosEndereco,
    DesignadoModel
} from '../models';
import { formatarTelefone } from '../functions';
import { map, tap } from 'rxjs/internal/operators';
import { Observable } from 'rxjs';
import { Endereco } from '../models/sinqia-api/endereco';
import { Contato } from '../models/sinqia-api/contato';
import { BeneficiarioResponseDto } from '../models/sinqia-api/beneficiario-response.dto';
import { AdesaoSinqiaDto } from '../models/sinqia-api/adesao-sinqia.dto';
import { TipoSituacaoParticipante } from '../constants/tipo-situacao-participante';
import { ContatoDto } from '../models/sinqia-api/contato.dto';
import { StorageService } from './storage.service';
import { DadosCadastraisService } from './dados-cadastrais.service';
import { HttpClientService } from './http-client.service';
import { ETokenApi } from '../enums';
import { HttpHeaderService } from './http-header.service';
import { HEADERS } from '../constants';

@Injectable()
export class GestaoPlanoCadastroService extends Service {
    constructor(http: HttpClient, @Inject('environment') private environment,
        private storageService: StorageService,
        private dadosCadastraisService: DadosCadastraisService,
        private httpClientService: HttpClientService,
        private httpHeaderService: HttpHeaderService) {
        super(http);
        this.url = this.environment.API_GESTAO_PLANO_CADASTRO;
    }
    static emitirDescricaoLogradouro = new EventEmitter<string>();

    private url: string;

    Cadastros_ObterAdesao(): Observable<AdesaoSinqiaDto> {
        const url = `${this.url}/Adesao/ObterAdesao`;
        return this.get<AdesaoSinqiaDto>(url);
    }

    Cadastros_ObterListaAdesoes(cpf: string,): Observable<AdesaoSinqiaDto[]> {
        const url = `${this.url}/Adesao/ObterListaAdesoes/${cpf}`;
        return this.httpClientService.get<AdesaoSinqiaDto[]>(url, ETokenApi.TokenApiBd);
    }

    addDescricaoLogradouro(descricaoLogradouro: string) {
        GestaoPlanoCadastroService.emitirDescricaoLogradouro.emit(descricaoLogradouro);
    }

    consultaCEPCorreios(cep: string): Observable<DadosEndereco> {
        const url = `${this.url}/Cadastros/ConsultaCEPCorreios/${cep}`;
        return this.get<DadosEndereco>(url);
    }

    Cadastros_ConsultaPlanilhaDir(
        ordem: number,
        percent: number
    ): Observable<GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem[]> {
        const url = `${this.url}/Cadastros/ConsultaPlanilhaDir?ordem=${ordem}&percent=${percent}`;
        return this.cachedGet<GestaoPlanoCadastroServiceCadastrosConsultaPlanilhaDirResponseItem[]>(url);
    }

    Cadastros_GetAniversariantes(): Observable<GestaoPlanoCadastroServiceCadastrosGetResponse[]> {
        const url = `${this.url}/Cadastros/GetAniversariantes`;
        return this.cachedGet<GestaoPlanoCadastroServiceCadastrosGetResponse[]>(url);
    }

    Cadastros_GetAposentadosMes(): Observable<GestaoPlanoCadastroServiceCadastrosGetAposentadosMesResponse[]> {
        const url = `${this.url}/Cadastros/GetAposentadosMes`;
        return this.cachedGet<GestaoPlanoCadastroServiceCadastrosGetAposentadosMesResponse[]>(url);
    }

    Cadastros_Get(): Observable<GestaoPlanoCadastroServiceCadastrosGetResponse> {
        const url = `${this.url}/Cadastros/0`;
        return this.get<GestaoPlanoCadastroServiceCadastrosGetResponse>(url);
    }

    Cadastros_GetCadastroEmail(): Observable<GestaoPlanoCadastroServiceCadastrosGetCadastroEmailResponseItem[]> {
        const url = `${this.url}/Cadastros/GetCadastroEmail`;
        return this.cachedGet<GestaoPlanoCadastroServiceCadastrosGetCadastroEmailResponseItem[]>(url);
    }

    Cadastros_GetInfoCadastrais(): Observable<GestaoPlanoCadastroServiceCadastrosGetInfoCadastraisResponse> {
        const url = `${this.url}/Cadastros/GetInfoCadastrais`;
        return this.get<GestaoPlanoCadastroServiceCadastrosGetInfoCadastraisResponse>(url);
    }

    Cadastros_GetCadastroGeral(): Observable<CadastroGeral> {
        const url = `${this.url}/Cadastros/GetCadastroGeral`;
        return this.get<CadastroGeral>(url);
    }

    Cadastros_GetContatoSinqia(): Observable<ContatoDto> {
        const url = `${this.url}/Cadastro/GetContato`;
        return this.get<ContatoDto>(url);
    }

    Cadastros_GetLogradouro(): Observable<any> {
        const url = `${this.url}/Cadastros/GetLogradouro`;
        return this.get<LogradouroResponse>(url);
    }

    Cadastros_GetUsuario(): Observable<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse> {
        const url = `${this.url}/Cadastros/0`;
        return this.get<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse>(url);
    }

    Cadastros_GetGrupoData(cache: boolean = true): Observable<GestaoPlanoCadastroServiceCadastrosGetGrupoDataResponse> {
        const url = `${this.url}/Cadastros/GetGrupoData?usaSimulacao=false`;
        if (cache) {
            return this.cachedGet<GestaoPlanoCadastroServiceCadastrosGetGrupoDataResponse>(url);
        } else {
            return this.get<GestaoPlanoCadastroServiceCadastrosGetGrupoDataResponse>(url);
        }
    }

    Cadastros_NumBeneficos(): Observable<any> {
        const url = `${this.url}/Cadastros/NumBeneficos`;
        return this.get<any>(url);
    }

    Dependente_GetDependenteBenef(): Observable<GestaoPlanoCadastroServiceDependenteGetDesignadoResponseItem[]> {
        const url = `${this.url}/Dependente/GetDependenteBenef`;
        return this.cachedGet<GestaoPlanoCadastroServiceDependenteGetDependenteBenefResponseItem[]>(url);
    }

    Dependente_GetDesignado(): Observable<GestaoPlanoCadastroServiceDependenteGetDesignadoResponseItem[]> {
        const url = `${this.url}/Dependente/GetDesignado`;
        return this.cachedGet<GestaoPlanoCadastroServiceDependenteGetDesignadoResponseItem[]>(url);
    }

    Telefones_Filter(): Observable<GestaoPlanoCadastroServiceTelefonesFilterResponse> {
        const url = `${this.url}/Telefones/Filter`;
        return this.cachedPost<GestaoPlanoCadastroServiceTelefonesFilterResponse>(url, {
            fields: [
                {
                    fieldName: 'RegFibra',
                    expression: 'Equals'
                }
            ],
            page: 1,
            resultsPerPage: 10,
            totalResults: 0
        });
    }

    Telefones_GetContatos(): Observable<GestaoPlanoCadastroServiceTelefonesFilterResponse> {
        const url = `${this.url}/Telefones/Filter`;
        return this.post<GestaoPlanoCadastroServiceTelefonesFilterResponse>(url, {
            fields: [
                {
                    fieldName: 'RegFibra',
                    expression: 'Equals'
                }
            ],
            page: 1,
            resultsPerPage: 10,
            totalResults: 0
        });
    }

    Telefones_GetCelular(): Observable<GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem[]> {
        const url = `${this.url}/Telefones/GetCelular?sms=true`;
        return this.get<GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem[]>(url);
    }

    Telefones_GetTelefoneSMS(): Observable<any> {
        const url = `${this.url}/Telefones/GetTelefoneSMS`;
        return this.cachedGet<GestaoPlanoCadastroTelefonesGetTelefoneSMSResponse>(url).pipe(
            map(telefone => this.formatarTelefone(telefone))
        );
    }

    Telefones_Update(celular: GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem): Observable<any> {
        const url = `${this.url}/Telefones/Update`;
        return this.post<any>(url, celular)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    Endereco_Update(cadastro: CadastroGeral): Observable<CadastroGeral> {
        const url = `${this.url}/CadatroSinqia/UpdateEnderecoCadastro`;
        return this.post<CadastroGeral>(url, cadastro)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    Contato_Update(cadastro: CadastroGeral): Observable<CadastroGeral> {
        const url = `${this.url}/CadatroSinqia/UpdateContatoCadastro`;
        return this.post<CadastroGeral>(url, cadastro)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    DadosBancario_Update(cadastro: CadastroGeral): Observable<CadastroGeral> {
        const url = `${this.url}/CadastroSinqia/UpdateDadosBancariosCadastro`;
        return this.post<CadastroGeral>(url, cadastro)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    private formatarTelefone(dadosTelefone: GestaoPlanoCadastroTelefonesGetTelefoneSMSResponse) {
        if (!dadosTelefone) {
            return '(Telefone Nâo Cadastrado)';
        }

        return formatarTelefone(dadosTelefone.ddd, dadosTelefone.telefone);
    }

    atualizarContato(idAdesao: number, contato: Contato) {
        const url = `${this.url}/CadastroSinqia/AtualizaContato/${idAdesao}`;
        if (contato.celular)
            contato.telefone = contato.telefone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g, '');
        if (contato.celular)
            contato.celular = contato.celular.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '').replace(/ /g, '');

        return this.put<Contato>(url, contato)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    atualizarEndereco(idAdesao: number, endereco: Endereco) {
        const url = `${this.url}/CadastroSinqia/AtualizaEndereco/${idAdesao}`;
        return this.put<Endereco>(url, endereco)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    atualizarContaBancaria(idAdesao: number, contaBancariaFormData: FormData) {
        const url = `${this.url}/CadastroSinqia/AtualizaContaBancaria/${idAdesao}`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.put<boolean>(url, contaBancariaFormData, headersAuth)
            .pipe(tap(() => {
                this.dadosCadastraisService.atualizarDadosCadastrais();
            }));
    }

    getBeneficiarios(idAdesao: number | null = null): Observable<Array<BeneficiarioResponseDto>> {
        let headers: HttpHeaders | null = null;
        if (idAdesao)
            headers = new HttpHeaders().append(HEADERS.ADESAO_ID, idAdesao.toString());
        const url = `${this.url}/CadastroSinqia/GetBeneficiarios`;
        return this.httpClientService.get<Array<BeneficiarioResponseDto>>(url, ETokenApi.TokenApiBd, headers);
    }

    getDesignados(idAdesao: number | null = null): Observable<Array<DesignadoModel>> {
        let headers: HttpHeaders | null = null;
        if (idAdesao)
            headers = new HttpHeaders().append(HEADERS.ADESAO_ID, idAdesao.toString());
        const url = `${this.url}/CadastroSinqia/GetDesignados`;
        return this.httpClientService.get<Array<DesignadoModel>>(url, ETokenApi.TokenApiBd, headers);

    }

    userIsActive(tipoSituacao: string): boolean {
        return (tipoSituacao &&
            (tipoSituacao != TipoSituacaoParticipante.APOSENTADO) &&
            (tipoSituacao != TipoSituacaoParticipante.PENSIONISTA));
    }
}
