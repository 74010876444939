import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertModalComponent, AdesaoService, ETipoPlano } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-certificado-inscricao',
    templateUrl: './certificado-inscricao.component.html',
    styleUrls: ['./certificado-inscricao.component.css']
})
export class CertificadoInscricaoComponent implements OnInit {
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    msgs: any[];
    isLoading: boolean = false;

    constructor(private adesaoService: AdesaoService,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    getCertificadoInscricao() {
        this.isLoading = true;
        this.adesaoService.getCertificadoInscricao(() => {
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }
}