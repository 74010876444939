import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { STORAGE_KEYS } from "../constants";
import { DadosCadastraisSinqiaDto } from "../models/sinqia-api/dados-cadastrais-sinqia.dto";
import { Service } from "./service";
import { StorageService } from "./storage.service";
import { SessionInitService } from "./session-init.service";


@Injectable()
export class DadosCadastraisService extends Service {
    cadastroUrl = `${this.environment.API_GESTAO_PLANO_CADASTRO}/CadastroSinqia/GetCadastroGeral`;
    onDadosCadastraisCarregado: EventEmitter<DadosCadastraisSinqiaDto> = new EventEmitter<DadosCadastraisSinqiaDto>();
    onDadosPerfilCarregado: EventEmitter<any> = new EventEmitter<any>();

    constructor(httpClient: HttpClient,
        @Inject('environment') private environment,
        private storageService: StorageService
    ) {
        super(httpClient);
    }

    getCadastroGeral(cached: boolean = false): Observable<DadosCadastraisSinqiaDto> {
        if (cached) {
            let dadosCad = this.storageService.getSecureParse<DadosCadastraisSinqiaDto>(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA);
            if (dadosCad) {
                this.onDadosCadastraisCarregado.emit(dadosCad);
                return of(dadosCad);
            }
        }

        return this.get<DadosCadastraisSinqiaDto>(this.cadastroUrl)
            .pipe(tap(dadosCad => {
                let dadosCadJson = JSON.stringify(dadosCad);
                this.storageService.setSecure(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA, dadosCadJson);
                this.onDadosCadastraisCarregado.emit(dadosCad);
            }));
    }

    async carregarDadosDoPerfil(accessToken: string | null = null) {
        let httpHeader = new HttpHeaders();
        this.storageService.remove(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL);

        if (accessToken) {
            httpHeader = new HttpHeaders({ 'Authorization': `Bearer ${accessToken}` });
        }
        var url = `${this.environment.API_GESTAO_PLANO_CADASTRO}/perfil/autoatendimento`;
        var dadosDoPerfil = await this.httpClient.get<any>(url, { headers: httpHeader })
        .pipe(tap(dadosCad => {
            let dadosCadJson = JSON.stringify(dadosCad);
            this.storageService.set(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL, dadosCadJson);
            this.onDadosPerfilCarregado.emit(dadosCad);
        })).toPromise();
        return dadosDoPerfil;
    }

    async naoMostrarMaisTelaInicialSaldado(accessToken: string | null = null) {
        var accessToken = sessionStorage.getItem('accessToken');
        let httpHeader = new HttpHeaders();
        if (accessToken) {
            httpHeader = new HttpHeaders({ 'Authorization': accessToken });
        }
        var url = `${this.environment.API_GESTAO_PLANO_CADASTRO}/perfil/autoatendimento/anotar`;
        var dadosDoPerfil = await this.httpClient.post<any>(url, { Chave: "tela-inicial-plano-saldo", Nota: "pular" }, { headers: httpHeader }).toPromise();
        this.storageService.set(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL, JSON.stringify(dadosDoPerfil));
    }

    obterPerfil() {
        return this.storageService.getParse<{
            usuario: string,
            anotacoes: Array<{ chave: string, nota: string, criadoEm: string }>
        }>(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL);
    }

    carregarDadosCadastrais(accessToken: string | null = null) {
        return new Promise<boolean>((resolve, reject) => {
            this.storageService.remove(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA);
            let httpHeader = new HttpHeaders();
            if (accessToken)
                httpHeader = new HttpHeaders({ 'Authorization': `Bearer ${accessToken}` });

            this.httpClient.get<DadosCadastraisSinqiaDto>(this.cadastroUrl, { headers: httpHeader })
                .pipe(tap(dadosCad => {
                    let dadosCadJson = JSON.stringify(dadosCad);
                    this.storageService.setSecure(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA, dadosCadJson);
                    this.onDadosCadastraisCarregado.emit(dadosCad);
                })).subscribe(dadosCad => {
                    resolve(dadosCad != null);
                }, error => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    limparDadosCadastraisLocalStorage() {
        this.storageService.remove(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA);
    }

    atualizarDadosCadastrais() {
        this.getCadastroGeral(false).subscribe();
    }

    getCadastroGeralLocal() {
        let dadosCad = this.storageService.getSecureParse<DadosCadastraisSinqiaDto>(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA);
        return dadosCad;
    }
}
