import { EnderecoDto } from "./endereco.dto";
import { EstadoCivilSinqiaDto } from "./estado-civil-sinqia.dto";
import { PessoaPoliticamenteExpostaDto } from "./pessoa-politicamente-exposta.dto";


export class PessoaDto {
    id?: number;
    nomeCompleto: string;
    cpf: string;
    rg?: string | null;
    estadoCivil: EstadoCivilSinqiaDto;
    cpfRepresentanteLegal?: string;
    sexo: string;
    email: string;
    quartoGrauParentesco?: boolean;
    dataNascimento: Date;
    telefoneCelular: string;
    politicamenteExposto?: PessoaPoliticamenteExpostaDto | null = null;
    dependenteFinanceiro?: boolean;
    endereco: EnderecoDto;
}
