import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { forkJoin } from 'rxjs';

import { DadosCadastraisService, formatarTelefone } from '@fibra/fibra-shared-lib';
import { GestaoPlanoCadastroService, GestaoRelacionamentoComunicacaoService } from '@fibra/fibra-shared-lib';
import {
    GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem,
    GetListaPerfilComunicResponseItem as GetListaPerfilComunicResponseItem
} from '@fibra/fibra-shared-lib';
import { ERRO_CRITICO_DE_CARREGAMENTO } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-perfil-comunicacao-form',
    styleUrls: ['./perfil-comunicacao-form.component.css'],
    templateUrl: './perfil-comunicacao-form.component.html',
    animations: [
        trigger('esconderMensagem', [
            state(
                'true',
                style({
                    opacity: 0
                })
            ),
            transition('* => true', [
                style({
                    opacity: 1
                }),
                animate(200)
            ])
        ])
    ]
})
export class PerfilComunicacaoFormComponent implements OnInit {
    readonly ERRO_CRITICO_DE_CARREGAMENTO = ERRO_CRITICO_DE_CARREGAMENTO;

    // flags indicadoras de loading
    alterandoOpcaoSMS: boolean[] = [];
    alterandoRecebimentoInformativo: boolean[] = [];
    cadastrandoNovoCelular = false;
    isLoading = true;

    // feedbacks de alterações do usuário
    feedbackAlteracaoInformativo: Feedback[] = [];
    feedbackAlteracaoSMS: Feedback[] = [];
    feedbackCadastroNovoCelular: Feedback = {
        animate: false,
        animationDelay: undefined,
        display: false,
        success: undefined
    };

    // dados do formulário
    emailCadastrado: string;
    informativos: GetListaPerfilComunicResponseItem[] = [];
    novoNumeroCelular: string;
    celulares: GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem[] = [];

    // auxiliar de UX
    erroAoCarregar = false;
    usuarioTentoucadastrarNovoCelular = false;

    get clientePossuiCelularMarcadoParaReceberSMS() {
        return this.celulares.some(telefone => telefone.sms);
    }

    get novoTelefoneIsInvalid() {
        return !this.novoNumeroCelular || this.novoNumeroCelular.length < 10 || this.novoNumeroCelular.length > 11;
    }

    constructor(
        private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private gestaoRelacionamentoComunicacaoService: GestaoRelacionamentoComunicacaoService,
        private dadosCadastraisService: DadosCadastraisService
    ) { }

    ngOnInit() {
        forkJoin([
            this.dadosCadastraisService.getCadastroGeral(true),
            this.gestaoPlanoCadastroService.Telefones_GetCelular(),
            this.gestaoRelacionamentoComunicacaoService.ListaPerfilComunic_GetListaPerfilComunic()
        ]).subscribe(
            ([dadosusuario, celulares, dadosPerfilComunicacao]) => {
                this.emailCadastrado = dadosusuario.contato.email;
                this.setarDadosDosInformativos(dadosPerfilComunicacao);
                this.setarDadosDosCelularesParaSMS(celulares);
                this.isLoading = false;
            },
            () => (this.erroAoCarregar = true)
        );
    }

    alterarRecebimentoDeInformativo(
        informativo: GetListaPerfilComunicResponseItem,
        index: number
    ) {
        this.alterandoRecebimentoInformativo[index] = true;
        this.sanitizarFeedbackAlteracaoRecebimentoInformativo(index);

        // este setTimeout serve apenas para dar ao usuário a sensação de processamento
        setTimeout(() => {
            this.gestaoRelacionamentoComunicacaoService
                .ListaPerfilComunic_AlteraPerfilComunic(informativo.idPerfil, Number(informativo.ativo) === 1)
                .subscribe(
                    response => {
                        if (!response.success) {
                            informativo.ativo = Number(informativo.ativo) === 1 ? 0 : 1;
                        }

                        this.atualizarFeedbackAlteracaoInformativo(index, response.success);
                    },
                    () => {
                        informativo.ativo = Number(informativo.ativo) === 1 ? 0 : 1;
                        this.atualizarFeedbackAlteracaoInformativo(index, false);
                    }
                );
        }, 300);
    }

    cadastrarNovoCelular() {
        this.usuarioTentoucadastrarNovoCelular = true;

        if (this.novoTelefoneIsInvalid) {
            return;
        }

        this.cadastrandoNovoCelular = true;
        this.sanitizarFeedbackCadastroNovoCelular();

        // este setTimeout serve apenas para dar ao usuário a sensação de processamento
        setTimeout(() => {
            const novoNumero = this.novoNumeroCelular.replace(/[^0-9]/g, '');
            const ddd = novoNumero.slice(0, 2);
            const celular = novoNumero.slice(2);

            this.gestaoRelacionamentoComunicacaoService.PerfilComunicacao_SetCelular(ddd, celular).subscribe(
                response => {
                    this.atualizarFeedbackCadastroNovoCelular(response.success);
                },
                () => {
                    this.atualizarFeedbackCadastroNovoCelular(false);
                }
            );
        }, 300);
    }

    finalizarAnimacaoFeedbackAlteracaoInformativo(e: AnimationEvent, index: number) {
        if (String(e.toState) === 'true') {
            this.sanitizarFeedbackAlteracaoRecebimentoInformativo(index);
        }
    }

    finalizarAnimacaoFeedbackAlteracaoSMS(e: AnimationEvent, index: number) {
        if (String(e.toState) === 'true') {
            this.sanitizarFeedbackAlteracaoSMS(index);
        }
    }

    finalizarAnimacaoFeedbackCadastroNovoCelular(e: AnimationEvent) {
        if (String(e.toState) === 'true') {
            this.sanitizarFeedbackCadastroNovoCelular();
        }
    }

    formatarTelefone(telefone: GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem) {
        return formatarTelefone(telefone.ddd, telefone.telefone);
    }

    mudarCelularQueRecebeSMS(celular: GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem, index: number) {
        this.alterandoOpcaoSMS[index] = true;
        this.sanitizarFeedbackAlteracaoSMS(index);

        // Este setTimeout serve apenas para dar ao usuário a sensação de processamento
        setTimeout(() => {
            this.gestaoPlanoCadastroService.Telefones_Update(celular).subscribe(
                response => {
                    if (!response.success) {
                        celular.sms = !celular.sms;
                    }

                    this.atualizarFeedbackAlteracaoSMS(index, response.success);
                },
                () => {
                    celular.sms = !celular.sms;
                    this.atualizarFeedbackAlteracaoSMS(index, false);
                }
            );
        }, 300);
    }

    private atualizarFeedbackAlteracaoInformativo(index: number, success: boolean) {
        this.feedbackAlteracaoInformativo[index].display = true;
        this.feedbackAlteracaoInformativo[index].success = success;

        clearTimeout(this.feedbackAlteracaoInformativo[index].animationDelay);

        this.feedbackAlteracaoInformativo[index].animationDelay = setTimeout(() => {
            this.feedbackAlteracaoInformativo[index].animate = true;
        }, 3000);

        this.alterandoRecebimentoInformativo[index] = false;
    }

    private atualizarFeedbackAlteracaoSMS(index: number, success: boolean) {
        this.feedbackAlteracaoSMS[index].display = true;
        this.feedbackAlteracaoSMS[index].success = success;

        clearTimeout(this.feedbackAlteracaoSMS[index].animationDelay);

        this.feedbackAlteracaoSMS[index].animationDelay = setTimeout(() => {
            this.feedbackAlteracaoSMS[index].animate = true;
        }, 3000);

        this.alterandoOpcaoSMS[index] = false;
    }

    private atualizarFeedbackCadastroNovoCelular(success: boolean) {
        this.feedbackCadastroNovoCelular.display = true;
        this.feedbackCadastroNovoCelular.success = success;

        clearTimeout(this.feedbackCadastroNovoCelular.animationDelay);

        this.feedbackCadastroNovoCelular.animationDelay = setTimeout(() => {
            this.feedbackCadastroNovoCelular.animate = true;
        }, 3000);

        this.cadastrandoNovoCelular = false;
    }

    private sanitizarFeedbackAlteracaoRecebimentoInformativo(index: number) {
        this.feedbackAlteracaoInformativo[index].animate = false;
        this.feedbackAlteracaoInformativo[index].display = false;
        this.feedbackAlteracaoInformativo[index].success = undefined;
    }

    private sanitizarFeedbackAlteracaoSMS(index: number) {
        this.feedbackAlteracaoSMS[index].animate = false;
        this.feedbackAlteracaoSMS[index].display = false;
        this.feedbackAlteracaoSMS[index].success = undefined;
    }

    private sanitizarFeedbackCadastroNovoCelular() {
        this.feedbackCadastroNovoCelular.animate = false;
        this.feedbackCadastroNovoCelular.display = false;
        this.feedbackCadastroNovoCelular.success = undefined;
    }

    private setarDadosDosCelularesParaSMS(celulares: GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem[]) {
        this.celulares = celulares;
        this.alterandoOpcaoSMS = new Array(this.celulares.length).fill(false);
        this.feedbackAlteracaoSMS = Array.from({ length: this.celulares.length }, () => ({
            animate: false,
            animationDelay: undefined,
            display: false,
            success: undefined
        }));
    }

    private setarDadosDosInformativos(
        dadosPerfilComunicacao: GetListaPerfilComunicResponseItem[]
    ) {
        this.informativos = dadosPerfilComunicacao;
        this.alterandoRecebimentoInformativo = new Array(this.informativos.length).fill(false);
        this.feedbackAlteracaoInformativo = Array.from({ length: this.informativos.length }, () => ({
            animate: false,
            animationDelay: undefined,
            display: false,
            success: undefined
        }));
    }
}

interface Feedback {
    animate: boolean;
    display: boolean;
    animationDelay: any;
    success: boolean;
}
