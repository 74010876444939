import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { AdesaoService, AuthService, DesignadoModel, ESituacaoParticipante, ETipoPlano, GestaoPlanoCadastroService, TipoSituacaoParticipante } from '@fibra/fibra-shared-lib';
import { BeneficiarioResponseDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/beneficiario-response.dto';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-dependentes',
    styleUrls: ['./dependentes.component.css'],
    templateUrl: './dependentes.component.html'
})
export class DependentesComponent implements OnInit {
    beneficiados: Array<BeneficiarioResponseDto> = [];
    designados: DesignadoModel[] = [];
    pensionista = false;
    isLoading = true;

    constructor(private router: Router, private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private authService: AuthService, private adesaoService: AdesaoService) { }

    ngOnInit() {
        let adesao = this.adesaoService.getAdesaoContexto();
        this.pensionista = adesao.tipoSituacao == TipoSituacaoParticipante.PENSIONISTA;
        this.getRequisicoes();
    }

    getRequisicoes() {
        let adesao = this.adesaoService.getAdesaoContexto();
        let requisicoes: (Observable<BeneficiarioResponseDto[]> | Observable<DesignadoModel[]>)[]
            = [this.gestaoPlanoCadastroService.getBeneficiarios()];        

        if (!this.pensionista) {
            requisicoes.push(this.gestaoPlanoCadastroService.getDesignados());
        }

        forkJoin(requisicoes).subscribe(([
            beneficiados,
            designados
        ]) => {
            this.beneficiados = this.pensionista ? null : beneficiados as BeneficiarioResponseDto[];
            this.designados = designados as DesignadoModel[];
            this.isLoading = false;
        }, () => { this.isLoading = false; });
    }

    goToBeneficiario() {
        this.router.navigate(['/painel/plano-bd/solicitacoes/exclusao-beneficiario'])
    }

    goToDesignado() {
        this.router.navigate(['/painel/plano-bd/solicitacoes/nomeacao-designado'])
    }

    goToDeclaracao() {
        this.router.navigate(['/painel/plano-bd/solicitacoes/declaracao-dep-ir'])
    }
}
