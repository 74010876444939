import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Service } from './service';
import { GetListaPerfilComunicResponseItem as GetListaPerfilComunicResponseItem } from '../models';

@Injectable()
export class GestaoRelacionamentoComunicacaoService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_GESTAO_RELACIONAMENTO_COMUNICACAO;
    }

    Enviar_SMSToken() {
        const url = `${this.url}/SMS/EnviarTokenSMS`;
        return this.get<any>(url);
    }

    Enviar_SMSPorEmail() {
        const url = `${this.url}/SMS/EnviarSMSPorEmail`;
        return this.get<any>(url);
    }

    Verificar_SMSValido(sms: number) {
        const url = `${this.url}/SMS/VerificaSMSValido?sms=${sms}`;
        return this.get<boolean>(url);
    }

    ListaPerfilComunic_GetListaPerfilComunic(): Observable<any> {
        const url = `${this.url}/ListaPerfilComunic/GetListaPerfilComunic`;
        return this.get<GetListaPerfilComunicResponseItem[]>(
            url
        );
    }

    ListaPerfilComunic_AlteraPerfilComunic(idPerfil: number, isAtivo: boolean): Observable<any> {
        const url = `${this.url}/ListaPerfilComunic/AlteraPerfilComunic`;
        return this.post<{ success: boolean }>(
            url,
            {
                perfil: idPerfil,
                ativo: isAtivo
            },
            true,
            true
        );
    }

    PerfilComunicacao_SetCelular(ddd: string, celular: string): Observable<any> {
        const url = `${this.url}/PerfilComunicacao/SetCelular`;
        return this.post<{ success: boolean }>(url, { ddd, celular }, true, true);
    }
}
