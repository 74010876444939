export class PessoaPoliticamenteExpostaModel {
    possuiMandatoEletivo: boolean;
    ministroEstado: boolean;
    presidenteDiretorAutarquia: boolean;
    grupoDirecaoAssessoramento: boolean;
    membroConselhoSupremoTribunal: boolean;
    membroConselhoMinisterioPublico: boolean;
    membroTribunalContasUniao: boolean;
    governadorEstadoDistrito: boolean;
    prefeitoPresidenteCamara: boolean;
    funcaoPublicaPaisEstrangeiro: boolean;
    familiaresFuncaoPublica: boolean;
}