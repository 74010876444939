import { Component, OnInit } from '@angular/core';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-cadastrais-sinqia.dto';

import {
    AdesaoService,
    AuthService,
    DadosCadastraisService,
    ESituacaoParticipante,
    GestaoPlanoBeneficioService,
    GestaoPlanoCadastroService,
    SimulacaoBeneficioService
} from '@fibra/fibra-shared-lib';
import { DatePipe } from '@angular/common';
import { SimulacaoBeneficioModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';

@Component({
    selector: 'app-previsao-aposentadoria',
    styleUrls: ['./previsao-aposentadoria.component.css'],
    templateUrl: './previsao-aposentadoria.component.html'
})
export class PrevisaoAposentadoriaCdComponent implements OnInit {
    definicaoDoGrafico: any;
    isLoading = true;
    opcoesDoGrafico: any;
    usuarioPossuiPermissao = false;
    dataAposentadoria: Date;
    beneficioEstimado: number;
    private dadosCadastrais: DadosCadastraisSinqiaDto;
    simulacaoBeneficio: SimulacaoBeneficioModel | null = null;


    constructor(
        private dadosCadastraisService: DadosCadastraisService,
        private simulacaoBeneficioService: SimulacaoBeneficioService,
        private adesaoService: AdesaoService) {
    }

    ngOnInit() {
        this.dadosCadastraisService.getCadastroGeral(true).subscribe(usuario => {
            this.dadosCadastrais = usuario;
        });

        this.isLoading = true;
        this.simulacaoBeneficioService.obterSimulacaoBeneficio()
            .subscribe(simulacaoBen => {
                this.isLoading = false;
                this.simulacaoBeneficio = simulacaoBen.data;
            }, error => {
                this.isLoading = false;
                console.error(error);
            });
    }

    init() {
        let adesao = this.adesaoService.getAdesaoContexto();
        let ehAtivo = this.adesaoService.validarSituacao(ESituacaoParticipante.Ativo, adesao.tipoSituacao);
        if (ehAtivo) {
            this.usuarioPossuiPermissao = true;
        }
        this.isLoading = false;
    }

    private preencherDadosDoGrafico() {

    }

}
