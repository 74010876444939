import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { BaseStorageService } from './base-storage.service';

/**
 * Storage service for Local Storage management.
 */
@Injectable()
export class StorageService extends BaseStorageService {
    constructor() {
        super(window.localStorage)
    }
}