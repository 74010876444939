import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';
import { ListaNoticiaResponseItem, Noticia, NoticiaItem, NoticiaResponseItem } from '../models';
import { Observable } from 'rxjs';

@Injectable()
export class NoticiasService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.URL_API_NOTICIAS;
    }

    getNoticias(pageNumber = 0): Observable<ListaNoticiaResponseItem[]> {
        return this.get<ListaNoticiaResponseItem>(`${this.url}?page=${pageNumber}`, false, true);
    }

    selecionarNoticia(noticiaItem: NoticiaItem, paginaAtual: number): Observable<any> {
        return this.get<any>(`${this.url}/noticia/${noticiaItem.linkNoticia}/${paginaAtual}`);
    }
}
