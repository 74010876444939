import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BD_ROUTES } from './painel-bd.routes';

@NgModule({
    imports: [
        RouterModule.forChild(BD_ROUTES)
    ],
    exports: [RouterModule]
})
export class DashboardBdRoutingModule { }
