import { SaldoIR } from '@fibra/fibra-shared-lib';
import { Component, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-saldo-ir',
  templateUrl: './saldo-ir.component.html',
  styleUrls: ['./saldo-ir.component.css']
})
export class SaldoIrComponent implements OnInit {

  isLoading: boolean;
  msgErro: string;
  erro: boolean;
  saldoIR: SaldoIR;

  constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

  ngOnInit() {
    this.isLoading = true;
    this.getSaldoIR();
  }

  private getSaldoIR() {
    this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldoIR().subscribe(saldo => {
        this.saldoIR = saldo;
        this.isLoading = false;
    }, () => this.informarErroDeCarregamento());
  }

  private informarErroDeCarregamento() {
    this.isLoading = false;
    this.erro = true;
    this.msgErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
    ' Se o problema persistir entre em contato pelo 0800 041 4404';
  }

}
