import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem } from '../../models';

@Component({
    selector: 'app-carrossel',
    styleUrls: ['./carrossel.component.css'],
    templateUrl: './carrossel.component.html',
    animations: [
        trigger('slide', [
            state('*', style({
                'margin-left': '-{{ margem }}%',
                'margin-right': '{{ margem }}%'
            }), { params: { margem: 0 } }),
            transition('* <=> *', [
                animate(300)
            ]),
        ])
    ]
})
export class CarrosselComponent implements OnInit {
    readonly QTD_VIDEOS_VISIVEIS = 2;

    @Input() descricao: string;
    @Input() exibirTitulosDosVideos = true;
    @Input() formatoDeColuna = false;
    @Input() titulo: string;
    @Input() videos: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem[];
    @Output() aoClicarNoVideo = new EventEmitter<GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem>();

    indicadoresDePagina: number[] = [];
    margem = 0;
    paginaAtiva = 1;
    slideCommand: boolean = undefined;

    private ultimaPagina: number;

    ngOnInit() {
        for (let i = 1, numeroPagina = 1; i <= this.videos.length; i++) {
            if (i % this.QTD_VIDEOS_VISIVEIS === 0 || i === this.videos.length) {
                this.indicadoresDePagina.push(numeroPagina++);
            }
        }

        this.ultimaPagina = this.indicadoresDePagina[this.indicadoresDePagina.length - 1];
    }

    ativarPagina(paginaASerAtivada: number) {
        if (paginaASerAtivada < 1) {
            paginaASerAtivada = this.ultimaPagina;
        }

        if (paginaASerAtivada > this.ultimaPagina) {
            paginaASerAtivada = 1;
        }

        this.paginaAtiva = paginaASerAtivada;
        this.margem = this.paginaAtiva * 100 - 100;
        this.slideCommand = !this.slideCommand;
    }

    cliqueNoVideo(item: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem) {
        this.aoClicarNoVideo.emit(item);
    }

    getColunaDeVideos() {
        const colunas: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem[][] = [];
        let i: number;

        for (i = 0; i < this.videos.length; i++) {
            if (i % this.QTD_VIDEOS_VISIVEIS === 0) {
                colunas.push(this.videos.slice(i, i + this.QTD_VIDEOS_VISIVEIS));
            }
        }

        if (i < this.videos.length) {
            colunas.push(this.videos.slice(i));
        }

        return colunas;
    }
}
