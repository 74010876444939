import { CidadeSinqiaDto } from "./cidade-sinqia.dto";


export class EnderecoDto {
    id?: number;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cep: string;
    cidade: CidadeSinqiaDto;
    pessoaId?: number;
    tipoLogradouro?: string;
}
