
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { DialogModule } from 'primeng/dialog';
import { ModuleWithProviders } from '@angular/core';
import { DatePipe } from '@angular/common';

// Services
import { ArquivosService } from './services/arquivos.service';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { RoleGuardService } from './guards/role-guard.service';
import { PensionistaOuAssistidoGuardService } from './guards/pensionista_ou_assistido-guard.service';
import { PensionistaGuardService } from './guards/pensionista-guard.service';
import { AssistidoGuardService } from './guards/assistido-guard.service';
import { AtivoGuardService } from './guards/ativo-guard.service';
import { GestaoFinanceiraEmprestimoService } from './services/gestao-financeira-emprestimo.service';
import { GestaoPlanoBeneficioService } from './services/gestao-plano-beneficio.service';
import { GestaoPlanoCadastroService } from './services/gestao-plano-cadastro.service';
import { MenuAppService } from './services/menu-app.service';
import { GestaoPlanoContribuicaoService } from './services/gestao-plano-contribuicao.service';
import { GestaoRelacionamentoComunicacaoService } from './services/gestao-relacionamento-comunicacao.service';
import { GestaoPlanoBiService } from './services/gestao-plano-bi.service';
import { SessionInitService } from './services/session-init.service';
import { NoticiasService } from './services/noticias.service';
import { TermoAdesaoService } from './services/termo-adesao.service';
import { TvFibraService } from './services/tv-fibra.service';
import { FormularioService } from './services/formulario.service';
import { StorageService } from './services/storage.service';
import { AuthCdService } from './services/contribuicao-definida/auth-cd.service';
import { SolicitacaoDescontoFolhaService } from './services/solicitacao-desconto-folha.service';
import { AdesaoService, SimuladorService, AnotacaoService, DadosCadastraisService, DocumentoService, HeaderManagerService, HttpClientService, IndiceFinanceiroService, PerfilInvestimentoService, SessionStorageService, HttpHeaderService, UploadClientService, RelacaoDependenciaService, LoginIntegradoService } from './services';
import { AdesaoCdService } from './services/contribuicao-definida/adesao-cd.service';
import { PessoaPoliticamenteExpostaService } from './services/contribuicao-definida/pessoa-politicamente-exposta.service';

// Components
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { CardBlockerModalComponent } from './components/card-blocker-modal/card-blocker-modal.component';
import { CarrosselComponent } from './components/carrossel/carrossel.component';
import { IconeSucessoOuErroComponent } from './components/icone-sucesso-ou-erro/icone-sucesso-ou-erro.component';
import { LightboxComponent } from './components/lightbox/lightbox.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal-component';

// Pipes
import { SafePipe } from './pipes/safe.pipe';

// Diretivas
import { HoverCardDirective } from './directives/hover-card.directive';
import { NumericDirective } from './directives/numeric.directive';

import { CPFPipe } from './directives/cpf.pipe';
import { SimulacaoBeneficioService, TokenService } from './services/contribuicao-definida';

@NgModule({
    imports: [CommonModule, DialogModule, HttpClientModule, RouterModule],
    exports: [
        BreadcrumbsComponent,
        SideMenuComponent,
        CardBlockerModalComponent,
        CarrosselComponent,
        IconeSucessoOuErroComponent,
        LightboxComponent,
        ModalComponent,
        SafePipe,
        CPFPipe,
        HoverCardDirective,
        NumericDirective,
        AlertModalComponent
    ],
    declarations: [
        BreadcrumbsComponent,
        SideMenuComponent,
        CardBlockerModalComponent,
        CarrosselComponent,
        IconeSucessoOuErroComponent,
        LightboxComponent,
        ModalComponent,
        NotFoundComponent,
        SafePipe,
        CPFPipe,
        HoverCardDirective,
        NumericDirective,
        AlertModalComponent
    ],
    providers: [
        AnotacaoService,
        UploadClientService,
        ArquivosService,
        AuthService,
        GestaoFinanceiraEmprestimoService,
        GestaoPlanoBeneficioService,
        GestaoPlanoCadastroService,
        GestaoPlanoContribuicaoService,
        GestaoRelacionamentoComunicacaoService,
        GestaoPlanoBiService,
        SessionInitService,
        AuthGuardService,
        RoleGuardService,
        AssistidoGuardService,
        PensionistaGuardService,
        PensionistaOuAssistidoGuardService,
        PensionistaGuardService,
        AtivoGuardService,
        NoticiasService,
        MenuAppService,
        SafePipe,
        CPFPipe,
        DatePipe,
        TermoAdesaoService,
        TvFibraService,
        SolicitacaoDescontoFolhaService,
        FormularioService,
        AdesaoService,
        StorageService,
        SessionStorageService,
        DadosCadastraisService,
        AuthCdService,
        AdesaoCdService,
        PerfilInvestimentoService,
        PessoaPoliticamenteExpostaService,
        TokenService,
        HeaderManagerService,
        IndiceFinanceiroService,
        SimuladorService,
        IndiceFinanceiroService,
        HttpClientService,
        DocumentoService,
        SimulacaoBeneficioService,
        HttpHeaderService,
        RelacaoDependenciaService,
        AnotacaoService,
        LoginIntegradoService
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule {
    public static forRoot(environment: any): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: 'environment', // you can also use InjectionToken
                    useValue: environment
                }
            ]
        };
    }
}
