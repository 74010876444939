import { Component, OnInit } from '@angular/core';
import { GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem, corrigirLinkYoutube
} from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-beneficio-vitalicio',
    templateUrl: './beneficio-vitalicio.component.html',
    styleUrls: ['./beneficio-vitalicio.component.css']
})
export class BeneficioViatlicioComponent implements OnInit {
    

    ngOnInit() {

	}
}
