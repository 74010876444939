import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-modal-form',
    templateUrl: './modal-form.component.html',
    styleUrls: ['./modal-form.component.css']
})
export class ModalFormComponent implements OnInit {

    @Input("display") display: boolean = false;
    @Input('nomeParticipante') nomeParticipante: string = '';
    @Output("onSubmit") onSubmit = new EventEmitter<boolean>();
    dataAtual: Date = new Date();
    private formModal: any;
    window: any = window;

    constructor() { }

    ngOnInit() {
        this.formModal = new this.window.bootstrap.Modal(
            document.getElementById('exampleModalLong')
        );
        
        if (this.display)
            this.mostrar();
    }

    concordo() {
        this.onSubmit.emit(true);
    }

    discordo() {
        this.onSubmit.emit(false);
    }

    mostrar() {
        this.display = true;
        this.formModal.show();
    }

    ocultar() {
        this.display = false;
        this.formModal.hide();
    }
}
