import { NgModule } from '@angular/core';
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { TwoFactorAuthEmailComponent } from './two-factor-auth/two-factor-auth-email/two-factor-auth-email.component';
import { TwoFactorAuthSmsComponent } from './two-factor-auth/two-factor-auth-sms/two-factor-auth-sms.component';
import { RodapeModule } from '../painel/rodape/rodape.module';
import { HeaderModule } from '../painel/header/header.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ChartModule } from 'primeng/chart';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DashboardBdRoutingModule } from '../painel/bd/painel-bd-routing.module';
import { DialogModule } from 'primeng/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerModule } from 'primeng/spinner';
import { CardModule } from 'primeng/card';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationService } from 'primeng/api';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule } from 'ngx-mask';
import { NgxLoadingModule } from 'ngx-loading';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { SimuladorComponent } from './simulador/simulador.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NovoDependenteComponent } from './novo-dependente/novo-dependente.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { environment } from '../../environments/environment';
import { RelacaoDependenciaService, SharedModule } from '@fibra/fibra-shared-lib';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { DependenteAdesaoComponent } from './dependente-adesao/dependente-adesao.component';
import { DependenteComponent } from './dependente/dependente.component';
import { ToolbarModule } from 'primeng/toolbar';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadAttachComponent } from './file-upload-attach/file-upload-attach.component';
import { IsencaoIrMolestiaGraveComponent } from '../painel/bd/solicitacoes/isencao-ir-molestia-grave/isencao-ir-molestia-grave.component';
import { IsencaoIrMolestiaGraveCDComponent } from '../painel/cd/solicitacoes/isencao-ir-molestia-grave/isencao-ir-molestia-grave.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { SignWidgetComponent } from './clicksign/sign-widget/sign-widget.component';
import { FileUploadSesuiteComponent } from './file-upload-sesuite/file-upload-sesuite.component';
import { NotificationOptionDialogComponent } from './notification-option-dialog/notification-option-dialog.component';
import { InformativoAlteracaoSenhaComponent } from './informativo-alteracao-senha/informativo-alteracao-senha.component';
import { AlterarSenhaComponent } from './alterar-senha';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { PasswordTemplateComponent } from './password-template/password-template.component';
import { ZonaEquilibrioComponent } from './zona-equilibrio/zona-equilibrio.component';


@NgModule({
    declarations: [
        SignWidgetComponent,
        TwoFactorAuthComponent,
        TwoFactorAuthEmailComponent,
        TwoFactorAuthSmsComponent,
        ModalFormComponent,
        SimuladorComponent,
        NovoDependenteComponent,
        PdfViewerComponent,
        DependenteComponent,
        DependenteAdesaoComponent,
        FileUploadComponent,
        FileUploadAttachComponent,
        IsencaoIrMolestiaGraveComponent,
        IsencaoIrMolestiaGraveCDComponent,
        BackButtonComponent,
        SignWidgetComponent,
        FileUploadSesuiteComponent,
        NotificationOptionDialogComponent,
        InformativoAlteracaoSenhaComponent,
        AlterarSenhaComponent,
        PasswordTemplateComponent,
        ZonaEquilibrioComponent
    ],
    imports: [
        RodapeModule,
        HeaderModule,
        BrowserAnimationsModule,
        BrowserModule,
        ChartModule,
        AutoCompleteModule,
        CheckboxModule,
        DashboardBdRoutingModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        NgxLoadingModule,
        CurrencyMaskModule,
        NgxMaskModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        RadioButtonModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        SidebarModule,
        SliderModule,
        TableModule,
        PaginatorModule,
        TooltipModule,
        OverlayPanelModule,
        CalendarModule,
        InputMaskModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        FileUploadModule,
        CardModule,
        SpinnerModule,
        ConfirmDialogModule,
        ToolbarModule,
        BadgeModule,
        InputSwitchModule,
        PasswordModule,
        DividerModule,
        SharedModule.forRoot(environment)
    ],
    exports: [
        TwoFactorAuthComponent,
        TwoFactorAuthEmailComponent,
        TwoFactorAuthSmsComponent,
        ModalFormComponent,
        SimuladorComponent,
        NovoDependenteComponent,
        PdfViewerComponent,
        DependenteComponent,
        DependenteAdesaoComponent,
        SignWidgetComponent,
        FileUploadSesuiteComponent,
        NotificationOptionDialogComponent,
        InformativoAlteracaoSenhaComponent,
        AlterarSenhaComponent,
        PasswordTemplateComponent,
        ZonaEquilibrioComponent
    ],

    providers: [
        ConfirmationService,
        RelacaoDependenciaService
    ],

})
export class ComponentsModule { }

