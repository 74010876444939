import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';
import { FibraNumerosModel } from '../models';
import { Observable } from 'rxjs';

@Injectable()
export class GestaoPlanoBiService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_GESTAO_PLANO_BI;
    }

    BI_GetFibraNumeros(): Observable<any> {
        const url = `${this.url}/FibraNumeros/Get`;
        return this.get<FibraNumerosModel>(url);
    }

}
