import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormularioService, AlertModalComponent, scrollIntoViewToTop } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { IsencaoIrMolestiaGraveModel } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR, MESSAGE_SEVERITY } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';

@Component({
    selector: 'app-isencao-ir-molestia-grave',
    templateUrl: './isencao-ir-molestia-grave.component.html',
    styleUrls: ['./isencao-ir-molestia-grave.component.css']
})
export class IsencaoIrMolestiaGraveCDComponent implements OnInit {
    isencaoIrMolestiaGrave: IsencaoIrMolestiaGraveModel;
    isencaoIrMolestiaGraveForm: FormGroup;
    mostrarCamposDataValidadeLaudo: boolean;
    ptBR = LOCALE_PT_BR;
    isLoading: boolean;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    urlArquivoSeSuite: string;
    formValido: boolean = true;
    erros: Array<string> = [];
    msgs: any = [];
    yearRange: string;
    uploadedFiles: any[] = [];
    minDate: Date;
    isSimulado = sessionStorage.getItem('isSimulado');
    opcaoDocIsencaoMolestia: string;
    opcaoComplementarIsencaoMolestia: string;
    city: string;

    constructor(private formularioService: FormularioService,
                private authService: AuthService,
                private fb: FormBuilder) {
        this.yearRange = ((new Date()).getFullYear()) + ':' + ((new Date()).getFullYear() + 5);
        this.minDate = new Date();
    }

    ngOnInit() {
        this.isLoading = false;

        this.isencaoIrMolestiaGraveForm = this.fb.group({
            opcaoDocIsencaoMolestia: ['laudoPericial', Validators.required], // Definindo o valor inicial para 'laudoPericial'
            opcaoComplementarIsencaoMolestia: ['cartaConcessao']
        });

        this.isencaoIrMolestiaGraveForm.valueChanges
            .subscribe(isencaoIrMolestiaGraveForm => {
                this.validarForm();
            });
    }

    ngOnDestroy(): void {}

    validarForm(): boolean {
        this.limparMensagem();
        this.formValido = true;

        if (this.mostrarCamposDataValidadeLaudo && !this.isencaoIrMolestiaGraveForm.get('dataValidadeLaudo').valid) {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Dados não informados: Data de validade do laudo.");
            this.formValido = false;
        }

        if (!this.formValido) {
            scrollIntoViewToTop();
        }

        return this.formValido;
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar(isencaoIrMolestiaGrave: IsencaoIrMolestiaGraveModel) {
        this.isLoading = true;

        if (!this.validarForm()) {
            this.isLoading = false;
            return;
        }

        const opcaoSelecionada = this.isencaoIrMolestiaGraveForm.get('opcaoDocIsencaoMolestia').value;
        const opcaoComplementar = this.isencaoIrMolestiaGraveForm.get('opcaoComplementarIsencaoMolestia').value;

        switch (opcaoSelecionada) {
            case 'laudoPericial':
                isencaoIrMolestiaGrave.laudoPericial = true;
                break;
            case 'copiaSentencaJudicial':
                isencaoIrMolestiaGrave.copiaSentencaJudicial = true;
                break;
            case 'laudosMedicos':
                isencaoIrMolestiaGrave.laudosMedicos = true;
                break;
            default:
                break;
        }

        if(opcaoComplementar) {
            isencaoIrMolestiaGrave.cartaConcessao = true;
        }

        this.authService.getRegFibra()
            .subscribe(regFibra => {
                let formData = new FormData();
                formData.append('isencaoIrMolestiaJson', JSON.stringify(isencaoIrMolestiaGrave));

                if (this.fileUpload.hasFiles()) {
                    this.fileUpload.files.forEach((file, index) => {
                        formData.append('anexo_' + index, file);
                    });

                    this.formularioService.gerarFormIsencaoIrMolestiaGrave(regFibra, formData)
                        .subscribe(urlArquivoSeSuite => {
                            this.isLoading = false;
                            this.urlArquivoSeSuite = urlArquivoSeSuite;
                            this.alertModalComponent.show('Solicitação enviada com sucesso!', true);
                            if (this.isSimulado === 'true') {
                                window.open(urlArquivoSeSuite, '_blank');
                            }
                            this.reset();
                        }, () => {
                            this.isLoading = false;
                            this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                            this.reset();
                        });
                }
            }, () => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                this.reset();
            });
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    mudarCamposDataValidadeLaudo(mostrarCampos: string) {
        this.mostrarCamposDataValidadeLaudo = mostrarCampos == 'true';
    }

    onUpload(event: any) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    verificar2fa() {
        if (!this.fileUpload.hasFiles()) {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Adicione o(s) anexo(s).");
            this.formValido = false;
        }

        if (!this.validarForm()) {
            return;
        }
        this.twoFactorAuth.show();
    }

    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar(this.isencaoIrMolestiaGraveForm.value);
    }
}
