import { BeneficiarioSupAposentadoriaModel } from "./beneficiario-sup-aposentadoria.model";
import { DesignadoSupAposentadoriaModel } from "./designado-sup-aposentadoria.model";
import { DesignadoModel } from './designado.model'
export class SuplementacaoAposentadoriaModel {
    tipoBeneficio: number;
    nomeFacelidoSupPensao: string;
    dataObitoFacelidoSupPensa: Date;
    nomeFacelidoBetm: string;
    dataObitoFalecidoBetm: Date;
    nomeFacelidoSupPensaoConversao: string;
    dataFacelidoSupPensaoConversao: Date;
    opcaoSeguroVida: number;
    abonoAnual: boolean;
    recluso: string;
    beneficiarios: Array<BeneficiarioSupAposentadoriaModel>;
    designados: Array<DesignadoSupAposentadoriaModel>;
    aposentadoPrevOficial: boolean | null = null;
    dataVigencia: Date | null = null;
}