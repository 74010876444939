import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tela-previsao-aposentadoria',
    templateUrl: './tela-previsao-aposentadoria.component.html',
    styleUrls: ['./tela-previsao-aposentadoria.component.css']
})
export class TelaPrevisaoAposentadoriaComponent {

    
}
