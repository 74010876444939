import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormularioService, AlertModalComponent } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-suplementacao-pensao',
    templateUrl: './suplementacao-pensao.component.html',
    styleUrls: ['./suplementacao-pensao.component.css']
})
export class SuplementacaoPensaoComponent implements OnInit {
    isLoading: boolean = false;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    msgs: any[];

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar() {
        this.isLoading = true;
        this.authService.getRegFibra()
            .subscribe(regFibra => {
                this.formularioService.baixarFormAntecipacaoSupPensao(regFibra, () => {
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                });
            }, () => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }
}
