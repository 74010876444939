import { Routes } from '@angular/router';

import { NotFoundComponent } from '@fibra/fibra-shared-lib';
import { ServidorIndisponivelComponent } from './servidor-indisponivel/servidor-indisponivel.component';

export const ROUTES: Routes = [
    {
        path: '503',
        component: ServidorIndisponivelComponent
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];
