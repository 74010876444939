import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { TelaPpdvComponent } from './tela-ppdv';

@Component({
    selector: 'app-tela-previsao-aposentadoria',
    templateUrl: './tela-previsao-aposentadoria.component.html',
    styleUrls: ['./tela-previsao-aposentadoria.component.css']
})
export class TelaPrevisaoAposentadoriaComponent {
    @ViewChild('telaPpdv', { static: true }) telaPpdv: TelaPpdvComponent;

    getAutopatrocinio() {
        this.telaPpdv.getAutoPatrocinio().then();
    }
}
