import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '@fibra/fibra-shared-lib';

@Injectable()
export class TwoFactorAuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;

        let hasValidToken = this.authService.MultiFactorAuth_HasValidToken();
        if (hasValidToken) {
            let token = this.authService.MultiFactorAuth_GetToken();
            let tokenJsonStr = JSON.stringify(token);
            let tokenJsonBase64 = btoa(tokenJsonStr);
            request = req.clone({ setHeaders: { twoFactorAuthCode: tokenJsonBase64 } });
        }

        return next.handle(request);
    }
}