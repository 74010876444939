import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';
import { ListaNoticiaResponseItem } from '../models';
import { Observable } from 'rxjs';

@Injectable()
export class TvFibraService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.URL_API_TV_FIBRA;
    }

    getVideos(pageNumber = 0): Observable<any> {
        return this.get<any>(`${this.url}?page=${pageNumber}`, false, true);
        // return this.get<any>(`${this.url}`, false, true);
    }
}
