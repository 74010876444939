import { Routes } from '@angular/router';

import {
    CadastroComponent,
    ConfirmarSenhaComponent,
    EntrarComponent,
    LoginComponent,
    LoginIntegradoComponent,
    RecuperarSenhaComponent,
    SelecionarPerfilComponent
} from '.';
import { AlterarSenhaComponent } from './alterar-senha';

export const LOGIN_ROUTES: Routes = [
    {
        path: '',
        component: LoginComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: EntrarComponent
            }, {
                path: 'login/selecionar-perfil/:user/:password/:returnUrl',
                component: SelecionarPerfilComponent
            }, {
                path: 'login-integrado/:cpf',
                component: LoginIntegradoComponent
            }, {
                path: 'cadastro',
                component: CadastroComponent
            }, {
                path: 'confirmar-senha',
                component: ConfirmarSenhaComponent
            }, {
                path: 'recuperar-senha',
                component: RecuperarSenhaComponent
            }, {
                path: 'alterar-senha/:cpf',
                component: AlterarSenhaComponent
            }
        ]
    }
];
