export const RELACAO_DEPENDENCIA = [
    {
        id: "1",
        descricao: "Cônjuge"
    },
    {
        id: "2",
        descricao: "Irmao(a)"
    },
    {
        id: "3",
        descricao: "Neto(a)"
    },
    {
        id: "4",
        descricao: "Ex-conjuge"
    },
    {
        id: "5",
        descricao: "Ex-companheir0(a)"
    },
    {
        id: "6",
        descricao: "Sobrinho(a)"
    },
    {
        id: "7",
        descricao: "Companheiro(a)"
    },
    {
        id: "8",
        descricao: "Avô"
    },
    {
        id: "9",
        descricao: "Cunhado(a)"
    },
    {
        id: "10",
        descricao: "Avó"
    },
    {
        id: "11",
        descricao: "Maior Invalido"
    },
    {
        id: "12",
        descricao: "Menor Sob Guarda"
    },
    {
        id: "13",
        descricao: "Outros"
    },
    {
        id: "14",
        descricao: "Tutelado(a)"
    },
    {
        id: "15",
        descricao: "Filho(a)"
    },
    {
        id: "16",
        descricao: "Enteado(a)"
    },
    {
        id: "17",
        descricao: "Pai"
    },
    {
        id: "18",
        descricao: "Filho(a) Maior Inval"
    },
    {
        id: "19",
        descricao: "Mae"
    },
    {
        id: "21",
        descricao: "Sogra"
    },
    {
        id: "22",
        descricao: "Outros (d)"
    }
];