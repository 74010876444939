import { Injectable } from "@angular/core";
import { RELACAO_DEPENDENCIA } from "../constants/relacao-dependencia.const";

@Injectable()
export class RelacaoDependenciaService {
    readonly relacaoDependenciaDados = RELACAO_DEPENDENCIA;

    getRelacaoDependencia() {
        return this.relacaoDependenciaDados;
    }
}