import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { RequestCacheService } from "./request-cache.service";

function _window(): any {
    // return the global native browser window object
    return window;
}
declare var device;

@Injectable()
export abstract class Service {
    constructor(protected httpClient: HttpClient) { }

    protected baixarArquivo(
        url: string,
        fileMimeType: string,
        callback?: Function,
        erroCallBack?: Function,
        thisArg?: any, httpHeaders?: Array<{ name, value }>
    ) {
        const token = sessionStorage.getItem("accessToken");
        let requestHttpHeaders = new HttpHeaders();
        requestHttpHeaders = requestHttpHeaders.append('Authorization', token as string);
        requestHttpHeaders = requestHttpHeaders.append("Content-Disposition", "Attachment");
        httpHeaders?.forEach(header => {
            requestHttpHeaders = requestHttpHeaders.append(header.name, header.value);
        });

        this.httpClient.get(url, {
            responseType: "blob",
            headers: requestHttpHeaders,
            withCredentials: true,
        }).subscribe(
            (file) => {
                const blob = new Blob([file], { type: fileMimeType });
                const fileName = `documento-${new Date().getTime()}.pdf`;
                let path;
                const nav = window.navigator as any;
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                } else {
                    const fileUrl = window.URL.createObjectURL(blob);
                    if (_window().cordova) {
                        if (device.platform === "iOS") {
                            path = _window().cordova.file.tempDirectory;
                        } else if (device.platform === "Android") {
                            path = _window().cordova.file.dataDirectory;
                        }
                        this.resolveLocalFileSystem(path, blob, fileName);
                    } else {
                        window.open(fileUrl);
                    }
                }
                if (callback) {
                    callback.call(thisArg);
                }
            },
            (error) => {
                console.log(error);
                if (erroCallBack) {
                    //throw new Error(error);
                    erroCallBack.call(error);
                }
                throw new Error(error);
            }
        );
    }

    resolveLocalFileSystem(path, blob, fileName) {
        _window().resolveLocalFileSystemURL(path, function (dirEntry) {
            dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
                fileEntry.createWriter(function (fileWriter) {
                    fileWriter.write(blob);
                    fileWriter.onwriteend = function () {
                        if (fileWriter.length === 0) {
                            fileWriter.write(blob);
                        } else {
                            if (_window().cordova) {
                                console.log(path + fileName);
                                _window().cordova.plugins.fileOpener2.open(
                                    path + fileName,
                                    "application/pdf",
                                    {
                                        error: function (e) {
                                            console.error(e);

                                            console.log(
                                                "Error status: " +
                                                e.status +
                                                " - Error message: " +
                                                e.message
                                            );
                                        },
                                        success: function () {
                                            console.log("Arquivo redirecionado com sucesso");
                                        },
                                    }
                                );
                            }
                        }
                    };
                });
            }, error => {
                console.log(error);
            });
        });
    }

    protected get<T>(
        url: string,
        sendAuthorizationHeader = true,
        xform = false
    ): Observable<any> {
        return this.httpClient.get<T>(url, {
            headers: this.getHeaders(sendAuthorizationHeader, xform),
            withCredentials: sendAuthorizationHeader,
        });
    }

    protected getTyped<T>(
        url: string,
        sendAuthorizationHeader = true,
        xform = false
    ): Observable<T> {
        return this.httpClient.get<T>(url, {
            headers: this.getHeaders(sendAuthorizationHeader, xform),
            withCredentials: sendAuthorizationHeader,
        });
    }

    protected getOptions<T>(url: string, options: any): Observable<any> {
        return this.httpClient.get<T>(url, options);
    }

    protected cachedGet<T>(
        url: string,
        sendAuthorizationHeader = true
    ): Observable<T> {
        const cacheAddress = this.obterEnderecoDeCache(url);

        if (!RequestCacheService.cache[cacheAddress]) {
            RequestCacheService.cache[cacheAddress] = this.get(
                url,
                sendAuthorizationHeader
            ).pipe(shareReplay());
        }

        return RequestCacheService.cache[cacheAddress];
    }

    protected post<T>(
        url: string,
        requestBody: Object | HttpParams = null,
        sendAuthorizationHeader = true,
        sendParamsAs_x_www_form_urlencoded = false
    ): Observable<any> {
        return this.httpClient.post<T>(
            url,
            sendParamsAs_x_www_form_urlencoded ? null : requestBody,
            {
                headers: this.getHeaders(
                    sendAuthorizationHeader,
                    sendParamsAs_x_www_form_urlencoded
                ),
                withCredentials: sendAuthorizationHeader,
                params: sendParamsAs_x_www_form_urlencoded
                    ? <HttpParams>requestBody
                    : null,
            }
        );
    }

    protected postTyped<T>(url: string, requestBody: any, sendAuthorizationHeader = true): Observable<T> {
        return this.httpClient.post<T>(
            url, requestBody,
            {
                headers: this.getHeaders(
                    sendAuthorizationHeader, false
                ),
                withCredentials: sendAuthorizationHeader
            }
        );
    }

    protected put<T>(
        url: string,
        requestBody: Object | HttpParams = null,
        sendAuthorizationHeader = true,
        sendParamsAs_x_www_form_urlencoded = false
    ): Observable<T> {
        return this.httpClient.put<T>(url, sendParamsAs_x_www_form_urlencoded ? null : requestBody, {
            headers: this.getHeaders(sendAuthorizationHeader, sendParamsAs_x_www_form_urlencoded),
            withCredentials: sendAuthorizationHeader,
            params: sendParamsAs_x_www_form_urlencoded ? <HttpParams>requestBody : null
        });
    }

    protected postOptions<T>(
        url: string,
        body: any,
        sendAuthorizationHeader = true,
        responseType: string
    ): Observable<any> {
        const config = {
            headers: this.getHeaders(sendAuthorizationHeader),
            withCredentials: sendAuthorizationHeader,
        };

        if (responseType) {
            config["responseType"] = responseType;
        }

        return this.httpClient.post<T>(url, body, config);
    }

    protected cachedPost<T>(
        url: string,
        requestBody: any = null,
        sendAuthorizationHeader = true,
        sendParamsAs_x_www_form_urlencoded = false
    ): Observable<T> {
        const cacheAddress = this.obterEnderecoDeCache(url, requestBody);

        if (!RequestCacheService.cache[cacheAddress]) {
            RequestCacheService.cache[cacheAddress] = this.post(
                url,
                requestBody,
                sendAuthorizationHeader,
                sendParamsAs_x_www_form_urlencoded
            ).pipe(shareReplay());
        }

        return RequestCacheService.cache[cacheAddress];
    }

    protected getHeaders(
        sendAuthorizationHeader: boolean,
        sendParamsAs_x_www_form_urlencoded = false
    ) {
        const headers = {
            // 'Ocp-Apim-Subscription-Key': '9fca89eebae34011b88c954f95022554',
            "Content-Type": sendParamsAs_x_www_form_urlencoded
                ? "application/x-www-form-urlencoded"
                : "application/json",
            Accept: "application/json",
        };

        if (sendAuthorizationHeader) {
            const accessToken = sessionStorage.getItem("accessToken");

            if (!!accessToken) {
                headers["Authorization"] = accessToken;
            }
        }

        return new HttpHeaders(headers);
    }


    private obterEnderecoDeCache(url: string, corpoDaRequisicao?: Object) {
        let enderecoDeCache = url;

        if (corpoDaRequisicao) {
            const sufixo = Object.keys(corpoDaRequisicao).reduce(
                (previous, current) => {
                    if (corpoDaRequisicao.hasOwnProperty(current)) {
                        current =
                            previous +
                            current +
                            encodeURIComponent(corpoDaRequisicao[current]);
                    }

                    return current;
                }
            );

            enderecoDeCache = `${enderecoDeCache}_${sufixo}`;
        }
        return enderecoDeCache.replace(/[^0-9a-zA-Z]/g, "");
    }

    protected getHttpHeadersAuthorization() {
        const accessToken = sessionStorage.getItem("accessToken");
        const headers = {
            Authorization: accessToken,
        };
        return new HttpHeaders(headers);
    }
}
