import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DocumentoService, TipoSeDocumento, ArquivosService } from '@fibra/fibra-shared-lib';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { PdfViewerComponent } from 'projects/FibraAutoAtendimento/src/app/components/pdf-viewer/pdf-viewer.component';
import { HtmlParser } from '@angular/compiler';

@Component({
    selector: 'app-rentabilidade-card',
    styleUrls: ['./rentabilidade-card.component.css'],
    templateUrl: './rentabilidade-card.component.html'
})
export class RentabilidadeCardComponent implements OnInit, AfterViewInit {

    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;
	data: any;
    sharedService: any;
    documento: any;
    blob: string;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;
    rentabilidadePorPerfil : string[];
    IDDocRentabilidade : number = TipoSeDocumento.LaminaCD;

    constructor(private documentoService: DocumentoService, private arquivosService: ArquivosService) {

	}

    ngOnInit() {

        debugger;

        this.isLoading = true;

        this.arquivosService.getListSeDocs(TipoSeDocumento.LaminaCD).subscribe(files =>
            {
                this.rentabilidadePorPerfil = files;
            },
            (err) => {
                this.isLoading = false;
            },
            () =>{
                this.isLoading = false;
            }
        );
    }

    downloadFile(idDoc: string, fileName: string) {
        debugger;
        this.isLoading = true;
        this.arquivosService.getSeDocsByName(idDoc,fileName);
        setTimeout(() => {
            this.isLoading = false;
          }, 2000);
    }

    ngAfterViewInit() {

        /*let request = this.documentoService.getSeDocs(TipoSeDocumento.LaminaCD)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request);
        */
    }
}
