import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { Service } from "./service";
import { StorageService } from "./storage.service";
import { STORAGE_KEYS } from "../constants";

@Injectable()
export class AnotacaoService extends Service {
    constructor(httpClient: HttpClient,
        @Inject('environment') private environment,
        private storageService: StorageService
    ) {
        super(httpClient);
    }

    async getAnotacoes(){
        return this.storageService.getParse<{
            usuario: string,
            anotacoes: Array<{ chave: string, nota: string, criadoEm: string }>
        }>(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL);
    }

    async setAnotacoes(chave: string, nota: string) {
        var accessToken = sessionStorage.getItem('accessToken');
        let httpHeader = new HttpHeaders();
        if (accessToken) {
            httpHeader = new HttpHeaders({ 'Authorization': accessToken });
        }
        var url = `${this.environment.API_GESTAO_PLANO_CADASTRO}/perfil/autoatendimento/anotar`;
        var dadosDoPerfil = await this.httpClient.post<any>(url, { chave: chave, nota: nota }, { headers: httpHeader }).toPromise();
        this.storageService.set(STORAGE_KEYS.DADOS_CADASTRAIS_PERFIL, JSON.stringify(dadosDoPerfil));
    }
}
