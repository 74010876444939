import { Component, OnInit, ViewChild } from '@angular/core';
import { DesignadoModel, LOCALE_PT_BR, MESSAGE_SEVERITY, scrollIntoViewToTop, validCPF, validFullName, formatarTelefone, RespostaDocumentoCriadoModel, SignDataModel} from '@fibra/fibra-shared-lib';
import { FormularioService, AlertModalComponent } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { NomeacaoDesignadoModel } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
import { SelectItem } from 'primeng/api';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';

@Component({
    selector: 'app-nomeacao-designado',
    templateUrl: './nomeacao-designado.component.html',
    styleUrls: ['./nomeacao-designado.component.css']
})
export class NomeacaoDesignadoComponent implements OnInit {
    isSimulado = sessionStorage.getItem('isSimulado');
    documentoAssinado: boolean = false;
    mostrarSignWidget: boolean = false;
    designados: Array<DesignadoModel>;
    designado: DesignadoModel;
    formValido: boolean;
    observacao: string;
    isLoading: boolean;
    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    urlArquivoSeSuite: string;
    msgs: any[];
    yearRange: string;
    dateMax: Date;
    ptBR = LOCALE_PT_BR;
    sexoOptions: SelectItem[] = [
        {label: 'Masculino', value: 'M'},
        {label: 'Feminino', value: 'F'}
    ];
    
    constructor(private formularioService: FormularioService, private authService: AuthService) { }

    ngOnInit() {
        this.designados = new Array<DesignadoModel>();
        this.dateMax = new Date();
        this.yearRange = '1900:' + (new Date()).getFullYear();
        this.inicializarDesignado();
    }

    ngOnDestroy(): void {
    }

    formatarCelular(string: string) {

        let ddd = string.substring(0, 2);
        let celular = string.substring(2);

        string = formatarTelefone(ddd, celular);

        return string
    }

    inicializarDesignado() {
        this.designado = {
            id: this.designados.length + 1,
            nome: '',
            cpf: '',
            percentual: 0,
            idDesignado: 0,
            nomeDesignado: '',
            cpfDesignado: '',
            dataNascimento: null,
            sexo: null,
            telefone: null,
            email: ''
        }
    }

    adicionarDesignado() {
        if (this.validarForm()) {
            this.designados.push(this.designado);
            this.inicializarDesignado();
        }
    }

    removerDesignado(i: number) {
        this.designados.splice(i, 1);
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.limparMensagem();
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar() {

        let percentualValido = this.validarPercentualTotal();
        
        if(percentualValido == true){

            const nomeacaoDesignado: NomeacaoDesignadoModel = {
                observacao: this.observacao,
                designados: this.designados
            };
    
            this.isLoading = true;
    
            this.formularioService.gerarFormNomeacaoDesignado(nomeacaoDesignado)
                .subscribe((resposta: RespostaDocumentoCriadoModel) => {
                    this.isLoading = false;
                    
                    if (this.isSimulado === 'true') {
                        window.open(resposta.urlDoDocumentoNaoAssinado, '_blank');
                    }
    
                    if(resposta.sucesso && (this.isSimulado == 'false' || this.isSimulado == null)){
                        this.mostrarSignWidget = true;
                        setTimeout(() => {
                            this.signWidget.carregarWidget({
                                signKey: resposta.idDaRequisicaoDaAssinatura,
                                idDocumento: resposta.idDoDocumento
                            });
                        }, 500);
                    }
                    this.ngOnInit();
                }, () => {
                    this.isLoading = false;
                    this.ngOnInit();
                });
        }

    }

    validarForm(): boolean {
        this.limparMensagem();
        this.formValido = true;

        if (this.designado.nome === '' ||
            this.designado.cpf === '' ||
            this.designado.sexo === '' ||
            this.designado.sexo === null ||
            this.designado.telefone === '' ||
            this.designado.percentual === 0) {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Informe todos os dados do formulário.");
            scrollIntoViewToTop();
            return false;
        }

        if ((this.designado.nome &&
            this.designado.nome.trim().length < 3) ||
            !validFullName(this.designado.nome)) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O campo 'Nome Completo' é permitido apenas letras.`);
            this.formValido = false;
        }

        if (!validCPF(this.designado.cpf)) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `Por favor entre com 'CPF' válido.`);
            this.formValido = false;
        }

        if (this.designado.dataNascimento == null) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O campo 'Data de Nascimento' é obrigatório.`);
            this.formValido = false;
        }

        this.designados.forEach((designado) => {
            if (designado.percentual > 100 || this.designado.percentual < 0) {
                this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                    `O percentual não pode ser menor que 0 ou maior que 100%.`);
                this.formValido = false;
            }
        });

        let totalPercentual = this.designado.percentual;
        this.designados.forEach((designado) => {
            totalPercentual += designado.percentual;
        });

        if (totalPercentual > 100) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O percentual total dos designados declarados não pode ser maior que 100%.`);
            this.formValido = false;
        }

        if (!this.formValido)
            scrollIntoViewToTop();

        return this.formValido;
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        let percentualValido = this.validarPercentualTotal();
        if (percentualValido)
            this.twoFactorAuth.show();
    }

    /**
    * Lida com o sucesso da autenticação de dois fatores.
    * @param twoFactorAuthToken 2fa token.
    */
    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar();
    }

    registrarDocumentoAssinado(signData: SignDataModel) {
        this.formularioService.registrarAssinaturaDocumento(signData.idDocumento)
            .subscribe(resposta => {
                this.documentoAssinado = resposta.success && resposta.data;
            }, error => {
                console.error(error);
            });
    }
    
    validarPercentualTotal() {
        let totalPercentual = 0;
        this.designados.forEach((designado) => {
            totalPercentual += designado.percentual;
        });

        if (totalPercentual != 100) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O percentual total dos designados declarados deve ser igual a 100%.`);
            this.formValido = false;
            scrollIntoViewToTop();
        }
        return this.formValido;
    }
}
