export class GestaoFinanceiraEmprestimoServiceSaldoGetInformacoesAdicionaisSaldoEmprestimoResponse {
    saldoDevedor: number;
    iof: number;
    disponivelEmprestimo: number;
    disponivelEmprestimoComAdicional: number;
    valorCarencia: number;
    saldoInadimplente: number;
    salario: number;
    salarioComAdicional: number;
    valorTotalCredito: number;
    enquadramento: string;
    enquadramentoComAdicional: string;
}
