export const FAIXAS_SALARIAL: Array<{ valor: number,percentual: number}> = [
	{
		valor: 2758.48,
		percentual: 2.89,
	},
	{
		valor: 5873.9,
		percentual: 4.80,
	},
	{
		valor: 55169500.00,
		percentual: 14.47,
	}
]

export function ajustarFaixas(urf:number) {
    FAIXAS_SALARIAL[0].valor = urf / 2;
    FAIXAS_SALARIAL[1].valor = urf;
    FAIXAS_SALARIAL[2].valor = urf + (urf / 2);
}
