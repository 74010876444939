import { Component, OnInit } from '@angular/core';

import { GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-participantes-beneficiarios',
  templateUrl: './participantes-beneficiarios.component.html',
  styleUrls: ['./participantes-beneficiarios.component.css']
})
export class ParticipantesBeneficiariosComponent {

    isLoading = true;

    ngOnInit() {
        this.isLoading = false;
    }
}
