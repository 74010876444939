import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploadModel } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-file-upload-attach',
    templateUrl: './file-upload-attach.component.html',
    styleUrls: ['./file-upload-attach.component.css']
})
export class FileUploadAttachComponent implements OnInit {
    @Input() uploadedFiles: FileUploadModel[] = [];
    @Output() onFileUpload: EventEmitter<FileUploadModel> = new EventEmitter<FileUploadModel>();
    @Output() onFileRemoved: EventEmitter<string> = new EventEmitter<string>();
    visible = false;

    get totalAttached(): string {
        return this.uploadedFiles.length > 0 ? this.uploadedFiles.length.toString() : '';
    }

    constructor() { }

    ngOnInit(): void {
    }

    attach() {
        this.visible = true;
    }

    hideModal() {
        this.visible = false;
    }
}
