import { Component, OnInit, ChangeDetectionStrategy,Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { DadosCadastraisService, SessionInitService, TipoSeDocumento, DocumentoService } from '@fibra/fibra-shared-lib';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { PdfViewerComponent } from '../../components/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'app-plano-bd-saldado-ativo',
    templateUrl: './plano-bd-saldado-ativo.component.html',
    styleUrls: ['./plano-bd-saldado.ativo.component.css']
})
export class PlanoBdSaldadoAtivoComponent implements OnInit {
    [x: string]: any;
    assistido = true;
    isLoading: boolean = false;
    mostrarOpcaoIndice: boolean = false;
    resumoAdesao: ResumoAdesaoDto;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(private router: Router, private sessionService: SessionInitService, private dadosCadastrais: DadosCadastraisService, private documentoService: DocumentoService) { }

    ngOnInit() {
        var perfil = this.sessionService.sessionPerfil();
        var anotacao = perfil.anotacoes.filter(f => f.chave.toLowerCase() == "tela-inicial-plano-saldo");
        if(anotacao.length == 0)
            return;
        if(anotacao[0].nota.toLocaleLowerCase() != "pular")
            return;
        this.goToPainel();
    }

    abrirCartilhaSaldamento(){
        this.isLoading = true;
        let fileName =  encodeURIComponent('Cartilha Online Ativos e Assistidos.pdf');
        let request = this.documentoService.getSeDocsByName(TipoSeDocumento.SinteseSaldamentoAtivoAssistidos,fileName)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request,true)
    }

    goToPainel() {
        this.router.navigateByUrl('/painel/plano-bd/inicio');
    }

    async naoMostrarMais() {
        await this.dadosCadastrais.naoMostrarMaisTelaInicialSaldado();
        this.goToPainel();
    }
}
