import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { STORAGE_KEYS, StorageService } from '@fibra/fibra-shared-lib';
import { Dialog } from 'primeng/dialog';

@Component({
    selector: 'app-informativo-alteracao-senha',
    templateUrl: './informativo-alteracao-senha.component.html',
    styleUrls: ['./informativo-alteracao-senha.component.css']
})
export class InformativoAlteracaoSenhaComponent implements OnInit {

    @ViewChild('dialog') dialog: Dialog;
    //header = 'Aviso Importante!';
    visible = false;
    hideMessage = false;
    style = { width: '30vw' };
    // Vai mostrar o aviso temporariamente antes da atualização da senha.
    startDate = new Date('2024-07-22');
    endData = new Date('2024-08-17');

    constructor(private platform: Platform, private storageService: StorageService, private router: Router) { }

    ngOnInit(): void {
        let dateToday = new Date();
        let hideInfoPass = this.storageService.get(STORAGE_KEYS.HIDE_INFO_PASSWORD) === 'true';
        let isInTimeRange = dateToday >= this.startDate && dateToday <= this.endData;
        this.visible = isInTimeRange;

        let urlParams = new URLSearchParams(window.location.search);
        let testInfo = urlParams.get('test-info');
        if (testInfo === 'true') {
            this.visible = true;
        }

        let testInfoReset = urlParams.get('test-info-reset');
        if (testInfoReset === 'true') {
            this.storageService.remove(STORAGE_KEYS.HIDE_INFO_PASSWORD);
            hideInfoPass = false;
            this.visible = true;
        }

        this.visible = this.visible && !hideInfoPass;

        if (this.visible && this.platform.IOS || this.platform.ANDROID) {
            this.style = { width: '100vw' };
        }
    }

    onHide() {
        if (this.hideMessage)
            this.storageService.set(STORAGE_KEYS.HIDE_INFO_PASSWORD, 'true');
    }
}
