import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-password-template',
    templateUrl: './password-template.component.html',
    styleUrls: ['./password-template.component.css']
})
export class PasswordTemplateComponent implements OnInit {
    @Input() requirements: { length: boolean; uppercase: boolean; lowercase: boolean; number: boolean; special: boolean; };

    constructor() { }

    ngOnInit(): void {
    }

    checkRequirements(password: string) {
        this.requirements = {
            length: false,
            uppercase: false,
            lowercase: false,
            number: false,
            special: false
        };

        if (/(?=.{8,})/.test(password)) {
            this.requirements.length = true;
        }

        if (/(?=.*[A-Z])/.test(password)) {
            this.requirements.uppercase = true;
        }

        if (/(?=.*[a-z])/.test(password)) {
            this.requirements.lowercase = true;
        }

        if (/(?=.*[0-9])/.test(password)) {
            this.requirements.number = true;
        }

        if (/[^A-Za-z0-9]/.test(password)) {
            this.requirements.special = true;
        }
    }

}
