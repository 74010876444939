export class GestaoFinanceiraEmprestimoServiceSaldoGetSaldoAtualResponse {
    dataIniMov: Date;
    saldoAnterior: number;
    ultimoPgto: number;
    correcao: number;
    seguro: number;
    juros: number;
    saldoAtual: number;
    iof: number;
    dataFimMov: Date;
}
