import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AdesaoService } from '../services/adesao.service';
import { ESituacaoParticipante } from '../enums';

@Injectable()
export class FibraGuardService implements CanActivate {
    papeisUsuario: string[] = [];

    constructor(private authService: AuthService, private adesaoService: AdesaoService, private router: Router) { }

    canActivate() {
        let adesao = this.adesaoService.getAdesaoContexto();
        let situacaoValida = this.adesaoService.validarSituacao(ESituacaoParticipante.Ativo, adesao?.tipoSituacao);
        if (!situacaoValida) {
            this.router.navigate(['/404']);
            return false;
        }
        return true;
    }
}
