export function inputChange(event: any): any | string {

    let file: any | string;
    const pattern = /image-*|pdf/;

    file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];

    if (!file.type.match(pattern)) { return file = 'Formato Inválido'; }

    if (file.size > 2097152) { return file = 'Tamanho máximo do arquivo de imagem 2Mb'; }

    return file;
}
