import { Component, OnInit } from '@angular/core';

import { NomeArquivos } from '@fibra/fibra-shared-lib';
import { ArquivosService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-normas',
    templateUrl: './normas.component.html'
})
export class NormasComponent implements OnInit {
    isLoading = false;

    constructor() { }
    //removido as normas
    ngOnInit() {

    }
}
