import { Component, OnInit } from '@angular/core';

import {
    AuthService,
    DadosCadastraisService,
    GestaoPlanoBeneficioService,
    GestaoPlanoCadastroService
} from '@fibra/fibra-shared-lib';
import { DatePipe } from '@angular/common';
import { CarenciaModel, PrevisaoModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
//import { PrevisaoModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/previsao-model';
//import { CarenciaModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/carencia-model';

@Component({
    selector: 'app-previsao-aposentadoria',
    styleUrls: ['./previsao-aposentadoria.component.css'],
    templateUrl: './previsao-aposentadoria.component.html'
})
export class PrevisaoAposentadoriaComponent implements OnInit {
    definicaoDoGrafico: any;
    isLoading = true;
    opcoesDoGrafico: any;
    usuarioPossuiPermissao = false;
    bloquear: boolean;
    mensagemDeErro: string;
    dataRef: string;

    previsoesModel: PrevisaoModel[] = [];
    valoresAposentadoria: CarenciaModel;

    constructor(
        private authService: AuthService,
        private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService,
        private dadosCadastraisService: DadosCadastraisService,
        private datePipe: DatePipe
    ) { }

    ngOnInit() {
        this.init();
        // this.dadosCadastraisService.getCadastroGeral(true).subscribe(usuario => {
        //     this.dadosCadastrais = usuario;
        //     this.authService.AccountCPF_GetRoles(usuario.cpf).subscribe(perfisUsuario => {
        //         this.init(perfisUsuario || []);
        //     });
        // });
    }

    init() {
        //if (perfisUsuario.includes(AuthService.USUARIO_ATIVO)) {
        //this.usuarioPossuiPermissao = true;
        this.gestaoPlanoBeneficioService.PercentuaisApos_GetPrevisaoAposentadoria()
            .subscribe(previsoes => {
                previsoes.slice(-1).forEach(previsao => {
                    let idTipoConcessao = previsao.idTipoConcessao;
                    this.dataRef = this.datePipe.transform(previsao.dataPrevApos, 'yyyy-MM-dd');
                    this.gestaoPlanoBeneficioService.PercentuaisApos_GetCarenciaApos(idTipoConcessao, this.dataRef)
                        .subscribe(valoresAposentadoria => {
                            console.log('valoresAposentadoria', valoresAposentadoria);
                            this.valoresAposentadoria = valoresAposentadoria;
                            this.isLoading = false;
                        }, () => this.informarErroDeCarregamento())
                }, this);
                //this.preencherDadosDoGrafico();
                this.isLoading = false;
            }, error => {
                console.error(error);
                this.isLoading = false;
            });
        //}
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
            ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }


    /*private preencherDadosDoGrafico() {
        this.definicaoDoGrafico = {
            labels: this.labelsDoGrafico,
            datasets: [{
                label: 'Previsão',
                barThickness: 15,
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.valoresDoGrafico
            }]
        };

        this.opcoesDoGrafico = {
            title: { display: false },
            legend: false,
            scales: {
                xAxes: [{
                    gridLines: { display: true },
                    barPercentage: 0.4
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };
    }*/
}
