import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-emprestimo-negado',
    templateUrl: './emprestimo-negado.component.html'
})
export class EmprestimoNegadoComponent {
    @Input() exibirModal: boolean;
    @Output() exibirModalChange = new EventEmitter<boolean>();

    fecharModal() {
        this.exibirModal = false;
        this.exibirModalChange.emit(this.exibirModal);
    }
}
