import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    title = 'app';

    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription: Subscription;
    private popupCloseSubscription: Subscription;
    private initializeSubscription: Subscription;
    private statusChangeSubscription: Subscription;
    private revokeChoiceSubscription: Subscription;
    private noCookieLawSubscription: Subscription;

    constructor(private ccService: NgcCookieConsentService,
        private httpClient: HttpClient,
        private router: Router,
        private config: PrimeNGConfig,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.httpClient.get(`${environment.API_LOGIN}/api/StatusApi`).subscribe();

        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.initializeSubscription = this.ccService.initialize$.subscribe(
            (event: NgcInitializeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
            (event: NgcStatusChangeEvent) => {
                // you can use this.ccService.getConfig() to do stuff...

                const name = "cookieconsent_status"

                var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

                if (match) {
                    if (match[2] == "deny") {
                        const date = new Date();

                        // Set it expire in -1 days
                        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

                        // Set it
                        document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/; domain=" + environment.COOKIE_DOMAIN;
                    }
                }

            });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
            () => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
            (event: NgcNoCookieLawEvent) => {
                // you can use this.ccService.getConfig() to do stuff...
            });

        this.translate('pt-BR');
    }

    translate(lang: string) {
        this.translateService.use(lang);
        this.config.setTranslation(LOCALE_PT_BR);
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializeSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }
}
