export const TipoBloqueioConst = {
    PENDENCIA: 1,
    INADIMPLENCIA: 2,
    CARENCIA: 3,
    CARTEIRA: 4,
    PLANO: 5,
    PARTICIPANTE: 6,
    ELEGIBILIDADE: 7,
    BLOQUEIO_MISTO: 8,
    NAO_EXISTE_VALOR_EMPRESTIMO: 9,
    NAO_EXISTE_DADO_BANCARIO: 10,
    EMPRESTIMO_ANDAMENTO: 211
};

