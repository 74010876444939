import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StatusApiModel } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-servidor-indisponivel',
    templateUrl: './servidor-indisponivel.component.html',
    styleUrls: ['./servidor-indisponivel.component.css']
})
export class ServidorIndisponivelComponent implements OnInit {
    msgs: Array<string> = ['Em manutenção.'];

    constructor(private httpClient: HttpClient, private router: Router, @Inject('environment') private environment) { }

    ngOnInit() {
        this.httpClient.get<StatusApiModel>(`${this.environment.API_LOGIN}/api/StatusApi`)
            .subscribe(s => {
                this.router.navigate(["/login"]);
            }, (err: HttpErrorResponse) => {
                this.msgs = (err.error.mensagem as string).split(';');
            });
    }

}
