import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class HeaderManagerService {
    addHeader(name: string, value: string, headers: HttpHeaders | null = null) {
        if (headers == null) {
            headers = new HttpHeaders();
        }

        let newHeaders = headers.append(name, value);
        return newHeaders;
    }
}