export class DadosParaSimulacao {
    idade: number;
    //Salário Atual do Participante
    salario: number;
    //Saldo de Conta Atual do Participante
    saldo: number;
    //Saldo de Portabilidade do Participante
    saldoPortabilidade: number;
    //Tempo em Anos para Aposentadoria
    carencia: number;
    // nao sei o que significa
    urf: number;
    // taxa de juros para o usuario
    taxaDeJuros: number;
    beneficioRendaFinanceiraCd: number;
}
