import { TempoVinculacaoModel } from "./tempo-vinculacao.model";

export class DeclaracaoTempoServicoModel {
    aposentadoPrevidenciaSocial: boolean;
    dataAposentadoriaPrevidenciaSocial: Date;
    possuiVinculoOutraEntidade: boolean;
    dataVinculoOutraEntidade: Date;
    vinculoOutraEntidade: string;
    funcaoVinculoOutraEntidade: string;
    observacoesTempoVinculacao: string;
    observacoesInfoAdicionais: string;
    tempoVinculacao: Array<TempoVinculacaoModel>;
}