import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';

import {
    GestaoFinanceiraEmprestimoService, SimulacaoValores,
    Contrato, ConfirmacaoEmprestimo,
    ETipoEnvioNotificacao,
    DadosCadastraisService,
    AdesaoService,
    ETipoPlano,
    applyPhoneNumberMask
} from '@fibra/fibra-shared-lib';
import { ContratoProntoParaAssinar } from '@fibra/fibra-shared-lib/lib/domain/gestaofinanceira/emprestimo/models/contrato-pronto-para-assinar';
import { NotificationOptionDialogComponent } from 'projects/FibraAutoAtendimento/src/app/components/notification-option-dialog/notification-option-dialog.component';

@Component({
    selector: 'app-confirmacao-emprestimo',
    styleUrls: ['./confirmacao-emprestimo.component.css'],
    templateUrl: './confirmacao-emprestimo.component.html'
})
export class ConfirmacaoEmprestimoComponent implements OnInit {
    @Output() depoisDeConfirmarEmprestimo = new EventEmitter<Contrato>();
    @Output() emprestimoSolicitado = new EventEmitter<ContratoProntoParaAssinar>();
    @Input() exibirModal: boolean;
    @Output() exibirModalChange = new EventEmitter<boolean>();
    @Input() planoDeParcelamento: SimulacaoValores;
    @Input() valorPedido: number;
    @ViewChild('notificationOptionDialog') notificationOptionDialog: NotificationOptionDialogComponent;

    clientePossuiDoencaGrave: boolean = undefined;
    error = false;
    isLoading = true;
    submitAttempted = false;
    confirmarToken = false;
    contrato: Contrato;
    confirmacaoEmprestimo: ConfirmacaoEmprestimo;

    headerDialogNotification: string = 'Verificação de Segurança';
    descricaoNotificacaoEmail: string = 'Receber um código no e-mail cadastrado';
    descricaoNotificacaoSms: string = 'Receber um código através de SMS no número cadastrado';
    notificacaoInformacaoExtra: string = 'Caso seu endereço de e-mail ou número de telefone esteja incorreto, realize a atualização cadastral.'

    get formInvalido() {
        return this.clientePossuiDoencaGrave === undefined && this.submitAttempted;
    }

    get saldoDevedorAposLiberacao() {
        const saldoApos = this.confirmacaoEmprestimo ? this.confirmacaoEmprestimo.saldoApos : 0;
        return saldoApos + this.valorSeguro;
    }

    get valorSeguro() {
        const valorSeguro = this.confirmacaoEmprestimo ? this.confirmacaoEmprestimo.seguro : 0;
        return this.clientePossuiDoencaGrave ? 0 : valorSeguro;
    }

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
        private dadosCadService: DadosCadastraisService, private adesaoService: AdesaoService
    ) { }

    ngOnInit() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_PlanoDeParcelamento(this.planoDeParcelamento).subscribe(plano => {
            this.confirmacaoEmprestimo = plano;
            this.isLoading = false;
        });

        //#region Tipo Notificação
        let dadosCadastro = this.dadosCadService.getCadastroGeralLocal();
        if (dadosCadastro.contato?.email) {
            this.descricaoNotificacaoEmail = `Receber um código no e-mail: ${dadosCadastro.contato.email}`;
        }

        if (dadosCadastro.contato?.celular) {
            this.descricaoNotificacaoSms = `Receber um código através de SMS no número: ${applyPhoneNumberMask(dadosCadastro.contato.celular)}`;
        }

        const plano = this.adesaoService.getAdesaoContexto().idPlano;
        const urlCadastro = `/painel/plano-${plano == ETipoPlano.PlanoBD ? 'bd' : 'cd'}/dados-cadastrais/cadastro`;
        const messagemAtualizacaoCad = `Seu E-mail ou celular está incorreto?  <a href='${urlCadastro}'>atualize o cadastro aqui</a>.`;
        this.notificacaoInformacaoExtra = messagemAtualizacaoCad;
        //#endregion
    }

    exibirDialogTipoEnvioNotificacao() {
        this.notificationOptionDialog.show();
    }

    selecionarTipoEnvioNotificacao(eTipoEnvioNotificacao: ETipoEnvioNotificacao) {
        this.notificationOptionDialog.close();
        this.confirmarEmprestimo(eTipoEnvioNotificacao);
    }

    confirmarEmprestimo(tipoEnvioNotificacao: ETipoEnvioNotificacao) {
        this.submitAttempted = true;
        if (this.formInvalido) {
            return;
        }

        this.isLoading = true;
        this.planoDeParcelamento.doencaGrave = this.clientePossuiDoencaGrave;
        this.planoDeParcelamento.taxasAntecipadas[0].valor = this.valorSeguro;
        this.planoDeParcelamento.valorSolicitado = this.confirmacaoEmprestimo.valorSolicitado;

        this.gestaoFinanceiraEmprestimoService.Emprestimo_InserirSolicitacao(this.planoDeParcelamento, tipoEnvioNotificacao)
            .subscribe(resposta => {
                this.isLoading = false;
                if (resposta.sucesso) {
                    this.emprestimoSolicitado.emit(resposta);
                }
            }, (promiseError) => {
                this.isLoading = false;
                this.error = true;
                console.error(promiseError);
            });
    }

    exibirModalTokenChange(exibirModalToken: boolean) {
        this.isLoading = exibirModalToken;
        this.confirmarToken = false;
    }

    fecharModal() {
        this.exibirModal = false;
        this.exibirModalChange.emit(this.exibirModal);
        window.location.reload();
    }

    fecharModalCasoErro(exibirModal: boolean) {
        this.exibirModalChange.emit(exibirModal);
    }

    depoisDeConfirmarToken(resultado: boolean) {
        this.depoisDeConfirmarEmprestimo.emit(this.contrato);
    }
}
