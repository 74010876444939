import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService, Contrato } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-confirmacao-token',
  templateUrl: './confirmacao-token.component.html',
  styleUrls: ['./confirmacao-token.component.css']
})
export class ConfirmacaoTokenComponent implements OnInit {
    @Input() exibirModal: boolean;
    @Input() reloadAposReenvioToken: boolean;
    @Output() exibirModalTokenChange = new EventEmitter<boolean>();
    @Output() depoisDeConfirmarToken = new EventEmitter<boolean>();
    @Output() fecharModalCasoErro = new EventEmitter<boolean>();

    isLoading = true;
    error = false;
    submitAttempted = false;

    naoMostrar = true;
    isModalVisible = false;

    token: string;
    mensagemLinha1: string;
    mensagemLinha2: string;
    contratoInserido: Contrato;
    contDigitos = 0;
    habilitaBotao = false;
    classeBotao = 'btn btn-success';

    @Input() set contrato(contrato: Contrato) {
        if (contrato) {
            this.contratoInserido = contrato;
            this.textoMensagem(this.contrato.tokenDestino);
        }
    }

    get contrato(): Contrato { return this.contratoInserido; }

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        this.isLoading = false;
    }

    textoMensagem(destino: string) {

        if (this.contratoInserido) {
            if (destino.includes('@')) {
                const emailMascara = destino.replace(/^(.)(.*)(.@.*)$/,
                    (_, a, b, c) => a + b.replace(/./g, '*') + c
                );
                this.mensagemLinha1 = 'Enviamos um e-mail com um código de';
                this.mensagemLinha2 = 'verificação para ' + emailMascara;
            } else {
                let telefoneMascara = '';
                telefoneMascara = destino.substring(0, destino.length - 2).replace(/[0-9]/g, '*');
                telefoneMascara = telefoneMascara.concat(destino.substring(destino.length - 2));
                this.mensagemLinha1 = 'Enviamos um SMS com um código de';
                this.mensagemLinha2 = 'verificação para ' + telefoneMascara;
            }
        }
    }

    confirmarToken() {
        this.submitAttempted = true;

        this.isLoading = true;

        this.gestaoFinanceiraEmprestimoService.Emprestimo_ConfirmarSolicitacao
        (this.contrato.matriculaParticipante, this.contrato.id, this.token)
        .subscribe(data => {
            if (data.type === 0) {
                this.error = true;
                this.token = '';
                this.isLoading = false;
            } else if (data === false) {
                this.token = '';
                this.isLoading = false;
                this.isModalVisible = true;
                this.naoMostrar = true;
            } else {
                this.isLoading = false;
                this.depoisDeConfirmarToken.emit(this.exibirModal);
                if (this.reloadAposReenvioToken) {
                    this.fechar();
                } else {
                    this.fecharModal();
                }
            }
        }, () => {
            this.token = '';
            this.isLoading = false;
            this.isModalVisible = true;
            this.naoMostrar = true;
        });
    }

    enviarNovoCodigo() {
        this.error = false;
        this.isLoading = true;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_ReenviarToken(this.contrato.id)
        .subscribe(data => {
            if (data) {
                console.log(data);
                this.token = '';
                this.textoMensagem(data);
                this.isLoading = false;
            } else {
                console.log('Error no retorno do enviarNovoCodigo');
                this.isLoading = false;
                this.error = true;
            }
        });
    }

    fecharModal() {
        this.error = false;
        this.exibirModal = false;
        this.exibirModalTokenChange.emit(this.exibirModal);
        this.isModalVisible = false;
        this.naoMostrar = false;
    }

    fechar() {
        this.exibirModal = false;
        this.exibirModalTokenChange.emit(this.exibirModal);
        this.fecharModalCasoErro.emit(this.exibirModal);
        this.isModalVisible = false;
        this.naoMostrar = false;
        window.location.reload();
    }

    validaDigitos(e) {
        this.contDigitos = e.length;
        if (this.contDigitos === 6) {
            const element = document.getElementById('btnEnviar');
            element.classList.add('btn-success');
            this.habilitaBotao = true;
        } else {
            const element = document.getElementById('btnEnviar');
            element.classList.remove('btn-success');
            this.habilitaBotao = false;
        }
    }

}
