import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageService } from '@fibra/fibra-shared-lib';
import { STORAGE_KEYS } from '@fibra/fibra-shared-lib';
import { AuthCdService } from '@fibra/fibra-shared-lib';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthCdGuard implements CanActivate, CanActivateChild {
    constructor(private storageService: StorageService, private router: Router, private authCdService: AuthCdService) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const anyToken = this.storageService.any(STORAGE_KEYS.HANDSHAKE_TOKEN);
        let tokenApiBd = sessionStorage.getItem("accessToken");

        if (!anyToken) {
            tokenApiBd = tokenApiBd.replace('Bearer ', '');
            return this.authCdService.getHandshakeToken(tokenApiBd)
                .then((success: boolean) => {
                    return success;
                }, error => {
                    this.router.navigate(['/painel'], { queryParams: { returnUrl: state.url } });
                    console.error(error);
                    return false;
                });
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
