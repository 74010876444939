import { Observable } from 'rxjs';

export class RequestCacheService {
    static cache: { [id: string]: Observable<any> } = {};

    static clearCache() {
        RequestCacheService.cache = null;
        RequestCacheService.cache = {};
    }
}
