import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-perfil-investimento',
    styleUrls: ['./perfil-investimento.component.css'],
    templateUrl: './perfil-investimento.component.html'
})
export class PerfilInvestimentoComponent implements OnInit {

    isLoading = true;
	data: any;

    constructor() {
	}

    ngOnInit() {
        this.isLoading = false;
    }

}