import { Inject, Injectable } from "@angular/core";
import { HttpClientService } from "./http-client.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ETokenApi } from "../enums";
import { TipoSeDocumento } from "../constants/tipo-se-docs";

@Injectable()
export class DocumentoService {

    constructor(
        private httpClientService: HttpClientService,
        @Inject('environment') private environment, private sanitizer: DomSanitizer) {
    }

    getExtratoBps(cpf?: string) {
        let url = `${this.environment.API_DOCUMENTOS}/api/Download/GetExtratoBps` + (cpf ? '/' + cpf : '');
        return this.httpClientService.getFile(url, ETokenApi.TokenApiBd);
    }

    getExtratoContribuicaoPdf(ano: number) {
        let url = `${this.environment.API_CONTRIBUICAO}/Extrato/ExtratoContribuicaoPdf?ano=${ano}`;
        return this.httpClientService.getFile(url, ETokenApi.TokenApiBd);
    }

    getSeDocs(tipoSeDoc: TipoSeDocumento, callback?: Function, thisArg?: any) {
        const url = `${this.environment.API_DOCUMENTOS}/api/Download/GetSeDocs?tipoNormativo=${tipoSeDoc}`;
        return this.httpClientService.getFile(url, ETokenApi.TokenApiBd);
    }


    getSeDocsByName(tipoSeDoc: TipoSeDocumento,fileName: string, callback?: Function, thisArg?: any) {
        const url = `${this.environment.API_DOCUMENTOS}/api/Download/GetSeDocsByName?idDoc=${tipoSeDoc}&fileName=${fileName}`;
        return this.httpClientService.getFile(url, ETokenApi.TokenApiBd);
    }
}
