import { LotacaoDto } from "./lotacao.dto";

export class DadosFibraDto {
    registroFibra: String;
    matriculaItaipu: String;
    dataAdmissao: Date;
    aposentadoINSS: Boolean;
    dataAposentadoria: Date;
    filiacaoFibra: Date;
    lotacao: LotacaoDto;
    tipoSituacao: String;
    situacao: String;
}
