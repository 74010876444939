import { Component, OnInit } from '@angular/core';

import { AdesaoCdService, DocumentoService, HttpClientService, obterNomeDoMesAPartirDoNumeroDoMes } from '@fibra/fibra-shared-lib';
import { ContribuicaoAnoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contribuicao-ano.dto';
import { ContribuicaoMesDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contribuicao-mes.dto';
import { ContribuicaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contribuicao.dto';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
    selector: 'app-contribuicao-extrato-card',
    styleUrls: ['./contribuicao-extrato-card.component.css'],
    templateUrl: './contribuicao-extrato-card.component.html'
})
export class ContribuicaoExtratoCardComponent implements OnInit {

    readonly CODIGO_DECIMO_TERCEIRO = 10116;
    show = false;
    contribuicaoDto: ContribuicaoDto;
    contribuicaoAnoDto: ContribuicaoAnoDto[] = [];
    contribuicaoMesesDto: ContribuicaoMesDto[] = [];
    indexAnoSelecionado: number;
    mensagemDeErro: string;
    isLoading = false;
    bloquear: boolean;
    ano: number;
    anoSelecionado: number;
    mes: number;
    obterNomeDoMesAPartirDoNumeroDoMes = obterNomeDoMesAPartirDoNumeroDoMes;

    constructor(private adesaoCDService: AdesaoCdService,
        private documentoService: DocumentoService, private httpClientService: HttpClientService) {
        const dataAtual = new Date();
        this.ano = dataAtual.getFullYear();
    }

    ngOnInit() {
        this.getContribuicaoAno();
    }

    getContribuicaoAno() {
        this.isLoading = true;
        this.adesaoCDService.obterExtratoContribuicaoPorAno(this.ano).subscribe(contribuicao => {
            this.show = true;
            if (contribuicao) {
                this.contribuicaoDto = contribuicao;
                this.contribuicaoAnoDto = this.contribuicaoDto.contribuicoesAnos.sort(this.ordenarPorAnoDecrescente);
                this.contribuicaoMesesDto = this.contribuicaoDto.contribuicaoMeses;
                this.indexAnoSelecionado = 0;
                this.isLoading = false;
            } else {
                this.informarErroDeCarregamento();
            }
        }, error => {
            this.isLoading = false;
        });
    }

    getContribuicaoMeses() {
        this.isLoading = true;
        this.adesaoCDService.obterExtratoContribuicaoPorAno(this.contribuicaoAnoDto[this.indexAnoSelecionado].ano).subscribe(contribInfo => {
            if (contribInfo) {
                this.contribuicaoDto = contribInfo;
                this.contribuicaoMesesDto = this.contribuicaoDto.contribuicaoMeses;
                this.isLoading = false;
            } else {
                this.informarErroDeCarregamento();
            }
        }, () => this.informarErroDeCarregamento());
    }

    obterNomeDoMes(data: Date): string {
        if (data) {
            let mes = new Date(data).getMonth() + 1;
            return obterNomeDoMesAPartirDoNumeroDoMes(mes);
        }
        return '';
    }

    private ordenarPorAnoDecrescente(
        a: ContribuicaoAnoDto,
        b: ContribuicaoAnoDto
    ) {
        return a.ano < b.ano ? 1 : a.ano > b.ano ? -1 : 0;
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
            ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }

    baixarExtrato(ano: number) {
        this.isLoading = true;
        let request = this.documentoService.getExtratoContribuicaoPdf(ano)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.httpClientService.openPdfNewTab(request);
    }
}
