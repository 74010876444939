<h4 class="text-center" *ngIf="titulo">{{ titulo }}</h4>
<div class="text-center" *ngIf="descricao">
    <span *ngIf="descricao != titulo">{{ descricao }}</span>
</div>
<section class="d-flex justify-content-center" *ngIf="videos?.length > QTD_VIDEOS_VISIVEIS">
    <ol class="app-carrossel-indicadores">
        <li *ngFor="let pagina of indicadoresDePagina" [class.ativo]="paginaAtiva == pagina" (click)="ativarPagina(pagina)">{{ pagina }}</li>
    </ol>
</section>
<div class="d-flex align-items-center justify-content-around">
    <button class="btn btn-success app-carrossel-gatilho" type="button" *ngIf="videos?.length > QTD_VIDEOS_VISIVEIS" (click)="ativarPagina(paginaAtiva - 1)">
        <i class="fa fa-chevron-left"></i>
    </button>
    <div class="app-carrossel-conteiner">
        <ol class="app-carrossel-itens" [@slide]="{ value: slideCommand, params: { margem: margem } }">
            <ng-container *ngIf="formatoDeColuna else formatoDeLinha">
                <li class="app-carrossel-coluna" *ngFor="let coluna of getColunaDeVideos()">
                    <figure *ngFor="let video of coluna">
                        <img [src]="video.image | safe:'url'" [alt]="video.body" (click)="cliqueNoVideo(video)" />
                        <figcaption *ngIf="exibirTitulosDosVideos">{{ video.body }}</figcaption>
                    </figure>
                </li>
            </ng-container>
            <ng-template #formatoDeLinha>
                <li class="app-carrossel-item" *ngFor="let video of videos">
                    <figure>
                        <img [src]="video.image | safe:'url'" [alt]="video.body" (click)="cliqueNoVideo(video)" />
                        <figcaption *ngIf="exibirTitulosDosVideos">{{ video.body }}</figcaption>
                    </figure>
                </li>
            </ng-template>
        </ol>
    </div>
    <button class="btn btn-success app-carrossel-gatilho" type="button" *ngIf="videos?.length > QTD_VIDEOS_VISIVEIS" (click)="ativarPagina(paginaAtiva + 1)">
        <i class="fa fa-chevron-right"></i>
    </button>
</div>
