import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contribuicao-mensal-card',
    styleUrls: ['./contribuicao-mensal-card.component.css'],
    templateUrl: './contribuicao-mensal-card.component.html'
})
export class ContribuicaoMensalCardComponent implements OnInit {

    mensagemDeErro: string;
    isLoading = true;
    bloquear: boolean;

    constructor(){ }

    ngOnInit() {
        this.isLoading = false;
    }

}
