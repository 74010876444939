import { Component } from '@angular/core';

import { ArquivosService } from '@fibra/fibra-shared-lib';
import { NomeArquivos } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-como-fazer',
    templateUrl: './como-fazer.component.html',
    styleUrls: ['./como-fazer.component.css']
})
export class ComoFazerComponent {
    DECLARACAO_PESSOAL_EXPOSTA: string = NomeArquivos.DECLARACAO_PESSOAL_EXPOSTA;
    NOMEACAO_DESIGNADOS: string = NomeArquivos.NOMEACAO_DESIGNADOS;
    SOLICITACAO_INSCRICAO_BENEF: string = NomeArquivos.SOLICITACAO_INSCRICAO_BENEF;
    SOLCITACAO_AUTOPATROC_PARCIAL: string = NomeArquivos.SOLCITACAO_AUTOPATROC_PARCIAL;
    DEPENDENTES_IR: string = NomeArquivos.DEPENDENTES_IR;
    ADIANTAMENTO_ABONO: string = NomeArquivos.ADIANTAMENTO_ABONO;


    constructor(private arquivosService: ArquivosService) { }

    downloadFile(nome: string) {
        this.arquivosService.getArquivoByNome(nome);
    }
}
