import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-finalizado',
  templateUrl: './finalizado.component.html',
  styleUrls: ['./finalizado.component.css']
})
export class FinalizadoComponent implements OnInit {
  constructor(private router: Router) { }

  items: MenuItem[];
  activeIndex: number = 0;

  ngOnInit() {
    this.activeIndex = 2;
    this.items = [
      {label: 'Informações iniciais'},
      {label: 'Perfil de investimento'},
      {label: 'Conclusão'}
    ];
  }

  onSubmit(){
  }

}
