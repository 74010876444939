import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DadosCadastraisService, ESituacaoGeral } from '@fibra/fibra-shared-lib';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdesaoPendenteGuard implements CanActivate {

    constructor(private router: Router, private dadosCadService: DadosCadastraisService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let dadosCad = this.dadosCadService.getCadastroGeralLocal();
        if (dadosCad?.informacoesAdicionais?.situacaoGeral == ESituacaoGeral.AdesaoCdPendente) {
            this.router.navigate(['/painel']);
            return false;
        }
        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
