import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'primeng/chart';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { ButtonModule } from 'primeng/button';
import { HeaderModule } from './header/header.module';
import { RodapeModule } from './rodape/rodape.module';
import {
    PainelComponent,
    TrocaPlanoComponent,
    AdesaoCdComponent,
    Passo1Component,
    Passo2Component,
    Passo3Component,
    Passo4Component,
    FinalizadoComponent,
    AdesaoSimuladorComponent,
    //plano bd saldado
    PlanoBdSaldadoAssistidoComponent,
    PlanoBdSaldadoAtivoComponent,
} from '.';
import { DashboardRoutingModule } from './painel-routing.module';
import { SharedModule } from '@fibra/fibra-shared-lib';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
    AuthGuardService, RoleGuardService,
    AssistidoGuardService,
    AtivoGuardService, FibraGuardService
} from '@fibra/fibra-shared-lib';


import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { SpinnerModule } from 'primeng/spinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { StepsModule } from 'primeng/steps';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { DependenteComponent } from '../components/dependente/dependente.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { TokenDialogComponent } from '../components/token-dialog/token-dialog.component';
import { SignWidgetComponent } from '../components/clicksign/sign-widget/sign-widget.component';
//import { IndiceFinanceiroComponent } from './indice-financeiro/indice-financeiro.component';
import { PessoaPoliticamenteExpostaComponent } from './geral/solicitacoes/pessoa-politicamente-exposta/pessoa-politicamente-exposta.component';
import { ComponentsModule } from '../components/components.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';


@NgModule({
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        ComponentsModule,
        HeaderModule,
        RodapeModule,
        BrowserAnimationsModule,
        BrowserModule,
        // CarouselModule,
        ChartModule,
        AutoCompleteModule,
        CheckboxModule,
        CurrencyMaskModule,
        DashboardRoutingModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        InputTextModule,
        NgxLoadingModule,
        NgxMaskModule,
        NgxPaginationModule,
        InfiniteScrollModule,
        RadioButtonModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        SharedModule,
        SidebarModule,
        SliderModule,
        TableModule,
        PaginatorModule,
        TooltipModule,
        OverlayPanelModule,
        CalendarModule,
        InputMaskModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        FileUploadModule,
        CardModule,
        FullCalendarModule,
        SpinnerModule,
        InputNumberModule,
        StepsModule,
        ButtonModule,
        PanelModule,
        ToolbarModule,
        ProgressSpinnerModule,
        BlockUIModule,
        ConfirmDialogModule
    ],
    declarations: [
        PainelComponent,
        TrocaPlanoComponent,
        AdesaoCdComponent,
        Passo1Component,
        Passo2Component,
        Passo3Component,
        Passo4Component,
        FinalizadoComponent,
        AdesaoSimuladorComponent,
        //plano bd saldado
        PlanoBdSaldadoAssistidoComponent,
        PlanoBdSaldadoAtivoComponent,
        TokenDialogComponent,
        //IndiceFinanceiroComponent,
        PessoaPoliticamenteExpostaComponent
    ],
    providers: [
        AuthGuardService,
        RoleGuardService,
        AtivoGuardService,
        FibraGuardService,
        AssistidoGuardService,
        MessageService,
        ConfirmationService
    ],
    exports: []
})
export class PainelModule { }
