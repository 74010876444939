import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';
import {
    GestaoFinanceiraEmprestimoServiceExtratoGetInfoCardResponse,
    GestaoFinanceiraEmprestimoServiceExtratoGetResponseItem
} from '@fibra/fibra-shared-lib';
import { obterNomeDoMesAPartirDoNumeroDoMes, inserirZeroAEsquerda } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-extrato-sidebar',
    styleUrls: ['./extrato-sidebar.component.css'],
    templateUrl: './extrato-sidebar.component.html'
})
export class ExtratoSidebarComponent implements OnInit {
    @Input() resumo: GestaoFinanceiraEmprestimoServiceExtratoGetInfoCardResponse;
    @Input() show: boolean;
    @Output() showChange = new EventEmitter<boolean>();

    isLoading = true;
    extrato: GestaoFinanceiraEmprestimoServiceExtratoGetResponseItem[] = [];

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        // this.gestaoFinanceiraEmprestimoService.Extrato_Get(this.resumo).subscribe(extrato => {
        //     this.extrato = extrato;
        //     this.isLoading = false;
        // });
    }

    fecharSidebar() {
        this.show = false;
        this.showChange.emit(this.show);
    }

    getColorCodeClass(c: number) {
        if (c <= 0) {
            return 'red-border';
        } else {
            return 'green-border';
        }
    }

    getAno(dateString: string) {
        return new Date(dateString).getFullYear();
    }

    getDia(dateString: string) {
        return inserirZeroAEsquerda(new Date(dateString).getDate());
    }

    getMes(dateString: string) {
        const numeroMes = new Date(dateString).getMonth() + 1;
        return obterNomeDoMesAPartirDoNumeroDoMes(numeroMes);
    }

    toggleParentElementClass(e: MouseEvent) {
        // TODO: Angular Update
        // const extratoElement = this.findExtratoElementInDOM(e.srcElemen);

        // if (extratoElement.classList.contains('expanded')) {
        //     extratoElement.classList.remove('expanded');
        //     return;
        // }

        // extratoElement.classList.add('expanded');
    }

    private findExtratoElementInDOM(e: Element) {
        const parentElement = e.parentElement;

        if (parentElement.classList.contains('extrato')) {
            return parentElement;
        }

        return this.findExtratoElementInDOM(parentElement);
    }
}
