import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-institutos-card',
    styleUrls: ['./institutos-card.component.css'],
    templateUrl: './institutos-card.component.html'
})
export class InstitutosCardComponent implements OnInit {

    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;

    constructor() { 
	}

    ngOnInit() {
        this.isLoading = false;
    }

}