import { DatePipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';
import { CarenciaModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/carencia-model';
import { PrevisaoModel } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/previsao-model';

@Component({
    selector: 'app-carencias',
    styleUrls: ['./carencias.component.css'],
    templateUrl: './carencias.component.html'
})
export class CarenciasComponent implements OnInit {
    @Input() consultaGerencialRegFibra: number = undefined;
    @Input() consultaGerencialIdGrupo: number = undefined;

    indexPrevisaoSelecionada: number;
    dataPrevisaoSelecionada: Date;

    previsoesModel: PrevisaoModel[] = [];
    valoresAposentadoria: CarenciaModel;

    mensagemDeErro: string;
    isLoading = true;
    show = false;
    bloquear: boolean;

    constructor(private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService, private datePipe: DatePipe) { }

    ngOnInit() {
        this.consultaGerencial();
    }

    consultaGerencial()
    {
        let regFibra = this.consultaGerencialRegFibra ? this.consultaGerencialRegFibra : null;
        this.carregarPrevisoes(regFibra);
    }

    carregarPrevisoes(regFibra?: number)
    {
        this.isLoading = true;
        let registro = regFibra ? regFibra.toString() : null;

        this.gestaoPlanoBeneficioService.PercentuaisApos_GetPrevisoesApos(registro).subscribe(previsoes => {
            this.previsoesModel = previsoes;
            this.indexPrevisaoSelecionada = 0;
            this.carregarValoresDeAposentadoriaPrevistos();
        }, () => this.informarErroDeCarregamento());
    }

    carregarValoresDeAposentadoriaPrevistos() {
        this.isLoading = true;
        console.log( this.previsoesModel[this.indexPrevisaoSelecionada]);
        this.dataPrevisaoSelecionada = this.previsoesModel[this.indexPrevisaoSelecionada].dataPrevApos;
        let idTipoConcessao = this.previsoesModel[this.indexPrevisaoSelecionada].idTipoConcessao;
        const dataRef = this.datePipe.transform(this.dataPrevisaoSelecionada, 'yyyy-MM-dd');
        this.gestaoPlanoBeneficioService.PercentuaisApos_GetCarenciaApos(idTipoConcessao,dataRef)
        .subscribe(valoresAposentadoria => {
            console.log('valoresAposentadoria',valoresAposentadoria);
            this.valoresAposentadoria = valoresAposentadoria;
            this.isLoading = false;
        } , () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }
}
