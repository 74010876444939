import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormularioService, AlertModalComponent, MESSAGE_SEVERITY, validCPF, validFullName, scrollIntoViewToTop, GestaoPlanoCadastroService } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { BeneficioAuxilioFuneralModel } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { BeneficiarioResponseDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/beneficiario-response.dto';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
import { Observable } from 'rxjs';
import { BeneficiarioMockService } from 'projects/FibraAutoAtendimento/src/app/mocks/beneficiarios-mock.service';
import { DesignadoMockService } from 'projects/FibraAutoAtendimento/src/app/mocks/designados-mock.service'; 
@Component({
    selector: 'app-beneficio-auxilio-funeral',
    templateUrl: './beneficio-auxilio-funeral.component.html',
    styleUrls: ['./beneficio-auxilio-funeral.component.css']
})
export class BeneficioAuxilioFuneralComponent implements OnInit, OnDestroy {
    beneficioAuxFuneral?: BeneficioAuxilioFuneralModel = null;
    ptBR = LOCALE_PT_BR;
    isLoading: boolean;
    urlArquivoSeSuite: any;
    @ViewChild('alertModalComponent', { static: false }) alertModalComponent: AlertModalComponent;
    msgs: any[] = [];
    formValido: boolean = false;
    yearRange: string;
    parentesco: Array<{ descricao: string, valor: number }> = [];
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
    uploadedFiles: any[] = [];
    dateMax: Date;
    isSimulado = sessionStorage.getItem('isSimulado');
    @ViewChild('twoFactorAuth', { static: false }) twoFactorAuth: TwoFactorAuthComponent;
    beneficiariosResponse: Array<BeneficiarioResponseDto> = [];
    temBeneficiario: Boolean = false;
    beneficiarioIdSelecionado?: number = null;
    certidaoObitoLabel: string = 'Certidão de Óbito';
    dataObitoLabel: string = 'Data do Óbito';
    dataObito?: Date = null;

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private designadoMockService: DesignadoMockService,
        private beneficiarioMockService: BeneficiarioMockService
    ) {
    }

    ngOnInit() {
        this.isLoading = true;

        // use 'false' para dados mockados, e 'true' para obter os dados via API.
        this.fetchBeneficiarios(true).subscribe(ben => {
            this.temBeneficiario = ben.length > 0;
            this.beneficiariosResponse = ben;
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
        });
    }

    fetchBeneficiarios(useMock: boolean): Observable<BeneficiarioResponseDto[]> {
        if (!useMock) {
          return this.beneficiarioMockService.getBeneficiariosMock();
        }
        return this.gestaoPlanoCadastroService.getBeneficiarios();
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    ngOnDestroy(): void {
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar(benefAuxFuneralForm: BeneficioAuxilioFuneralModel) {
        this.isLoading = true;
        if (!this.beneficioAuxFuneral)
            return;
        this.beneficioAuxFuneral.dataObito = this.dataObito;

        this.authService.getRegFibra()
            .subscribe(regFibra => {
                let formData = new FormData();
                formData.append('auxilioFuneralJson', JSON.stringify(benefAuxFuneralForm));

                if (this.fileUpload.hasFiles()) {
                    this.fileUpload.files.forEach((file, index) => {
                        formData.append('anexo_' + index, file);
                    });
                }

                this.formularioService.gerarFormBeneficioAuxilioFuneral(formData)
                    .subscribe(urlArquivoSeSuite => {
                        this.isLoading = false;
                        this.urlArquivoSeSuite = urlArquivoSeSuite;
                        if (this.isSimulado === 'true') {
                            window.open(urlArquivoSeSuite, '_blank');
                        }
                        this.alertModalComponent.show('Solicitação enviada com sucesso!', true);
                        this.reset();
                    }, () => {
                        this.isLoading = false;
                        this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                        this.reset();
                    });
            }, () => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                this.reset();
            });
    }

    onUpload(event: any) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
        this.beneficiarioIdSelecionado = null;
        this.beneficioAuxFuneral = null;
        this.dataObito = null;
        this.certidaoObitoLabel = `Certidão de Óbito`;
        this.dataObitoLabel = `Data do Óbito`;
    }

    selecionarBeneficiario(id: number) {
        this.beneficiarioIdSelecionado = id;
        let beneficiario = this.beneficiariosResponse.find(x => x.id == id);
        this.certidaoObitoLabel = `Certidão de Óbito de ${beneficiario.nome}`;
        this.dataObitoLabel = `Data do Óbito de ${beneficiario.nome}`;

        this.beneficioAuxFuneral = {
            cpfFacelido: beneficiario.cpf,
            nomeFacelido: beneficiario.nome,
            parentesco: beneficiario.grauParentesco,
            dataObito: this.dataObito
        };
    }

    validarForm(): Boolean {
        let valido = this.beneficioAuxFuneral && this.dataObito && this.fileUpload.files.length > 0;
        if (!valido) {
            this.addMensagem('warn', 'Atenção!', 'É necessário preencher os dados antes de enviar.');
            scrollIntoViewToTop();
        }
        return valido;
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        if (!this.validarForm()) {
            return;
        }

        if (this.beneficioAuxFuneral)
            this.twoFactorAuth.show();
    }

    /**
     * Lida com o sucesso da autenticação de dois fatores.
     * @param twoFactorAuthToken 2fa token.
     */
    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar(this.beneficioAuxFuneral);
    }
}
