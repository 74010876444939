import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent {
    isVisible: boolean;
    message: string[] = [];
    isSuccessDialogVisible: boolean;
    sucesso: boolean;

    @Output() close = new EventEmitter<void>();
    @Input() showCloseButton: boolean = false;
    @Input() showCloseIcon: boolean = true;
    @Input() exitOnPressEsc: boolean = true;
    @Input() exitOnClickOutside: boolean = true;

    show(message: string, success: boolean, showCloseButton: boolean = false,
        showCloseIcon: boolean = true, exitOnPressEsc: boolean = true, exitOnClickOutside: boolean = true) {
        this.isVisible = true;
        var lines = message.split("\n")
        for(var i =0; i < lines.length; i++)
            this.message.push(lines[i]);
        this.sucesso = success;

        this.showCloseButton = showCloseButton;
        this.showCloseIcon = showCloseIcon;
        this.exitOnPressEsc = exitOnPressEsc;
        this.exitOnClickOutside = exitOnClickOutside;
    }

    hide() {
        this.isVisible = false;
    }

    modalFechou() {
        this.close.emit();
    }
}
