import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import {
    AdesaoCdService, AdesaoService, CELULAR, CEP, CPF, CustomValidators, DadosCadastraisService, DadosEndereco, DocumentoService, ESTADO_CIVIL, ETipoPlano, GestaoPlanoCadastroService,
    PessoaPoliticamenteExpostaService, SolicitacaoAdesaoCdModel, TipoSeDocumento, limparMascaraCep, validarCep
} from '@fibra/fibra-shared-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PerguntasPpe } from '@fibra/fibra-shared-lib';
import { CargoPessoaPoliticamenteExpostaDto, EnderecoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { PdfViewerComponent } from '../../../components/pdf-viewer/pdf-viewer.component';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { DependenteModel } from '../../../models/dependente.model';
import { DependenteDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dependente-dto';
import { ETipoDependente } from '@fibra/fibra-shared-lib';
import * as _ from "lodash";

@Component({
    selector: 'app-passo-01',
    templateUrl: './passo-01.component.html',
    styleUrls: ['./passo-01.component.css']
})
export class Passo1Component implements OnInit {
    items: MenuItem[];
    activeIndex: number = 0;
    solicitacaoAdesao: SolicitacaoAdesaoCdModel = null;
    isLoading: boolean;
    dadosAdesaoForm: FormGroup = this.formBuilder.group({
        nome: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator(), Validators.minLength(3),
        ]),
        cpf: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator(), CustomValidators.CPF
        ]),
        dataNascimento: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator()
        ]),
        sexo: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator()
        ]),
        estadoCivil: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator()
        ]),
        // Contato
        telefone: this.formBuilder.control(null, [
            CustomValidators.notEmptyValidator()
        ]),

        // Endereço
        cep: this.formBuilder.control(null, []),
        logradouro: this.formBuilder.control(null, []),
        numero: this.formBuilder.control(null, []),
        bairro: this.formBuilder.control(null, []),
        cidade: this.formBuilder.control(null, []),
        uf: this.formBuilder.control(null, []),
        complemento: this.formBuilder.control(null, [])
    });

    readonly CPF = CPF;
    readonly CELULAR = CELULAR;
    readonly CEP = CEP;
    tipoLogradouroLista:Array<{cdLogradouro: string, descLogradouro: string}> = [];;
    dropDownSexos = ['Masculino', 'Feminino'];
    perguntasPpe: Array<PerguntasPpe> = PerguntasPpe.obterPerguntas();
    perguntasInvalidas: boolean | null = null;
    estadosCivis = ESTADO_CIVIL;
    enderecoCorreio: DadosEndereco | null = null;
    loadingEndereco: boolean = false;
    cepNaoEncontrado: boolean = false;
    mensagemCampoObrigatorio = '*Campo obrigatório';
    cargosPpe: Array<CargoPessoaPoliticamenteExpostaDto> = [];
    cargoSelecionadoId: number | null = null;
    logradouro:Array<{cdLogradouro: string, descLogradouro: string}> = [];
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;


    constructor(private router: Router,
        private dadosCadService: DadosCadastraisService,
        private adesaoCdService: AdesaoCdService,
        private adesaoService: AdesaoService,
        private formBuilder: FormBuilder,
        private cadastroService: GestaoPlanoCadastroService,
        private ppeService: PessoaPoliticamenteExpostaService,
        private messageService: MessageService,
        private documentoService: DocumentoService) { }

    ngOnInit() {
        this.activeIndex = 0;
        this.items = [
            {
                label: 'Informações iniciais',
                routerLink: '/painel/adesao-cd/informacoes-iniciais'
            },
            {
                label: 'Perfil de investimento',
                routerLink: '/painel/adesao-cd/perfil-investimento'
            },
            {
                label: 'Conclusão',
                routerLink: '/painel/adesao-cd/conclusao'
            }
        ];

        this.solicitacaoAdesao = this.adesaoCdService.getSolicitacaoAdesao();
        this.ppeService.getCargosPssoasPoliticamenteExposta()
            .subscribe(cargos => {
                this.cargosPpe = cargos;
            }, error => {
                console.error(error);
            });

        if (this.solicitacaoAdesao == null) {
            this.isLoading = true;
            this.dadosCadService.getCadastroGeral(true)
                .subscribe(dadosCad => {
                    this.adesaoCdService.carregarDadosAdesaoPlanoCd(dadosCad);
                    this.solicitacaoAdesao = this.adesaoCdService.getSolicitacaoAdesao();
                    if (this.solicitacaoAdesao?.perguntasPpe?.length > 0)
                        this.perguntasPpe = this.solicitacaoAdesao.perguntasPpe;
                    this.carregarFormulario(this.solicitacaoAdesao);
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    console.error(error);
                });
        } else {
            this.carregarFormulario(this.solicitacaoAdesao);
            if (this.solicitacaoAdesao?.perguntasPpe?.length > 0)
                this.perguntasPpe = this.solicitacaoAdesao.perguntasPpe;
        }
        this.dadosAdesaoForm.valueChanges.subscribe((solicitacaoAde: any) => {
            this.atualizarSolicitacaoAdesao(solicitacaoAde);
        });

        this.perguntasInvalidas = !PerguntasPpe.validarPerguntas(this.perguntasPpe);
        this.dadosAdesaoForm.get('cep').valueChanges.subscribe(cep => {
            this.cepNaoEncontrado = false;
            this.getEnderecoPorCep(cep);
        });
    }

    carregarLogradouro() {
        return this.cadastroService.Cadastros_GetLogradouro().subscribe(info => {
            this.logradouro = info.sort((a,b) => {
                if(a.descLogradouro < b.descLogradouro)
                    return -1;
                if(a.descLogradouro > b.descLogradouro)
                    return 1;
                else
                    return 0;
            });
        });
    }

    private getEnderecoPorCep(cep: string, enderecoCadastro?: EnderecoDto) {
        const cepLimpo = limparMascaraCep(cep);
        if (!validarCep(cepLimpo)) {
            return;
        }
        this.loadingEndereco = true;
        this.cadastroService.consultaCEPCorreios(cepLimpo)
            .subscribe(endereco => {
                let enderecoCorreio = endereco;
                enderecoCorreio.numero = enderecoCadastro?.numero;
                enderecoCorreio.complemento = enderecoCadastro?.complemento;
                this.loadingEndereco = false;
                this.atualizarFormEndereco(enderecoCorreio);
            }, error => {
                this.loadingEndereco = false;
                this.limparFormEndereco();
                this.cepNaoEncontrado = true;
                console.error('ERROR: ', error);
            });
    }

    private atualizarSolicitacaoAdesao(solicitacaoAde: any) {
        this.solicitacaoAdesao = this.adesaoCdService.getSolicitacaoAdesao();

        let estadoCivil = this.estadosCivis.find(x => x.id == solicitacaoAde.estadoCivil);

        this.solicitacaoAdesao.pessoa.nomeCompleto = solicitacaoAde.nome;
        this.solicitacaoAdesao.pessoa.sexo = solicitacaoAde.sexo;
        this.solicitacaoAdesao.pessoa.estadoCivil = estadoCivil;
        this.solicitacaoAdesao.pessoa.telefoneCelular = solicitacaoAde.telefone;
        this.solicitacaoAdesao.pessoa.dataNascimento = solicitacaoAde.dataNascimento;
        // Endereço
        this.solicitacaoAdesao.pessoa.endereco.cep = solicitacaoAde.cep;
        this.solicitacaoAdesao.pessoa.endereco.logradouro = solicitacaoAde.logradouro;
        this.solicitacaoAdesao.pessoa.endereco.tipoLogradouro = solicitacaoAde.tipoLogradouro;
        this.solicitacaoAdesao.pessoa.endereco.numero = solicitacaoAde.numero;
        this.solicitacaoAdesao.pessoa.endereco.bairro = solicitacaoAde.bairro;
        this.solicitacaoAdesao.pessoa.endereco.complemento = solicitacaoAde.complemento;
        this.solicitacaoAdesao.pessoa.endereco.cidade.nome = solicitacaoAde.cidade;
        this.solicitacaoAdesao.pessoa.endereco.cidade.siglaUF = solicitacaoAde.uf;


        this.adesaoCdService.atualizarSolicitacaoAdesao(this.solicitacaoAdesao);
    }

    onSubmit() {
        let dependentes = this.adesaoCdService.getDependentes();
        let designados = dependentes.filter(x => x.tipoDependente == ETipoDependente.Designado);
        if (!_.isEmpty(designados)) {
            if (!this.adesaoCdService.validarPercentualDependente(designados)) {
                this.messageService.clear();
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Percentual inconsistente',
                    detail: `A soma dos percentuais dos designados deve ser igual a 100%.`
                });
                return;
            }

            let dependentesModel = this.converterDependenteDtoParaModel(dependentes);
            if (dependentesModel.some(dep => !dep.valido)) {
                this.messageService.clear();
                this.messageService.add({
                    severity: 'warn',
                    summary: 'Dependentes com dados incorretos',
                    detail: `Não é possível avançar, pois existem dados incorretos na lista de dependentes ou designados.`
                });
                return;
            }
        }

        if (this.dadosAdesaoForm.valid) {
            this.router.navigate(['/painel/adesao-cd/perfil-investimento']);
        } else {
            return false;
        }
    }

    carregarFormulario(adesaoDto: SolicitacaoAdesaoCdModel) {
        const dataNasc = new Date(adesaoDto.pessoa?.dataNascimento);
        const estadoCivilId = adesaoDto.pessoa?.estadoCivil?.id > 0 ? adesaoDto.pessoa?.estadoCivil?.id : 0;
        let cep = adesaoDto.pessoa?.endereco?.cep;

        if (!validarCep(cep))
            cep = null;

        this.dadosAdesaoForm = new FormGroup({
            nome: this.formBuilder.control(adesaoDto.pessoa?.nomeCompleto, [
                CustomValidators.notEmptyValidator(), Validators.minLength(3),
            ]),
            cpf: this.formBuilder.control(adesaoDto.pessoa?.cpf, [
                CustomValidators.notEmptyValidator(), CustomValidators.CPF
            ]),
            dataNascimento: this.formBuilder.control(dataNasc, [
                CustomValidators.notEmptyValidator()
            ]),
            sexo: this.formBuilder.control(adesaoDto.pessoa?.sexo, [
                CustomValidators.notEmptyValidator()
            ]),
            estadoCivil: this.formBuilder.control(estadoCivilId, [
                CustomValidators.notEmptyValidator(), Validators.min(1), Validators.max(10)
            ]),

            // Contato
            telefone: this.formBuilder.control(adesaoDto.pessoa?.telefoneCelular, [
                CustomValidators.notEmptyValidator(), Validators.pattern(/^\d{11}$/)
            ]),

            // Endereço
            cep: this.formBuilder.control(cep, [
                CustomValidators.notEmptyValidator(), CustomValidators.Cep()
            ]),
            logradouro: this.formBuilder.control('', [
                CustomValidators.notEmptyValidator()
            ]),
            tipoLogradouro: this.formBuilder.control('', [
                CustomValidators.notEmptyValidator()
            ]),
            numero: this.formBuilder.control(adesaoDto.pessoa?.endereco?.numero, [
                CustomValidators.notEmptyValidator()
            ]),
            bairro: this.formBuilder.control('', [
                CustomValidators.notEmptyValidator()
            ]),
            cidade: this.formBuilder.control('', [
                CustomValidators.notEmptyValidator()
            ]),
            uf: this.formBuilder.control('', [
                CustomValidators.notEmptyValidator()
            ]),
            complemento: this.formBuilder.control(adesaoDto.pessoa?.endereco?.complemento, [
            ]),
        });
        this.carregarLogradouro().add(() => {
            this.getEnderecoPorCep(cep, adesaoDto.pessoa?.endereco);
        });
        this.cargoSelecionadoId = adesaoDto.pessoa?.politicamenteExposto?.cargoId;
    }

    atualizarFormEndereco(dadosEndereco: DadosEndereco) {
        let endereco = {
            logradouro: dadosEndereco.endereco,
            bairro: dadosEndereco.bairro,
            cidade: dadosEndereco.cidade,
            uf: dadosEndereco.estado,
            numero: dadosEndereco.numero,
            complemento: dadosEndereco.complemento
        };


        if(this.logradouro.findIndex((o) => o.descLogradouro == dadosEndereco.logradouro?.descLogradouro) < 0)
            this.tipoLogradouroLista = this.logradouro;
        else
            this.tipoLogradouroLista = this.logradouro.filter((o) => o.descLogradouro == dadosEndereco.logradouro?.descLogradouro);

        Object.keys(endereco).forEach(prop => {
            if (prop)
                this.dadosAdesaoForm.get(prop).patchValue(endereco[prop]);
        });
        this.solicitacaoAdesao.pessoa.endereco.tipoLogradouro = dadosEndereco.logradouro?.descLogradouro ?? "";
        this.dadosAdesaoForm.controls.tipoLogradouro.setValue(this.solicitacaoAdesao.pessoa.endereco.tipoLogradouro);
    }

    limparFormEndereco() {
        let endereco = {
            logradouro: '',
            numero: '',
            bairro: '',
            cidade: '',
            uf: '',
            complemento: ''
        };

        Object.keys(endereco).forEach(prop => {
            this.dadosAdesaoForm.get(prop).patchValue('');
        });
    }

    definirPessoaPoliticamenteExposta(exposto: boolean) {
        this.solicitacaoAdesao.pessoa.politicamenteExposto.exposto = exposto;
        this.adesaoCdService.definirPessoaPoliticamenteExposta(exposto);
        if (!exposto) {
            this.cargoSelecionadoId = null;
            this.solicitacaoAdesao.pessoa.politicamenteExposto.cargoId = null;
        }
    }

    salvarPerguntasPpe(perguntasPpe: Array<PerguntasPpe>) {
        this.adesaoCdService.salvarPerguntasPpe(perguntasPpe);
    }

    onChengePerguntas() {
        this.perguntasInvalidas = !PerguntasPpe.validarPerguntas(this.perguntasPpe);
    }

    atualizarPpe(cargoSelecionadoId: number | null = null) {
        this.adesaoCdService.atualizarCargoPpe(cargoSelecionadoId);
        this.solicitacaoAdesao.pessoa.politicamenteExposto.cargoId = cargoSelecionadoId;
    }

    podeAvancar(): boolean {
        let solicitacaoAdesao = this.adesaoCdService.getSolicitacaoAdesao();

        if (solicitacaoAdesao?.pessoa == null) {
            return false;
        }

        if (solicitacaoAdesao?.pessoa?.politicamenteExposto.exposto == null) {
            return false;
        }

        if (this.dadosAdesaoForm == null || !this.dadosAdesaoForm.valid) {
            return false;
        }

        if (solicitacaoAdesao.pessoa?.politicamenteExposto?.exposto == true
            && this.solicitacaoAdesao.pessoa?.politicamenteExposto?.cargoId == null) {
            return false;
        }

        let dependenteSemDocumento = _.some(solicitacaoAdesao.dependentes, x => x.tipoDependente == ETipoDependente.Beneficiario
                && x.existente == false && _.isEmpty(x.documentos));
        if (dependenteSemDocumento)
            return false;

        return true;
    }

    downloadRegulamentoCD() {
        this.isLoading = true;

        let request = this.documentoService.getSeDocs(TipoSeDocumento.RegulamentoCD)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request, true)
    }

    converterDependenteDtoParaModel(dependenteDto: DependenteDto[]) {
        return dependenteDto.map(depDto => {
            let dependenteModal: DependenteModel = new DependenteModel();
            dependenteModal.codigo = depDto.codigo;
            dependenteModal.cpf = depDto.cpf;
            dependenteModal.dataNascimento = depDto.dataNascimento;
            dependenteModal.email = depDto.email;
            dependenteModal.grauParentesco = depDto.grauParentesco;
            dependenteModal.nome = depDto.nome;
            dependenteModal.percentualParticipacao = depDto.percentualParticipacao;
            dependenteModal.sexo = depDto.sexo;
            dependenteModal.telefone = depDto.telefone;
            dependenteModal.tipoDependente = depDto.tipoDependente;

            return dependenteModal;
        });
    }
}

