export const LinksIntegracao = {
    PE_DE_MEIA : 'https://pedemeia.fundacaoitaipu.com.br',
    WHATS_APP: 'https://api.whatsapp.com/send?phone=558000414404',
    PLAY_STORE: 'https://play.google.com/store/apps/details?id=br.com.fundacaoitaipu',
    FUNDACAO_ITAIPU: 'https://www.fundacaoitaipu.com.br',
    SESUITE: 'https://ecm.fundacaoitaipu.com.br/softexpert/login',
    ATENA: 'https://erp-atena-pro.fundacaoitaipu.com.br',
    ESCRITORIO_VIRTUAL: 'https://www.fundacaoitaipu.com.br/escritoriovirtual',
    OFFICE: 'http://webmail.fundacaoitaipu.com.br',
    WHATS_APP_GRUPO: 'https://api.whatsapp.com/send?phone=558000414404',
    ALTERAR_SENHA_OFFICE_365: 'https://adfs.fundacaoitaipu.com.br/adfs/portal/updatepassword',
    CRM: 'https://fibraprd.crm2.dynamics.com/main.aspx'
};
