import { Component, OnInit } from '@angular/core';

import { GestaoFinanceiraEmprestimoServiceSaldoGetSaldoAtualResponse } from '@fibra/fibra-shared-lib';
import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';
import { obterNomeDoMesAPartirDoNumeroDoMes } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-acompanhamento-card',
    templateUrl: './acompanhamento-card.component.html',
})
export class AcompanhamentoCardComponent implements OnInit {
    extratoSaldoAtual = new GestaoFinanceiraEmprestimoServiceSaldoGetSaldoAtualResponse();
    isLoading = true;
    mes: string;
    dataAux: Date;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        // this.gestaoFinanceiraEmprestimoService.Saldo_GetSaldoAtual().subscribe(extratoSaldoAtual => {
        //         this.extratoSaldoAtual.correcao = extratoSaldoAtual.correcao;
        //         this.extratoSaldoAtual.dataIniMov = extratoSaldoAtual.dataIniMov;
        //         this.extratoSaldoAtual.dataFimMov = extratoSaldoAtual.dataFimMov;
        //         this.extratoSaldoAtual.iof = extratoSaldoAtual.iof;
        //         this.extratoSaldoAtual.juros = extratoSaldoAtual.juros;
        //         this.extratoSaldoAtual.saldoAnterior = extratoSaldoAtual.saldoAnterior;
        //         this.extratoSaldoAtual.saldoAtual = extratoSaldoAtual.saldoAtual;
        //         this.extratoSaldoAtual.seguro = extratoSaldoAtual.seguro;
        //         this.extratoSaldoAtual.ultimoPgto = extratoSaldoAtual.ultimoPgto;
        //     if ( this.extratoSaldoAtual.dataIniMov != null ) {
        //         this.dataAux =  new Date(this.extratoSaldoAtual.dataFimMov.toString());
        //         this.mes = obterNomeDoMesAPartirDoNumeroDoMes(this.dataAux.getMonth() + 1);
        //     }
        //     this.isLoading = false;
        // });
    }
}
