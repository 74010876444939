import { HttpClient, HttpHeaders } from '@angular/common/http';


export abstract class BaseService {
    protected baseUrl: string = '';
    protected endpoint: string = '';

    constructor(protected httpClient: HttpClient, protected environment: any, protected prefixoRota = null) {
        this.baseUrl = environment.BASE_URL_CONTRIBUICAO_DEFINIDA;
        this.endpoint = `${this.baseUrl}/api/v1`
        if (prefixoRota)
            this.endpoint = `${this.endpoint}/${prefixoRota}`
    }
}