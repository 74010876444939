import { Component } from '@angular/core';


@Component({
    selector: 'app-como-fazer',
    templateUrl: './como-fazer.component.html',
    styleUrls: ['./como-fazer.component.css']
})
export class ComoFazerComponent {


    constructor() { }

}
