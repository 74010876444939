import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { DadosCadastraisService, GestaoPlanoCadastroService } from '@fibra/fibra-shared-lib';
import {
    GestaoPlanoCadastroServiceCadastrosGetInfoCadastraisResponse
} from '@fibra/fibra-shared-lib';
import { ContaBancaria } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/conta-bancaria';

@Component({
    selector: 'app-dados-para-deposito-card',
    templateUrl: './dados-para-deposito-card.component.html'
})
export class DadosParaDepositoCardComponent implements OnInit {
    contaBancaria: ContaBancaria;
    dadosCadastraisAdicionais: GestaoPlanoCadastroServiceCadastrosGetInfoCadastraisResponse;
    isLoading = true;

    constructor(private dadosCadastraisService: DadosCadastraisService) { }

    ngOnInit() {
        this.dadosCadastraisService.getCadastroGeral(true)
            .subscribe(cadastro => {
                this.contaBancaria = cadastro.contaBancaria;
                this.isLoading = false;
            });
    }
}
