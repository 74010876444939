export interface BeneficiarioSinqiaDto{
    nome: string,
    cpf: string,
    dataNascimento: Date,
    percentualParticipacao: number,

    id?:number,    
    guid?: string,    
    idAdesao?: number,
}

// {
//     "grauParentesco": "string",
//     "sexo": "string",
// }