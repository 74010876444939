type InformacaoEndividamento = Array<{categoria: string, descricao: string}>;
type LimitePorTempo = Array<{idade: string, teto: string}>;
export const INFORMACAO_ENDIVIDAMENTO: InformacaoEndividamento = [
        {
            categoria: 'Participantes Ativos',
             descricao: '11 (onze) vezes o valor do salário-base de enquadramento, acrescido do adicional anuênio e adicional regional, cadastrado na Fibra;'
        },
        {
            categoria: 'Participantes Nomeados',
             descricao: '11 (onze) vezes o valor utilizado para o cálculo das suas contribuições;'
        },
        {
            categoria: 'Participantes Assistidos',
             descricao: '11 (onze) vezes o valor do benefício bruto mensal;'
        },
        {
            categoria: 'Limites por tempo de filiação',
             descricao: 'Participantes ativos com o tempo de filiação inferior a 2 (dois) anos,' +
              'o limite de empréstimo será de R$ 3.000,00 (três mil reais) ou o saldo de contribuições normais, o que for maior;'
        },
        {
            categoria: 'Limites por tempo de filiação',
             descricao: 'Participantes ativos com tempo de filiação entre 2 (dois) e 5 (cinco) anos,' +
              'o limite de empréstimo será o valor do saldo de contribuições normais ou 3 (três) vezes valor do salário-base' +
               ' de enquadramento acrescido do adicional anuênio e adicional regional, o que for maior;'
        },
    ];

    export const LIMITE_POR_TEMPO: LimitePorTempo = [
        {
            idade: '65',
            teto: 'R$ 150.000,00'
        },
        {
            idade: '66',
            teto: 'R$ 147.000,00'
        },
        {
            idade: '67',
            teto: 'R$ 144.000,00'
        },
        {
            idade: '68',
            teto: 'R$ 141.000,00'
        },
        {
            idade: '69',
            teto: 'R$ 138.000,00'
        },
        {
            idade: '70',
            teto: 'R$ 135.000,00'
        },
        {
            idade: '71',
            teto: 'R$ 131.000,00'
        },
        {
            idade: '72',
            teto: 'R$ 127.000,00'
        },
        {
            idade: '73',
            teto: 'R$ 123.000,00'
        },
        {
            idade: '74',
            teto: 'R$ 118.000,00'
        },
        {
            idade: '75',
            teto: 'R$ 113.000,00'
        },
        {
            idade: '76',
            teto: 'R$ 107.000,00'
        },
        {
            idade: '77',
            teto: 'R$ 101.000,00'
        },
        {
            idade: '78',
            teto: 'R$ 94.000,00'
        },
        {
            idade: '79',
            teto: 'R$ 86.000,00'
        },
        {
            idade: '80',
            teto: 'R$ 77.000,00'
        },
        {
            idade: '81',
            teto: 'R$ 67.000,00'
        },
        {
            idade: '82',
            teto: 'R$ 56.000,00'
        },
        {
            idade: '83',
            teto: 'R$ 44.000,00'
        },
        {
            idade: '84',
            teto: 'R$ 30.000,00'
        },
        {
            idade: '85 e +',
            teto: 'R$ 15.000,00'
        },
    ];
