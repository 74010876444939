import { Component } from '@angular/core';
import { LinksIntegracao } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.css'
    ]
})
export class LoginComponent {
    readonly OpenExternalURL = LinksIntegracao.WHATS_APP;

    openExternalURL(link: string) {
        window.open(link, 'blank');
    }
}
