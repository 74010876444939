export const LIMITE_ENDIVIDAMENTO = {
    MESSAGE: 'Valor máximo de empréstimo.'
};

export const SALDO_QUITACAO = {
    MESSAGE: 'Valor pago do empréstimo.'
};

export const CARENCIA = {
    MESSAGE: 'Valor a ser pago para um novo empréstimo.'
};

export const INADIMPLENCIA = {
    MESSAGE: 'Valor de saldo devedor.'
};

export const PERCENTUAL_DESCONTO = {
    MESSAGE: 'Valor de desconto.'
};
