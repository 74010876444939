import { environment } from './../../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { NgxLoadingModule } from 'ngx-loading';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

import {
    CadastroComponent,
    ConfirmarSenhaComponent,
    EntrarComponent,
    LoginComponent,
    LoginIntegradoComponent,
    RecuperarSenhaComponent,
    SelecionarPerfilComponent
} from '.';
import { SessionInitService } from '@fibra/fibra-shared-lib';
import { LoginRoutingModule } from './login-routing.module';
import { SharedModule } from '@fibra/fibra-shared-lib';

import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';

import { ComponentsModule } from '../components/components.module';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';

/**
 * Documentation: https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-angular-auth-code
 */

export const protectedResourceMap: [string, string[]][] = [
    ['https://graph.microsoft-ppe.com/v1.0/me', ['user.read']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.KEY_CLIENT_ID,
            authority: `${environment.MSAL_AUTHORITY}/${environment.KEY_TENANT_ID}`,
            redirectUri: environment.URL_DO_AMBIENTE,
            postLogoutRedirectUri: environment.URL_DO_AMBIENTE,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                loggerCallback,
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [
                'user.read',
                'openid',
                'profile'
            ],
            extraQueryParameters: {}
        }
    };
}

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        DialogModule,
        NgxLoadingModule,
        LoginRoutingModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        ButtonModule,
        ConfirmDialogModule,
        PasswordModule,
        DividerModule,
        ComponentsModule,
        MsalModule.forRoot(
            // MSAL Configuration
            MSALInstanceFactory(),
            // MSAL Guard Configuration
            MSALGuardConfigFactory(),
            {
                // MSAL Interceptor Configurations
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['user.read']]
                ])
            })
    ],
    declarations: [
        CadastroComponent,
        ConfirmarSenhaComponent,
        EntrarComponent,
        LoginComponent,
        LoginIntegradoComponent,
        RecuperarSenhaComponent,
        SelecionarPerfilComponent,
        AlterarSenhaComponent
    ],
    exports: [
        CadastroComponent,
        ConfirmarSenhaComponent,
        EntrarComponent,
        LoginComponent,
        LoginIntegradoComponent,
        RecuperarSenhaComponent,
        SelecionarPerfilComponent
    ],
    providers: [
        SessionInitService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard,
        ConfirmationService
    ]
})
export class LoginModule { }
