import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Service } from "./service";
import { Beneficiario } from "../models/beneficiario.model";
import { Observable } from "rxjs";
import { ArquivoSeSuite, BeneficioAuxilioFuneralModel, DeclaracaoDependentesIr, DeclaracaoTempoServicoModel, IsencaoIrMolestiaGraveModel, NomeacaoDesignadoModel, PessoaPoliticamenteExpostaModel, ResponseApiBdModel, SuplementacaoAposentadoriaModel, SuspensaoContribuicaoModel } from "../models";

@Injectable()
export class FormularioService extends Service {
    private url: string;
    fileMimeTypePdf: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = environment.API_GESTAO_PLANO_BENEFICIO;
        this.fileMimeTypePdf = 'application/pdf';
    }

    gerarFormInscricaoBeneficiario(regFibra: number, fdBeneficiario: FormData): Observable<any> {
        const url = `${this.url}/formulario/FormInscricaoBeneficiario/${regFibra}`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<string>(url, fdBeneficiario, headersAuth);
    }

    gerarFormDeclaracaoTempoServico(fdDeclaracaoTempoServico: FormData) {
        const url = `${this.url}/formulario/FormDeclaracaoTempoServico`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<string>(url, fdDeclaracaoTempoServico, headersAuth);
    }

    gerarFormDeclaracaoDependentesIR(declaracao: DeclaracaoDependentesIr) {
        const url = `${this.url}/formulario/FormDeclaracaoDependentesIR`;
        return this.post<string>(url, declaracao);
    }

    gerarFormBeneficioAuxilioFuneral(fdBeneficioAuxFuneral: FormData) {
        const url = `${this.url}/formulario/FormBenefAuxFuneral`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<string>(url, fdBeneficioAuxFuneral, headersAuth);
    }

    gerarFormSuplementacaoAposentadoria(fdSupAposentadoria: FormData) {
        const url = `${this.url}/formulario/FormSuplementacaoAposentadoria`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<any>(url, fdSupAposentadoria, headersAuth);
    }

    gerarFormIsencaoIrMolestiaGrave(regFibra: number, fdIsencaoIrMolestiaGrave: FormData) {
        const url = `${this.url}/formulario/FormIsencaoIrMolestiaGrave/${regFibra}`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<string>(url, fdIsencaoIrMolestiaGrave, headersAuth);
    }

    gerarFormPessoaPoliticamenteExposta(regFibra: number, pessoaPoliticamenteExposta: PessoaPoliticamenteExpostaModel) {
        const url = `${this.url}/formulario/FormPessoaPoliticamenteExposta/${regFibra}`;
        return this.post<string>(url, pessoaPoliticamenteExposta);
    }

    gerarFormSuspensaoContribuicao(regFibra: number, fdSuspensaoContribuicao: FormData) {
        const url = `${this.url}/formulario/FormSuspensaoContribuicao/${regFibra}`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<string>(url, fdSuspensaoContribuicao, headersAuth);
    }

    gerarFormNomeacaoDesignado(nomeacaoDesignado: NomeacaoDesignadoModel) {
        const url = `${this.url}/formulario/FormNomeacaoDesignado`;
        return this.post<string>(url, nomeacaoDesignado);
    }

    gerarFormContratoAntecipacaoBeneficio(regFibra: number) {
        const url = `${this.url}/formulario/FormContratoAntecipacaoBeneficio/${regFibra}`;
        return this.get<string>(url);
    }

    baixarFormContratoAntecipacaoBeneficio(callback?: Function,
        erroCallBack?: Function, thisArg?: any) {
        const url = `${this.url}/formulario/FormContratoAntecipacaoBeneficio`;
        this.baixarArquivo(url, this.fileMimeTypePdf, callback, erroCallBack, thisArg);
    }

    baixarFormAntecipacaoSupPensao(callback?: Function,
        erroCallBack?: Function, thisArg?: any) {
        const url = `${this.url}/formulario/FormAntecipacaoSupPensao`;
        this.baixarArquivo(url, this.fileMimeTypePdf, callback, erroCallBack, thisArg);
    }

    gerarFormExclusaoBeneficiario(beneficiarios: Array<Beneficiario>) {
        const url = `${this.url}/formulario/FormExclusaoBeneficiario`;
        return this.post<string>(url, beneficiarios);
    }

    uploadAnexo(regFibra: number, anexo: FormData) {
        const url = `${this.url}/formulario/UploadAnexos/${regFibra}`;
        return this.httpClient.post<Array<ArquivoSeSuite>>(url, anexo);
    }

    gerarFormProrrogacaoAdiantamentoParcial(regFibra: number, fdProrrogacaoAdiantamento: FormData) {
        const url = `${this.url}/formulario/FormProrrogacaoAdiantamentoParcial/${regFibra}`;
        let headersAuth = { headers: this.getHttpHeadersAuthorization() };
        return this.httpClient.post<any>(url, fdProrrogacaoAdiantamento, headersAuth);
    }

    registrarAssinaturaDocumento(idDoDocumento: string) {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/formulario/assinadopelocliente`;
        const idDocumento = {
            idDoDocumento: idDoDocumento
        };

        return this.postTyped<ResponseApiBdModel<boolean>>(endpoint, idDocumento);
    }
}
