import { Component, OnInit } from '@angular/core';

import { GestaoFinanceiraEmprestimoService, ExtratoIR, AuthService, GestaoPlanoCadastroService, DadosCadastraisService, AdesaoService, ESituacaoParticipante } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-informacoes-card',
    templateUrl: './informacoes-card.component.html'
})
export class InformacoesCardComponent implements OnInit {
    extratoImpostoRenda: ExtratoIR;
    isLoading = true;
    ano: number;
    mostraBotao = false;
    mostrarIR = false;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
        private authService: AuthService,
        private adesaoService: AdesaoService
    ) { }

    ngOnInit() {
        this.ano = new Date().getFullYear() - 1;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_ExtratoPorExercicio(this.ano).subscribe(response => {
            this.extratoImpostoRenda = response;
            if (this.extratoImpostoRenda != null) {
                this.mostrarIR = true;
                let adesao = this.adesaoService.getAdesaoContexto();
                let ehAssistido = this.adesaoService.validarSituacao(ESituacaoParticipante.Assistido, adesao.tipoSituacao);
                if (ehAssistido) {
                    this.mostraBotao = true;
                }
                this.isLoading = false;
            } else {
                this.mostrarIR = false;
            }
        });
    }

    baixarIR() {
        this.isLoading = true;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_PdfPorExercicio(this.ano, () => { this.isLoading = false; }, this);
    }

}
