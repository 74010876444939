import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignadoMockService } from './designados-mock.service';
import { BeneficiarioMockService } from './beneficiarios-mock.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  providers: [
    DesignadoMockService,
    BeneficiarioMockService
  ],
  exports: [
    
  ]
})
export class MockModule { }

export { DesignadoMockService };
export { BeneficiarioMockService };
