import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-extrato-bps',
    styleUrls: ['./extrato-bps.component.css'],
    templateUrl: './extrato-bps.component.html'
})
export class ExtratoBpsComponent implements OnInit {

    isLoading = true;
	data: any;

    constructor() {
	}

    ngOnInit() {
        this.isLoading = false;
    }

}