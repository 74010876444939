import { Component, OnInit, Inject } from '@angular/core';
import { forkJoin } from 'rxjs';

import { GestaoPlanoCadastroService, AuthService, LogService, DadosCadastraisService } from '@fibra/fibra-shared-lib';
import { formatDate } from '@angular/common';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-cadastrais-sinqia.dto';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    providers: [LogService]
})
export class HeaderComponent implements OnInit {
    isSimulado = false;
    isHomologado = false;
    isLoading = true;
    nome: string;
    ultimoAcesso: any;
    sexo: string;
    saudacao: string;
    dadosCadastraisSinqia: DadosCadastraisSinqiaDto;

    constructor(
        private dadosCadastraisService: DadosCadastraisService,
        private authService: AuthService,
        private logService: LogService,
        @Inject('environment') private environment
    ) { }

    ngOnInit() {
        forkJoin([
            this.dadosCadastraisService.getCadastroGeral(true),
            this.logService.Log_GetUltimoAcesso()]
        ).subscribe(([dadosCadastraisSinqia, acesso]) => {
            this.dadosCadastraisSinqia = dadosCadastraisSinqia;
            this.saudacao = (this.dadosCadastraisSinqia.sexo.substring(0, 1) === 'M') ? 'Seja bem-vindo, ' : 'Seja bem-vinda, ';
            let nomeParticipante = this.dadosCadastraisSinqia.nome;
            this.nome = nomeParticipante.split(' ')[0].slice(0, 1) + nomeParticipante.split(' ')[0].slice(1, (nomeParticipante.length)).toLowerCase();
            this.ambienteSimulado();
            this.isLoading = false;
            this.ultimoAcesso = acesso.ultimoAcesso != null ?
                formatDate(acesso.ultimoAcesso, 'dd/MM/yyyy HH:mm:ss', 'pt-BR')
                : 'Primeiro acesso';
        });
    }

    ambienteSimulado() {
        if ((sessionStorage.getItem('isSimulado') === 'true')) {
            this.isSimulado = true;
            return;
        }

        if (!this.environment.production) {
            this.isHomologado = true;
            return;
        }
    }


    logout() {
        this.authService.getLogout().subscribe((data) => {
            if (data) {
                this.authService.logout();
            }
        });
    }
}
