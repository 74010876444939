export const STORAGE_KEYS = {
    TERMO_ADESAO: 'termo_adesao',
    DADOS_CADASTRAIS_SINQIA: 'dados_cadastrais_sinqia',
    DADOS_CADASTRAIS_PERFIL: 'dados_cadastrais_perfil',
    HANDSHAKE_TOKEN: 'handshake_token',
    ADESAO_PLANO_CD: 'ade_plano_cd',
    TOKEN_ADE_CD: 'token_ade_cd',
    TOKEN_API_BD: 'accessToken',
    PULAR_TERMO_ADESAO: 'pular_termo_adesao',
    ADESAO_CONTEXTO: 'ade_contexto',
    LOGIN_INTEGRADO: 'isSimulado',
    LOGIN_INTEGRADO_ROLE: 'roleSimulado',
    HIDE_INFO_PASSWORD: 'hide_info_password',
}
