import { GestaoFinanceiraEmprestimoServiceGetSimulacaoResponse } from '../models';

export class GestaoFinanceiraEmprestimoServiceGetValoresSimulacaoResponse {

    TipoEmpId: number;
    ValorMaximo: number;
    ValorMaximoLiquido: number;
    ValorMinimo: number;
    MensagemValorMaximo: any;
    Margem: number;
    Matricula: any;
    CpfRecebedor: any;
    IdAdesao: number;
    Usuario: string;
    SaldoAnterior: number;
    Prazo: number;
    Percentual: number;
    DoencaGrave: boolean;
    ValorSolicitado: number;
    Temcontrato: boolean;
    Elegivel: boolean;
    Simulacao: GestaoFinanceiraEmprestimoServiceGetSimulacaoResponse[];
    Suspensao: number;
    ContaBancaria: any;
    DataPagamento: Date | string;
    ALiquidar: number[];
}
