import { forkJoin } from 'rxjs';
import {
    SituacaoAtual, TipoSeDocumento,
    ArquivosService, GestaoFinanceiraEmprestimoService,
    INFORMACAO_ENDIVIDAMENTO, LIMITE_POR_TEMPO, SaldoEmprestimo
} from '@fibra/fibra-shared-lib';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-situacao-card',
    templateUrl: './situacao-card.component.html',
    styleUrls: ['./situacao-card.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SituacaoCardComponent implements OnInit {

    situacaoAtual: SituacaoAtual;
    saldoEmprestimoConcessao: SaldoEmprestimo;
    tipoSeDoc: TipoSeDocumento;

    mensagemDeErro: string;
    nomeArquivoASerBaixado: string;

    isLoading = true;
    baixandoArquivo = false;
    isInfoLoading: boolean;
    bloquear: boolean;
    displayDialogBaseCalculo: boolean = false;

    readonly INFORMACAO_ENDIVIDAMENTO = INFORMACAO_ENDIVIDAMENTO;
    readonly LIMITE_POR_TEMPO = LIMITE_POR_TEMPO;


    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
                private arquivosService: ArquivosService) { }

    ngOnInit() {
        forkJoin([this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSituacaoAtual(),this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldoConcessao(true)])
        .subscribe(([situacao,saldoEmprestimoConcessao]) => {
            this.situacaoAtual = situacao;
            this.saldoEmprestimoConcessao = saldoEmprestimoConcessao;
            this.situacaoAtual.limiteEndividamento = this.saldoEmprestimoConcessao.valorMaximoLiquido < 0 ? 0 : this.saldoEmprestimoConcessao.valorMaximoLiquido;
            this.isLoading = false;       
 
        }, () => this.informarErroDeCarregamento());
    }

    downloadSeDocs() {
        this.isInfoLoading = true;
        this.tipoSeDoc = TipoSeDocumento.NormaPessoalEmprestimo;
        this.baixandoArquivo = true;
        this.nomeArquivoASerBaixado = 'Regulamento de Empréstimos';
        this.arquivosService.getSeDocs(this.tipoSeDoc, () => {
            this.baixandoArquivo = false;
            this.isInfoLoading = false;
        }, this);
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
        ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquear = true;
        this.isLoading = false;
    }

    public showDialogBaseCalculo(){
        this.displayDialogBaseCalculo = true;
    }
}
