export class MigracaoModel {
    idMigracao?: number;
    dataMigracao?: string;
    idAdesaoOrigem?: number;
    idAdesaoDestino?: number;
    tipoMigracao?: string;
    saldamentoModel?: SaldamentoModel;
    idPessoaFisica?: number;
  }
  
  export class SaldamentoModel {
    idSaldamento?: number;
    vigencia?: Vigencia;
    dataEfetivacao?: string;
    reservaMatematicaProgramada?: number;
    reservaMatematicaRisco?: number;
    reservaMatematicaPeculio?: number;
    valorBeneficio?: number;
    historicos?: Historico[];
    indiceReajuste?: IndiceReajuste;
    srb?: number;
    src?: number;
    fcs?: number;
    reservaPoupanca?: number;
    reservaJoia?: number;
    tempoContribuicao?: number;
    tempoDiferimento?: number;
    tipoBPS?: string;
    valorBeneficioOrdinario?: number;
    valorBeneficioAdicional?: number;
  }
  
  export class Vigencia {
    caption?: any;
    dataInicial?: string;
    dataFinal?: any;
    guid?: string;
    text?: string;
  }
  
  export class Historico {
    idHistorico?: number;
    idSaldamento?: number;
    vigencia?: Vigencia2;
    percentualReajuste?: number;
    valorBeneficio?: number;
  }
  
  export class Vigencia2 {
    caption?: any;
    dataInicial?: string;
    dataFinal?: string;
    guid?: string;
    text?: string;
  }
  
  export class IndiceReajuste {
    id?: number;
    nome?: string;
    codigo?: string;
    periodicidade?: string;
    unidadePadrao?: string;
    tipoDivulgacao?: string;
    usadoBenchMark?: boolean;
    codigoBovespa?: any;
    codigoPrevic?: any;
    itemIndiceFinanceiro?: any[];
  }
  