import { Component, OnInit } from '@angular/core';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { GestaoFinanceiraEmprestimoService, SaldoDevedor } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-amortizacao-card',
    templateUrl: './amortizacao-card.component.html',
    styleUrls: ['./amortizacao-card.component.css']
})
export class AmortizacaoCardComponent implements OnInit {

    isLoading = true;
    currencyMaskConfig: CurrencyMaskConfig;
    valorAmortizacao: number = 0;
    valorMaximoPermitido: number;
    emprestimoSaldo: SaldoDevedor;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) {

        this.currencyMaskConfig = {
            align: 'left',
            allowNegative: false,
            decimal: ',',
            precision: 2,
            prefix: '',
            suffix: '',
            thousands: '.'
        };
    }

    ngOnInit() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldoDevedor(new Date()).subscribe(saldo => {
            this.emprestimoSaldo = saldo;
            this.valorMaximoPermitido = this.emprestimoSaldo.saldoEfetivo;
            this.isLoading = false;
        });
    }

    alteraValor() {

    }

    obterBoletoAmortizacao() {

    }

}
