export * from './painel-bd.component';
export * from './atuarias';
export * from './acompanhamento-gerencial';
export * from './beneficios-previdenciarios';
export * from './dados-cadastrais';
export * from './demonstrativo-pagamento';
export * from './desligamento';
export * from './desligamento';
export * from './emprestimo';
export * from './ficha-financeira';
export * from './informe-rendimento';
export * from './inicio';
export * from './normas';
export * from './identificacao-plano';
export * from './perfil-comunicacao';
export * from './tela-contribuicao';
export * from './tela-fibra-numeros';
export * from './tela-previsao-aposentadoria';
export * from './tela-tvfibra';
export * from './como-fazer';
export * from './baixar-app';
export * from './extrato-atas';
export * from './seguro-vida';
export * from './emprestimo/acompanhamento-emprestimo/info-pgto-extra';
export * from './tela-previsao-aposentadoria/tela-ppdv';
export * from './emprestimo/acompanhamento-emprestimo/time-line';
export * from './emprestimo/acompanhamento-emprestimo';
export * from './emprestimo/informacoes-adicionais-emprestimo';
export * from './extrato-bps';
export * from './participantes-beneficiarios';
export * from './texto-informacoes-complementares';
export * from './rentabilidade';
