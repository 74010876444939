import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '@fibra/fibra-shared-lib';
import { ConfirmarCpfModel } from '@fibra/fibra-shared-lib';
import { Password } from 'primeng/password';
import { PasswordTemplateComponent } from '../../components/password-template/password-template.component';

@Component({
    selector: 'app-confirmar-senha',
    templateUrl: './confirmar-senha.component.html',
    styleUrls: ['./confirmar-senha.component.css']
})
export class ConfirmarSenhaComponent implements OnInit {
    confirmaRegistro: boolean;
    formulario: FormGroup;
    id: string;
    isDialogVisible = false;
    isLoading = false;
    mensagem: string;
    senhaCadastradaComSucesso: boolean;
    naoAtivouConta = false;
    setIntervalId: any;
    token: string;
    usuarioTentouEnviarFormulario = false;
    esqueciSenha = false;
    @ViewChild('passwordInput') passwordInput: Password;
    @ViewChild('passwordTemplate') passwordTemplate: PasswordTemplateComponent;

    get senha() {
        return this.formulario.get('senha');
    }

    get confirmarSenha() {
        return this.formulario.get('confirmarSenha');
    }

    get senhasNaoConferem() {
        return this.senha.value !== this.confirmarSenha.value;
    }

    get erroConfirmarNovaSenha() {
        if (!this.confirmarSenha.value) {
            return '';
        }

        if ((this.confirmarSenha.value || '').length === 0) {
            return 'Insira sua nova senha';
        }

        if (this.senhasNaoConferem) {
            return 'As senhas não coincidem';
        }
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {
        this.formulario = new FormGroup({
            senha: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)]),
            confirmarSenha: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)])
        });
    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.token = params['token'];
            this.id = params['id'];
            this.confirmaRegistro = params['registro'] === '1';

            if (!this.token || !this.id) {
                this.router.navigate(['/login']);
            }
        });

        this.formulario.get("senha").valueChanges.subscribe(value => {
            this.passwordTemplate.checkRequirements(value);
        });
    }

    confirmar() {
        this.usuarioTentouEnviarFormulario = true;

        if (this.formulario.invalid) {
            return;
        }

        this.isLoading = true;

        if (this.confirmaRegistro) {
            const model = new ConfirmarCpfModel(
                this.id,
                this.senha.value,
                this.confirmarSenha.value,
                this.token
            );

            this.authService.AccountCPF_ConfirmarRegistro(model)
                .subscribe(data => {
                    if (data.type === 1) {
                        this.darFeedbackDeSucessoERedirecionar(undefined);
                    } else {
                        this.informarErroDeServidor(data.message);
                    }
                }, () => {
                    this.informarErroDeServidor(undefined);
                });
        } else {
            this.authService.AccountCPF_ConfirmarEsqueciSenha(
                this.id,
                this.token,
                this.senha.value,
                this.confirmarSenha.value
            ).subscribe(data => {
                if (data.type === 1) {
                    this.darFeedbackDeSucessoERedirecionar(data.message);
                } else {
                    this.informarErroDeServidor(data.message);
                }
            }, () => this.informarErroDeServidor(undefined)
            );
        }
    }

    private informarErroDeServidor(message: string) {
        this.senhaCadastradaComSucesso = false;
        if (message && message.includes('Este link')) {
            this.esqueciSenha = true;
            this.mensagem = message + ' Para o reenvio ';
        } else {
            this.mensagem = 'Algo deu errado. Por favor tente novamente mais tarde.';
            this.esqueciSenha = false;
        }
        this.isLoading = false;
        this.isDialogVisible = true;
    }

    private darFeedbackDeSucessoERedirecionar(message: string) {
        this.senhaCadastradaComSucesso = true;
        if (message && message.includes('Link enviado')) {
            this.mensagem = message;
            this.esqueciSenha = false;
        } else if (message && message.includes('Um novo link')) {
            this.naoAtivouConta = true;
            this.mensagem = message;
        } else {
            this.mensagem = 'Senha cadastrada com sucesso.';
        }
        this.isLoading = false;
        this.isDialogVisible = true;
    }

    reenviarToken() {
        this.authService.AccountCPF_GetToken(this.id).subscribe(data => {
            if (data.type === 1) {
                this.darFeedbackDeSucessoERedirecionar(data.message);
            } else {
                this.informarErroDeServidor(undefined);
            }
        });
    }
}
