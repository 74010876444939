import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PERGUNTAS } from './questions'
import { AdesaoCdService, DocumentoService, EPerfilInvestimento, PerfilInvestimentoService, SolicitacaoAdesaoCdModel, TipoSeDocumento } from '@fibra/fibra-shared-lib';
import { PerguntaPerfilInvestimento, RespostaPerfilInvestimento } from '@fibra/fibra-shared-lib';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { PdfViewerComponent } from '../../../components/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'app-passo-02',
    templateUrl: './passo-02.component.html',
    styleUrls: ['./passo-02.component.css']
})
export class Passo2Component implements OnInit {
    perguntas = PERGUNTAS;
    resultTotal = -1;
    resultPerfil = '';
    perfilRecomendado: EPerfilInvestimento | null = null;
    items: MenuItem[];
    activeIndex: number = 0;
    openSection = 0;
    openSectionPerfilChanged = false;
    openToNext = false;
    ePerfilInvestimento = EPerfilInvestimento;
    solicitacaoAdesao: SolicitacaoAdesaoCdModel;
    perguntasPerfilInvestimento: PerguntaPerfilInvestimento[] = [];
    perguntasRespondidas: PerguntaPerfilInvestimento[] = [];
    perguntaAtual: PerguntaPerfilInvestimento;
    textoBotaoAvancar: string = "Próxima pergunta";
    exibirBotaoConcluir: boolean = false;
    desenquadramento: boolean = false;
    termoPerfilConservador: boolean = false;
    termoDesenquadramento: boolean = false;
    naoResponderQuestionario: boolean | null = null;
    mostrarPerguntas: boolean = true;
    mostrarOpcaoRefazer: boolean = false;
    mostrarOpcoesPerfil: boolean = false;
    labelBtnRefazerQuestionario: string;    ;
    isLoading: boolean;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(private router: Router, private adesaoService: AdesaoCdService,
        private perfilInvestimentoService: PerfilInvestimentoService,private documentoService: DocumentoService) { }

    ngOnInit() {
        this.activeIndex = 1;
        this.items = [
            { label: 'Informações iniciais' },
            { label: 'Perfil de investimento' },
            { label: 'Conclusão' }
        ];

        this.iniciarSimulador();
    }

    private iniciarSimulador() {
        this.solicitacaoAdesao = this.adesaoService.getSolicitacaoAdesao();
        this.termoPerfilConservador = this.solicitacaoAdesao?.termoPerfilConservador == true;
        this.termoDesenquadramento = this.solicitacaoAdesao?.termoDesenquadramento == true;

        if (this.solicitacaoAdesao?.naoResponderQuestionario == true) {
            this.ignorarQuestionario();
        }
        else {
            this.perguntasPerfilInvestimento = PerguntaPerfilInvestimento.getPerguntas();

            if (this.solicitacaoAdesao.perfilInvestimento != null) {
                this.perguntasRespondidas = this.solicitacaoAdesao.perguntasPerfilInvestimento;
                this.concluirQuestionario();
                this.perfilCheck(this.solicitacaoAdesao.perfilInvestimento);
            }

            if (this.solicitacaoAdesao?.perguntasPerfilInvestimento
                && this.solicitacaoAdesao?.perguntasPerfilInvestimento?.length > 0) {
                this.perguntasRespondidas = this.solicitacaoAdesao.perguntasPerfilInvestimento;
                let perguntas = this.perguntasRespondidas.filter(x => x.respondida).sort((x, y) => y.numero - x.numero);
                this.perguntaAtual = perguntas[0];
                this.verificarRespostas(this.perguntasRespondidas);
            } else {
                this.perguntasRespondidas = PerguntaPerfilInvestimento.getPerguntas();
                this.perguntaAtual = this.perguntasPerfilInvestimento[0];
            }
        }
    }

    perfilCheck(perfilSelecionado: EPerfilInvestimento) {
        if (!Object.keys(EPerfilInvestimento).includes(perfilSelecionado.toString()))
            return;

        this.desenquadramento =
            (this.perfilRecomendado == EPerfilInvestimento.Conservador
                && perfilSelecionado == EPerfilInvestimento.Moderado)
            || (this.perfilRecomendado == EPerfilInvestimento.Conservador
                && perfilSelecionado == EPerfilInvestimento.Arrojado)
            || (this.perfilRecomendado == EPerfilInvestimento.Moderado
                && perfilSelecionado == EPerfilInvestimento.Arrojado);

        this.adesaoService.definirPerfilInvestimento(perfilSelecionado, this.perfilRecomendado);
        this.solicitacaoAdesao.perfilInvestimento = perfilSelecionado;
        this.solicitacaoAdesao.perfilInvestimentoIndicado = this.perfilRecomendado;
        this.solicitacaoAdesao.termoDesenquadramento = null;
        this.termoDesenquadramento = false;
        this.definirTermoDesenquadramento(false);
    }

    onBack() {
        this.router.navigate(['/painel/adesao-cd/informacoes-iniciais'])
    }

    onSubmit() {
        this.router.navigate(['/painel/adesao-cd/conclusao'])
    }

    avancarPergunta(perguntaAtual: PerguntaPerfilInvestimento) {
        let pergunta = this.perguntasRespondidas.find(x => x.numero == perguntaAtual.numero + 1);
        if (pergunta)
            this.perguntaAtual = pergunta;
        this.verificarRespostas(this.perguntasRespondidas);
    }

    voltarPergunta(perguntaAtual: PerguntaPerfilInvestimento) {
        let pergunta = this.perguntasRespondidas.find(x => x.numero == perguntaAtual.numero - 1);
        if (pergunta)
            this.perguntaAtual = pergunta;
        this.verificarRespostas(this.perguntasRespondidas);
    }

    marcarResposta(resposta: RespostaPerfilInvestimento) {
        this.perguntaAtual.respondida = true;
        this.perguntaAtual.respostas.forEach(x => {
            x.selecionada = x.numero == resposta.numero;
        });
        this.atualizarPergunta(this.perguntaAtual);
    }

    atualizarPergunta(pergunta: PerguntaPerfilInvestimento) {
        let perguntas = this.perguntasRespondidas.filter(x => x.numero != pergunta.numero);
        perguntas.push(pergunta);
        this.perguntasRespondidas = perguntas.sort((x, y) => x.numero - y.numero);
        this.adesaoService.atualizarPerguntasPerfilInvestimento(this.perguntasRespondidas);
        this.verificarRespostas(this.perguntasRespondidas);
    }

    verificarRespostas(perguntasRespondidas: Array<PerguntaPerfilInvestimento>) {
        let naoRespondidas = perguntasRespondidas.filter(pergunta => {
            return pergunta.respondida == null || pergunta.respondida == false;
        });

        let numerosPerguntas = perguntasRespondidas.map(x => x.numero);
        let numeroUltimaPergunta = Math.max(...numerosPerguntas);

        this.exibirBotaoConcluir = naoRespondidas.length == 0 && this.perguntaAtual.numero == numeroUltimaPergunta;
    }

    concluirQuestionario() {
        this.mostrarPerguntas = false;
        this.mostrarOpcoesPerfil = true;
        this.mostrarOpcaoRefazer = true;
        this.labelBtnRefazerQuestionario = 'Responder novamente';
        this.resultTotal = this.perfilInvestimentoService.calcularResultadoQuestionario(this.perguntasRespondidas);
        this.perfilRecomendado = this.perfilInvestimentoService.getPerfilInvestimento(this.resultTotal);
        if (!this.solicitacaoAdesao.perfilInvestimento) {
            this.solicitacaoAdesao.perfilInvestimento = this.perfilRecomendado;
            this.solicitacaoAdesao.perfilInvestimento = this.perfilRecomendado;
            this.adesaoService.definirPerfilInvestimento(this.perfilRecomendado, this.perfilRecomendado);
        }
        this.resultPerfil = this.perfilInvestimentoService.GerarDescricaoPerfilInvestimento(this.perfilRecomendado);
    }

    definirAceiteTermoPerfilConservador(aceito: boolean) {
        this.solicitacaoAdesao.termoPerfilConservador = aceito;
        this.adesaoService.definirAceiteTermoPerfilConservador(aceito);
    }

    definirTermoDesenquadramento(aceito: boolean) {
        this.solicitacaoAdesao.termoDesenquadramento = aceito;
        this.adesaoService.definirTermoDesenquadramento(aceito);
    }

    ignorarQuestionario() {
        this.adesaoService.ignorarPerguntasPerfilInvestimento();
        this.adesaoService.definirNaoResponderQuestionario(true);
        this.solicitacaoAdesao.perfilInvestimento = EPerfilInvestimento.Conservador;
        this.naoResponderQuestionario = true;
        this.mostrarPerguntas = false;
        this.mostrarOpcaoRefazer = true;
        this.labelBtnRefazerQuestionario = 'Responder questionário';
        this.resultPerfil = null;
    }

    refazerQuestionario() {
        this.resultTotal = -1;
        this.resultPerfil = null;
        this.perfilRecomendado = null;
        this.perguntasRespondidas = [];
        this.textoBotaoAvancar = "Próxima pergunta";
        this.exibirBotaoConcluir = false;
        this.desenquadramento = false;
        this.termoPerfilConservador = false;
        this.termoDesenquadramento = false;
        this.naoResponderQuestionario = null;
        this.mostrarPerguntas = true;
        this.mostrarOpcaoRefazer = false;
        this.mostrarOpcoesPerfil = false;

        this.adesaoService.atualizarPerguntasPerfilInvestimento([]);
        this.adesaoService.definirPerfilInvestimento(null, null);
        this.definirTermoDesenquadramento(false);
        this.definirAceiteTermoPerfilConservador(false);
        this.adesaoService.definirNaoResponderQuestionario(false);
        this.iniciarSimulador();
    }

    podeAvancar() {
        if (!this.solicitacaoAdesao?.perfilInvestimento) {
            return false;
        }
        if (this.naoResponderQuestionario && !this.solicitacaoAdesao?.termoPerfilConservador) {
            return false;
        }
        if (this.desenquadramento && !this.solicitacaoAdesao?.termoDesenquadramento) {
            return false;
        }
        return true;
    }

    downloadPerfilInvestimento(tipoSeDocumento: TipoSeDocumento, fileName: string) {
        this.isLoading = true;

        fileName =  encodeURIComponent('Cartilha Perfil de Investimentos.pdf');

        let request = this.documentoService.getSeDocsByName(TipoSeDocumento.SinteseSaldamentoNovoPlanoAtivos,fileName)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request,true)
    }
}
