import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormularioService, AlertModalComponent, AdesaoCdService } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { SuspensaoContribuicaoModel } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';

@Component({
    selector: 'app-solicitacao-suspensao-contribuicao',
    templateUrl: './suspensao-contribuicao.component.html',
    styleUrls: ['./suspensao-contribuicao.component.css']
})
export class SolicitacaoSuspensaoContribuicaoComponent implements OnInit {
    suspensaoContribuicao: SuspensaoContribuicaoModel;
    suspensaoContribuicaoForm: FormGroup;
    isLoading: boolean = false;
    ptBR = LOCALE_PT_BR;
    @ViewChild('alertModalComponent', { static: false }) alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth', { static: false }) twoFactorAuth: TwoFactorAuthComponent;
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
    urlArquivoSeSuite: string;
    msgs: any[];
    yearRange: string;
    uploadedFiles: any[] = [];
    isSimulado = sessionStorage.getItem('isSimulado');

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private fb: FormBuilder,
        private adesaoCdService: AdesaoCdService
    ) {
        this.yearRange = '1900:' + (new Date()).getFullYear();
    }

    ngOnInit() {
        this.suspensaoContribuicaoForm = this.fb.group({
            dataSuspensaoContrato: null,
            declaraSuspensao: false
        });
    }

    ngOnDestroy(): void {
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    async enviar(suspensaoContribuicao: SuspensaoContribuicaoModel) {
        this.isLoading = true;
        this.suspensaoContribuicao = suspensaoContribuicao;
        try{
            var resultado = await this.adesaoCdService.solicitarSuspensaoDaConstribuicao(suspensaoContribuicao.dataSuspensaoContrato, this.fileUpload.files).toPromise();
            if(resultado) {
                this.alertModalComponent.show('Solicitação enviada com sucesso!', true);
                return;
            } else {
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                return;
            }
        } catch(e) {
            if(e.error && e.error.errors) {
                var message = e.error.errors.reduce((pre, cc) => pre + "\n" + cc);
                this.alertModalComponent.show(message, false);
                return;
            }
            this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);

        } finally {
            this.isLoading = false;
        }
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    formInvalido() {
        if(this.suspensaoContribuicaoForm.controls['declaraSuspensao'].value == false)
            return true;
        if(this.suspensaoContribuicaoForm.controls['dataSuspensaoContrato'].value == null)
            return true;
        if(!this.fileUpload.files || this.fileUpload.files.length == 0)
            return true;
        return false;
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        this.twoFactorAuth.show();
    }

     /**
     * Lida com o sucesso da autenticação de dois fatores.
     * @param twoFactorAuthToken 2fa token.
     */
      on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar(this.suspensaoContribuicaoForm.value);
    }
}
