import { Routes } from '@angular/router';
import { TermoAdesaoComponent } from './termo-adesao.component';

import { AuthGuardService } from '@fibra/fibra-shared-lib';

export const routes: Routes = [
    {
        path: 'termo-adesao',
        component: TermoAdesaoComponent,
        canActivate: [AuthGuardService]
    }
];
