import { Inject, Injectable } from "@angular/core";
import { Service } from "./service";
import { HttpClient } from "@angular/common/http";
import { IndiceFinanceiro } from "../models/sinqia-api";
import { EIndiceFinanceiro, ETokenApi } from "../enums";
import { RespostaDocumentoCriadoModel } from "../models/click-sign/resposta-documento-criado.mode";
import { ESituacaoDocumento } from "../models/click-sign/situacao-documento.enum";
import { ResponseApiBdModel } from "../models/response-api-plano-bd.model";
import { HttpClientService } from "./http-client.service";

@Injectable()
export class IndiceFinanceiroService extends Service {

    constructor(protected httpClient: HttpClient, @Inject('environment') private environment,
        private httpClientService: HttpClientService) {
        super(httpClient);
    }

    getIndiceFinanceiro() {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/saldamento/indice-reajuste`;
        return this.get<IndiceFinanceiro>(endpoint);
    }

    solicitarAjusteIndiceFinanceiro(indiceFinanceiro: EIndiceFinanceiro, cpf: string) {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/saldamento/solicitar`;
        const indiceFin = {
            idIndiceFinanceiro: indiceFinanceiro,
            cpf: cpf
        };

        return this.post<RespostaDocumentoCriadoModel>(endpoint, indiceFin);
    }

    getFormularioReajusteIndice(indiceFinanceiro: EIndiceFinanceiro, cpf: string) {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/formulario/formtermoindicedeadesaosaoldado/0`;
        const indiceFin = {
            idIndiceFinanceiro: indiceFinanceiro,
            cpf: cpf
        };

        return this.post<any>(endpoint, indiceFin);
    }

    getSituacaoAssinaturaDocumento() {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/saldamento/situacao`;
        return this.getTyped<ResponseApiBdModel<{ situacao: ESituacaoDocumento }>>(endpoint);
    }

    registrarAssinaturaDocumento(idDoDocumento: string) {
        const endpoint = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/saldamento/assinadopelocliente`;
        const idDocumento = {
            idDoDocumento: idDoDocumento
        };

        return this.postTyped<ResponseApiBdModel<boolean>>(endpoint, idDocumento);
    }

    uploadFileSeSuite(file: File, indiceFinanceiro: EIndiceFinanceiro) {
        const url = `${this.environment.API_GESTAO_PLANO_BENEFICIO}/saldamento/solicitar-via-upload`;
        let formData = new FormData();
        formData.append('indiceReajuste', String(indiceFinanceiro));
        formData.append('documento', file);

        return this.httpClientService.upload<{ success: boolean, data: any }>(url, formData, ETokenApi.TokenApiBd);
    }
}
