import { Component, OnInit, Input, Output } from '@angular/core';
import {
    AuthService,
    DadosCadastraisService,
    GestaoPlanoCadastroService,
    GestaoPlanoContribuicaoService,
    HttpClientService
} from '@fibra/fibra-shared-lib';
import { ContribuicaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contribuicao.dto';
import { ContribuicaoAnoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contribuicao-ano.dto';

@Component({
    selector: 'app-contribuicao',
    templateUrl: './contribuicao.component.html',
    styleUrls: ['./contribuicao.component.css']
})
export class ContribuicaoComponent implements OnInit {
    @Input() mostrarLinkParaExtratoCompleto = true;
    show = false;
    contribuicaoDto: ContribuicaoDto;
    mensagemDeErro: string;
    bloquearContribuicaoSaldo: boolean;
    isLoading = true;
    ano: number;
    contribuicaoAnoDto: ContribuicaoAnoDto[] = [];
    indexAnoSelecionado: number;    

    constructor(
        private authService: AuthService,
        private dadosCadastraisService: DadosCadastraisService,
        private saldoContribuicaoService: GestaoPlanoContribuicaoService,        
    ) {
        const dataAtual = new Date();
        this.ano = dataAtual.getFullYear();
        
    }

    ngOnInit() {        
        this.saldoContribuicaoService.obterExtratoContribuicaoPorAno(this.ano).subscribe(contribuicao => {
            this.show = true;
            this.contribuicaoDto = contribuicao;
            this.contribuicaoAnoDto = this.contribuicaoDto.contribuicoesAnos.sort(this.ordenarPorAnoDecrescente);
            this.isLoading = false;
        }, httpError => {
            if (httpError.status === 400) {
                this.show = false;
                this.isLoading = false;
                return;
            }
            this.informarErroDeCarregamento();
        });
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro. Por favor, tente novamente.' +
            ' Se o problema persistir entre em contato pelo 0800 041 4404';
        this.bloquearSaldoDisponivelDeCarregamento();
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        this.bloquearContribuicaoSaldo = true;
        this.isLoading = false;
    }

    baixarExtrato(ano: number) {
        this.isLoading = true;
        this.saldoContribuicaoService.ExtratoContribuicaoPdf(ano, () => {
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private ordenarPorAnoDecrescente(
        a: ContribuicaoAnoDto,
        b: ContribuicaoAnoDto
    ) {
        return a.ano < b.ano ? 1 : a.ano > b.ano ? -1 : 0;
    }

    trocaAno() {
        this.isLoading = true;
        this.saldoContribuicaoService.Extrato_ExtratoContribuicaoPorAno(this.contribuicaoAnoDto[this.indexAnoSelecionado].ano).subscribe(contribInfo => {
            if (contribInfo) {
                this.contribuicaoDto = contribInfo;
                this.isLoading = false;
            } else {
                this.informarErroDeCarregamento();
            }
        }, () => this.informarErroDeCarregamento());
    }
}
