export const PERGUNTAS = [
	{
		"pergunta":"Qual a sua expectativa quanto à sua renda para os próximos anos?",
		"respostas":[
			{
				"resposta": "Preservar minha renda para o futuro",
				"value": 1
			},
			{
				"resposta": "Preservar a renda vislumbrando alguns aumentos ao longo da carreira",
				"value": 2
			},
			{
				"resposta": "Busco uma carreira ascendente, e vislumbro promoções em minha trajetória profissional",
				"value": 4
			},
			{
				"resposta": "Estou em fase final de carreira e almejo me aposentar apenas com a renda dos recursos investidos",
				"value": 1
			},
			{
				"resposta": "Sou aposentado",
				"value": 1
			}
		]
	},
	{
		"pergunta":"Quando você planeja se aposentar?",
		"respostas":[
			{
				"resposta": "Faltam mais de 10 anos para me aposentar.",
				"value": 4
			},
			{
				"resposta": "Dentro dos próximos 10 anos.",
				"value": 3
			},
			{
				"resposta": "Dentro dos próximos 5 anos.",
				"value": 2
			},
			{
				"resposta": "Já estou aposentado.",
				"value": 1
			}
		]
	},
	{
		"pergunta":"Você diria que sua situação financeira (ou de sua  família) é:",
		"respostas":[
			{
				"resposta": "Sem dívidas e temos reservas financeiras e/ou investimentos.",
				"value": 5
			},
			{
				"resposta": "Sem dívidas, porém sem reservas financeiras.",
				"value": 3
			},
			{
				"resposta": "Algumas despesas fixas, mas com espaço para guardar parte da renda.",
				"value": 2
			},
			{
				"resposta": "Dívidas consomem uma parcela importante da renda e não há espaço no orçamento para reservas periódicas.",
				"value": 1
			}
		]
	},
	{
		"pergunta":"Se você não estivesse trabalhando, como faria para se manter?",
		"respostas":[
			{
				"resposta": "Pode contar com sua família / cônjuge para assumir as despesas da casa, caso perca a principal fonte de renda.",
				"value": 4
			},
			{
				"resposta": "Possui economias, investimentos ou alguma atividade secundária que permitem sua família manter um razoável padrão de vida por cerca de 12 meses.",
				"value": 3
			},
			{
				"resposta": "Não possuo outras fontes de renda ou reservas financeiras.",
				"value": 0
			},
			{
				"resposta": "Sou aposentado.",
				"value": 1
			}
		]
	},
	{
		"pergunta":"Qual valor você consegue investir por mês além das contribuições para a Fibra?",
		"respostas":[
			{
				"resposta": "Não tenho realizado investimentos.",
				"value": 0
			},
			{
				"resposta": "Até 10% do salário.",
				"value": 1
			},
			{
				"resposta": "Entre 10 e 15% do salário.",
				"value": 2
			},
			{
				"resposta": "Entre 15 e 20% do salário.",
				"value": 3
			},
			{
				"resposta": "Acima de 20% do salário.",
				"value": 5
			}
		]
	},
	{
		"pergunta":"Já possui investimentos?",
		"respostas":[
			{
				"resposta": "Não.",
				"value": 0
			},
			{
				"resposta": "Sim. Poupança.",
				"value": 1
			},
			{
				"resposta": "Sim. Fundos de Renda Fixa, CDB ou títulos públicos.",
				"value": 2
			},
			{
				"resposta": "Sim. Tenho aplicações diversificadas, Incluindo Renda Variável.",
				"value": 4
			}
		]
	},
	{
		"pergunta":"Você possui alguma experiência com produtos do mercado financeiro, tais como Fundos de Investimento, Ações, Fundos Multimercado e CDB?",
		"respostas":[
			{
				"resposta": "Não conheço os produtos do mercado financeiro.",
				"value": 1
			},
			{
				"resposta": "Sim, mas invisto meus recursos apenas em produtos tradicionais e/ou com orientação profissional.",
				"value": 2
			},
			{
				"resposta": "Sim, conheço produtos do mercado financeiro e participo ativamente na seleção dos meus investimentos.",
				"value": 4
			}
		]
	},
	{
		"pergunta":"Considerando que risco é a incerteza sobre o retorno, ou até mesmo a possibilidade de perda do capital investido, e que investimentos mais arriscados podem trazer retornos maiores, quanto do seu patrimônio você aplicaria em investimentos com maior risco?",
		"respostas":[
			{
				"resposta": "Pretendo ter o menor risco possível (de 0% a 5% do patrimônio).",
				"value": 1
			},
			{
				"resposta": "Aceito riscos em uma pequena parcela do patrimônio investido para obter retorno um pouco maior (até 15 % do patrimônio).",
				"value": 2
			},
			{
				"resposta": "Aceito correr riscos controlados em busca da possibilidade de um retorno maior (até 30% do patrimônio).",
				"value": 3
			},
			{
				"resposta": "Aceito correr mais riscos em busca da possibilidade de rentabilidades ainda mais elevadas (40% ou mais do patrimônio).",
				"value": 5
			}
		]
	},
	{
		"pergunta":"Qual das alternativas abaixo você acredita que descreve melhor o seu perfil de risco:",
		"respostas":[
			{
				"resposta": "Preservar capital. Prefiro investir meu capital em aplicações menos arriscadas, mesmo sabendo que meus ganhos podem não ser elevados.",
				"value": 0
			},
			{
				"resposta": "Procuro alternativas de melhor rentabilidade, porém em investimentos conservadores. Só me permito entrar em investimentos que envolvem baixo risco.",
				"value": 1
			},
			{
				"resposta": "Procuro alternativas buscando melhor rentabilidade, mesmo envolvendo algum nível risco, porém arrisco apenas pequena parte da minha reserva. A maior parte mantenho em investimentos conservadores.",
				"value": 2
			},
			{
				"resposta": "Procuro sempre alternativas buscando melhor rentabilidade, mesmo que possuam maior risco. No entanto, não invisto todo o meu dinheiro, pois sempre deixo uma parte menor em investimentos mais conservadores.",
				"value": 4
			},
			{
				"resposta": "Estou o tempo todo em contato com o mercado financeiro, de olho nas tendências e investindo meu dinheiro apenas em aplicações de alto retorno esperado, independente do risco.",
				"value": 8
			}
		]
	},
	{
		"pergunta":"Como se sentiria se em 1 ano, em um determinado investimento, você perdesse o equivalente a 15% do seu patrimônio acumulado?",
		"respostas":[
			{
				"resposta": "Não me incomodaria muito, porque espero que no longo prazo essa perda seja revertida.",
				"value": 4
			},
			{
				"resposta": "Me incomodaria um pouco e ficaria em alerta: se em mais alguns meses a rentabilidade não ficasse positiva, buscaria outra opção de investimento menos arriscado.",
				"value": 2
			},
			{
				"resposta": "Contataria um especialista e pediria para trocar o investimento por uma opção mais conservadora.",
				"value": 1
			},
			{
				"resposta": "Não aplicaria neste tipo de investimento.",
				"value": 0
			}
		]
	},
	{
		"pergunta":"Se a perda da pergunta anterior fosse maior, cerca de 50% de suas aplicações, como você se sentiria?",
		"respostas":[
			{
				"resposta": "Não me incomodaria muito, porque espero que no longo prazo essa perda seja revertida.",
				"value": 8
			},
			{
				"resposta": "Me incomodaria um pouco e ficaria em alerta: se em mais alguns meses a rentabilidade não ficasse positiva, buscaria outra opção de investimento menos arriscado.",
				"value": 4
			},
			{
				"resposta": "Contataria um especialista e pediria para trocar o investimento por uma opção mais conservadora.",
				"value": 2
			},
			{
				"resposta": "Não aplicaria neste tipo de investimento.",
				"value": 0
			}
		]
	},
	{
		"pergunta":"Qual seria a sua reação ao comprar ações de alguma empresa lucrativa que, logo em seguida, perdeu significativamente o valor na bolsa de valores, sem motivo aparente?",
		"respostas":[
			{
				"resposta": "Não compro ações.",
				"value": 0
			},
			{
				"resposta": "Venderia assim que começasse a cair, ou seja, ao menor sinal de perda, já passaria as ações para frente.",
				"value": 1
			},
			{
				"resposta": "Venderia apenas se o valor caísse muito. Depois de uma perda muito grande, venderia para não correr mais risco.",
				"value": 2
			},
			{
				"resposta": "Esperaria na tentativa de recuperar, um dia, o investimento feito.",
				"value": 4
			},
			{
				"resposta": "Compraria mais ações.",
				"value": 8
			}
		]
	},
	{
		"pergunta":"Quando investe algum dinheiro, você:",
		"respostas":[
			{
				"resposta": "Sabe onde buscar informações sobre  investimentos e avalia o rendimento obtido nos últimos meses.",
				"value": 4
			},
			{
				"resposta": "Tenta se informar por conta própria sobre o investimento e avalia o rendimento obtido nos últimos meses.",
				"value": 3
			},
			{
				"resposta": "Confia na indicação dos amigos e só olha a rentabilidade atual.",
				"value": 2
			},
			{
				"resposta": "Segue a recomendação de um especialista.",
				"value": 1
			}
		]
	}
]