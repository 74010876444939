import { Injectable } from '@angular/core';
import { BaseStorageService } from './base-storage.service';

/**
 * Storage service for Session Storage management.
 */
@Injectable()
export class SessionStorageService extends BaseStorageService {
    constructor() {
        super(window.sessionStorage)
    }
}