import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-prorrogacao-adiantamento-parcial',
    templateUrl: './prorrogacao-adiantamento-parcial.component.html',
    styleUrls: ['./prorrogacao-adiantamento-parcial.component.css']
})
export class ProrrogacaoAdiantamentoParcialComponent implements OnInit {
    constructor(private router: Router){

    }

    ngOnInit() {

	}

    goToForm (){
        this.router.navigate(['/painel/plano-cd/solicitacoes/prorrogacao-adiantamento-parcial'])
    }
}