import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StatusApiModel } from '../models/status-api.model';

@Injectable()
export class ServicoIndisponivelGuard implements CanActivate {

    constructor(private httpClient: HttpClient, private router: Router, @Inject('environment') private environment) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.httpClient.get<StatusApiModel>(`${this.environment.API_LOGIN}/api/StatusApi`)
            .subscribe(s => {
                if (s.servidorIndisponivel)
                    this.router.navigate(['/503']);
            });

        return true;
    }
}
