import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'app-adiantamento-abono',
    templateUrl: './adiantamento-abono.component.html',
    styleUrls: ['./adiantamento-abono.component.css']
})
export class AdiantamentoAbonoComponent implements OnInit {
    constructor(private router: Router){

    }

    ngOnInit() {

	}

    goToForm (){
        this.router.navigate(['/painel/plano-cd/solicitacoes/adiantamento-abono'])
    }
}
