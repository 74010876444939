import { Component, OnInit, Input, Output } from '@angular/core';
import {
    GestaoPlanoContribuicaoService
} from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-tela-contribuicao',
    templateUrl: './tela-contribuicao.component.html'
})
export class TelaContribuicaoComponent implements OnInit {
    show = false;
    ano: number;

    constructor(
        private saldoContribuicaoService: GestaoPlanoContribuicaoService
    ) {
        const dataAtual = new Date();
        this.ano = dataAtual.getFullYear();
     }

    ngOnInit() {
       /* this.saldoContribuicaoService.Extrato_ExtratoContribuicaoPorAno(this.ano).subscribe(() => {
            this.show = true;
        }, httpError => {
            if(httpError.status === 400)
            {
                this.show = false;
                return;
            }
        });*/
    }
}
