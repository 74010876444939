import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-pgto-extra',
  templateUrl: './info-pgto-extra.component.html'
})
export class InfoPgtoExtraComponent implements OnInit {

  // tslint:disable-next-line:no-empty
  constructor() {
   }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
  }

}
