import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, TermoAdesaoService, GestaoPlanoCadastroService, DadosCadastraisService, AdesaoService, ETipoPlano, ArquivosService, TipoSeDocumento } from '@fibra/fibra-shared-lib';
import { CadastroGeral, PalavraInvalida, inputChange, isString, InfoTermo } from '@fibra/fibra-shared-lib';
import { TELEFONE, CELULAR, CEP } from '@fibra/fibra-shared-lib';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-cadastrais-sinqia.dto';
import { Endereco } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/endereco';
import { Contato } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contato';
import { ContaBancaria } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/conta-bancaria';
import { ComprovanteBancarioModel } from '@fibra/fibra-shared-lib/lib/models/comprovante-bancario.model';
import { FileUpload } from 'primeng/fileupload';
import { DadosFibraDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-fibra.dto';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';

@Component({
    selector: 'app-cadastro',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {

    readonly InfoTermo = InfoTermo;
    role: string[] = [];

    isLoading = true;
    desabilitaBotaoAlterar = true;
    habilitarCamposFormularioContato = true;
    habilitarCamposFormularioEndereco = true;
    habilitarCamposFormularioDadosBancario = true;
    palavraNaoPermitida: string | boolean = false;

    logradouroValue = '';
    errorMessage: string;
    errorImageMSF: string;
    enderecoDescricao = '';


    enderecoGroup: FormGroup;
    contatoGroup: FormGroup;
    contaBancariaGroup: FormGroup;

    dataDeAposentadoria: Date;

    contagemRegressiva = 3;
    isModalVisible = false;
    enderecoInvalido = false;
    isSuccessDialogVisible = false;

    validForm: boolean;
    showUpload: boolean;
    isPdfFile: boolean;
    isInvalidFormat: boolean;
    hasTermoAdesao: boolean;
    habilitarDataAposentadoria: boolean;

    readonly TELEFONE = TELEFONE;
    readonly CELULAR = CELULAR;
    readonly CEP = CEP;

    imageSrc: any;
    file: any;
    termoHTML: any;
    pdfLink: any;
    setIntervalId: any;
    logradouro: any;

    cadastroGeral: CadastroGeral;
    dadosCadastraisSinqiaDto: DadosCadastraisSinqiaDto;
    dadosFibra: DadosFibraDto;
    endereco: Endereco;
    contato: Contato
    contaBancaria: ContaBancaria;
    adesao: ResumoAdesaoDto;
    indiceReajusteExists: boolean;
    indiceReajuste: string = "";

    //#region Upload Comprovante Bancário
    uploadedFiles: any[] = [];
    @ViewChild('fileUpload') fileUpload: FileUpload;
    //#endregion

    constructor(private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private formBuilder: FormBuilder, private authService: AuthService,
        private termoAdesaoService: TermoAdesaoService,
        private dadosCadastraisService: DadosCadastraisService,
        private adesaoService: AdesaoService,
        private arquivosService: ArquivosService,
    ) { this.errorImageMSF = ''; }

    ngOnInit() {
        this.carregarCadastro();
        this.assinouTermoAdesao();

        if(this.adesao.tipoSituacao == "Aposentado" || this.adesao.tipoSituacao == 'Pensionista'){
            this.checkIndiceFinanceiro();
        }
    }

    carregarCadastro() {
        this.dadosCadastraisService.getCadastroGeral(true).subscribe(info => {
            this.dadosCadastraisSinqiaDto = info;
            this.adesao = this.adesaoService.getAdesao(ETipoPlano.PlanoBD);
            this.dadosFibra = this.adesao.dadosFibra;

            this.indiceReajuste = '';
            if (this.adesao.migracoes.length > 0)
                this.indiceReajuste = this.adesao.migracoes[0].saldamentoModel?.indiceReajuste?.nome;

            this.endereco = info.endereco;
            this.contato = info.contato;
            this.contaBancaria = info.contaBancaria;
            this.enderecoDescricao = this.montarDescricaoEndereco(info.endereco);
            this.logradouro = this.endereco.logradouro;
            this.logradouroValue = this.logradourValorAtual(this.endereco.tipoLogradouro);
            this.buildForm();
            this.isLoading = false;
        }, (error) => this.handleError(error, 'Ocorreu um erro ao carregar os dados cadastrais.'));
    }

    assinouTermoAdesao() {
        this.termoAdesaoService.isTermAccepted(true).subscribe(dataTermo => {
            if (dataTermo) {
                this.hasTermoAdesao = true;
            } else {
                this.hasTermoAdesao = false;
            }
        });
    }

    checkIndiceFinanceiro(){
        var cpf = this.adesao.cpf;

        if(this.adesao.tipoSituacao == 'Pensionista'){
            var dados_user = JSON.parse(localStorage.getItem('dados_cadastrais_perfil'));

            cpf = dados_user.usuario;
        }

        this.arquivosService.getIndiceFinanceiro(cpf, true).subscribe(
            () => {
                this.indiceReajusteExists = true;
            },(error: any) => {
                this.indiceReajusteExists = false;
            }
        );
    }

    getDownloadIndiceFinanceiro() {
        var cpf = this.adesao.cpf;

        if(this.adesao.tipoSituacao == 'Pensionista'){
            var dados_user = JSON.parse(localStorage.getItem('dados_cadastrais_perfil'));
            cpf = dados_user.usuario;
        }

        this.arquivosService.getIndiceFinanceiro(cpf, false, 
            () => {
                console.log('Arquivo baixado com sucesso');
            }, 
            error => {
                console.error('Erro ao baixar o arquivo', error);
            });
    }

    handleError(error: any, message: string) {
        this.isLoading = false;
        this.isModalVisible = true;
        this.errorMessage = message ? message : 'Desculpe ocorreu um erro! Contate o suporte.';
        this.isSuccessDialogVisible = false;
    }

    onSubmitEndereco() {
        if (!this.enderecoGroup.valid) {
            return;
        }
        this.isLoading = true;
        let enderecoForm = this.enderecoGroup.value;
        let enderecoAtual: Endereco = {
            idEndereco: this.endereco.idEndereco,
            bairro: enderecoForm.bairro,
            cep: enderecoForm.cep,
            complemento: enderecoForm.complemento,
            cidade: {
                id: this.endereco.cidade.id,
                nome: enderecoForm.cidade,
                siglaUF: enderecoForm.estado
            },
            logradouro: enderecoForm.logradouro,
            numero: enderecoForm.numero
        };

        this.gestaoPlanoCadastroService
            .atualizarEndereco(this.dadosCadastraisSinqiaDto.id, enderecoAtual)
            .subscribe((endereco: Endereco) => {
                this.endereco = endereco;
                this.dadosCadastraisSinqiaDto.endereco = endereco;
                this.carregarEndereco(this.endereco);
                this.enderecoDescricao = this.montarDescricaoEndereco(this.endereco);
                this.habilitarCamposEndereco();
                this.isLoading = false;
            }, (error) => this.handleError(error, 'Não foi possível salvar o endereco! Contate o suporte.'));
    }

    onSubmitContato() {
        // TODO: Apresentar mensagem de form inválido.
        if (!this.contatoGroup.valid)
            return;
        this.isLoading = true;
        let contatoForm = this.contatoGroup.value;
        let contatoAtual: Contato = {
            idContato: this.contato.idContato,
            email: contatoForm.email,
            celular: contatoForm.celular,
            telefone: contatoForm.telefone,
        };

        this.gestaoPlanoCadastroService.atualizarContato(this.dadosCadastraisSinqiaDto.id, contatoAtual)
            .subscribe(contato => {
                this.contato = this.dadosCadastraisSinqiaDto.contato = contato;
                this.habilitarCamposContato();
                this.isLoading = false;
            }, (error) => this.handleError(error, 'Não foi possível salvar os contatos! Contate o suporte.'));
    }

    onSubmitBancario() {
        this.isLoading = true;
        let formData = new FormData();
        let comprovanteBancario: ComprovanteBancarioModel = {
            idAdesao: this.dadosCadastraisSinqiaDto.id,
            registroFibra: Number(this.dadosFibra.registroFibra),
            nomeParticipante: this.dadosCadastraisSinqiaDto.nome,
            cpfParticipante: this.dadosCadastraisSinqiaDto.cpf
        };
        formData.append('comprovante_bancario', JSON.stringify(comprovanteBancario));

        if (this.fileUpload.hasFiles()) {
            this.fileUpload.files.forEach((file, index) => {
                formData.append('anexo_' + index, file);
            });
        }

        this.gestaoPlanoCadastroService.atualizarContaBancaria(this.dadosCadastraisSinqiaDto.id, formData)
            .subscribe(resultado => {
                this.isLoading = false;
                this.habilitarCamposDadosBancarios();
                this.isModalVisible = true;
                // TODO: Revisar -> Variável de mensagem de erro armazenando mensagem de sucesso.
                this.errorMessage = 'Comprovante bancário enviado com sucesso!.';
                this.isSuccessDialogVisible = true;
                this.acionarContagemRegressiva();
            }, (error) => this.handleError(error, 'Não foi possível salvar os dados bancários! Contate o suporte.'));
    }

    habilitarCamposEndereco() {
        this.enderecoInvalido = false;
        this.habilitarCamposFormularioEndereco = !this.habilitarCamposFormularioEndereco;
        this.desabilitaBotaoAlterar = !this.desabilitaBotaoAlterar;
    }

    habilitarCamposContato() {
        this.habilitarCamposFormularioContato = !this.habilitarCamposFormularioContato;
        this.desabilitaBotaoAlterar = !this.desabilitaBotaoAlterar;
    }

    habilitarCamposDadosBancarios() {
        this.habilitarCamposFormularioDadosBancario = !this.habilitarCamposFormularioDadosBancario;
        this.desabilitaBotaoAlterar = !this.desabilitaBotaoAlterar;
    }

    buildForm(): void {
        this.enderecoGroup = this.formBuilder.group({
            logradouro: [this.endereco.logradouro],
            endereco: [this.enderecoDescricao],
            numero: [this.endereco.numero],
            complemento: [this.endereco.complemento],
            bairro: [this.endereco.bairro],
            cidade: [this.endereco.cidade.nome, Validators.required],
            estado: [this.endereco.cidade.siglaUF, Validators.required],
            cep: [this.endereco.cep, Validators.compose([
                Validators.required,
                Validators.minLength(CEP.LENGTH),
                Validators.maxLength(CEP.LENGTH)
            ])],
        });

        this.contatoGroup = this.formBuilder.group({
            email: [this.contato.email, Validators.required],
            telefone: [this.contato.telefone, Validators.compose([
                Validators.maxLength(TELEFONE.LENGTH_MAX_MASK),
                Validators.minLength(TELEFONE.LENGTH_MIN_MASK),
            ])],
            celular: [this.contato.celular, Validators.compose([
                Validators.maxLength(CELULAR.LENGTH_MAX_MASK),
                Validators.minLength(CELULAR.LENGTH_MIN_MASK),
            ])]
        });

        this.contaBancariaGroup = this.formBuilder.group({
            nomeBanco: [{ value: this.contaBancaria.banco.nome, disabled: true }, Validators.required],
            cdAgencia: [{ value: this.contaBancaria.agencia, disabled: true }, Validators.required],
            contaBco: [{
                value: this.contaBancaria.numero + '-' + this.contaBancaria.digito, disabled: true
            }, Validators.required],
            fotoConta: [''],
        });
    }

    isValidEndereco(): boolean {
        this.validForm = (this.enderecoGroup.get('cep').valid &&
            this.enderecoGroup.get('cidade').valid &&
            this.enderecoGroup.get('estado').valid);
        return this.validForm;
    }

    isValidContato(): boolean {
        this.validForm = (
            this.contatoGroup.get('email').valid &&
            this.contatoGroup.get('telefone').valid &&
            this.contatoGroup.get('celular').valid
        );

        return this.validForm;
    }

    isValidDadosBancarios(): boolean {
        this.validForm = this.fileUpload != null && this.fileUpload.hasFiles != null && this.fileUpload.hasFiles();
        return this.validForm;
    }

    onfocus() {
        this.enderecoGroup.get('cep').setValue('');
    }

    setValueEndereco() {
        this.enderecoGroup.get('logradouro').setValue('');
        this.enderecoGroup.get('numero').setValue('');
        this.enderecoGroup.get('complemento').setValue('');
    }

    loadAddress(cep: string): void {
        this.enderecoInvalido = false;
        this.isLoading = true;
        this.gestaoPlanoCadastroService.consultaCEPCorreios(cep).subscribe(dadosEndereco => {
            if (dadosEndereco != null) {
                let logradouro = null;
                if (dadosEndereco.logradouro && dadosEndereco.logradouro.descLogradouro && dadosEndereco.endereco)
                    logradouro = dadosEndereco.logradouro.descLogradouro + " " + dadosEndereco.endereco;

                let enderecoTemporario: Endereco = {
                    bairro: dadosEndereco.bairro,
                    cep: dadosEndereco.cep,
                    complemento: dadosEndereco.complemento,
                    cidade: {
                        id: 0,
                        nome: dadosEndereco.cidade,
                        siglaUF: dadosEndereco.estado
                    },
                    logradouro: logradouro,
                    tipoLogradouro: "",
                    numero: ''
                };
                this.carregarEndereco(enderecoTemporario);
                this.isLoading = false;
            } else {
                this.enderecoGroup.reset();
                this.gestaoPlanoCadastroService.addDescricaoLogradouro('');
                this.isLoading = false;
            }
        }, (error) => {
            this.handleError(error, 'Ops!. Serviço dos Correios indísponivel ou CEP inválido.');
            this.gestaoPlanoCadastroService.addDescricaoLogradouro('');
            this.enderecoGroup.reset();
            this.isLoading = false;
        });
    }

    carregarEndereco(endereco: Endereco) {
        this.enderecoGroup.patchValue(
            {
                logradouro: endereco.logradouro,
                numero: endereco.numero,
                complemento: endereco.complemento,
                bairro: endereco.bairro,
                cidade: endereco.cidade.nome,
                estado: endereco.cidade.siglaUF,
                cep: endereco.cep
            }
        );
        this.gestaoPlanoCadastroService.addDescricaoLogradouro(endereco.tipoLogradouro);
    }

    carregarContato(contato: Contato) {
        this.contatoGroup.patchValue({
            email: contato.email,
            telefone: contato.telefone,
            celular: contato.celular
        });
    }

    carregarContaBancaria(contaBancaria: ContaBancaria) {
        this.contatoGroup.patchValue({
            nomeBanco: contaBancaria.banco.nome,
            cdAgencia: contaBancaria.agencia,
            contaBco: contaBancaria.numero
        });
    }

    handleInputChange(e) {
        this.errorImageMSF = '';
        this.imageSrc = null;
        this.isPdfFile = false;
        this.isInvalidFormat = false;

        const reader = new FileReader();

        this.file = inputChange(e);

        this.errorImageMSF = isString(this.file) ? this.file : '';

        if (this.errorImageMSF === InfoTermo.FORMATO_INVALIDO) {
            this.isInvalidFormat = true;
        }

        if (!isString(this.file)) {
            this.isPdfFile = this.file.name.toLowerCase().endsWith('pdf');
        }

        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(this.file);

    }

    handleReaderLoaded(e) {
        const reader = e.target;
        this.imageSrc = reader.result;
        this.cadastroGeral.bancoCadastro.fotoDaConta = this.imageSrc;
    }

    removeImage(): void {
        this.errorImageMSF = '';
        this.imageSrc = '';
        this.isPdfFile = false;
        this.isInvalidFormat = false;
        this.cadastroGeral.bancoCadastro.fotoDaConta = '';
        if (this.showUpload) {
            this.contaBancariaGroup.get('fotoConta').setValue(null);
        }
    }

    acionarContagemRegressiva() {
        this.setIntervalId = setInterval(() => {
            this.contagemRegressiva--;
            if (this.contagemRegressiva === 0) {
                this.limparModal();
            } else if (this.contagemRegressiva < 0) {
                this.limparModal();
            }
        }, 1000);
    }

    limparModal() {
        clearInterval(this.setIntervalId);
        this.isSuccessDialogVisible = false;
        this.isModalVisible = false;
        this.contagemRegressiva = 3;
    }

    montarDescricaoEndereco(endereco: Endereco) {
        return (endereco.logradouro ? endereco.logradouro : '')
    }

    alterarEndereco(endereco: Endereco) {
        this.carregarEndereco(endereco);
        this.habilitarCamposEndereco();
    }

    alterarContato(contato: Contato) {
        this.carregarContato(contato);
        this.habilitarCamposContato();
    }

    alterarContaBancaria(contaBancaria: ContaBancaria) {
        this.carregarContaBancaria(contaBancaria);
        this.habilitarCamposDadosBancarios();
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    reciverFeedback(respostaCodigoLogradouro: string) {

        if (this.enderecoInvalido) {
            this.enderecoInvalido = false;
            this.enderecoGroup.get('endereco').setValue('');
        }
        this.endereco.tipoLogradouro = respostaCodigoLogradouro === '' ? null : respostaCodigoLogradouro;
        this.logradouroValue = this.endereco.tipoLogradouro;
    }

    carregarLogradouro() {
        this.gestaoPlanoCadastroService.Cadastros_GetLogradouro().subscribe(info => {
            this.logradouro = info;
        });
    }

    logradourValorAtual(logradouro: string): string {
        return logradouro == null ? '' : logradouro;
    }


    limparCampoEndereco(endereco: string) {
        this.palavraNaoPermitida = PalavraInvalida(endereco, this.logradouro);
    }

}
