import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

import { GestaoPlanoBeneficioService } from "@fibra/fibra-shared-lib";
import { ServicoTotal, ServicoModel } from "@fibra/fibra-shared-lib/lib/models";
import { MessageService } from "primeng/api";

@Component({
    selector: "app-tela-inss",
    templateUrl: "./tela-inss.component.html",
    styleUrls: ["./tela-inss.component.css"],
})
export class TelaInssComponent implements OnInit {
    isLoading: boolean;

    servicoTotal: ServicoTotal;
    listaServicosComun: ServicoModel[] = [];
    listaServicosEspecial: ServicoModel[] = [];
    listaConcomitante: ServicoModel[] = [];
    listaAtual: ServicoModel[] = [];
    listaAcomprovar: ServicoModel[] = [];

    anos = 0;
    meses = 0;
    dias = 0;
    anosE = 0;
    mesesE = 0;
    diasE = 0;

    totalComum = "";
    totalEspecial = "";
    total = "";

    constructor(
        private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService
            .Beneficios_GetInss()
            .subscribe((data) => {
                this.servicoTotal = data;

                if (this.servicoTotal && this.servicoTotal.servicos) {
                    this.listaServicosComun = this.servicoTotal.servicos.filter(
                        (d) =>
                            d.tipoAposentadoria === "Comum" ||
                            !d.tipoAposentadoria
                    );

                    this.listaServicosEspecial =
                        this.servicoTotal.servicos.filter(
                            (d) => d.tipoAposentadoria === "Especial"
                        );

                    this.listaConcomitante = this.servicoTotal.servicos.filter(
                        (d) => d.tipoAposentadoria === "Concomitante"
                    );

                    this.listaAtual = this.servicoTotal.servicos.filter(
                        (d) => d.tipoAposentadoria === "Atual"
                    );

                    this.listaAcomprovar = this.servicoTotal.servicos.filter(
                        (d) => d.tipoAposentadoria === "Comprometido"
                    );
                }
                this.isLoading = false;
            }, (error: HttpErrorResponse) => {
                this.isLoading = false;
            });
    }
}
