import { Endereco } from "./endereco";
import { ETipoDependente } from "../../enums"

export class DependenteBeneficiarioModel {
    tipoDependente: ETipoDependente | null = null;
    codigo?: string = crypto.randomUUID();
    nome?: string | null = null;
    cpf?: string | null = null;
    nascimento?: Date;
    grauParentesco?: string | null = null;;
    percentualDesignado?: number;
    email?: string | null = null;;
    endereco?: Endereco | null = null;
}