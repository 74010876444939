import { Component } from '@angular/core';
import { ArquivosService, TipoSeDocumento } from '@fibra/fibra-shared-lib';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-seguro-vida',
    templateUrl: './seguro-vida.component.html',
})
export class SeguroVidaComponent {
    isLoading = false;

    constructor(private arqService: ArquivosService, private messageService: MessageService) { }

    DownloadFormulario() {
        this.isLoading = true;
        this.arqService.getSeDocs(TipoSeDocumento.SeguDeVida,
            () => this.isLoading = false, this, () => {
                this.isLoading = false;
                this.messageService.clear();
                this.messageService.add({
                    key: 'seguro-vida',
                    severity: 'error',
                    summary: 'Erro',
                    detail: `Erro ao baixar o formulário.`
                });
            });
    }
}
