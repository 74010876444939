import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AdesaoCdService } from '@fibra/fibra-shared-lib';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdesaoCdGuard implements CanActivate {

    constructor(private adesaoService: AdesaoCdService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let solicitacaoAde = this.adesaoService.getSolicitacaoAdesao();
        if (solicitacaoAde == null) {
            this.router.navigate(["/painel"]);
            return false;
        }
        return true;
    }

}
