import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export class ValidatorExtention {

    static dateValidator(formats: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const dataNasc = control.value as string;
            for (var format of formats) {
                if (moment(dataNasc, format, true).isValid()) {
                    return null;
                }
            }
            return { dateValidate: true };
        };
    }
}