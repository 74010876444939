import { Injectable } from '@angular/core';
import { BeneficiarioResponseDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class BeneficiarioMockService {
  getBeneficiariosMock(): Observable<BeneficiarioResponseDto[]> {

    const mockBeneficiarios: BeneficiarioResponseDto[] = [
      {
        id: 1,
        idAdesao: 101,
        nome: 'João Silva',
        dataNascimento: new Date('1990-01-01'),
        grauParentesco: 'Filho',
        percentualParticipacao: 50,
        fator: 1,
        cpf: '49337426005',
        sexo: 'Masculino',
        escolaridade: 'Superior Completo',
        tipoDependencia: 'Dependente',
        tipoDependenciaId: 1,
        cPFRepresentanteLegal: '000.111.222-33',
        nomeRepresentanteLegal: 'Pedro Silva',
        remover: false,
        relacaoDependencia: {
          descricao: 'Filho menor de 21 anos',
          id: 1
        }
      },
      {
        id: 2,
        idAdesao: 102,
        nome: 'Maria Oliveira',
        dataNascimento: new Date('1985-05-15'),
        grauParentesco: 'Cônjuge',
        percentualParticipacao: 50,
        fator: 1,
        cpf: '52100578952',
        sexo: 'Feminino',
        escolaridade: 'Médio Completo',
        tipoDependencia: 'Dependente',
        tipoDependenciaId: 2,
        cPFRepresentanteLegal: '',
        nomeRepresentanteLegal: '',
        remover: false,
        relacaoDependencia: {
          descricao: 'Cônjuge',
          id: 2
        }
      }
    ];
  
    return of(mockBeneficiarios);
  }
}

