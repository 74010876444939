import { ServicoModel } from "./servico-model";

export class ServicoTotal {
    subTotal?: string;
    total?: string;
    totalComum?: string;
    totalEspecial?: string;
    tempoAComprovar?: string;
    tempoTotalEmprego?: string;
    servicos: ServicoModel[] = [];
}
