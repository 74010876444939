import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MenuItem} from 'primeng/api';
import SimuladorCd from '../../functions/simulador';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { CRESCI_SALARIAL }  from '../../functions/assets/crescimento-salarial';
import  { FAIXAS_SALARIAL } from '../../functions/assets/faixas';
import  {URF} from '../../functions/assets/urf';
import { AlertModalComponent } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.css']
})
export class AdesaoSimuladorComponent implements OnInit {
	@ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
	items: MenuItem[];

	constructor() {


	}

	ngOnInit() {
	}
}
