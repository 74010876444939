import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
    selector: 'app-reajuste-beneficio-card',
    templateUrl: './reajuste-beneficio-card.component.html',
    styleUrls: ['./reajuste-beneficio-card.component.css']
})
export class ReajusteBeneficioCardComponent implements OnInit {
    show = true;
    mensagemDeErro: string;
    isLoading = false;
    ano: number;

    constructor(
    ) {
    }

    ngOnInit() {
        
    }
}
