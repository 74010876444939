<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="col-md-6">
            <div class="card text-center">
                <div class="card-header text-white bg-danger">
                    <h1>Página Não Encontrada</h1>
                </div>
                <div class="card-body">
                    <p>
                        <img class="img-responsive" src="/assets/warning.svg" />
                    </p>
                    <p>Não conseguimos encontrar a página que você solicitou.</p>
                    <p>Por favor, verifique se o endereço está correto.</p>
                    <p>
                        <a [routerLink]="'/painel'">&lt;&lt; Voltar Para a Tela Inicial</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>