import { Component, OnInit } from '@angular/core';

import { GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-informe-rendimento',
  templateUrl: './informe-rendimento.component.html',
  styleUrls: ['./informe-rendimento.component.css']
})
export class InformeRendimentoComponent {

    isLoading = false;
    anos : Anos[];
    anoSelecionado : number;
    mensagemDeErro: string;
    bloquearInforme: boolean;

    constructor(private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService) {
        this.anos =[
            {label:'2023', value:2023},
            {label:'2022', value:2022},
            {label:'2021', value:2021}
            ];

    }
    ObterInformeRendimento(ano: number) {
        this.isLoading = true;
        this.gestaoPlanoBeneficioService.Beneficios_InformeRendimentos(ano, () => {
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Caso encontre dificuldades na geração do seu Informe de Rendimentos, entre em contato com a Fibra: telefone e whatsapp 0800 041 4404 / e-mail atendimento@fundacaoitaipu.com.br';
        this.bloquearSimuladorEInformarTerminoDeCarregamento();
    }

    private bloquearSimuladorEInformarTerminoDeCarregamento() {
        this.bloquearInforme = true;
        this.isLoading = false;
    }
}

class Anos{
    label: string;
    value: number;
}
