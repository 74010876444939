import { ValidatorFn, AbstractControl } from "@angular/forms";
import { ValidationErrors } from '@angular/forms';
import { validarCep } from "../functions/util";


export class CustomValidators {

    static CPF: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

        if (!control.value) return null;

        const cpf = new OnlyNumbers(control.value).format();

        let error: ValidationErrors = { 'cpf': { value: control.value } };

        if (cpf === '') { return null }

        if (cpf.length !== 11 || cpf === '') { return error }

        // Elimina CPFs invalidos conhecidos
        if (cpf.length !== 11 ||
            cpf === '00000000000' ||
            cpf === '11111111111' ||
            cpf === '22222222222' ||
            cpf === '33333333333' ||
            cpf === '44444444444' ||
            cpf === '55555555555' ||
            cpf === '66666666666' ||
            cpf === '77777777777' ||
            cpf === '88888888888' ||
            cpf === '99999999999') { return error; }

        let add = 0;
        // tslint:disable-next-line:radix
        for (let i = 0; i < 9; i++) { add += parseInt(cpf.charAt(i)) * (10 - i); }
        let rev = 11 - (add % 11);

        if (rev === 10 || rev === 11) { rev = 0; }

        // tslint:disable-next-line:radix
        if (rev !== parseInt(cpf.charAt(9))) { return error; }

        add = 0;
        // tslint:disable-next-line:radix
        for (let i = 0; i < 10; i++) { add += parseInt(cpf.charAt(i)) * (11 - i); }
        rev = 11 - (add % 11);

        if (rev === 10 || rev === 11) { rev = 0; }

        // tslint:disable-next-line:radix
        if (rev !== parseInt(cpf.charAt(10))) { return error; }

        return null;
    }


    static fullname(minLength: number): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {

            let name: string = control.value?.toString();

            let error: ValidationErrors = { 'fullname': { value: control.value } };

            if (name === '' || name == null) { return error }

            let arr = name.trim().split(/ /g);

            arr = arr.filter((v) => v.trim() != '');

            return arr.length >= minLength ? null : error;
        }
    }

    static fullnameOptional(minLength: number): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {

            let name: string = control.value?.toString();
            if (name == null)
                return null;

            let error: ValidationErrors = { 'fullname': { value: control.value } };

            if (name === '' || name == null) { return error }

            let arr = name.trim().split(/ /g);

            arr = arr.filter((v) => v.trim() != '');

            return arr.length >= minLength ? null : error;
        }
    }


    static date: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
        const dateStr = control.value;
        // Length of months (will update for leap years)
        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // Object to return if date is invalid
        const invalidObj = { 'date': true };

        const DATE_REGEX = new RegExp(/^(\d{2}|\d{1})\/(\d{2}|\d{1})\/\d{4}$/);


        // First check for m/d/yyyy or mm/dd/yyyy format
        // If the pattern is wrong, don't validate dates yet
        if (!DATE_REGEX.test(dateStr)) {
            return null;
        }

        // Parse the date input to integers
        const parts = dateStr.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);

        // Make sure date is in range
        if (year < 1900 || year > 3000 || month === 0 || month > 12) {
            return invalidObj;
        }
        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }
        // Check the range of the day
        if (!(day > 0 && day <= monthLength[month - 1])) {
            return invalidObj;
        };

        return null;
    }


    static different(toCompare: { value: string }): ValidatorFn {

        return (control: AbstractControl): ValidationErrors | null => {

            let value: string = control.value?.toString();

            let error: ValidationErrors = { 'different': { value: control.value } };

            if (value === '' || value == null || value != toCompare.value) { return error }

            return null;
        }
    }

    static conditional(predicate, validator) {
        return (formControl => {
            if (!formControl.parent) {
                return null;
            }
            if (predicate()) {
                return validator(formControl);
            }
            return null;
        });
    }

    static notEmptyValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value === null || value === undefined || value === '' || value?.toString().trim() == '') {
                return { required: true };
            }

            return null;
        };
    }

    static Cep(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (!validarCep(value)) {
                return { invalid: true };
            }

            return null;
        };
    }

    static allowedValuesValidator(allowedValues: string[]): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          const value = control.value;
          if (allowedValues.includes(value)) {
            return null;
          }
          return { allowedValues: true };
        };
      }
}

export interface Formatter {
    format(): string;
}

export class OnlyNumbers implements Formatter {

    constructor(private value: any) { }

    format(): any {
        if (typeof (this.value) === 'string') {
            return this.value.replace(/[^\d]+/g, '');
        }
        return this.value;
    }

}
