import { Component, OnInit } from '@angular/core';

import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';
import { forkJoin } from 'rxjs';
import { GestaoFinanceiraEmprestimoServiceCadastroGetContratoAtivoResponse } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-download-contrato-card',
    templateUrl: './download-contrato-card.component.html'
})
export class DownloadContratoCardComponent implements OnInit {
    possuiEmprestimoPendente = false;
    isLoading = false;
    ultimoContratoAtivo: GestaoFinanceiraEmprestimoServiceCadastroGetContratoAtivoResponse;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        // forkJoin(
        //     this.gestaoFinanceiraEmprestimoService.Emprestimo_VerificaEmprestimoPendente(),
        //     this.gestaoFinanceiraEmprestimoService.Cadastro_GetContratoAtivo()
        // ).subscribe(([
        //     possuiEmprestimoPendente,
        //     ultimoContratoAtivo
        //  ]) => {
        //     this.possuiEmprestimoPendente = possuiEmprestimoPendente;
        //     this.ultimoContratoAtivo = ultimoContratoAtivo;
        // });
    }

    baixarContrato() {
        this.isLoading = true;

        // this.gestaoFinanceiraEmprestimoService.Emprestimo_DownloadContrato(this.ultimoContratoAtivo.modalidadePlus, () => {
        //     this.isLoading = false;
        // }, this);
    }
}
