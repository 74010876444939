import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';
import { Observable } from 'rxjs';

@Injectable()
export class LogService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_LOG;
    }

    Log_GetUltimoAcesso(): Observable<any> {
        const url = `${this.url}/Logs/GetUltimoAcesso`;
        return this.cachedGet<any>(url);
    }

    Menu_GetJson(): Observable<any> {
        const url = `${this.url}/Menu/ObtemMenu`;
        return this.cachedGet<any>(url);
    }
}
