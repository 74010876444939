import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
    AdesaoCdService, AdesaoService, ETipoPlano, FileUploadModel, GestaoPlanoCadastroService,
    SolicitacaoAdesaoCdModel
} from '@fibra/fibra-shared-lib';
import { DependenteDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dependente-dto';
import { ETipoDependente } from '@fibra/fibra-shared-lib';
import * as _ from "lodash";
import { DependenteModel } from '../../models/dependente.model';


@Component({
    selector: 'app-dependente-adesao',
    templateUrl: './dependente-adesao.component.html',
    styleUrls: ['./dependente-adesao.component.css']
})
export class DependenteAdesaoComponent implements OnInit {
    solicitacaoAdesao: SolicitacaoAdesaoCdModel = null;
    dependentes: Array<DependenteModel> = [];
    eTipoDependente = ETipoDependente;
    isGridBeneficiarioLoading: boolean = false;
    isGridDesignadoLoading: boolean = false;

    get dependentesGrid(): DependenteModel[] {
        let dependentes = this.dependentes?.filter(x => x.tipoDependente == ETipoDependente.Beneficiario);
        if (dependentes)
            return _.sortBy(dependentes, 'nome');
        return dependentes;
    }

    get designadosGrid() {
        let designados = this.dependentes?.filter(x => x.tipoDependente == ETipoDependente.Designado);
        if (designados)
            return _.sortBy(designados, 'nome');
        return designados;
    }

    constructor(private adesaoCdService: AdesaoCdService,
        private adesaoService: AdesaoService,
        private cadastroService: GestaoPlanoCadastroService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.solicitacaoAdesao = this.adesaoCdService.getSolicitacaoAdesao();

        if (_.isEmpty(this.solicitacaoAdesao.dependentes))
            this.carregarDependentes(ETipoDependente.Beneficiario, ETipoDependente.Designado);
        else
            this.dependentes = this.converterDependenteDtoParaModel(this.solicitacaoAdesao.dependentes);
    }

    private carregarDependentes(...tipoDependente: ETipoDependente[]) {
        let promises: Promise<DependenteModel[]>[] = [];

        if (tipoDependente.includes(ETipoDependente.Beneficiario)) {
            promises.push(this.carregarBeneficiarios());
        }

        if (tipoDependente.includes(ETipoDependente.Designado)) {
            promises.push(this.carregarDesignados());
        }

        Promise.all(promises)
            .then(dependentesArr => {
                dependentesArr.forEach(dependentes => {
                    this.adesaoCdService.addDependentes(dependentes as DependenteDto[]);
                });
                this.dependentes = this.converterDependenteDtoParaModel(this.adesaoCdService.getDependentes());
            }, error => {
                console.error(error);
            });
    }

    private carregarBeneficiarios() {
        return new Promise<DependenteModel[]>((resolve, reject) => {
            this.isGridBeneficiarioLoading = true;

            let adesao = this.adesaoService.getAdesao(ETipoPlano.PlanoBD);
            this.cadastroService.getBeneficiarios(adesao?.idAdesao)
                .subscribe(beneficiarios => {
                    let dependentes: DependenteModel[] = [];

                    if (beneficiarios?.length > 0) {
                        dependentes = beneficiarios.map(ben => {
                            let dependente = new DependenteModel();
                            dependente.tipoDependente = ETipoDependente.Beneficiario;
                            dependente.codigo = crypto.randomUUID();
                            dependente.nome = ben.nome;
                            dependente.cpf = ben.cpf;
                            dependente.dataNascimento = new Date(ben.dataNascimento);
                            dependente.grauParentesco = ben.grauParentesco;
                            dependente.sexo = ben?.sexo ? ben?.sexo[0] : '0';
                            dependente.existente = true;
                            return dependente;
                        });
                    }

                    resolve(dependentes);
                    this.isGridBeneficiarioLoading = false;
                }, error => {
                    reject(error);
                }, () => {
                    this.isGridBeneficiarioLoading = false;
                });
        });
    }

    private carregarDesignados() {
        return new Promise<DependenteModel[]>((resolve, reject) => {
            this.isGridDesignadoLoading = true;

            let adesao = this.adesaoService.getAdesao(ETipoPlano.PlanoBD);
            this.cadastroService.getDesignados(adesao?.idAdesao)
                .subscribe(designados => {
                    let dependentes: DependenteModel[] = [];

                    if (designados?.length > 0) {
                        dependentes = designados.map(desig => {
                            let dependente = new DependenteModel();
                            dependente.tipoDependente = ETipoDependente.Designado;
                            dependente.codigo = crypto.randomUUID();
                            dependente.nome = desig.nomeDesignado;
                            dependente.cpf = desig.cpfDesignado;
                            dependente.dataNascimento = new Date(desig.dataNascimento);
                            dependente.percentualParticipacao = desig.percentual;
                            dependente.sexo = desig?.sexo ? desig?.sexo[0] : '0';
                            dependente.existente = true;
                            return dependente;
                        });
                    }

                    this.isGridDesignadoLoading = false;
                    resolve(dependentes);
                }, error => {
                    reject(error);
                }, () => {
                    this.isGridDesignadoLoading = false;
                });
        });
    }

    salvarDependente(dependente: DependenteModel) {
        let sucesso = this.adesaoCdService.addDependente(dependente as DependenteDto);
        this.dependentes = this.converterDependenteDtoParaModel(this.adesaoCdService.getDependentes());
        if (!sucesso) {
            this.messageService.clear();
            this.messageService.add({
                severity: 'warn',
                summary: '',
                detail: `Já existe um cadastro com este nome e CPF na lista.`
            });
        }
    }

    atualizarDependente(dependente: DependenteModel) {
        this.adesaoCdService.atualizarDependente(dependente as DependenteDto);
        this.dependentes = this.converterDependenteDtoParaModel(this.adesaoCdService.getDependentes());
    }

    removerDependente(dependente: DependenteModel) {
        let sucesso = this.adesaoCdService.removerDependente(dependente.codigo);
        this.dependentes = this.converterDependenteDtoParaModel(this.adesaoCdService.getDependentes());
    }

    resetarDados(ev: any, tipoDependente: ETipoDependente) {
        this.adesaoCdService.limparDependentes(tipoDependente);
        this.carregarDependentes(tipoDependente);
    }

    validarPercentualDependente(dependentes: DependenteModel[]) {
        return this.adesaoCdService.validarPercentualDependente(dependentes as DependenteDto[]);
    }

    converterDependenteDtoParaModel(dependenteDto: DependenteDto[]) {
        return dependenteDto.map(depDto => {
            let dependenteModal: DependenteModel = new DependenteModel();
            dependenteModal.codigo = depDto.codigo;
            dependenteModal.cpf = depDto.cpf;
            dependenteModal.dataNascimento = depDto.dataNascimento;
            dependenteModal.email = depDto.email;
            dependenteModal.grauParentesco = depDto.grauParentesco;
            dependenteModal.nome = depDto.nome;
            dependenteModal.percentualParticipacao = depDto.percentualParticipacao;
            dependenteModal.sexo = depDto.sexo;
            dependenteModal.telefone = depDto.telefone;
            dependenteModal.tipoDependente = depDto.tipoDependente;
            dependenteModal.documentos = depDto.documentos;
            dependenteModal.existente = depDto.existente;

            return dependenteModal;
        });
    }

    onFileUpload(event: { dependente: DependenteModel, file: FileUploadModel }) {
        this.adesaoCdService.addDocumentoDependente(event.dependente.codigo, event.file);
    }

    onFileRemoved(event: { dependente: DependenteModel, documentId: string }) {
        this.adesaoCdService.removerDocumentoDependente(event.dependente.codigo, event.documentId);
    }
}
