import { Component, OnInit, Input, Output } from '@angular/core';
import { AdesaoSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { AdesaoCdService, AdesaoService, ESituacaoParticipante } from '@fibra/fibra-shared-lib';
import { ItemReservaSinqiaDto } from "@fibra/fibra-shared-lib/lib/models/sinqia-api/item-reserva-sinqia.dto";
@Component({
    selector: 'app-contribuicao',
    templateUrl: './contribuicao.component.html',
    styleUrls: ['./contribuicao.component.css']
})
export class ContribuicaoCdComponent implements OnInit {
    @Input() mostrarLinkParaExtratoCompleto = true;
    show = true;
    mensagemDeErro: string;
    bloquearContribuicaoSaldo: boolean;
    isLoading = true;
    ano: number = new Date().getFullYear();
    saldoPatrocinador?: Number = 0;
    saldoParticipante?: Number = 0;
    saldoPortabilidade?: Number = 0;
    contribuicaoAnoAtual?: Number = 0;
    contribucaoAnoAnterior?: Number = 0;
    contribuicaoMes?:Number = 0;
    ativo: boolean = false;
    constructor(private adesaoCDService: AdesaoCdService, private adesaoService: AdesaoService)
    {

    }

    ngOnInit() {
        /* Buscar DADOS CD */
        let adesao = this.adesaoService.getAdesaoContexto();
        this.ativo = this.adesaoService.validarSituacao(ESituacaoParticipante.Ativo, adesao.tipoSituacao);
        console.log(adesao);
        this.adesaoCDService.obterExtratoContribuicaoAtivoCD(this.ano).subscribe(contribuicaoAtivoCD => {
            if(contribuicaoAtivoCD)
            {
                this.saldoParticipante = contribuicaoAtivoCD.data.saldoParticipante;
                this.saldoPatrocinador = contribuicaoAtivoCD.data.saldoPatrocinadora
                this.saldoPortabilidade = contribuicaoAtivoCD.data.saldoPortabilidade
                this.contribuicaoAnoAtual = contribuicaoAtivoCD.data.contribuicaoAno;
                this.contribucaoAnoAnterior = contribuicaoAtivoCD.data.contribuicaoAnoAnterior;
                this.contribuicaoMes = contribuicaoAtivoCD.data.contribuicaoMensal;
                this.isLoading = false;
            }
            else{

            }
        })
    }

    private informarErroDeCarregamento() {
        /* Buscar DADOS CD */
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        /* Buscar DADOS CD */
    }

    baixarExtrato(ano: number) {
        /* Buscar DADOS CD */
    }

    trocaAno()
    {
       /* Buscar DADOS CD */
    }
}
