export class GestaoPlanoCadastroServiceTelefonesGetCelularResponseItem {
    indice: number;
    regFibra: number;
    tipo: string;
    ordem: number;
    ddd: string;
    telefone: string;
    observacao: string;
    sms: boolean;
}
