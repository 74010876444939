import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Boleto, DocumentoService, TipoSeDocumento } from '@fibra/fibra-shared-lib';
import { PdfViewerComponent } from './../../../../components/pdf-viewer/pdf-viewer.component';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
    selector: 'app-rentabilidade-card',
    styleUrls: ['./rentabilidade-card.component.css'],
    templateUrl: './rentabilidade-card.component.html'
})
export class RentabilidadeCardComponent implements OnInit, AfterViewInit {
    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;
    erroLeituraExtratoBPS: boolean;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(private documentoService: DocumentoService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.isLoading = true;
        let request = this.documentoService.getSeDocs(TipoSeDocumento.LaminaBD)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                this.erroLeituraExtratoBPS = true;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request);
    }
}
