import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {
    @Input() safeUrlDoc: SafeResourceUrl;
    @Input() headers: HttpHeaders;
    @Input() width: string = '100%';
    @Input() height: string = '600px';

    @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<any> = new EventEmitter<any>();

    isMobile: boolean;

    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, private platform: Platform) { }

    ngOnInit(): void {
        this.isMobile = this.platform.ANDROID || this.platform.IOS;
    }

    fetchPdfData(endpoint: string, openNewTab: boolean = false) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
        const request = this.httpClient.get<ArrayBuffer>(endpoint, {
            headers: headers,
            responseType: 'arraybuffer' as 'json'
        });

        return this.getPdfData(request, openNewTab);
    }

    getPdfData(request: Observable<ArrayBuffer>, openNewTab: boolean = false) {
        return request.subscribe((response: ArrayBuffer) => {
            const pdfBlob = new Blob([response], { type: 'application/pdf' });
            const pdfData = URL.createObjectURL(pdfBlob);
            const safeUrlDoc = this.sanitizer.bypassSecurityTrustResourceUrl(pdfData);
           
            if (openNewTab)
                window.open(pdfData);
            else
            
            this.safeUrlDoc = safeUrlDoc;

            this.onSuccess.emit(true);

        }, error => {
            this.onError.emit(error);
            console.error(error);
        });
    }
}
