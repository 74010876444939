/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheInterceptor } from './cache-interceptor';
import { ServidorIndisponivelInterceptor } from './servidor-indisponivel-interceptor';
import { TwoFactorAuthInterceptor } from './two-factor-auth-interceptor';
import { AdesaoContextoInterceptor } from './adesao-contexto-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ServidorIndisponivelInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TwoFactorAuthInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AdesaoContextoInterceptor,
        multi: true
    }
];