import { Component, Input, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent {
    @Output() close = new EventEmitter<void>();
    @Input() isVisible = false;
    @Output() isVisibleChange = new EventEmitter<boolean>();
    @Input() showCloseButton = true;
    @Input() showCloseIcon = true;
    @Input() exitOnPressEsc = true;
    @Input() exitOnClickOutside = true;
    @Input() onCloseHref: string;
    @Input() buttonCloseMessage = 'Fechar';

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27 && this.exitOnPressEsc) {
            this.closeDialog();
        }
    }
    constructor(@Inject(DOCUMENT) private document: Document, private router: Router) { }

    checarVisibilidade() {
        if (this.isVisible) {
            this.document.body.classList.add('no-scroll');
        }

        return this.isVisible;
    }

    clickOutside() {
        if (this.exitOnClickOutside) {
            this.closeDialog();
        }
    }

    closeDialog() {
        this.isVisible = false;
        this.isVisibleChange.emit(this.isVisible);
        this.close.emit();
        if(this.onCloseHref){
            this.router.navigate([this.onCloseHref]);
        }
    }
}
