import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    GestaoPlanoCadastroService,
    AuthService,
    SessionInitService,
    DadosCadastraisService,
} from "@fibra/fibra-shared-lib";
import { SendEmailCodeModel, SendSmsCodeModel, TwoFactorAuthTokenModel, VerifyCodeModel, VerifyCodeResponseModel }
    from '@fibra/fibra-shared-lib/lib/models/fibra-identity';

@Component({
    selector: 'app-two-factor-auth',
    templateUrl: './two-factor-auth.component.html',
    styleUrls: ['./two-factor-auth.component.css']
})
export class TwoFactorAuthComponent implements OnInit {
    @Input() private display: boolean = true;
    @Input() private checkType: string | undefined = undefined;
    @Output() onSuccess = new EventEmitter<TwoFactorAuthTokenModel>();

    private isLoading: boolean = false;
    private code: number | null = null;
    private userName?: string = null;
    private cpf?: string = null;
    private obfuscatedEmail: string | null = null;
    private obfuscatedPhoneNumber: string | null = null;
    private isVerificationSuccess: boolean = false;
    private isInvalidCode: boolean = false;
    private expiresInSeconds: number = 60;
    private inputCodeDisabled: boolean = false;

    constructor(private dadosCadastraisService: DadosCadastraisService,
        private authService: AuthService,
        private sessionInitService: SessionInitService) { }

    async ngOnInit() {
        try {
            this.setLoading(true);
            this.cpf = (await this.dadosCadastraisService.getCadastroGeral(true).toPromise()).cpf;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    private async selectType(type: string) {
        try {
            this.setLoading(true);
            if (type == 'email') {
                let sendEmailResult = await this.sendEmailCode();
                this.obfuscatedEmail = sendEmailResult.obfuscatedEmail;
            } else if (type == 'sms') {
                var sendSmsResult = await this.sendSmsCode();
                this.obfuscatedPhoneNumber = sendSmsResult.obfuscatedPhoneNumber;
            }
            this.checkType = type;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.code = null;
            this.isVerificationSuccess = false;
            this.isInvalidCode = false;
            this.setLoading(false);
        }
    }

    private goBack() {
        this.code = null;
        this.checkType = undefined;
        this.isVerificationSuccess = false;
        this.isInvalidCode = false;
    }

    private async onCodeChange(code: string) {
        try {
            this.isVerificationSuccess = false;
            this.isInvalidCode = false;
            if (code.length >= 6) {
                this.disableInputCode(true);
                this.setLoading(true);

                await this.verifyCodeAsync(code);
            }
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
            this.disableInputCode(false);
        }
    }

    private async verifyCodeAsync(code: string) {
        let verifyCodeResult = await this.VerifyCodeIdentity(code);
        this.isVerificationSuccess = verifyCodeResult.success;
        this.isInvalidCode = !verifyCodeResult.success;

        if (verifyCodeResult.success) {
            let expires = new Date();
            expires.setSeconds(expires.getSeconds() + this.expiresInSeconds);

            let twoFactorAuthToken: TwoFactorAuthTokenModel = {
                userName: verifyCodeResult.userName,
                success: verifyCodeResult.success,
                code: code,
                expires: expires,
                expiresTime: expires.getTime(),
                expiresIn: this.expiresInSeconds,
                issued: new Date(),
                provider: verifyCodeResult.provider,
                tokenType: 'secury_code'
            };

            this.sessionInitService.sessionSaveTwoFactorAuthToken(twoFactorAuthToken);
            setTimeout(() => {
                this.hide();
                this.goBack();
                this.onSuccess.emit(twoFactorAuthToken);
            }, 3000);
        }
    }

    private disableInputCode(disabled: boolean) {
        this.inputCodeDisabled = disabled;
    }

    private setLoading(loading: boolean) {
        this.isLoading = loading;
    }

    /**
     * Envia código de segurança via e-mail.
     * @returns OK
     */
    async getProviders() {
        try {
            this.setLoading(true);
            let provedores = await this.authService.MultiFactorAuth_Providers(this.cpf).toPromise();
            return provedores;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    /**
     * Envia código de segurança via e-mail.
     * @returns Promise
     */
    private async sendEmailCode() {
        try {
            this.setLoading(true);
            let cpf = this.cpf;
            let sendEmailCode: SendEmailCodeModel = {
                cpf: cpf,
                userName: cpf
            };

            let result = await this.authService.MultiFactorAuth_SendEmailCode(sendEmailCode, this.sessionInitService.plano).toPromise();
            return result;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    /**
     * Envia código de segurança via SMS.
     * @returns Promise
     */
    private async sendSmsCode() {
        try {
            let cpf = this.cpf;
            let sendSmsCode: SendSmsCodeModel = {
                cpf: cpf,
                userName: cpf
            };

            let result = await this.authService.MultiFactorAuth_SendSmsCode(sendSmsCode).toPromise();
            return result;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    /**
     * Verifica código de segurança.
     * @returns Promise
     */
    private async VerifyCodeIdentity(code: string) {
        try {
            this.setLoading(true);
            let cpf = this.cpf;
            let verifyCode: VerifyCodeModel = {
                code: code,
                userName: cpf,
                provider: this.checkType
            };

            let result = await this.authService.MultiFactorAuth_VerifyCode(verifyCode).toPromise();
            return result;
        } catch (e) {
            if (e instanceof HttpErrorResponse)
                console.error('HttpErrorResponse:', e);
            throw e;
        } finally {
            this.setLoading(false);
        }
    }

    hasValidToken() {
        return this.authService.MultiFactorAuth_HasValidToken();
    }

    show() {
        this.display = true;
    }

    hide() {
        this.display = false;
    }
}
