import { Routes } from '@angular/router';

import {
    AcompanhamentoGerencialComponent,
    AcompanhamentoEmprestimoComponent,
    AutopatrocinioComponent,
    BpdComponent,
    ComoFazerComponent,
    BaixarAppComponent,
    //dados cadastrais
    CadastroComponent,
    AlterarSenhaComponent,
    DependentesComponent,
    //extrato bps
    ExtratoBpsComponent,

    DemonstrativoPagamentoComponent,
    FichaFinanceiraComponent,
    InformeRendimentoComponent,
    InicioComponent,
    MorteBeneficiarioComponent,
    PedidoEmprestimoComponent,
    PensaoComponent,
	BeneficioAbonoAnualComponent,
    PerfilComunicacaoComponent,
    PortabilidadeComponent,
    ReclusaoComponent,
    ResgateComponent,
    TelaContribuicaoComponent,
    TelaFibraNumerosComponent,
    TelaPrevisaoAposentadoriaComponent,
    TelaTvfibraComponent,
    TempoEspecialComponent,
    TempoIdadeComponent,
    TempoInvalidezComponent,
    TemporarioMorteComponent,
    TempoServicoComponent,
    //VestingComponent,
    AtuariasComponent,
    NormasComponent,
    SeguroVidaComponent,
    ExtratoAtasComponent,
    PagamentosEmprestimoComponent,
    SaldoIrComponent,
    //IdentificacaoPlanoComponent,
    PainelBdComponent,
    RegulamentoComponent,
    TelaInssComponent,
    //participantes e beneficiarios
    ParticipantesBeneficiariosComponent,
    //informacoes complementares
    TextoInformacoesComplementaresComponent,
    RentabilidadeComponent
} from '.';
import { PlanoContinuidadeDeNegocioComponent } from './plano-continuidade-de-negocio/plano-continuidade-de-negocio.component';
import { SolicitacaoDescontoFolhaComponent } from './familia-itaipu/solicitacao-desconto-folha/solicitacao-desconto-folha.component';
import { DeclaracaoDependenteIrComponent } from './solicitacoes/declaracao-dependente-ir/declaracao-dependente-ir.component';
import { BeneficioAuxilioFuneralComponent } from './solicitacoes/beneficio-auxilio-funeral/beneficio-auxilio-funeral.component';
import { SuplementacaoAposentadoriaComponent } from './solicitacoes/suplementacao-aposentadoria/suplementacao-aposentadoria.component';
import { PessoaPoliticamenteExpostaComponent } from '../geral/solicitacoes/pessoa-politicamente-exposta/pessoa-politicamente-exposta.component';
import { NomeacaoDesignadoComponent } from './solicitacoes/nomeacao-designado/nomeacao-designado.component';
import { ContratoAntecipacaoBeneficioComponent } from './solicitacoes/contrato-antecipacao-beneficio/contrato-antecipacao-beneficio.component';
import { ExclusaoBeneficiarioComponent } from './solicitacoes/exclusao-beneficiario/exclusao-beneficiario.component';
import { CertificadoInscricaoComponent } from './certificado-inscricao/certificado-inscricao.component';
import { ProrrogacaoAdiantamentoParcialComponent } from './solicitacoes/prorrogacao-adiantamento-parcial/prorrogacao-adiantamento-parcial.component';
import { DocSaldamentoPlanoCDComponent } from './doc-saldamento-plano-cd/doc-saldamento-plano-cd.component';

import {
    AuthGuardService, RoleGuardService,
    AssistidoGuardService,
    PensionistaGuardService,
    PensionistaOuAssistidoGuardService,
    AtivoGuardService
} from '@fibra/fibra-shared-lib';
//import { IndiceFinanceiroComponent } from '../indice-financeiro/indice-financeiro.component';
import { AdesaoContextoGuard } from '../../guards/adesao-contexto.guard';
import { IsencaoIrMolestiaGraveComponent } from '../bd/solicitacoes/isencao-ir-molestia-grave/isencao-ir-molestia-grave.component';
import { TempoProgramadaComponent } from '../../components/tempo-programada';
import { MorteAtivoComponent } from '../../components/morte-ativo';
import { MorteAssistidoComponent } from '../../components/morte-assistido';
import { LongevidadeComponent } from '../../components/longevidade';
import { AuxilioFuneralComponent } from '../../components/auxilio-funeral';
import { DeclaracaoTempoServicoComponent } from './solicitacoes/declaracao-tempo-servico/declaracao-tempo-servico.component';
import { SignWidgetComponent } from '../../components/clicksign/sign-widget/sign-widget.component';
import { InscricaoBeneficiarioComponent } from './solicitacoes/inscricao-beneficiario/inscricao-beneficiario.component';


export const BD_ROUTES: Routes = [
    {
        path: 'painel/plano-bd',
        component: PainelBdComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService, AdesaoContextoGuard],
        children: [
            {
                path: 'clicksign-widget',
                component: SignWidgetComponent
            },
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full'
            },
            {
                path: 'informacoes-complementares',
                component: TextoInformacoesComplementaresComponent
            },
            {
                path: 'aposentadoria',
                canActivate: [AtivoGuardService],
                component: TelaPrevisaoAposentadoriaComponent
            },
            {
                path: 'beneficios',
                children: [
                    {
                        path: 'beneficios-previstos',
                        component: RegulamentoComponent
                    },
                    {
                        path: 'aposentadoria-programada',
                        component: TempoProgramadaComponent
                    },
                    {
                        path: 'pensao-morte-ativo',
                        component: MorteAtivoComponent
                    },
                    {
                        path: 'pensao-morte-assistido',
                        component: MorteAssistidoComponent
                    },
                    {
                        path: 'servico',
                        component: TempoServicoComponent
                    },
                    {
                        path: 'idade',
                        component: TempoIdadeComponent
                    },
                    {
                        path: 'invalidez',
                        component: TempoInvalidezComponent
                    },
                    {
                        path: 'longetividade',
                        component: LongevidadeComponent
                    },
                    {
                        path: 'auxilio-funeral',
                        component: AuxilioFuneralComponent
                    },
                    {
                        path: 'especial',
                        component: TempoEspecialComponent
                    },
                    {
                        path: 'morte-beneficiario',
                        component: MorteBeneficiarioComponent
                    },
                    {
                        path: 'temporario-morte',
                        component: TemporarioMorteComponent
                    },
                    {
                        path: 'pensao',
                        component: PensaoComponent
                    },
                    {
                        path: 'reclusao',
                        component: ReclusaoComponent
                    },
                    {
                        path: 'abono-anual',
                        component: BeneficioAbonoAnualComponent
                    }
                ]
            },
            {
                path: 'comunicacao',
                component: PerfilComunicacaoComponent
            },
            {
                path: 'contribuicao',
                canActivate: [AtivoGuardService],
                component: TelaContribuicaoComponent
            },
            {
                path: 'acompanhamento-gerencial',
                component: AcompanhamentoGerencialComponent
            },
            {
                path: 'demonstrativo',
                canActivate: [AssistidoGuardService],
                component: DemonstrativoPagamentoComponent
            },
            {
                path: 'informe-rendimento',
                canActivate: [AssistidoGuardService],
                component: InformeRendimentoComponent
            },
            {
                path: 'desligamento',
                children: [
                    {
                        path: 'autopatrocinio',
                        component: AutopatrocinioComponent
                    },
					{
                        path: 'bpd',
                        component: BpdComponent
                    },
                    {
                        path: 'portabilidade',
                        component: PortabilidadeComponent
                    },
                    {
                        path: 'resgate',
                        component: ResgateComponent
                    },
                    /*{
                        path: 'vesting',
                        component: VestingComponent
                    }*/
                ]
            },
            {
                path: 'emprestimo',
                children: [
                    {
                        path: 'acompanhamento',
                        canActivate: [RoleGuardService],
                        component: AcompanhamentoEmprestimoComponent
                    },
                    {
                        path: 'pedido',
                        component: PedidoEmprestimoComponent
                    },
                    {
                        path: 'pagamentos',
                        canActivate: [RoleGuardService],
                        component: PagamentosEmprestimoComponent
                    },
                    {
                        path: 'saldo-ir',
                        component: SaldoIrComponent
                    }
                ]
            },
            {
                path: 'fibranumeros',
                component: TelaFibraNumerosComponent
            },
            {
                path: 'fichafinanceira',
                canActivate: [AssistidoGuardService],
                component: FichaFinanceiraComponent
            },
            {
                path: 'inicio',
                component: InicioComponent,
            },
            {
                path: 'normas',
                component: NormasComponent
            },
            /*
            {
                path: 'identificacao-plano',
                component: IdentificacaoPlanoComponent
            },
            */
            {
                path: 'seguro-vida',
                canActivate: [AssistidoGuardService],
                component: SeguroVidaComponent
            },
            {
                path: 'extrato-atas',
                component: ExtratoAtasComponent
            },
            {
                path: 'certificado-inscricao',
                component: CertificadoInscricaoComponent
            },
            {
                path: 'solicitacoes',
                children: [
                    {
                        path: 'inscricao-beneficiario',
                        //canActivate: [AtivoGuardService],
                        component: InscricaoBeneficiarioComponent
                    },
                    {
                        path: 'declaracao-dep-ir',
                        //canActivate: [AtivoGuardService],
                        component: DeclaracaoDependenteIrComponent
                    },
                    {
                        path: 'beneficio-auxilio-funeral',
                        //canActivate: [AtivoGuardService],
                        component: BeneficioAuxilioFuneralComponent
                    },
                    {
                        path: 'suplementacao-aposentadoria',
                        canActivate: [AtivoGuardService],
                        component: SuplementacaoAposentadoriaComponent
                    },
                    {
                        path: 'isencao-ir-molestia-grave',
                        //canActivate: [AtivoGuardService],
                        component: IsencaoIrMolestiaGraveComponent
                    },
                    {
                        path: 'pessoa-politicamente-exposta',
                        //canActivate: [AtivoGuardService],
                        component: PessoaPoliticamenteExpostaComponent
                    },/*
                    {
                        path: 'suspensao-contribuicao',
                        //canActivate: [AtivoGuardService],
                        component: SuspensaoContribuicaoComponent
                    },*/
                    {
                        path: 'nomeacao-designado',
                        //canActivate: [AtivoGuardService],
                        component: NomeacaoDesignadoComponent
                    },
                    {
                        path: 'contrato-antecipacao-beneficio',
                        //canActivate: [AtivoGuardService],
                        component: ContratoAntecipacaoBeneficioComponent
                    },
                    {
                        path: 'declaracao-tempo-servico',
                        canActivate: [AtivoGuardService],
                        component: DeclaracaoTempoServicoComponent
                    },
                    {
                        path: 'exclusao-beneficiario',
                       //canActivate: [AtivoGuardService],
                        component: ExclusaoBeneficiarioComponent
                    },
                    /*
                    Vendo que não vamos gerar a pensão na AA, então pode excluir no rol de "Solicitações" o "Contrato de Antecipação de Pensão"
                    {
                        path: 'suplementacao-pensao',
                       //canActivate: [AtivoGuardService],
                        component: SuplementacaoPensaoComponent
                    },*/
                    {
                        path: 'prorrogacao-adiantamento-parcial',
                        canActivate: [PensionistaOuAssistidoGuardService],
                        component: ProrrogacaoAdiantamentoParcialComponent
                    }
                ]
            },
            {
                path: 'como-fazer',
                component: ComoFazerComponent
            },
            {
                path: 'plano-continuidade-de-negocio',
                component: PlanoContinuidadeDeNegocioComponent
            },
            {
                path: 'baixar-app',
                component: BaixarAppComponent
            },
            {
                path: 'familia-itaipu',
                children: [
                    {
                        path: 'solicitacao-desconto-folha',
                        component: SolicitacaoDescontoFolhaComponent
                    }
                ]
            },
            {
                path: 'dados-cadastrais',
                children: [
                    {
                        path: '',
                        redirectTo: 'cadastro',
                        pathMatch: 'full'
                    },
                    {
                        path: 'cadastro',
                        component: CadastroComponent
                    },
                    {
                        path: 'alterar-senha',
                        component: AlterarSenhaComponent
                    },
                    {
                        path: 'dependentes',
                        component: DependentesComponent
                    },
                    {
                        path: 'tempo-servico',
                        component: TelaInssComponent
                    }
                ]
            },
            {
                path: 'extrato-bps',
				canActivate: [AtivoGuardService],
                component: ExtratoBpsComponent
            },
            {
                path: 'participantes-beneficiarios',
                component: ParticipantesBeneficiariosComponent
            },
            /*{
                path: 'indice-financeiro',
                component: IndiceFinanceiroComponent
            },*/
            {
                path: 'saldamento-plano-cd',
                component: DocSaldamentoPlanoCDComponent
            },
            {
                path: 'lamina-bd',
				//canActivate: [AtivoGuardService],
                component: RentabilidadeComponent
            }
        ],
    },

];
