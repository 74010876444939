import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';

import { routes } from './termo-adesao.route';
import { TermoAdesaoComponent } from './termo-adesao.component';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { SharedModule } from '@fibra/fibra-shared-lib';


@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        CheckboxModule,
        AutoCompleteModule,
        NgxMaskModule.forRoot(),
        SharedModule,
        NgxLoadingModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        ConfirmationService
    ],
    declarations: [
        TermoAdesaoComponent
    ],
    exports: [RouterModule]
})
export class TermoAdesaoModule {
}
