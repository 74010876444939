import { ModeloContribuicaoDto } from "./modelo-contribuicao.dto";
import { ContaBancariaDto } from "./conta-bancaria.dto";
import { PessoaDto } from "./pessoa.dto";
import { EPerfilInvestimento, ERegimeTributario } from "../../enums";
import { PerguntasPpe } from "./perguntas-ppe.model";
import { PerguntaPerfilInvestimento } from "../sinqia-api/pergunta-perfil-investimento"
import { DependenteDto } from "./dependente-dto";

export class SolicitacaoAdesaoCdModel {
    concordoTermo?: boolean = false;
    contribuicaoBasica?: number;
    valorPortabilidade?: number;
    //tipoRegimeTributario?: ERegimeTributario;
    tipoAdesao?: number;
    pessoa?: PessoaDto;
    contaBancaria?: ContaBancariaDto;
    modeloContribuicao?: ModeloContribuicaoDto;
    dependentes: Array<DependenteDto> = [];
    perfilInvestimento?: EPerfilInvestimento | null = null;
    perfilInvestimentoIndicado?: EPerfilInvestimento | null = null;
    perguntasPpe: Array<PerguntasPpe> = [];
    perguntasPerfilInvestimento?: Array<PerguntaPerfilInvestimento> | null = [];
    termoPerfilConservador: boolean | null = null;
    termoDesenquadramento: boolean | null = null;
    token: string | null = null;
    naoResponderQuestionario: boolean | null = null;
}

