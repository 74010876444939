import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
    selector: 'app-beneficio-card',
    templateUrl: './beneficio-card.component.html',
    styleUrls: ['./beneficio-card.component.css']
})
export class BeneficioCardComponent implements OnInit {
    @Input() mostrarLinkParaExtratoCompleto = true;
    show = true;
    mensagemDeErro: string;
    bloquearContribuicaoSaldo: boolean;
    isLoading = true;
    ano: number;
    indexAnoSelecionado: number;

    constructor(
        
    ) {
    }

    ngOnInit() {
        this.isLoading = false;
    }

    private informarErroDeCarregamento() {
    }

    private bloquearSaldoDisponivelDeCarregamento() {
    }

    baixarExtrato(ano: number) {
    }

    trocaAno()
    {
    }
}
