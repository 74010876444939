import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AdesaoCdService, CPF, DocumentoService, ERegimeTributario, GestaoPlanoCadastroService, SolicitacaoAdesaoCdModel, TipoSeDocumento } from '@fibra/fibra-shared-lib';

import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { PdfViewerComponent } from '../../../components/pdf-viewer/pdf-viewer.component';

@Component({
    selector: 'app-passo-03',
    templateUrl: './passo-03.component.html',
    styleUrls: ['./passo-03.component.css']
})
export class Passo3Component implements OnInit {
    solicitacaoAdesao?: SolicitacaoAdesaoCdModel = null;
    regimeTributario = ERegimeTributario;
    readonly CPF = CPF;
    isLoading: boolean;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(private router: Router,
        private adesaoService: AdesaoCdService, private cadastroService: GestaoPlanoCadastroService,
        private documentoService: DocumentoService
        ) { }

    items: MenuItem[];
    activeIndex: number = 0;

    ngOnInit() {
        this.activeIndex = 2;
        this.items = [
            { label: 'Informações iniciais' },
            { label: 'Perfil de investimento' },
            { label: 'Conclusão' }
        ];

        this.solicitacaoAdesao = this.adesaoService.getSolicitacaoAdesao();
    }

    onBack() {
        this.router.navigate(['/painel/adesao-cd/perfil-investimento'])
    }

    onSubmit() {
        this.router.navigate(['/painel/adesao-cd/resumo'])
    }

    // definirRegimeTributario(regimeTributario: ERegimeTributario) {
    //     this.solicitacaoAdesao.tipoRegimeTributario = regimeTributario;
    //     this.adesaoService.definirRegimeTributario(regimeTributario);
    // }

    downloadRegimeTributario() {
        this.isLoading = true;

        var fileName =  encodeURIComponent('Cartilha Tributacao.pdf');

        let request = this.documentoService.getSeDocs(TipoSeDocumento.SinteseSaldamentoNovoPlanoAtivos)
            .pipe(tap(data => {
                this.isLoading = false;
            }))

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request,true)
    }
}
