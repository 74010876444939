import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';

import {
    AuthService,
    GestaoFinanceiraEmprestimoService
} from '@fibra/fibra-shared-lib';
import {
    GestaoFinanceiraEmprestimoServiceEmprestimoGetBloqueioCarteiraResponse
} from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-bloqueio-carteira-emprestimo',
  templateUrl: './bloqueio-carteira-emprestimo.component.html',
  styleUrls: ['./bloqueio-carteira-emprestimo.component.css']
})
export class BloqueioCarteiraEmprestimoComponent implements OnInit {

    @Input() exibirModal: boolean;
    @Output() exibirModalChange = new EventEmitter<boolean>();

    error = false;
    isLoading = true;

    bloqueiCarteiraEmprestimo: GestaoFinanceiraEmprestimoServiceEmprestimoGetBloqueioCarteiraResponse;

  constructor( private authService: AuthService,
    private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        // forkJoin(
        //     this.gestaoFinanceiraEmprestimoService.Emprestimo_GetBloqueioCarteiraEmprestimo(),
        // ).subscribe(([bloqueiCarteiraEmprestimo]) => {
        //     this.bloqueiCarteiraEmprestimo = bloqueiCarteiraEmprestimo;
        //     this.isLoading = false;
        // }, () => {
        //     this.isLoading = false;
        //     this.error = true;
        // });
    }

    fecharModal() {
        this.exibirModal = false;
        this.exibirModalChange.emit(this.exibirModal);
    }

}
