import { Component, OnInit, ViewChild } from '@angular/core';
import { Beneficiario, ETipoPlano, GestaoPlanoCadastroService, RespostaDocumentoCriadoModel, SignDataModel } from '@fibra/fibra-shared-lib';
import { FormularioService } from '@fibra/fibra-shared-lib';
import { AuthService, RELACAO_DEPENDENCIA } from '@fibra/fibra-shared-lib';
import { BeneficiarioResponseDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { Observable } from 'rxjs';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';
import { BeneficiarioMockService } from 'projects/FibraAutoAtendimento/src/app/mocks/beneficiarios-mock.service'; 

@Component({
    selector: 'app-exclusao-beneficiario',
    templateUrl: './exclusao-beneficiario.component.html',
    styleUrls: ['./exclusao-beneficiario.component.css']
})
export class ExclusaoBeneficiarioComponent implements OnInit {
    documentoAssinado: boolean = false;
    mostrarSignWidget: boolean = false;
    @ViewChild('signWidget') signWidget: SignWidgetComponent
    temBeneficiario: Boolean = false;
    beneficiariosResponse: Array<Beneficiario> = [];
    beneficiariosParaExcluir: Array<Beneficiario> = [];
    isLoading: boolean;
    urlArquivoSeSuite: string;
    msgs: any[];
    isSimulado = sessionStorage.getItem('isSimulado');

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private beneficiarioMockService: BeneficiarioMockService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.beneficiariosResponse = [];
        this.beneficiariosParaExcluir = [];

        // use 'false' para dados mockados, e 'true' para obter os dados via API.
        this.fetchBeneficiarios(true).subscribe(ben => {
            this.temBeneficiario = ben.length > 0;
            ben.forEach(element => {
                this.beneficiariosResponse.push({
                    id: element.id,
                    nome: element.nome,
                    dataNascimento: element.dataNascimento,
                    parentesco: this.getParentescoDescricao(element.grauParentesco),
                    sexo: element.sexo,
                    cpf: element.cpf,
                    telefone: ""
                });
            });
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }
    
    fetchBeneficiarios(useMock: boolean): Observable<BeneficiarioResponseDto[]> {
        if (!useMock) {
          return this.beneficiarioMockService.getBeneficiariosMock();
        }
        return this.gestaoPlanoCadastroService.getBeneficiarios();
    }
    
    ngOnDestroy(): void {
    }

    registrarDocumentoAssinado(signData: SignDataModel) {
        this.formularioService.registrarAssinaturaDocumento(signData.idDocumento)
            .subscribe(resposta => {
                this.documentoAssinado = resposta.success && resposta.data;
            }, error => {
                console.error(error);
            });
    }
    
    getParentescoDescricao(id: string): string {
        const parentesco = RELACAO_DEPENDENCIA.find(item => item.id === id);
        return parentesco ? parentesco.descricao : 'Desconhecido';
    }

    removerBeneficiario(id: number) {
        let beneficiario = this.beneficiariosResponse.find(x => x.id == id);
        let benIndex = this.beneficiariosResponse.indexOf(beneficiario);
        this.beneficiariosResponse.splice(benIndex, 1);
        this.beneficiariosParaExcluir.push(beneficiario);
    }

    reverterBeneficiario(id: number) {
        let beneficiario = this.beneficiariosParaExcluir.find(x => x.id == id);
        let benIndex = this.beneficiariosParaExcluir.indexOf(beneficiario);
        this.beneficiariosParaExcluir.splice(benIndex, 1);
        this.beneficiariosResponse.push(beneficiario);
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    validarForm(): boolean {
        return this.beneficiariosParaExcluir.length > 0;
    }

    enviar() {
        this.isLoading = true;
        this.formularioService.gerarFormExclusaoBeneficiario(this.beneficiariosParaExcluir)
            .subscribe((resposta: RespostaDocumentoCriadoModel) => {
                this.isLoading = false;

                if (this.isSimulado === 'true') {
                    window.open(resposta.urlDoDocumentoNaoAssinado, '_blank');
                }

                if(resposta.sucesso){
                    this.mostrarSignWidget = true;
                    setTimeout(() => {
                        this.signWidget.carregarWidget({
                            signKey: resposta.idDaRequisicaoDaAssinatura,
                            idDocumento: resposta.idDoDocumento
                        });
                    }, 500);
                }
            }, () => {
                this.mostrarSignWidget = false;
                this.isLoading = false;
            });
    }

}
