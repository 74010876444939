import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdesaoCdService, STORAGE_KEYS, SessionStorageService, TokenService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-token-dialog',
    templateUrl: './token-dialog.component.html',
    styleUrls: ['./token-dialog.component.css']
})
export class TokenDialogComponent implements OnInit {
    @Input() display: boolean = false;
    @Input() infoMessage: string | null = null;
    @Input() email: string | null = null;
    @Input() autoSendToken: boolean | null = true;
    @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();
    codigoInvalido: boolean = false;
    erroMessage: string | null = null;
    isLoading: boolean = false;
    token: string | null = null;
    tokenEnviado: boolean = false;
    inputTokenDisabled: boolean;

    constructor(private tokenService: TokenService,
        private sessionStorageService: SessionStorageService) { }

    ngOnInit(): void {
        if (this.autoSendToken)
            this.enviarTokenEmail(this.email);
    }

    enviarTokenEmail(email: string, disableCach: boolean = false) {
        /*if (!disableCach && this.sessionStorageService.any(STORAGE_KEYS.TOKEN_ADE_CD)) {
            let tokenInfo = this.sessionStorageService.getSecureParse<{
                enviado: boolean,
                mensagem: string
            }>(STORAGE_KEYS.TOKEN_ADE_CD);
            this.tokenEnviado = true;
            this.infoMessage = tokenInfo.mensagem;
            return;
        }*/

        this.isLoading = true;
        this.token = null;
        this.erroMessage = null;
        this.infoMessage = null;
        this.inputTokenDisabled = true;

        this.tokenService.enviarTokenEmail(email)
            .subscribe(result => {
                this.inputTokenDisabled = false;
                this.tokenEnviado = true;
                this.isLoading = false;
                if (result.success && result.data) {
                    this.sessionStorageService.setAnyAsJsonSecure(STORAGE_KEYS.TOKEN_ADE_CD, {
                        enviado: result.success,
                        mensagem: result.data
                    });
                    this.infoMessage = result.data;
                }
            }, erro => {
                this.inputTokenDisabled = false;
                this.tokenEnviado = false;
                this.isLoading = false;
                console.error(erro);
                this.erroMessage = erro?.error?.errors?.length > 0 ? erro?.error?.errors[0] : '';
            });
    }

    validarToken(token: string) {
        this.isLoading = true;
        this.inputTokenDisabled = true;
        this.tokenService.validarToken(token)
            .subscribe(result => {
                this.inputTokenDisabled = false;
                this.isLoading = false;
                if (result.success) {
                    this.onSuccess.emit(result.data);
                    this.hide();
                } else {
                    this.erroMessage = 'Token inválido';
                }
            }, erro => {
                this.inputTokenDisabled = false;
                this.erroMessage = 'Token inválido';
                this.isLoading = false;
                console.error(erro);
            });
    }

    tokenChange(token: string) {
        if (token.length == 6) {
            this.validarToken(token);
        }
    }

    show() {
        this.display = true;
    }

    hide() {
        this.display = false;
    }
}
