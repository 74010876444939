import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GestaoPlanoCadastroService, AuthService, SessionInitService, TermoAdesaoService, ETipoPlano } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-selecionar-perfil',
    templateUrl: './selecionar-perfil.component.html',
    styleUrls: ['./selecionar-perfil.component.css']
})
export class SelecionarPerfilComponent implements OnInit {
    errorMessage = 'Houve um erro ao processar sua requisição';
    isErrorDialogVisible = false;
    isLoading = true;
    password: string;
    perfis: any[];
    username: string;
    returnUrl: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private authService: AuthService,
        private sessionInitService: SessionInitService,
        private termoAdesaoService: TermoAdesaoService,
        private router: Router
    ) { }

    ngOnInit() {

        this.activatedRoute.params.subscribe(params => {
            this.username = params['user'];
            this.password = params['password'];
            this.returnUrl = params['returnUrl'];

            this.carregaAdesoes(this.username);
        });
    }

    carregaAdesoes(cpf: string) {
        cpf = cpf.toString().replace(/\.|-/gm, '');
        this.gestaoPlanoCadastroService
            .Cadastros_ObterListaAdesoes(cpf).subscribe(adesoes => {
                this.perfis = adesoes;
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.errorFunction();
            });
    }

    selecionaPerfil(regFibra: string) {
        this.isLoading = true;

        this.authService.login(this.username, this.username, this.password, regFibra).subscribe(data => {
            this.authService.getTokenIntegracao(this.username, this.username, this.password, regFibra).subscribe(tokenIntegracao => {
                this.sessionInitService.sessionInitIntegracao(tokenIntegracao);
                this.termoAdesaoService.callbackURL = this.returnUrl;
                this.termoAdesaoService.isTermAccepted(false)
                    .subscribe(isAccepted => {
                        let returnUrl = this.returnUrl;
                        if (!isAccepted) {
                            returnUrl = '/termo-adesao';
                        } else if (this.returnUrl.includes('/login')) {
                            returnUrl = '/painel/plano-cd/inicio';
                        }

                        this.sessionInitService.sessionInit(data).then(success => {
                            if (success) {
                                this.router.navigateByUrl(decodeURIComponent(returnUrl));
                            }
                        }).catch(error => {
                            console.error(error);
                        });
                    });
            });
        }, this.errorFunction);

    }

    private errorFunction() {
        this.isLoading = false;
        this.isErrorDialogVisible = true;
    }
}
