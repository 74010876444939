import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ETokenApi } from "../enums/token-api.enum";
import { StorageService } from "./storage.service";
import { SessionStorageService } from "./session-storage.service";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { HttpHeaderService } from "./http-header.service";

/**
 * Http Client para requisição nas API's Fibra
 */
@Injectable()
export class HttpClientService extends HttpHeaderService {
    constructor(protected httpClient: HttpClient,
        protected router: Router,
        protected storageService: StorageService,
        protected sessionStorageService: SessionStorageService) {
        super(router, storageService, sessionStorageService);
    }

    get<T>(url: string, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null,
        responseType: any | undefined = undefined) {
        if (httpHeaders == null)
            httpHeaders = new HttpHeaders();

        if (tokenApi)
            httpHeaders = this.addHeaderTokenApi(tokenApi, httpHeaders);

        let options = { headers: httpHeaders, responseType: responseType as 'json' }

        return this.httpClient.get<T>(url, options);
    }

    post<T>(url: string, body: any, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null, responseType: string | null = null) {
        if (httpHeaders == null)
            httpHeaders = new HttpHeaders();

        if (tokenApi)
            httpHeaders = this.addHeaderTokenApi(tokenApi, httpHeaders);
        const config = {
            headers: httpHeaders
        }
        if (responseType) {
            config["responseType"] = responseType;
        }
        return this.httpClient.post<T>(url, body, config);
    }

    upload<T>(url: string, body: FormData, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null) {
        if (httpHeaders == null)
            httpHeaders = new HttpHeaders();

        if (tokenApi)
            httpHeaders = this.addHeaderTokenApi(tokenApi, httpHeaders);

        return this.httpClient.post<T>(url, body, { headers: httpHeaders });
    }

    put<T>(url: string, body: any, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null) {

        if (httpHeaders == null)
            httpHeaders = new HttpHeaders();

        if (tokenApi)
            httpHeaders = this.addHeaderTokenApi(tokenApi, httpHeaders);

        return this.httpClient.put<T>(url, body, { headers: httpHeaders });
    }


    delete<T>(url: string, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null) {
        if (httpHeaders == null)
            httpHeaders = new HttpHeaders();

        if (tokenApi)
            httpHeaders = this.addHeaderTokenApi(tokenApi, httpHeaders);

        return this.httpClient.delete<T>(url, { headers: httpHeaders });
    }

    getFile(url: string, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null) {

        let headers = new HttpHeaders({
            'Content-Type': 'application/pdf'
        });

        let responseType = 'arraybuffer';
        return this.get<ArrayBuffer>(url, tokenApi, headers, responseType);
    }

    openPdfNewTab(request: Observable<ArrayBuffer>) {
        return request.subscribe((response: ArrayBuffer) => {
            const pdfBlob = new Blob([response], { type: 'application/pdf' });
            const pdfData = URL.createObjectURL(pdfBlob);
            window.open(pdfData, '_blank')
        }, error => {
            console.error(error);
        });
    }

    openPdfNewTabByUrl(url: string, tokenApi: ETokenApi | null = null, httpHeaders: HttpHeaders | null = null) {
        return this.getFile(url, tokenApi, httpHeaders)
            .subscribe((response: ArrayBuffer) => {
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfData = URL.createObjectURL(pdfBlob);
                window.open(pdfData, '_blank')
            }, error => {
                console.error(error);
            });
    }
}
