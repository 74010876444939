export class GestaoPlanoBeneficioServicePercentuaisAposPlanValoresResponse {
    aposEstimada: number;
    artigo: number;
    complemFibra: number;
    complemFibraJoia: number;
    complemMin: number;
    difApu: number;
    fibraMeses: number;
    medFibraAtual: number;
    nIndComplem: number;
    percMin: number;
    redInss: number;
    redJoia: string;
    situacaoJoia: string;
    vlRedInss: number;
    benefEstimado: number;
}
