import { Routes } from '@angular/router';

import {
    PainelCdComponent,
    InicioCdComponent,
    //dados cadastrais
    CadastroComponent,
    AlterarSenhaComponent,
    DependentesComponent,
    ComoFazerComponent,
    IdentificacaoPlanoComponent,
	//beneficios
    RegulamentoComponent,
    AuxilioFuneralComponent,
    LongevidadeComponent,
    TempoInvalidezComponent,
    MorteAssistidoComponent,
    MorteAtivoComponent,
	RendaTemporariaPortabilidade,
    BpdComponent,
    PortabilidadeComponent,
    ResgateComponent,
    AutopatrocinioComponent,
    //suspensao de beneficio
    SuspensaoContribuicaoComponent,
    //beneficio
    TelaBeneficioComponent,
	//contribuicao,
	TelaContribuicaoComponent,
    //demonstrativo
    DemonstrativoPagamentoComponent,
    //ficha financeira
    FichaFinanceiraComponent,
    //informe de rendimentos
    InformeRendimentoComponent,
    //atuarias
    AtuariasComponent,
    //fibra em numeros
    TelaFibraNumerosComponent,
    //adiantamento abono
    AdiantamentoAbonoComponent,
    //prorrogação
    ProrrogacaoAdiantamentoParcialComponent,
    SolicitacaoProrrogacaoAdiantamentoParcialComponent,
    //historico de cotas
    //HistoricoCotasComponent,
    //rentabilidade
    RentabilidadeComponent,
    //Autopatrocinio
    AutopatrocinioFinanceiroComponent,
    //perfil investimento
    PerfilInvestimentoComponent,
    //institutos
    InstitutosComponent,
    SolicitacaoInstitutosComponent,
    //participantes e beneficiarios
    ParticipantesBeneficiariosComponent,
	//prorrogacao-adiantamento-parcial;
	//ProrrogacaoAntecipacaoBeneficioComponent,
} from '.';
//beneficio auxilio funeral
import { BeneficioAuxilioFuneralComponent } from './solicitacoes/beneficio-auxilio-funeral/beneficio-auxilio-funeral.component';
//isencao de imposto
import { IsencaoIrMolestiaGraveCDComponent } from '../cd/solicitacoes/isencao-ir-molestia-grave/isencao-ir-molestia-grave.component';
//solicitacao de beneficio\import
import { SuplementacaoAposentadoriaComponent } from './solicitacoes/suplementacao-aposentadoria/suplementacao-aposentadoria.component';
//solicitacao de suspensao\import
import { SolicitacaoSuspensaoContribuicaoComponent } from './solicitacoes/suspensao-contribuicao/suspensao-contribuicao.component';
//solicitacao de abono\import
import { SolicitacaoAdiantamentoAbonoComponent } from './solicitacoes/adiantamento-abono/adiantamento-abono.component';
//certificado participante
import { CertificadoInscricaoComponent } from './certificado-inscricao/certificado-inscricao.component';
//dependentes IR
import { DeclaracaoDependenteIrComponent } from './solicitacoes/declaracao-dependente-ir/declaracao-dependente-ir.component';
//nomecao designado
import { NomeacaoDesignadoComponent } from './solicitacoes/nomeacao-designado/nomeacao-designado.component';
//exclusao beneficiario
import { ExclusaoBeneficiarioComponent } from './solicitacoes/exclusao-beneficiario/exclusao-beneficiario.component';

import {
    AuthGuardService,
    AssistidoGuardService,
    AtivoGuardService,
    PensionistaGuardService
} from '@fibra/fibra-shared-lib';
import { AuthCdGuard } from '../../guards/auth-cd.guard';
import { AdesaoContextoGuard } from '../../guards/adesao-contexto.guard';
import { PessoaPoliticamenteExpostaComponent } from '../geral/solicitacoes/pessoa-politicamente-exposta/pessoa-politicamente-exposta.component';
import { TempoProgramadaComponent } from '../../components/tempo-programada';
import { DocSaldamentoPlanoCDComponent } from '../bd/doc-saldamento-plano-cd/doc-saldamento-plano-cd.component';
import { ExtratoAtasComponent } from '../bd/extrato-atas/extrato-atas.component';
import { DeclaracaoTempoServicoComponent } from './solicitacoes/declaracao-tempo-servico/declaracao-tempo-servico.component';
import { InscricaoBeneficiarioComponent } from './solicitacoes/inscricao-beneficiario/inscricao-beneficiario.component';
import { PlanoContinuidadeDeNegocioComponent } from '../bd/plano-continuidade-de-negocio/plano-continuidade-de-negocio.component';


export const CD_ROUTES: Routes = [
    {
        path: 'painel/plano-cd',
        component: PainelCdComponent,
        canActivate: [AuthGuardService, AuthCdGuard],
        canActivateChild: [AuthGuardService, AuthCdGuard, AdesaoContextoGuard],
        children: [
            {
                path: '',
                redirectTo: 'inicio',
                pathMatch: 'full'
            },
            {
                path: 'inicio',
                component: InicioCdComponent,
            },
            {
                path: 'como-fazer',
                component: ComoFazerComponent
            },
            {
                path: 'identificacao-plano',
                component: IdentificacaoPlanoComponent
            },
            {
                path: 'beneficios',
                children: [
                    {
                        path: 'beneficios-previstos',
                        component: RegulamentoComponent
                    },
                    {
                        path: 'aposentadoria-programada',
                        component: TempoProgramadaComponent
                    },
                    {
                        path: 'pensao-morte-ativo',
                        component: MorteAtivoComponent
                    },
                    {
                        path: 'pensao-morte-assistido',
                        component: MorteAssistidoComponent
                    },
                    {
                        path: 'invalidez',
                        component: TempoInvalidezComponent
                    },
                    {
                        path: 'longetividade',
                        component: LongevidadeComponent
                    },
                    {
                        path: 'auxilio-funeral',
                        component: AuxilioFuneralComponent
                    },
                    {
                        path: 'renda-temporaria-portabilidade',
                        component: RendaTemporariaPortabilidade
                    }
                ]
            },
            {
                path: 'desligamento',
                children: [
                    {
                        path: 'autopatrocinio',
                        component: AutopatrocinioComponent
                    },
                    {
                        path: 'bpd',
                        component: BpdComponent
                    },
                    {
                        path: 'portabilidade',
                        component: PortabilidadeComponent
                    },
                    {
                        path: 'resgate',
                        component: ResgateComponent
                    }
                ]
            },
            {
                path: 'beneficio',
                //#VOLTAR depois canActivate: [AtivoGuardService],
                component: TelaBeneficioComponent
            },
            {
                path: 'demonstrativo',
                //#VOLTAR depois canActivate: [AssistidoGuardService],
                component: DemonstrativoPagamentoComponent
            },
            {
                path: 'fichafinanceira',
                //#VOLTAR depois canActivate: [AssistidoGuardService],
                component: FichaFinanceiraComponent
            },
            {
                path: 'informe-rendimento',
                //#VOLTAR depois canActivate: [AssistidoGuardService],
                component: InformeRendimentoComponent
            },
            {
                path: 'atuarias',
                component: AtuariasComponent
            },
            {
                path: 'fibranumeros',
                component: TelaFibraNumerosComponent
            },
            {
                path: 'solicitacoes',
                children: [
                    {
                        path: 'inscricao-beneficiario',
                        //canActivate: [AtivoGuardService],
                        component: InscricaoBeneficiarioComponent
                    },
                    {
                        path: 'beneficio-auxilio-funeral',
                        //canActivate: [AtivoGuardService],
                        component: BeneficioAuxilioFuneralComponent
                    },
                    {
                        path: 'declaracao-dep-ir',
                        //canActivate: [AtivoGuardService],
                        component: DeclaracaoDependenteIrComponent
                    },
                    {
                        path: 'isencao-ir-molestia-grave',
                        //canActivate: [AtivoGuardService],
                        component: IsencaoIrMolestiaGraveCDComponent
                    },
                    {
                        path: 'pessoa-politicamente-exposta',
                        //canActivate: [AtivoGuardService],
                        component: PessoaPoliticamenteExpostaComponent
                    },
                    {
                        path: 'suplementacao-aposentadoria',
                        canActivate: [AtivoGuardService],
                        component: SuplementacaoAposentadoriaComponent
                    },
                    {
                        path: 'suspencao-contribuicao',
                        component: SolicitacaoSuspensaoContribuicaoComponent
                    },
                    {
                        path: 'exclusao-beneficiario',
                       //canActivate: [AtivoGuardService],
                        component: ExclusaoBeneficiarioComponent
                    },
                    {
                        path: 'nomeacao-designado',
                        //canActivate: [AtivoGuardService],
                        component: NomeacaoDesignadoComponent
                    },
                    {
                        path: 'adiantamento-abono',
                        component: SolicitacaoAdiantamentoAbonoComponent
                    },
                    {
                        path: 'prorrogacao-adiantamento-parcial',
                        canActivate: [PensionistaGuardService],
                        component: SolicitacaoProrrogacaoAdiantamentoParcialComponent
                    },
                    // {
                    //     path: 'prorrogacao-antecipacao-beneficio',
                    //     component: ProrrogacaoAntecipacaoBeneficioComponent
                    // },
                    {
                        path: 'institutos',
                        canActivate: [AtivoGuardService],
                        component: SolicitacaoInstitutosComponent
                    },
                    {
                        path: 'declaracao-tempo-servico',
                        canActivate: [AtivoGuardService],
                        component: DeclaracaoTempoServicoComponent
                    },
                ]
            },
            /*{
                path: 'historico-cotas',
                component: HistoricoCotasComponent
            },*/
            {
                path: 'rentabilidade',
                component: RentabilidadeComponent
            },
            {
                path: 'autopatrocinio-financeiro',
                component: AutopatrocinioFinanceiroComponent
            },
            {
                path: 'certificado-inscricao',
                component: CertificadoInscricaoComponent
            },
            {
                path: 'perfil-investimentos',
                //canActivate: [AtivoGuardService],
                component: PerfilInvestimentoComponent
            },
            {
                path: 'institutos',
                //canActivate: [AtivoGuardService],
                component: InstitutosComponent
            },
            {
                path: 'suspencao-contribuicao',
                component: SuspensaoContribuicaoComponent
            },
            {
                path: 'adiantamento-abono',
                component: AdiantamentoAbonoComponent
            },
            {
                path: 'participantes-beneficiarios',
                component: ParticipantesBeneficiariosComponent
            },
            {
                path: 'dados-cadastrais',
                children: [
                    {
                        path: '',
                        redirectTo: 'cadastro',
                        pathMatch: 'full'
                    },
                    {
                        path: 'cadastro',
                        component: CadastroComponent
                    },
                    {
                        path: 'alterar-senha',
                        component: AlterarSenhaComponent
                    },
                    {
                        path: 'dependentes',
                        component: DependentesComponent
                    }
                ]
            },
            {
                path: 'prorrogacao-adiantamento-parcial',
                canActivate: [AssistidoGuardService],
                component: ProrrogacaoAdiantamentoParcialComponent
            },
            {
                path: 'contribuicao',
                //canActivate: [AtivoGuardService],
                component: TelaContribuicaoComponent
            },
            {
                path: 'saldamento-plano-cd',
                component: DocSaldamentoPlanoCDComponent
            },
            {
                path: 'extrato-atas',
                component: ExtratoAtasComponent
            },
            {
                path: 'plano-continuidade-de-negocio',
                component: PlanoContinuidadeDeNegocioComponent
            },
        ]
    },

];
