import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-adesao-cd',
  templateUrl: './adesao-cd.component.html',
  styleUrls: ['./adesao-cd.component.css']
})
export class AdesaoCdComponent implements OnInit {

  mostraHeader = true;

  constructor(private router: Router) { }

  ngOnInit() {

    if(this.router.url.includes("finalizado"))
    {
      console.log("Estou na finalização")
      this.mostraHeader = false;
    } 
    else
    {
      console.log("Não estou na finalização")
      this.mostraHeader = true;
    }
      

    //this.mostraHeader = !this.router.url.includes("finalizado");

  }

  toSimulator = () => {
    this.router.navigate(['/painel/simulador']);
  }

}
