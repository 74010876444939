import { Component, OnInit, ViewChild } from '@angular/core';
import { Beneficiario, MESSAGE_SEVERITY, formatarTelefone, validFullName } from '@fibra/fibra-shared-lib';
import { FormularioService, AlertModalComponent, scrollIntoViewToTop } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { FileUpload } from 'primeng/fileupload';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
//import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';
import { SignWidgetComponent } from '../../../../components/clicksign/sign-widget/sign-widget.component';

@Component({
    selector: 'app-inscricao-beneficiario',
    templateUrl: './inscricao-beneficiario.component.html',
    styleUrls: ['./inscricao-beneficiario.component.css']
})
export class InscricaoBeneficiarioComponent implements OnInit {
    mostrarSignWidget: boolean = false;
    beneficiarios: Array<Beneficiario>;
    beneficiario: Beneficiario;
    isLoading: boolean;
    formValido: boolean;
    ptBR = LOCALE_PT_BR;
    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    urlArquivoSeSuite: string;
    msgs: any;
    yearRange: string;
    uploadedFiles: any[] = [];
    dateMax: Date;
    isSimulado = sessionStorage.getItem('isSimulado');

    constructor(private formularioService: FormularioService, private authService: AuthService) {
        this.beneficiarios = new Array<Beneficiario>();
        this.yearRange = '1900:' + (new Date()).getFullYear();
        this.dateMax = new Date();
    }

    ngOnInit() {
        this.beneficiarios = new Array<Beneficiario>();
        this.inicializarBeneficiario();
    }

    ngOnDestroy(): void {
    }
    
    formatarCelular(string: string) {

        let ddd = string.substring(0, 2);
        let celular = string.substring(2);

        string = formatarTelefone(ddd, celular);

        return string
    }

    inicializarBeneficiario() {
        this.beneficiario = {
            id: 0,
            nome: '',
            parentesco: '0',
            dataNascimento: new Date(),
            sexo: '0',
            cpf: "",
            telefone: ""
        };
    }

    adicionarBeneficiario() {
        if (this.validarForm()) {
            this.beneficiario.id = this.beneficiarios.length + 1;
            this.beneficiario.parentesco = this.getParentescoDescricao(this.beneficiario.parentesco)
            this.beneficiarios.push(this.beneficiario)
            this.inicializarBeneficiario();
        }
    }

    removerBeneficiario(i: number) {
        this.beneficiarios.splice(i, 1);
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    getParentescoDescricao(valor: string): string {
        switch (valor) {
            case 'Filho_Ate_25':
                return 'Filho até 25 anos';
            case 'Filho_Invalido':
                return 'Filho inválido';
            case 'Conjuge':
                return 'Cônjuge/Companheiro(a)';
            case 'Ex_Conjuge':
                return 'Ex-cônjuge/Ex-Companheiro(a)';
            case 'Enteado':
                return 'Enteado';
            case 'Pais':
                return 'Pais';
            case 'Menor_Sob_Guarda':
                return 'Menor sob guarda';
            default:
                return 'Desconhecido';
        }
    }

    validarForm(): boolean {
        this.limparMensagem();

        this.formValido = true

        if (this.beneficiario.nome === '' ||
            this.beneficiario.parentesco === '0' ||
            this.beneficiario.sexo === '0') {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Informe todos os dados do(s) beneficiário(s).");

            scrollIntoViewToTop();
            return false;
        }

        if ((this.beneficiario.nome &&
            this.beneficiario.nome.trim().length < 3) ||
            !validFullName(this.beneficiario.nome)) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O campo 'Nome Completo' é permitido apenas letras.`);
            this.formValido = false;
        }

        if (!this.formValido)
            scrollIntoViewToTop();

        return this.formValido;
    }

    validarSolicitacao() {
        this.limparMensagem();
        if((this.beneficiarios || []).length > 0) {
            return true;
        }
        this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', `Informar ao menos um beneficiário`);
        scrollIntoViewToTop();
        return false;
    }

    async enviar() {
        if (!this.validarSolicitacao())
            return;
        this.isLoading = true;
        let formData = new FormData();
        formData.append('beneficiariosJson', JSON.stringify(this.beneficiarios));

        if (this.fileUpload.hasFiles()) {
            this.fileUpload.files.forEach((file, index) => {
                formData.append('anexo_' + index, file);
            });
        }
        try{
            let resposta = await this.formularioService.gerarFormInscricaoBeneficiario(0, formData).toPromise()

            this.isLoading = false;

            if (this.isSimulado === 'true') {
                /*
                perguntar para o joao o que eh esse simulado
                */
                //window.open(urlArquivoSeSuite, '_blank');
            }
            this.mostrarSignWidget = true;
            setTimeout(() => {
                this.signWidget.carregarWidget({
                    signKey: resposta.idDaRequisicaoDaAssinatura,
                    idDocumento: resposta.idDoDocumento
                });
            }, 500);
        } catch (error) {
            this.isLoading = false;
            this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
        }
    }

    documentoAssinado() {
        this.uploadedFiles = [];
        this.fileUpload.clear();
        this.reset();
        //this.mostrarSignWidget = false;
        this.alertModalComponent.show('Sua solicitação foi enviada com sucesso e está em análise.', true);
        this.addMensagem(MESSAGE_SEVERITY.SUCCESS, 'Atenção: ', `Operação realizada com sucesso`);
        scrollIntoViewToTop();
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    onUpload(event: any) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    async verificar2fa() {
        // if (!this.validarForm())
        //     return;
        await this.enviar();
    }
}
