import { PagamentoEmprestimo } from './../domain/gestaofinanceira/emprestimo/models/pagamento-emprestimo';
import { TimeLineEmprestimo } from './../domain/gestaofinanceira/emprestimo/models/time-line-emprestimo';
import { SimulacaoValores } from './../domain/gestaofinanceira/emprestimo/models/simulacao-valores';
import { ConfirmacaoEmprestimo } from './../domain/gestaofinanceira/emprestimo/models/confirmacao-emprestimo';
import { BloqueioSimulador } from './../domain/gestaofinanceira/emprestimo/models/bloqueio-simulador';
import { SituacaoAtual } from './../domain/gestaofinanceira/emprestimo/models/situacao-atual';
import { SaldoEmprestimo } from './../domain/gestaofinanceira/emprestimo/models/saldo-emprestimo';
import { SaldoIR } from './../models/saldo-ir.model';
import { ExtratoMes } from './../domain/gestaofinanceira/emprestimo/models/extrato-mes';
import { Boleto } from './../domain/gestaofinanceira/emprestimo/models/boleto';
import { CobrancaGerarBoleto } from './../domain/gestaofinanceira/emprestimo/models/cobranca-gerar-boleto';
import { ExtratoIR } from './../domain/gestaofinanceira/emprestimo/models/extrato-ir';
import { ResultMessage } from './../domain/gestaofinanceira/emprestimo/models/result-message';
import { SaldoDevedor } from './../domain/gestaofinanceira/emprestimo/models/saldo-devedor';
import { ContratoAberturaCredito } from './../domain/gestaofinanceira/emprestimo/models/contrato-abertura-credito';

import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';

import { Contrato } from '../domain/gestaofinanceira/emprestimo/models/contrato';
import { SimulacaoEmprestimo } from '../domain/gestaofinanceira/emprestimo/models/simulacao-emprestimo';
import { Extrato } from '../domain/gestaofinanceira/emprestimo/models/extrato';
import { DatePipe } from '@angular/common';
import { Cobranca, HistoricoEmprestimo, TipoBloqueio, SimulacaoPercentual } from '../domain';
import { Observable } from 'rxjs';
import { ContratoProntoParaAssinar } from '../domain/gestaofinanceira/emprestimo/models/contrato-pronto-para-assinar';
import { ETipoEnvioNotificacao } from '../enums';

@Injectable()
export class GestaoFinanceiraEmprestimoService extends Service {
    private url: string;

    constructor(http: HttpClient, private datePipe: DatePipe, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_EMPRESTIMO;
    }

    EmprestimoAssinado(idDoContrato: number, idDocumento:string): Observable<any> {
        const url = `${this.url}contrato/${idDoContrato}/solicitacaoassinada`;
        return this.post(url, { idDocument: idDocumento, IdDocumentoProvedorExterno: idDocumento });
    }

    // Atena Service - Retorna string com mensagem de algum tipo de Bloqueio
    // Consulta Carteira, Plano, Participante, Elegibilidade, Contrato Pendente, etc.
    Emprestimo_GetBloqueio(): Observable<TipoBloqueio> {
        const url = `${this.url}Bloqueio`;
        return this.cachedGet<TipoBloqueio>(url);
    }

    // Atena Service - Retorna string se há bloqueio da Carteira de Empréstimo
    Emprestimo_GetBloqueioCarteiraEmprestimo(): Observable<string> {
        const url = `${this.url}Bloqueio/GetBloqueioCarteiraEmprestimo`;
        return this.get<string>(url);
    }

    // Retorna objeto de erros simulação empréstimo
    Emprestimo_GetBloqueioSimulacaoEmprestimo(): Observable<BloqueioSimulador> {
        const url = `${this.url}Bloqueio/GetBloqueioSimulacaoEmprestimo`;
        return this.get<BloqueioSimulador>(url);
    }

    // Atena Service - Retorna o Contrato de Abertura de Crédito para ser assinado.
    Emprestimo_GetContratoAberturaCredito(callBack: Function, thisArg: any) {
        const url = `${this.url}Contrato/GetContratoAberturaCredito`;
        this.baixarArquivo(url, 'application/pdf', callBack, thisArg);
    }

    // Atena Service - Confirma que o participante fez a assinatura do contrato de abertura de crédito
    Emprestimo_ConfirmaRecebimentoContratoAberturaCredito(dataReferencia: Date): Observable<string> {
        const dataRef = this.datePipe.transform(dataReferencia, 'yyyy-MM-dd');
        const url = `${this.url}Contrato/ConfirmaRecebimentoContratoAberturaCredito/${dataRef}`;
        return this.get<string>(url);
    }

    // Atena Service - Retorna se o participante possui a última versão do contrato de abertura de crédito
    Emprestimo_UltimaVersaoContratoAberturaCreditoParticipante(): Observable<ContratoAberturaCredito> {
        const url = `${this.url}Contrato/UltimaVersaoContratoAberturaCreditoParticipante`;
        return this.get<ContratoAberturaCredito>(url);
    }

    // Atena Service - Retorna os contratos de um participante
    Emprestimo_GetContratos(): Observable<Contrato[]> {
        const url = `${this.url}Contrato`;
        return this.get<Contrato[]>(url);
    }

    // Atena Service - Retorna os contratos em ordem decrescente dos participantes
    Emprestimo_GetContratosPainel(cached: boolean = true): Observable<HistoricoEmprestimo[]> {
        const url = `${this.url}Contrato/GetContratosPainel`;
        if (cached) {
            return this.cachedGet<HistoricoEmprestimo[]>(url);
        } else {
            return this.get<HistoricoEmprestimo[]>(url);
        }
    }

    // Atena Service - Retorna o último contrato ativo de um participante
    Emprestimo_GetContratoAtivo(cached: boolean = true): Observable<Contrato> {
        const url = `${this.url}Contrato/GetContratoAtivo`;
        if (cached) {
            return this.cachedGet<Contrato>(url);
        } else {
            return this.get<Contrato>(url);
        }
    }

    // Atena Service - Retorna o último contrato Pendente de um participante
    Emprestimo_GetContratoPendente(cached: boolean = true): Observable<Contrato> {
        const url = `${this.url}Contrato/GetContratoPendente`;
        if (cached) {
            return this.cachedGet<Contrato>(url);
        } else {
            return this.get<Contrato>(url);
        }
    }

    // Atena Service - Retorna o time-line de emprestimo do participante.
    Emprestimo_GetHistoricoSituacaoContratoPendente(): Observable<TimeLineEmprestimo> {
        const url = `${this.url}Contrato/GetHistoricoSituacaoContratoPendente`;
        return this.get<TimeLineEmprestimo>(url);
    }

    // Atena Service - Retorna a Situação do contrato Pendente de um participante (true/false)
    Emprestimo_GetSituacaoContratoPendente(): Observable<boolean> {
        const url = `${this.url}Contrato/GetSituacaoContratoPendente`;
        return this.cachedGet<boolean>(url);
    }

    // Atena Service - Retorna o saldo devedor de um contrato de empréstimo para a data específica
    Emprestimo_GetSaldoDevedor(dataReferencia: Date, cached: boolean = true): Observable<SaldoDevedor> {
        const dataRef = this.datePipe.transform(dataReferencia, 'yyyy-MM-dd');
        const url = `${this.url}Contrato/GetSaldoDevedor/${dataRef}`;
        if (cached) {
            return this.cachedGet<SaldoDevedor>(url);
        } else {
            return this.get<SaldoDevedor>(url);
        }
    }

        // Atena Service - Retorna o saldo devedor de um contrato de empréstimo para a data específica
        Emprestimo_GetSaldoDevedorConcessao(cached: boolean = true): Observable<SaldoDevedor> {
            const url = `${this.url}Contrato/GetSaldoDevedorConcessao}`;
            if (cached) {
                return this.cachedGet<SaldoDevedor>(url);
            } else {
                return this.get<SaldoDevedor>(url);
            }
        }

    // Atena se existir se nao Procedure - Retorna o saldo do IR
    Emprestimo_GetSaldoIR(cached: boolean = true) {
        const url = `${this.url}Contrato/GetSaldoIR`;
        if (cached) {
            return this.cachedGet<SaldoIR>(url);
        } else {
            return this.get<SaldoIR>(url);
        }
    }

    // Atena Service - Retorna o saldo devedor de um contrato de empréstimo para a data específica
    Emprestimo_GetSaldo(cached: boolean = true) {
        const url = `${this.url}Contrato/GetSaldoEmprestimo`;
        if (cached) {
            return this.cachedGet<SaldoEmprestimo>(url);
        } else {
            return this.get<SaldoEmprestimo>(url);
        }
    }

        // Atena Service - Retorna o saldo devedor de um contrato de empréstimo para a data específica
        Emprestimo_GetSaldoConcessao(cached: boolean = true) {
            const url = `${this.url}Contrato/GetSaldoEmprestimoConcessao`;
            if (cached) {
                return this.cachedGet<SaldoEmprestimo>(url);
            } else {
                return this.get<SaldoEmprestimo>(url);
            }
        }

    // Atena Service - Verifica as carências e regras de elegibilidade e retorna os tipos de empréstimo disponíveis
    Emprestimo_GetVerificarElegibilidade(): Observable<ResultMessage> {
        const url = `${this.url}Contrato/GetVerificarElegibilidade`;
        return this.get<ResultMessage[]>(url);
    }

    // Atena Service - Retorna os atributos para popular o card situação atual.
    Emprestimo_GetSituacaoAtual() {
        const url = `${this.url}Contrato/GetSituacaoAtual`;
        return this.cachedGet<SituacaoAtual>(url);
    }

    // Atena Service - Retorna os atributos para popular o card pagamento.
    Emprestimo_GetPagamento(): Observable<PagamentoEmprestimo> {
        const url = `${this.url}Contrato/GetPagamentos`;
        return this.get<PagamentoEmprestimo>(url);
    }

    // Atena Service - Retorna os valores para limitação do empréstimo - 1 = Tradicional, 2 = Plus
    Emprestimo_GetValorSimulacaoEmprestimo(tipoEmprestimo: Number): Observable<SimulacaoEmprestimo> {
        const url = `${this.url}Contrato/GetValoresSimulacaoEmprestimo/${tipoEmprestimo}`;
        return this.cachedGet<SimulacaoEmprestimo>(url);
    }

    // Atena Service - Retorna todos os valores para simulação de empréstimo - 1 = Tradicional, 2 = Plus
    Emprestimo_GetValoresSimulacaoEmprestimo(): Observable<SimulacaoEmprestimo[]> {
        const url = `${this.url}Contrato/GetValoresSimulacaoEmprestimo`;
        return this.cachedGet<SimulacaoEmprestimo[]>(url);
    }

    // Atena Service - Retorna as simulações de credito
    Emprestimo_SimularEmprestimo(simulacaoEmprestimo: SimulacaoEmprestimo): Observable<SimulacaoEmprestimo> {
        const url = `${this.url}Contrato/SimularEmprestimo`;
        return this.post<SimulacaoEmprestimo>(url, simulacaoEmprestimo);
    }
    // Pega dados para modal confirmação de empréstimo.
    Emprestimo_PlanoDeParcelamento(simulacaoContrato: SimulacaoValores): Observable<ConfirmacaoEmprestimo> {
        const url = `${this.url}Contrato/PlanoDeParcelamento`;
        return this.post<ConfirmacaoEmprestimo>(url, simulacaoContrato);
    }

    // Atena Service - Retorna as simulações de percentuais
    Emprestimo_SimularPercentual(): Observable<SimulacaoPercentual[]> {
        const url = `${this.url}Contrato/SimularPercentuais`;
        return this.cachedGet<SimulacaoPercentual[]>(url);
    }

    // Atena Service - Retorna a confirmação de alteração de percentual
    Emprestimo_AlterarPercentual(simulacaoEmprestimo: SimulacaoPercentual): Observable<boolean> {
        const url = `${this.url}Contrato/AlterarPercentual`;
        return this.post<boolean>(url, simulacaoEmprestimo);
    }

    // Atena Service - Insere a Solicitação no Sistema Atena
    Emprestimo_InserirSolicitacao(contrato: Contrato, tipoEnvioNotificacao: ETipoEnvioNotificacao): Observable<ContratoProntoParaAssinar> {
        const url = `${this.url}Contrato/InserirSolicitacao/${tipoEnvioNotificacao}`;
        return this.post<ContratoProntoParaAssinar>(url, contrato);
    }

    // Atena Service - Insere a Solicitação no Sistema Atena
    Emprestimo_ConfirmarSolicitacao(matricula: string, idContrato: number, token: string): Observable<any> {
        const url = `${this.url}Contrato/ConfirmarSolicitacao/${matricula}/${idContrato}/${token}`;
        return this.get<any>(url);
    }

    Emprestimo_FinalizarSolicitacaoPendente(idContrato: number, eTipoEnvioNotificacao: ETipoEnvioNotificacao): Observable<string> {
        const url = `${this.url}Contrato/FinalizarSolicitacaoPendente/${idContrato}/${eTipoEnvioNotificacao}`;
        return this.get<string>(url);
    }

    // Atena Service - Insere a Solicitação no Sistema Atena
    Emprestimo_ReenviarToken(idContrato: number): Observable<string> {
        const url = `${this.url}Contrato/ReenviarToken/${idContrato}`;
        return this.get<string>(url);
    }

    Emprestimo_GetDocumentosPorId(idContrato: Number, callback?: Function, thisArg?: any) {
        const url = `${this.url}Contrato/GetDocumentosPorId/${idContrato}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    Emprestimo_GetContratoSESuite(matricula: string, idContrato: number, callback?: Function, thisArg?: any) {
        const url = `${this.url}Contrato/GetContratoSESuite/${matricula}/${idContrato}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    Emprestimo_CheckContratoSESuite(matricula: string, idContrato: number): Observable<boolean> {
        const url = `${this.url}Contrato/CheckContratoSESuite/${matricula}/${idContrato}`;
        return this.get<boolean>(url);
    }

    // Atena Service - Insere a Solicitação no Sistema Atena
    Emprestimo_GerarBoletoOnline(cobranca: CobrancaGerarBoleto): Observable<Boleto> {
        cobranca.dataVencimento = this.datePipe.transform(cobranca.dataVencimento, 'yyyy-MM-dd');
        const url = `${this.url}Boleto/GerarBoletoOnline`;
        return this.post<Boleto>(url, cobranca);
    }

    // Obtem o boleto pelo tipo de pagamento.
    Emprestimo_ObterBoleto(pagamentoEmprestimo: PagamentoEmprestimo): Observable<Boleto> {
        const url = `${this.url}Boleto/ObterBoleto`;
        return this.post<Boleto>(url, pagamentoEmprestimo);
    }

    Emprestimo_ListarCobrancas(): Observable<Cobranca[]> {
        const url = `${this.url}Boleto/ListarCobrancas`;
        return this.get<Cobranca[]>(url);
    }

    // Atena Service - Retorna o extrato de empréstimo ou o extrato para fins de Imposto de Renda em PDF.
    Emprestimo_ExtratoPdfPorNumeroContrato(idContrato: Number, dataReferencia: Date, callBack: Function, thisArg: any) {
        const dataRef = this.datePipe.transform(dataReferencia, 'yyyy-MM-dd');
        const url = `${this.url}Extrato/PdfPorContrato/${idContrato}/${dataRef}`;
        this.baixarArquivo(url, 'application/pdf', callBack, thisArg);
    }

    // Atena Service - Retorna o extrato de empréstimo por numero de contrato ativo
    Emprestimo_ExtratoPdfPorParticipante(callBack: Function, thisArg: any) {
        const url = `${this.url}Extrato/ExtratoPdfPorParticipante`;
        this.baixarArquivo(url, 'application/pdf', callBack, thisArg);
    }

    // Atena Service - Retorna o extrato de empréstimo por numero de contrato ativo
    Emprestimo_ItensExtratoPorContrato(cached: boolean = true): Observable<ExtratoMes[]> {
        const url = `${this.url}Extrato/ItensExtratoPorContrato`;
        if (cached) {
            return this.cachedGet<ExtratoMes[]>(url);
        } else {
            return this.get<ExtratoMes[]>(url);
        }
    }

    // Atena Service - Retorna o extrato de empréstimo ou o extrato para fins de Imposto de Renda
    Emprestimo_ExtratoPorContrato(numeroContrato: Number, ano: Number, dataReferencia: Date): Observable<Extrato> {
        const dataRef = this.datePipe.transform(dataReferencia, 'yyyy-MM-dd');
        const url = `${this.url}Extrato/ExtratoPorContrato/${numeroContrato}/${ano}/${dataRef}`;
        return this.get<Extrato>(url);
    }

    // Atena Service - Retorna o extrato de empréstimo ou o extrato para fins de Imposto de Renda
    Emprestimo_ExtratoPorExercicio(exercicio: Number): Observable<ExtratoIR> {
        const url = `${this.url}Extrato/ExtratoPorExercicio/${exercicio}`;
        return this.get<ExtratoIR>(url);
    }

    // Atena Service - Retorna o extrato de empréstimo ou o extrato para fins de Imposto de Renda
    Emprestimo_PdfPorExercicio(exercicio: Number, callback?: Function, thisArg?: any) {
        const url = `${this.url}Extrato/PdfPorExercicio/${exercicio}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    // Atena Service - Retorna o extrato de empréstimo ou o extrato para fins de Imposto de Renda
    Emprestimo_BoletoPDF(idBoleto: Number, callback?: Function, erroCallback?: Function, thisArg?: any) {
        const url = `${this.url}Boleto/GetBoletoPDF/${idBoleto}`;
        this.baixarArquivo(url, 'application/pdf', callback, erroCallback, thisArg);
    }
}
