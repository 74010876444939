import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import * as _ from "lodash";
import { FileUploadModel, UploadClientService } from '@fibra/fibra-shared-lib';
import { ConfirmationService, Message, MessageService } from 'primeng/api';

@Component({
    selector: 'app-file-upload-sesuite',
    templateUrl: './file-upload-sesuite.component.html',
    styleUrls: ['./file-upload-sesuite.component.css']
})
export class FileUploadSesuiteComponent implements OnInit {
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Input() visible: boolean = false;
    @Input() disabled: boolean = false;
    @Input() uploadedFiles: FileUploadModel[] = [];
    @Output() onFileUpload: EventEmitter<FileUploadModel> = new EventEmitter<FileUploadModel>();
    @Output() onFileRemoved: EventEmitter<string> = new EventEmitter<string>();
    @Output() onHide = new EventEmitter();
    @Output() uploadHandler = new EventEmitter<{ files: File[] }>();

    isLoading = false;
    private readonly path = "temp";
    errorMessages: Array<any> = [];
    successMessages: Array<any> = [];
    componentId = crypto.randomUUID();

    get totalAttached(): string {
        return this.uploadedFiles.length > 0 ? this.uploadedFiles.length.toString() : '';
    }

    constructor(private uploadClientService: UploadClientService,
        private confirmationService: ConfirmationService, public messageService: MessageService) { }

    ngOnInit(): void {
        this.visible = false;
        this.messageService.clear();
    }

    hideModal() {
        this.visible = false;
        this.messageService.clear();
        this.onHide.emit();
    }

    reset() {
        this.fileUpload.clear();
        this.uploadedFiles = [];
        this.messageService.clear();
    }

    uploadHandlerOrigin(filesObj: { files: File[] }) {
        this.messageService.clear();
        this.uploadHandler.emit(filesObj);
    }

    removeFile(id: string) {
        this.confirmationService.confirm({
            key: this.componentId,
            message: "Tem certeza que deseja excluir o arquivo?",
            accept: () => {
                if (_.some(this.uploadedFiles, { id: id })) {
                    _.remove(this.uploadedFiles, { id: id });
                    this.onFileRemoved.emit(id);
                }
            }
        });
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
        this.fileUpload.disabled = isLoading;
    }

    addMessage(message: Message, delayMessageMs: number = 5000) {
        this.messageService.add(message);
        setTimeout(() => {
            this.messageService.clear();
        }, delayMessageMs);
    }
}
