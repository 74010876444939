export const NomeArquivos = {
    COMITE_INVESTIMENTOS: 'ExtratoCI.pdf',
    PREF_COMITE_INVESTIMENTOS: 'CI',
    CONSELHO_DELIBERATIVO: 'ExtratoCD.pdf',
    PREF_CONSELHO_DELIBERATIVO: 'CD',
    CONSELHO_FISCAL: 'ExtratoCF.pdf',
    PREF_CONSELHO_FISCAL: 'CF',
    DECLARACAO_PESSOAL_EXPOSTA: 'DeclaracaoPPE.PDF',
    DEMONSTRATIVO_ATUARIAL: 'da_assinado.pdf',
    DIRETORIA_EXECUTIVA: 'ExtratoDE.pdf',
    PREF_DIRETORIA_EXECUTIVA: 'DE',
    ESTATUTO: 'estatuto.pdf',
    FORM_ALT_CADASTRAL: 'AlteracaoFichaCadastral.pdf',
    HIST_INPC: 'inpc_juros.pdf',
    MANUAL_PARTICIPANTE: 'manual.pdf',
    NOMEACAO_DESIGNADOS: 'NomeacaoDesignado.pdf',
    NORMA_EMPRESTIMO: 'normas_emp.pdf',
    NORMA_INTERNA_ANTECIPACAO_SUPLEMACAO: 'norma_antecipacao_temp_suplementacao.pdf',
    NORMA_INTERNA_CALCULO_PART_REQUISITADOS: 'norma_requisitado.pdf',
    NORMA_INTERNA_TEMPO_CONTRIBUICAO: 'norma_comprovacao_tempo_contrib.pdf',
    NORMA_TAXA_ADMINISTRACAO: 'norma_taxa_administracao.pdf',
    NORMA_TAXA_INSCRICAO: 'norma_taxa_inscricao.pdf',
    REGULAMENTO_PLANO: 'regulamento.pdf',
    REL_INDICACAO_BENEF_SEG: 'RelIndicacaoBenSegur.pdf',
    SEGURO_VIDA_GRUPO: 'RelIndicacaoBenSegur.pdf',
    SOLCITACAO_AUTOPATROC_PARCIAL: 'SolicitAutopatParcial.pdf',
    SOLICITACAO_INSCRICAO_BENEF: 'SolicitInscBenef.pdf',
    TB_MULTIPLICADORA: 'tab_multiplicador.pdf',
    PPDV: 'PPDV.pdf',
    DEPENDENTES_IR: 'Dependentes_IR',
    ADIANTAMENTO_ABONO: 'Adiantamento_Suplementacao_Abono_Anual.pdf',
    PCN: 'lista-contatos-pcn.pdf',
    PROPOSTA_ALTERACAO_ESTATUTO: 'proposta-alteracao-estatuto.pdf',
    GUIA_ACESSO_REMOTO: 'guia-acesso-remoto.pdf',
    CERTIFICADO_PARTICIPANTE: 'certificado-participante-bd.pdf',
    POLITICA_INVESTIMENTOS_PBD: 'POL-INVESTIMENTOS-PBD.pdf',
    POLITICA_INVESTIMENTOS_PGA: 'POL-INVESTIMENTOS-PGA.pdf',
    REGULAMENTO_INVESTIMENTOS: 'REGULAMENTO-INVESTIMENTOS.pdf',
    REGULAMENTO_RISCOS_COMPLIANCE: 'REGULAMENTO-RISCOS-COMPLIANCE.pdf',
    LAMINA_BD:'LAMINA-BD.pdf'
};