import { Component, Input, OnInit } from '@angular/core';
import {
    GestaoRelacionamentoComunicacaoService,
    ERRO_CRITICO_DE_CARREGAMENTO,
    SimulacaoPercentual,
    GestaoFinanceiraEmprestimoService
} from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-modal-token',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.css']
})
export class ModalTokenComponent implements OnInit {
    @Input() exibirModal: boolean;
    @Input() planoDeParcelamentoEscolhido: SimulacaoPercentual;
    @Input()  set telefone(telefone: any) {
        if (telefone) {
            this.textoMensagem(telefone);
        }
    }

    readonly MENSAGEM_ERRO =  ERRO_CRITICO_DE_CARREGAMENTO;
    errorSMS = false;
    sendSMS = false;
    erro: boolean;
    msgErro: string;
    submitAttempted = false;
    confirmacao: boolean;
    naoMostrar = true;
    token: string;
    dataVigente: Date;
    telefoneMascara: string;
    mensagemLinha1: string;
    mensagemLinha2: string;
    contDigitos = 0;
    habilitaBotao = false;
    classeBotao = 'btn-success';
    isLoading = true;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
                private gestaoRelacionamentoComunicacaoService: GestaoRelacionamentoComunicacaoService) { }

    ngOnInit() {}

    textoMensagem(telefones: any) {
        this.isLoading = false;
        this.telefoneMascara = telefones ? telefones.substring(0, telefones.length - 2).replace(/[0-9]/g, '*') : '';
        this.telefoneMascara = this.telefoneMascara.concat(telefones.substring(telefones.length - 2));
        this.mensagemLinha1 = 'Enviamos um SMS com um código de';
        this.mensagemLinha2 = 'verificação para ' + this.telefoneMascara;
    }

    confirmarToken() {
        this.isLoading = true;
        this.submitAttempted = true;
        this.gestaoRelacionamentoComunicacaoService.Verificar_SMSValido(Number(this.token)).subscribe(data => {
            this.errorSMS = !data;
            if (data && data === true) {
                this.alterarPercentual();
            } else {
                this.isLoading = false; this.token = '';
            }
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    alterarPercentual() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_AlterarPercentual(this.planoDeParcelamentoEscolhido).subscribe(simulacao => {
            this.dataVigente = new Date(this.planoDeParcelamentoEscolhido.dataReferencia);
            this.dataVigente = new Date(this.dataVigente.setMonth(this.dataVigente.getMonth() + 1));
            if (simulacao) {
                this.confirmacao = true;
                this.isLoading = false;
            }
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    enviarNovoCodigo() {
        this.isLoading = true;
        this.sendSMS = false;
        this.gestaoRelacionamentoComunicacaoService.Enviar_SMSToken().subscribe(data => {
            this.isLoading = false;
            if (data && (data.descricao !== 'Ok' && data.descricao !== 'Error')) {
                this.erro = true;
                this.msgErro = data.descricao;
                return;
            }
            this.mensagemLinha1 = 'Reenviamos um novo SMS com o código de';
            this.mensagemLinha2 = 'verificação para ' + this.telefoneMascara;
            this.errorSMS = false;
            this.token = '';
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    enviarPorEmail() {
        this.isLoading = true;
        this.sendSMS = false;
        this.gestaoRelacionamentoComunicacaoService.Enviar_SMSPorEmail().subscribe(data => {
            if (data && (data.descricao !== 'Ok' && data.descricao !== 'Error')) {
                this.erro = true;
                this.msgErro = data.descricao;
                this.isLoading = false;
                return;
            }
            this.errorSMS = false;
            this.sendSMS = true;
            this.token = '';
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
            this.erro = true;
            this.msgErro = this.MENSAGEM_ERRO;
        });
    }

    fecharModal() {
        this.exibirModal = false;
        this.naoMostrar = false;
        this.errorSMS = false;
        this.sendSMS = false;
        window.location.reload();
    }

    validaDigitos(e) {
        this.contDigitos = e.length;
        if (this.contDigitos === 6) {
            const element = document.getElementById('btnEnviar');
            element.classList.add(this.classeBotao);
            this.habilitaBotao = true;
        } else {
            const element = document.getElementById('btnEnviar');
            element.classList.remove(this.classeBotao);
            this.habilitaBotao = false;
        }
    }
}
