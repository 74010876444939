import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdesaoService, DocumentoService, ESituacaoDocumento, IndiceFinanceiroService, TipoSeDocumento, TipoSituacaoParticipante } from '@fibra/fibra-shared-lib';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { PdfViewerComponent } from '../../components/pdf-viewer/pdf-viewer.component';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';


@Component({
    selector: 'app-plano-bd-saldado-assistido',
    templateUrl: './plano-bd-saldado-assistido.component.html',
    styleUrls: ['./plano-bd-saldado.assistido.component.css']
})
export class PlanoBdSaldadoAssistidoComponent implements OnInit {
    assistido = true;
    isLoading: boolean = false;
    mostrarOpcaoIndice: boolean = false;
    resumoAdesao: ResumoAdesaoDto;
    @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;

    constructor(private router: Router,
        private indiceFinanceiroService: IndiceFinanceiroService,
        private adesaoService: AdesaoService,
        private documentoService: DocumentoService) { }

    ngOnInit() {

        this.goToPainel();
        return;

        this.isLoading = true;

        this.resumoAdesao = this.adesaoService.getAdesaoContexto();

        if( this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.ATIVO || this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.AUTOPATR_PARCIAL || this.resumoAdesao.tipoSituacao == TipoSituacaoParticipante.AUTOPATR_TOTAL)
        {
            this.router.navigate(['/painel/plano-bd/inicio']);
        }
        else{

            if((this.resumoAdesao.migracoes?.length > 0)){
                this.router.navigate(['/painel/plano-bd/inicio']);
            }
            else{
                if (this.resumoAdesao.migracoes?.length <= 0)
                {
                    this.indiceFinanceiroService.getSituacaoAssinaturaDocumento()
                    .subscribe(dados => {
                        this.isLoading = false;
                        if (dados.data.situacao == ESituacaoDocumento.ASSINADO) {
                            this.router.navigate(['/painel/plano-bd/inicio']);
                        } else {
                            this.mostrarOpcaoIndice = true;
                        }
                    }, error => {
                        this.isLoading = false;
                        console.error(error);
                    });
                }
            }
        }
    }

    goToPainel() {
        this.router.navigateByUrl('/painel/plano-bd/inicio');
    }

    abrirCartilhaSaldamento(){
        this.isLoading = true;
        let fileName =  encodeURIComponent('Cartilha Online Ativos e Assistidos.pdf');
        let request = this.documentoService.getSeDocsByName(TipoSeDocumento.SinteseSaldamentoAtivoAssistidos,fileName)
            .pipe(tap(data => {
                this.isLoading = false;
            }))
            .pipe(catchError((error: any) => {
                this.isLoading = false;
                console.error('An error occurred:', error);
                return throwError(error);
            }));

        this.pdfViewer.onSuccess.subscribe(success => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe(error => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request,true)
    }

}
