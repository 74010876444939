export enum FormasPagamento {
    DebitoConta = 1,
    Boleto = 2,
    DescontoFolha = 3
}

// export interface IFormasPagamento {
//     DebitoConta: string,
//     Boleto: string,
//     DescontoFolha: string
// }
// export const FormasPagamento: IFormasPagamento = {

//     DebitoConta: 'Débito em conta',
//     Boleto: 'Boleto bancário',
//     DescontoFolha: 'Desconto em folha'
// }