import { Component, OnInit } from '@angular/core';

import { GestaoPlanoBeneficioService } from '@fibra/fibra-shared-lib';
import { GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem } from '@fibra/fibra-shared-lib';
import { obterNomeDoMesAPartirDoNumeroDoMes } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-calendario-pagamentos-card',
    templateUrl: './calendario-pagamentos-card.component.html'
})
export class CalendarioPagamentosCardComponent implements OnInit {
    historicoPagamentos: GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem[] = [];
    isLoading = true;
    bloquear: boolean;
    mensagemDeErro: string;

    constructor(private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService) { }

    ngOnInit() {
        const anoAtual = new Date().getFullYear();
        this.gestaoPlanoBeneficioService.Historico_DatasBeneficio(anoAtual).subscribe(datas => {
            this.historicoPagamentos = datas.sort(this.ordenarPorDataDePagamentoCrescente);
            this.isLoading = false;
        }, () => this.informarErroDeCarregamento());
    }

    nomeDoMes(pagamento: GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem) {
        if (!pagamento) {
            return '?';
        }

        if (pagamento.adiantamento) {
            return 'Adiantamento do Abono Anual';
        }

        return obterNomeDoMesAPartirDoNumeroDoMes(pagamento.mes);
    }

    private ordenarPorDataDePagamentoCrescente(
        a: GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem,
        b: GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem
    ) {
        const dateA = new Date(a.dtPgto);
        const dateB = new Date(b.dtPgto);
        return dateA.getTime() < dateB.getTime() ? -1 : dateA.getTime() > dateB.getTime() ? 1 : 0;
    }

    private informarErroDeCarregamento() {
        this.mensagemDeErro = 'Desculpe, ocorreu um erro.';
        this.bloquear = true;
        this.isLoading = false;
    }
}
