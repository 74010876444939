export class GraphMemberOf {
    id: string;
    deletedDateTime?: any;
    description: string;
    displayName: string;
    roleTemplateId: string;
    classification?: any;
    createdDateTime?: Date;
    creationOptions: string[];
    expirationDateTime?: any;
    groupTypes: string[];
    isAssignableToRole?: any;
    mail: string;
    mailEnabled?: boolean;
    mailNickname: string;
    membershipRule?: any;
    membershipRuleProcessingState?: any;
    onPremisesDomainName: string;
    onPremisesLastSyncDateTime?: Date;
    onPremisesNetBiosName: string;
    onPremisesSamAccountName: string;
    onPremisesSecurityIdentifier: string;
    onPremisesSyncEnabled?: boolean;
    preferredDataLocation?: any;
    preferredLanguage?: any;
    proxyAddresses: string[];
    renewedDateTime?: Date;
    resourceBehaviorOptions: string[];
    resourceProvisioningOptions: string[];
    securityEnabled?: boolean;
    securityIdentifier: string;
    theme?: any;
    visibility: string;
    onPremisesProvisioningErrors: any[];
}


