import { Component, OnInit } from '@angular/core';
import { AnotacaoService, GestaoPlanoBeneficioService, SessionInitService } from '@fibra/fibra-shared-lib';

@Component({
    selector: "app-informe-rendimento",
    templateUrl: "./informe-rendimento.component.html",
    styleUrls: ["./informe-rendimento.component.css"],
})
export class InformeRendimentoComponent {
    isLoading = false;
    anos: Number[];
    anoSelecionado: number;
    mensagemDeErro: string;
    isMsgDialogVisible: boolean = false;
    informeRendimento: boolean = true;
    naoMostrarMsg: boolean;
    chave: string = "mostrar-msg-termo-indice";
    nota: string = "nao-mostrar";
    MostraErroInformeRendimento: boolean;

    constructor(
        private gestaoPlanoBeneficioService: GestaoPlanoBeneficioService,
        private sessionService: SessionInitService,
        private anotacaoService: AnotacaoService
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.gestaoPlanoBeneficioService
            .Lancamento_AnoContraCheque(this.informeRendimento)
            .subscribe(
                (dadosAnos) => {
                    this.anos = dadosAnos;
                },
                (error) => {
                    console.log(error);
                    this.informarErroDeCarregamento();
                },
                () => {
                    this.isLoading = false;
                    this.ValidaMostraModal();
                }
            );
    }

    ObterInformeRendimento(ano: number) {

        this.isLoading = true;

        try {
            this.gestaoPlanoBeneficioService.Beneficios_InformeRendimentos(
                ano,
                () => {
                    this.isLoading = false;
                },
                (error) => {
                    console.log(error);
                    this.isLoading = false;
                    this.MostraErroInformeRendimento = true;
                    this.informarErroDeCarregamento();

                }
            );
        } catch (error) {
            this.MostraErroInformeRendimento = true;
            this.informarErroDeCarregamento(error);
        }
    }

    private informarErroDeCarregamento(mensagemErro: string = null) {
        if (mensagemErro)
            this.mensagemDeErro = mensagemErro;
        else
            this.mensagemDeErro =
                "Desculpe, não foi possivel completar sua solicitação. Caso encontre dificuldades na geração do seu Informe de Rendimentos, entre em contato com a Fibra: telefone e whatsapp 0800 041 4404 / e-mail atendimento@fundacaoitaipu.com.br";
    }

    public fechaModal() {
        if (this.naoMostrarMsg) {
            this.anotacaoService.setAnotacoes(this.chave, this.nota);
        }

        this.isMsgDialogVisible = false;
    }

    private ValidaMostraModal() {

        var perfil = this.sessionService.sessionPerfil();

        var anotacao = perfil.anotacoes.filter(
            (f) => f.chave?.toLowerCase() == this.chave
        );

        if (
            anotacao.length > 0 &&
            anotacao[0].nota.toLocaleLowerCase() == this.nota
        ) {
            this.isMsgDialogVisible = false;
        } else {
            this.isMsgDialogVisible = true;
        }
    }
}
