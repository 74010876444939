import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RodapeComponent } from "./rodape.component";
import { InputMaskModule } from 'primeng/inputmask';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
	schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
	declarations: [
		RodapeComponent
	],
	imports: [
		CommonModule,
		InputMaskModule,
		NgxMaskModule
	],
	exports:[
		RodapeComponent
	]
})
export class RodapeModule{}