import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem, corrigirLinkYoutube
} from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-suspensao-contribuicao',
    templateUrl: './suspensao-contribuicao.component.html',
    styleUrls: ['./suspensao-contribuicao.component.css']
})
export class SuspensaoContribuicaoComponent implements OnInit {
    constructor(private router: Router){

    }

    ngOnInit() {

	}

    goToForm (){
        this.router.navigate(['/painel/plano-cd/solicitacoes/suspencao-contribuicao'])
    }
}
