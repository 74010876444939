import { RelacaoDependenciaSinqiaDto } from "./relacao-dependencia-sinqia.dto";

export class BeneficiarioResponseDto {
    id: number;
    idAdesao: number;
    nome: string;
    dataNascimento: Date;
    grauParentesco: string;
    percentualParticipacao: number;
    fator: number
    cpf: string;
    sexo: string;
    escolaridade: string;
    tipoDependencia: string;
    tipoDependenciaId: number
    cPFRepresentanteLegal: string;
    nomeRepresentanteLegal: string;
    remover: boolean;
    relacaoDependencia: RelacaoDependenciaSinqiaDto;
}