import { Component, OnInit } from '@angular/core';
import { AlterarPerfilInvestimentoDTO } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/alterar-perfil-investimento.dto';
import * as _ from "lodash";
import { SolicitacaoAlteracaoPerfilInvestimentoDTO } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/solicitar-alteracao-perfil-investimento.dto';
import { ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/resumo-adesao.dto';
import { AdesaoCdService, AdesaoService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-perfil-investimento-card',
    styleUrls: ['./perfil-investimento-card.component.css'],
    templateUrl: './perfil-investimento-card.component.html'
})
export class PerfilInvestimentoCardComponent implements OnInit {
    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;
    perfilInvestimentoDTO: AlterarPerfilInvestimentoDTO;
    ultimoPerfilInvestimento: SolicitacaoAlteracaoPerfilInvestimentoDTO;
    solicitacoes: SolicitacaoAlteracaoPerfilInvestimentoDTO[];
    ultimaData: Date;
    resumoAdesao: ResumoAdesaoDto;

    constructor(private adesaoCDService: AdesaoCdService, private adesaoService: AdesaoService) { 
         
	}

    ngOnInit() {
        this.isLoading = true;
        this.ObterPerfilInvestimento();        
    }

    ObterPerfilInvestimento(){
        this.resumoAdesao = this.adesaoService.getAdesaoContexto();                
        this.adesaoCDService.ObterPerfilInvestimento().subscribe(perfilInvestimento => {
            this.isLoading = true;
            if(perfilInvestimento.data)
            {
                this.perfilInvestimentoDTO = perfilInvestimento.data;

                if(this.perfilInvestimentoDTO.solicitacoes.length > 0) {

                    this.solicitacoes =[{
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: this.resumoAdesao?.dataInscricao
                    }]

                    this.solicitacoes = this.solicitacoes.concat(this.perfilInvestimentoDTO.solicitacoes);

                    this.ultimoPerfilInvestimento = _.last(this.solicitacoes);
                }
                else
                {
                    this.ultimoPerfilInvestimento = {                                                
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: this.resumoAdesao.dataInscricao
                       }

                       this.solicitacoes =[{
                        perfilInvestimento: this.perfilInvestimentoDTO?.descricaoPerfilAnterior,
                        dataCompetencia: this.resumoAdesao.dataInscricao
                    }]
                }

            }
            this.isLoading = false;
        })
    }
}