import { EDocumentoApresentado } from "../enums/documento-apresentado.enum";


export const DocumentoApresentadoText = new Map<EDocumentoApresentado, string>([
    [EDocumentoApresentado.CarteiraTrabalho, "Carteira de trabalho"],
    [EDocumentoApresentado.GuiasRecolhimentoPrevidenciario, "Guias de recolhimento previdenciário"],
    [EDocumentoApresentado.ComprovantesVinculoProfissional, "Comprovantes de vínculo profissional"],
    [EDocumentoApresentado.GuiasRecolhimentoAutonomo, "Guias de recolhimento como autônomo"],
    [EDocumentoApresentado.CertificadoReservista, "Certificado de reservista"],
    [EDocumentoApresentado.CertidaoTempoServico, "Certidão de tempo de serviço"],
    [EDocumentoApresentado.Outros, "Outros"]
]);
