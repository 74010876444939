export function PalavraInvalida(endereco: string , logradouro: any): string | boolean {

    let palavraInvalida: string | boolean;
    
    endereco = endereco.trim();

    if(endereco)
    {
        for (let i = 0; i < logradouro.length; i++) {

            if (endereco.toLowerCase().indexOf(logradouro[i].descLogradouro.toLowerCase()) >= 0)
            {
               let palavra = endereco.indexOf(' ') >= 0 ? endereco.split(' ') : [endereco.toUpperCase()];
               palavraInvalida = palavra[0];
               return palavraInvalida ? palavraInvalida : 'do Logradouro';
            } 
            else
            {
                palavraInvalida = false;
            }
        }
        return palavraInvalida;
    }
    return false;
}

