<nav class="navbar align-items-start navbar-expand-lg navbar-light bg-light" id="side-menu">
    <a class="navbar-brand" href="#"></a>
    <button #navbarToggler class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" autofocus>
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav level-0" id="accordion" #accordion>
            <ng-template #items let-config>
                <ng-container *ngFor="let item of config.items, index as i">
                    <li *ngIf="isAuthorizedToAccess(item)">
                        <span class="icon" *ngIf="item.Icon" [innerHTML]="item.Icon | safe: 'html'"></span>
                        <ng-container *ngIf="item.RouterLink || item.DownloadFile || item.DownloadSeFile || item.DownloadSeFileByName || item.OpenExternalURL else subitems">
                            <ng-container *ngIf="item.DownloadFile || item.DownloadSeFile || item.DownloadSeFileByName else link">
                                <ng-container *ngIf="item.DownloadFile">
                                    <a href="javascript:void(0)" (click)="downloadFile(item.DownloadFile, item.Name); collapseNav()" class="item-name">{{ item.Name }}</a>
                                </ng-container>
                                <ng-container *ngIf="item.DownloadSeFile">
                                    <a href="javascript:void(0)" (click)="downloadSeDocs(item.DownloadSeFile, item.Name); collapseNav()" class="item-name">{{ item.Name }}</a>
                                </ng-container>
                                <ng-container *ngIf="item.DownloadSeFileByName">
                                    <a href="javascript:void(0)" (click)="downloadSeDocsByName(item.DownloadSeFileByName, item.NomeFileSE); collapseNav()" class="item-name">{{ item.Name }}</a>
                                </ng-container>
                            </ng-container>
                            <ng-template #link>
                                <ng-container *ngIf="item.RouterLink else externalURL">
                                    <a (click)="collapseNav()" [routerLink]="item.RouterLink" class="item-name">{{ item.Name }}</a>
                                </ng-container>
                                <ng-template #externalURL>
                                    <a href="javascript:void(0)" (click)="openExternalURL(item.OpenExternalURL)" target="blank" class="item-name">{{ item.Name }}</a>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                        <ng-template #subitems>
                            <ng-container *ngIf="item.Collapse else nomeItem">
                                <a href="#{{ getId(item) }}" class="item-name" data-toggle="collapse" aria-expanded="false" [attr.aria-controls]="getId(item)">{{ item.Name }}</a>
                                <svg class="menu-icon chevron-flag-right">
                                <use xlink:href="/assets/chevron-right.svg#image"></use>
                            </svg>
                                <svg class="menu-icon chevron-flag-down">
                                <use xlink:href="/assets/chevron-down.svg#image"></use>
                            </svg>
                            </ng-container>
                            <ng-template #nomeItem>
                                <span class="item-name">{{ item.Name }}</span>
                            </ng-template>
                            <ul class="navbar-nav" [class.collapse]="item.Collapse" [attr.id]="getId(item)" [attr.data-parent]="'#' + (item.ForceCollapsibleParentToBe || config.parent)" [ngClass]="'level-' + (config.currentDepth + 1)" *ngIf="item.Items">
                                <ng-container *ngTemplateOutlet="items; context:{ $implicit: { items: item.Items, currentDepth: config.currentDepth + 1, parent: getId(item) }}"></ng-container>
                            </ul>
                        </ng-template>
                    </li>
                </ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="items; context: { $implicit: { items: config, currentDepth: 0, parent: 'accordion' }}"></ng-container>
        </ul>
        <br/>
    </div>
</nav>
<app-modal [(isVisible)]="baixandoArquivo" [showCloseButton]="false" [showCloseIcon]="false" [exitOnClickOutside]="false" [exitOnPressEsc]="false">
    <p><i class="fa fa-download text-success"></i></p>
    <p>O download de {{ nomeArquivoASerBaixado }} iniciará em instantes.</p>
    <p>Por favor, aguarde.</p>
    <p><img src="/assets/loading.svg" class="loading" /></p>
</app-modal>
