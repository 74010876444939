import { DadosCadastraisService, InfoTermo, LogradouroResponse } from '@fibra/fibra-shared-lib';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { DadosEndereco, DadosContaBancaria, inputChange } from '@fibra/fibra-shared-lib';
import { GestaoPlanoCadastroService, TermoAdesaoService, LinksIntegracao, Logradouro } from '@fibra/fibra-shared-lib';
import { CELULAR, CEP, TIPO_CONTAS } from '@fibra/fibra-shared-lib';
import { isString } from '@fibra/fibra-shared-lib';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-cadastrais-sinqia.dto';
import { ContaBancaria } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/conta-bancaria';
import { Endereco } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/endereco';
import { Contato } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/contato';
import { DadosTelefone } from '@fibra/fibra-shared-lib/lib/models/termo-adesao.model';
import { TermoAdesao } from '@fibra/fibra-shared-lib/lib/models/termo-adesao.model';
import { Agencia } from '@fibra/fibra-shared-lib/lib/models/termo-adesao.model';
import { Banco } from '@fibra/fibra-shared-lib/lib/models/termo-adesao.model';
import { CidadeSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/cidade-sinqia.dto';
import { DadosFibraDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-fibra.dto';

@Component({
    selector: 'app-termo-adesao',
    templateUrl: './termo-adesao.component.html',
    styleUrls: ['./termo-adesao.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class TermoAdesaoComponent implements OnInit {

    readonly OpenExternalURL = LinksIntegracao.WHATS_APP;
    readonly InfoTermo = InfoTermo;

    currentStep: number;
    contagemRegressiva = 3;

    isLoading = true;

    validForm: boolean;
    showError: boolean;
    chkAceitar: boolean;
    isPdfFile: boolean;
    isInvalidFormat: boolean;


    isSuccessDialogVisible = false;
    isModalVisible = false;
    agoraNao = true;

    imageSrc: any;
    file: any;
    termoHTML: any;
    pdfLink: any;
    pdfBlob: any;
    setIntervalId: any;

    errorMessage: string;
    errorImageMSF: string;

    readonly tipoConta = TIPO_CONTAS;
    readonly CELULAR = CELULAR;
    readonly CEP = CEP;
    readonly CORREIO_INDISPONIVEL =
        'Ops! Serviço do correios indispónivel, não será possível realizar a assinatura do termo. Tente novamente mais tarde.';

    formGroup: FormGroup;
    enderecoGroup: FormGroup;
    contaBancariaGroup: FormGroup;

    dadosCadastraisSinqiaDto: DadosCadastraisSinqiaDto;
    contaBancaria: ContaBancaria;
    termoAdesao: TermoAdesao;
    endereco: Endereco;
    contato: Contato;
    dadosFibra: DadosFibraDto;

    logradouro: any;
    logradouroResponse: LogradouroResponse;

    objLogradouro: any[] = [];
    filteredLogradouroSingle: any[];

    constructor(
        private termoAdesaoService: TermoAdesaoService,
        private router: Router,
        private gestaoPlanoCadastroService: GestaoPlanoCadastroService,
        private formBuilder: FormBuilder,
        private sanitizer: DomSanitizer,
        private dadosCadastraisService: DadosCadastraisService
    ) {
        this.currentStep = 1;
        this.errorImageMSF = '';

        this.termoAdesao = <TermoAdesao>{};
        this.termoAdesao.dadosContaBancaria = {} as DadosContaBancaria;
        this.termoAdesao.dadosContaBancaria.banco = {} as Banco;
        this.termoAdesao.dadosContaBancaria.agencia = {} as Agencia;
        this.termoAdesao.dadosEndereco = {} as DadosEndereco;
        this.termoAdesao.dadosEndereco.logradouro = {} as Logradouro;
        this.termoAdesao.dadosTelefone = {} as DadosTelefone;
        this.contato = {} as Contato;
        this.endereco = {} as Endereco;
        this.endereco.cidade = {} as CidadeSinqiaDto;

        this.logradouroResponse = <LogradouroResponse>{};
    }

    ngOnInit() {
        this.buildForm();
        if (sessionStorage.getItem('termAccepted') === 'true') {
            this.router.navigateByUrl(this.termoAdesaoService.callbackURL);
        } else {
            this.loadData();
        }
    }

    buildForm() {
        this.formGroup = this.formBuilder.group({
            celular: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(CELULAR.LENGTH_MIN_MASK),
                    Validators.maxLength(CELULAR.LENGTH_MAX_MASK)
                ])
            ],
            email: ['', Validators.required],
            enderecoGroup: this.formBuilder.group({
                endereco: [''],
                logradouro: [''],
                numero: [''],
                complemento: [''],
                bairro: [''],
                cidade: [{ value: '', disabled: true }, Validators.required],
                estado: [{ value: '', disabled: true }, Validators.required],
                cep: [
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.maxLength(10),
                        Validators.minLength(8)
                    ])
                ]
            }),
            contaBancariaGroup: this.formBuilder.group({
                banco: [{ value: '', disabled: true }, Validators.required],
                agencia: [{ value: '', disabled: true }, Validators.required],
                tipoConta: [{ value: '', disabled: true }, Validators.required],
                numeroConta: [{ value: '', disabled: true }, Validators.required],
                fotoConta: ['']
            })
        });

        this.enderecoGroup = this.formGroup.get("enderecoGroup") as FormGroup;
        this.contaBancariaGroup = this.formGroup.get(
            "contaBancariaGroup"
        ) as FormGroup;

        this.formGroup.statusChanges.subscribe(() => this.isValid());

        this.contaBancariaGroup.valueChanges.subscribe(() => {
            if (this.currentStep === 3) {
                this.contaBancariaGroup
                    .get("fotoConta")
                    .setValidators(Validators.required);
            }
        });
    }

    private loadData() {
        this.carregarCadastro();
        this.carregarLogradouro();
    }

    carregarLogradouro() {
        this.gestaoPlanoCadastroService.Cadastros_GetLogradouro().subscribe(info => {
            this.logradouro = info;
        });
    }

    carregarCadastro() {
        this.dadosCadastraisService.getCadastroGeral(true).subscribe(
            info => {
                if (info) {

                    //set objetos
                    this.dadosCadastraisSinqiaDto = info;

                    //set dados fibra
                    this.dadosFibra = this.dadosCadastraisSinqiaDto.adesoes &&
                        this.dadosCadastraisSinqiaDto.adesoes.length > 0 ?
                        this.dadosCadastraisSinqiaDto.adesoes[0].dadosFibra : null;

                    this.endereco = info.endereco;
                    this.contato = info.contato;
                    this.contaBancaria = info.contaBancaria;

                    //set valores em tela
                    this.setValuesContato();
                    this.setValuesEndereco();
                    this.setValuesContaBancaria();

                    this.isLoading = false;

                } else {
                    this.isLoading = false;
                }
            },
            error =>
                this.handleError(
                    error,
                    "Ocorreu um erro ao carregar os dados cadastrais."
                )
        );
    }

    private setValuesContato() {
        if (this.contato) {
            this.formGroup.get("celular").setValue(this.contato.celular);
            this.formGroup.get("email").setValue(this.contato.email);
        }

    }

    private setValuesContaBancaria() {
        if (
            this.contaBancaria &&
            this.contaBancaria.banco &&
            this.contaBancaria.agencia
        ) {
            this.contaBancariaGroup
                .get("banco")
                .setValue(this.contaBancaria.banco.nome);
            this.contaBancariaGroup
                .get("agencia")
                .setValue(this.contaBancaria.agencia);

            this.contaBancariaGroup
                .get("tipoConta")
                .setValue(this.contaBancaria.tipoConta);

            this.contaBancariaGroup
                .get("numeroConta")
                .setValue(this.contaBancaria.numero);
        }
    }

    private setValuesEndereco() {

        if (this.endereco) {
            this.enderecoGroup
                .get("numero")
                .setValue(this.endereco.numero);
            this.enderecoGroup
                .get("complemento")
                .setValue(this.endereco.complemento);
            this.enderecoGroup
                .get("bairro")
                .setValue(this.endereco.bairro);
            this.enderecoGroup
                .get("cidade")
                .setValue(this.endereco.cidade.nome);
            this.enderecoGroup
                .get("estado")
                .setValue(this.endereco.cidade.siglaUF);
            this.enderecoGroup
                .get("cep")
                .setValue(this.endereco.cep);
            this.enderecoGroup
                .get("logradouro")
                .setValue(this.endereco.tipoLogradouro);
            this.enderecoGroup
                .get("endereco")
                .setValue(this.endereco.logradouro);
        }
    }

    loadAddress(cep: string) {
        if (this.enderecoGroup.get("cep").valid) {
            this.isLoading = true;
            this.termoAdesaoService.consultaCEPCorreios(cep).subscribe(
                endereco => {
                    if (endereco) {
                        this.endereco.bairro = endereco.bairro;
                        this.endereco.cep = cep;
                        this.endereco.cidade.nome = endereco.cidade;
                        this.endereco.cidade.siglaUF = endereco.estado;
                        this.endereco.logradouro = endereco.logradouro.descLogradouro + " " + endereco.endereco;

                        this.setValuesEndereco();

                        this.isLoading = false;

                    } else {
                        this.resetarEndereco();
                        this.isLoading = false;
                    }
                },
                httpError => {
                    this.resetarEndereco();

                    if (httpError.error &&
                        httpError.status !== 400 &&
                        httpError.error.exceptionMessage !== 'CEP INVÁLIDO' &&
                        httpError.error.exceptionMessage !== 'CEP NAO ENCONTRADO') {
                        this.handleError(httpError, "Serviço dos correios indisponível!. Tente mais tarde.");
                    } else {
                        this.enderecoGroup.get('cep').setValue('');
                    }
                    this.isLoading = false;
                }
            );
        }
    }

    exitFlow() {
        if (this.currentStep > 1) {
            this.router.navigateByUrl(this.termoAdesaoService.callbackURL);
        } else {
            this.router.navigate(['/painel/plano-bd/inicio']);
        }
    }

    exitTermoAdesao() {
        this.isModalVisible = true;
        this.agoraNao = true;
    }

    exitAgoraNao() {
        this.router.navigate(['/painel/plano-bd/inicio']);
    }

    fecharModal() {
        this.isModalVisible = false;
        this.agoraNao = false;
    }

    marcarCheckbox() {
        this.chkAceitar = !this.chkAceitar;
    }

    nextStep(): void {
        this.showError = false;
        this.isValid();

        switch (this.currentStep) {
            case 1:
                this.currentStep++;
                break;
            case 3:
                this.getHtmlTermoAdesao();
                break;
            case 4:
                this.aceitarTermo();
                break;
            default:
                this.currentStep++;
        }
    }

    fecharModalErro() {
        this.showError = false;
        this.currentStep = 1;
        this.isModalVisible = false;
        this.errorMessage = null;
        this.isSuccessDialogVisible = false;
        this.chkAceitar = false;
        this.removeImage();
    }

    goBack(): void {
        this.showError = false;
        this.currentStep--;
    }

    removeImage() {
        this.imageSrc = '';
        this.isPdfFile = false;
        this.isInvalidFormat = false;
        this.termoAdesao.dadosContaBancaria.fotoDaConta = '';
        this.contaBancariaGroup.get('fotoConta').setValue(null);
    }

    isValid(): boolean {

        this.validForm = this.currentStep === 2 ?
            (this.formGroup.get("celular").valid &&
                this.formGroup.get("email").valid &&
                this.formGroup.get("enderecoGroup").valid &&
                this.enderecoGroup.get("cep").value &&
                this.enderecoGroup.get("cidade").value &&
                this.enderecoGroup.get("estado").value) :
            (this.currentStep === 3 &&
                this.errorImageMSF.length === 0 &&
                this.contaBancariaGroup.get("numeroConta").value);

        return this.validForm;
    }

    novoTelefone() {
        this.contato.celular = this.formGroup.get("celular").value;
        this.formGroup.get("celular").setValue(this.contato.celular);
    }

    novoEmail() {
        this.contato.email = this.formGroup.get("email").value;
        this.formGroup.get("email").setValue(this.contato.email);
    }

    resetarEndereco() {
        this.formGroup.get("enderecoGroup").reset();
        this.isLoading = false;
    }

    handleInputChange(e) {
        this.errorImageMSF = '';
        this.imageSrc = null;
        this.isPdfFile = false;
        this.isInvalidFormat = false;

        const reader = new FileReader();

        this.file = inputChange(e);

        this.errorImageMSF = isString(this.file) ? this.file : '';

        if (this.errorImageMSF === InfoTermo.FORMATO_INVALIDO) {
            this.isInvalidFormat = true;
        }

        if (!isString(this.file)) {
            this.isPdfFile = this.file.name.toLowerCase().endsWith('pdf');
        }

        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(this.file);
    }

    private handleReaderLoaded(e) {
        const reader = e.target;
        this.imageSrc = reader.result;
    }

    private getHtmlTermoAdesao(): void {

        this.prepararTermoAdesao();

        this.showError = false;
        this.isLoading = true;

        this.termoAdesaoService.getHtmlTermoAdesao(this.termoAdesao).subscribe(
            (r: string) => {
                this.currentStep++;
                const cleanHTML = r.replace("width: 210mm;", "");
                this.termoHTML = this.sanitizer.bypassSecurityTrustHtml(cleanHTML);

                this.termoAdesaoService.getPdfTermoAdesao(this.termoAdesao).subscribe(
                    (pdf: any) => {
                        this.pdfBlob = pdf;
                        const newBlob = new Blob([pdf], { type: "application/pdf" });
                        this.pdfLink = window.URL.createObjectURL(newBlob);
                        this.pdfLink = this.sanitizer.bypassSecurityTrustUrl(this.pdfLink);
                        this.isLoading = false;
                    },
                    () => {
                        this.isLoading = false;
                        this.showError = true;
                        this.errorMessage = InfoTermo.ERRO_OBTER_PDF;
                        this.isSuccessDialogVisible = false;
                    }
                );
            },
            () => {
                this.isLoading = false;
                this.showError = true;
                this.errorMessage = InfoTermo.ERRO_GRAVAR_TERMO;
                this.isSuccessDialogVisible = false;
            }
        );
    }

    private prepararTermoAdesao() {
        // Termo adequerido pela versão mobile
        this.termoAdesao.mobile = false;

        // dados cadastrais
        this.termoAdesao.nome = this.dadosCadastraisSinqiaDto.nome;
        this.termoAdesao.cPF = this.dadosCadastraisSinqiaDto.cpf;
        this.termoAdesao.regFibra = Number(this.dadosFibra.registroFibra);

        // contato
        let reg = new RegExp(/[^0-9]/g);
        const completePhone: string = this.contato.celular.replace(reg, '');
        const ddd = completePhone.substring(0, 2);
        const numero = completePhone.substring(2, completePhone.length);

        this.termoAdesao.dadosTelefone.ddd = ddd;
        this.termoAdesao.dadosTelefone.telefone = numero;
        this.termoAdesao.email = this.contato.email;

        // endereco
        this.termoAdesao.dadosEndereco.estado = this.endereco.cidade.siglaUF;
        this.termoAdesao.dadosEndereco.cidade = this.endereco.cidade.nome;
        this.termoAdesao.dadosEndereco.cep = this.enderecoGroup.get("cep").value;
        this.termoAdesao.dadosEndereco.numero = this.enderecoGroup.get("numero").value;
        this.termoAdesao.dadosEndereco.bairro = this.enderecoGroup.get("bairro").value;
        this.termoAdesao.dadosEndereco.endereco = this.enderecoGroup.get("endereco").value;
        this.termoAdesao.dadosEndereco.logradouro.descLogradouro = this.enderecoGroup.get("logradouro").value;
        this.termoAdesao.dadosEndereco.complemento = this.enderecoGroup.get("complemento").value;

        // conta bancaria
        this.termoAdesao.dadosContaBancaria.agencia.cdAgencia = this.contaBancaria.agencia;
        this.termoAdesao.dadosContaBancaria.banco.nomeBanco = this.contaBancaria.banco.nome;
        this.termoAdesao.dadosContaBancaria.contaBco = this.contaBancaria.numero;
        this.termoAdesao.dadosContaBancaria.tipoCc = this.contaBancaria.tipoConta;
        this.termoAdesao.dadosContaBancaria.fotoDaConta = this.imageSrc;

    }

    removeMascaras() {
        this.novoTelefone();
        let reg = new RegExp(/[^0-9]/g);
        this.termoAdesao.dadosEndereco.cep = this.termoAdesao.dadosEndereco.cep.replace(
            reg,
            ""
        );
    }

    private aceitarTermo(): void {
        this.isLoading = true;

        this.removeMascaras();

        this.termoAdesao.mobile = false;
        this.chkAceitar = false;

        this.termoAdesaoService.aceitarTermoAdesao(this.termoAdesao)
            .subscribe(() => {
                this.isLoading = false;
                this.errorMessage = InfoTermo.CADASTRO_ATUALIZADO;
                this.isSuccessDialogVisible = true;
                this.acionarContagemRegressiva();
            }, (error) => this.handleError(error, InfoTermo.ERRO_GRAVAR_TERMO));
    }

    private handleError(error: any, message: string) {
        console.log(error);
        this.isLoading = false;
        this.showError = true;
        this.chkAceitar = true;
        this.errorMessage = message;
        this.isSuccessDialogVisible = false;
    }

    acionarContagemRegressiva() {
        this.setIntervalId = setInterval(() => {
            this.contagemRegressiva--;

            if (this.contagemRegressiva === 0) {
                this.router.navigate(['/painel/plano-cd/inicio']);
                clearInterval(this.setIntervalId);
            }
        }, 1000);
    }

    openExternalURL(link: string) {
        window.open(link, 'blank');
    }

    onfocus() {
        this.enderecoGroup.get("cep").setValue("");
    }


    filterLogradouroSingle(event) {
        this.filteredLogradouroSingle = this.filterLogradouro(event.query, this.logradouro);
    }

    private filterLogradouro(query, logradouroR: any[]): any[] {
        const filtered: any[] = [];
        for (let i = 0; i < logradouroR.length; i++) {
            this.logradouroResponse = logradouroR[i];
            if (this.logradouroResponse.descLogradouro.toLowerCase().indexOf(query.toLowerCase()) === 0) {
                filtered.push(this.logradouroResponse.descLogradouro);
                this.objLogradouro.push(this.logradouroResponse);
            }
        }
        return filtered;
    }


    selectedLogradouro(event) {
        for (let i = 0; i < this.objLogradouro.length; i++) {
            const objLogradouro = this.objLogradouro[i];
            if (objLogradouro.descLogradouro.toLowerCase() === event.toLowerCase()) {
                this.endereco.tipoLogradouro = objLogradouro.descLogradouro;
                return;
            }
        }

    }
}
