import { Component, ViewChild } from '@angular/core';
import { AuthService, ArquivosService, AlertModalComponent } from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-designados-seguro-de-vida-tab',
    templateUrl: './designados-seguro-de-vida.component.html'
})
export class DesignadosSeguroDeVidaTabComponent {
    isLoading = false;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;

    constructor(private arquivosService: ArquivosService, private authService: AuthService) { }

    baixarArquivo() {
        this.isLoading = true;
        this.arquivosService.getArquivoSeguro(() => this.isLoading = false);
        /*this.authService.getRegFibra()
            .subscribe(regFibra => {
                this.arquivosService.getArquivoSeguroVida(regFibra, () => this.isLoading = false);
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });*/
    }
}
