import { CidadeSinqiaDto } from "./cidade-sinqia.dto";
import { ContaBancaria } from "./conta-bancaria";
import { Contato } from "./contato";
import { Endereco } from "./endereco";
import { EstadoCivilSinqiaDto } from "./estado-civil-sinqia.dto";
import { Identidade } from "./identidade";
import { PeriodosPoliticamenteExposto } from "./periodos-politicamente-exposto";
import { ResumoAdesaoDto } from "./resumo-adesao.dto";

export class DadosCadastraisSinqiaDto {
    nome: string;
    id: number;
    cpf: string;
    contaBancaria: ContaBancaria;
    dataNascimento: Date;
    idade: number;
    estadoCivil: EstadoCivilSinqiaDto;
    sexo: string;
    escolaridade: string;
    filiacaoMae: string;
    filiacaoPai: string;
    identidade: Identidade;
    naturalidade: CidadeSinqiaDto;
    endereco: Endereco;
    contato: Contato;
    nacionalidade: string;
    profissao: string;
    ocupacaoProfissional: string;
    nomeConjuge: string;
    periodosPoliticamenteExposto: PeriodosPoliticamenteExposto[];
    adesoes: ResumoAdesaoDto[];
    informacoesAdicionais: InformacoesAdicionais | null = null;
}

export class InformacoesAdicionais {
    assinarTermoAdesao: boolean | null = null;
    planosBeneficio: Array<any> = [];
    situacaoGeral: string | null = null;
    empregadoFibra: boolean = false;
}