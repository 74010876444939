import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rentabilidade',
    styleUrls: ['./rentabilidade.component.css'],
    templateUrl: './rentabilidade.component.html'
})
export class RentabilidadeComponent implements OnInit {

    isLoading = true;
	data: any;

    constructor() {
	}

    ngOnInit() {
        this.isLoading = false;
    }

}
