export const CRESCI_SALARIAL: any = [
	"0,08892795",
	"0,093568522",
	"0,098436004",
	"0,103539872",
	"0,108889823",
	"0,114495768",
	"0,120367809",
	"0,126516221",
	"0,132951432",
	"0,139683994",
	"0,146724555",
	"0,154083827",
	"0,161772547",
	"0,169801436",
	"0,178181158",
	"0,186922264",
	"0,196035146",
	"0,205529972",
	"0,215416629",
	"0,225704652",
	"0,236403158",
	"0,247520768",
	"0,259065528",
	"0,271044832",
	"0,283465331",
	"0,296332851",
	"0,309652304",
	"0,323427596",
	"0,337661539",
	"0,352355764",
	"0,367510632",
	"0,38312515",
	"0,399196893",
	"0,415721929",
	"0,43269475",
	"0,450108214",
	"0,467953496",
	"0,486220044",
	"0,504895554",
	"0,523965955",
	"0,543415408",
	"0,563226318",
	"0,583379363",
	"0,603853543",
	"0,624626236",
	"0,645673283",
	"0,666969075",
	"0,688486666",
	"0,7101979",
	"0,732073543",
	"0,754083439",
	"0,776196671",
	"0,798381728",
	"0,820606687",
	"0,842839395",
	"0,865047653",
	"0,887199404",
	"0,909262917",
	"0,931206967",
	"0,95300101",
	"0,974615351",
	"0,996021297",
	"1,017191301",
	"1,038099099",
	"1,058719821",
	"1,079030093",
	"1,099008127",
	"1,118633788",
	"1,137888644",
	"1,15675601",
	"1,175220963",
	"1,193270349",
	"1,210892776",
	"1,228078594",
	"1,244819855",
	"1,261110273",
	"1,276945165",
	"1,29232139",
	"1,307237277",
	"1,321692544",
	"1,335688222",
	"1,349226565",
	"1,362310966",
	"1,374945863",
	"1,387136654",
	"1,398889603",
	"1,410211757",
	"1,42111086",
	"1,431595266",
	"1,441673862",
	"1,451355992",
	"1,460651381",
	"1,469570071",
	"1,478122354",
	"1,486318711",
	"1,494169757",
	"1,501686193",
	"1,508878753",
	"1,515758166",
	"1,522335115",
	"1,528620202",
	"1,534623918",
	"1,540356614",
	"1,54582848",
	"1,55104952",
	"1,556029535",
	"1,560778111",
	"1,5653046",
	"1,569618117",
	"1,573727525",
	"1,577641435",
	"1,581368194",
	"1,584915891",
	"1,588292346",
	"1,591505119",
	"1,594561504",
	"1,597468533",
	"1,600232982",
	"1,602861369",
	"1,605359963",
	"1,607734786"
]
	