import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { AuthService, DadosCadastraisService } from '@fibra/fibra-shared-lib';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { PasswordTemplateComponent } from '../password-template/password-template.component';

@Component({
    selector: 'app-alterar-senha',
    templateUrl: './alterar-senha.component.html',
    styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
    cpf: string;
    formulario: FormGroup;
    isLoading = false;
    mensagem: string;
    senhaAlteradaComSucesso = false;
    showDialog = false;
    usuarioTentouEnviarFormulario = false;
    modalMessageOnCloseHref = null;
    dadosCad: DadosCadastraisSinqiaDto;
    paswwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    @ViewChild('passwordTemplate') passwordTemplate: PasswordTemplateComponent;


    get confirmarNovaSenha() {
        return this.formulario.get('confirmarNovaSenha');
    }

    get erroConfirmarNovaSenha() {
        if (!this.confirmarNovaSenha.value) {
            return '';
        }

        if ((this.confirmarNovaSenha.value || '').length === 0) {
            return 'Insira sua nova senha';
        }

        if (this.senhasNaoCoincidem) {
            return 'As senhas não coincidem';
        }
    }

    get erroNovaSenha() {
        if (!this.novaSenha.value) {
            return '';
        }

        if ((this.novaSenha.value || '').length === 0) {
            return 'Insira sua nova senha';
        }
    }

    get novaSenha() {
        return this.formulario.get('novaSenha');
    }

    get senhaAntiga() {
        return this.formulario.get('senhaAntiga');
    }

    get senhasNaoCoincidem() {
        if (!this.confirmarNovaSenha.value) {
            return false;
        }

        return this.novaSenha.value !== this.confirmarNovaSenha.value;
    }

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private dadosCadService: DadosCadastraisService
    ) {
        this.formulario = new FormGroup({
            senhaAntiga: new FormControl('', [Validators.required,]),
            novaSenha: new FormControl('', [Validators.required, Validators.pattern(this.paswwordPattern)]),
            confirmarNovaSenha: new FormControl('', [Validators.required, Validators.pattern(this.paswwordPattern)])
        });
    }

    ngOnInit() {
        this.dadosCad = this.dadosCadService.getCadastroGeralLocal();
        if (this.authService.isAuthenticated() && this.dadosCad && this.dadosCad.cpf) {
            this.cpf = this.dadosCad.cpf;
        } else {
            this.activatedRoute.params.subscribe(params => {
                this.cpf = params['cpf'];
            });
        }

        this.formulario.get("novaSenha").valueChanges.subscribe(value => {
            this.passwordTemplate.checkRequirements(value);
        });
    }

    alterarSenha() {
        this.usuarioTentouEnviarFormulario = true;

        if (this.formulario.invalid || this.senhasNaoCoincidem) {
            return;
        }

        this.isLoading = true;

        this.authService.AccountCPF_ConfirmarAlterarSenha(
            this.cpf,
            this.formulario.value.senhaAntiga,
            this.formulario.value.novaSenha,
            this.formulario.value.confirmarNovaSenha
        ).subscribe(data => {
            if (Number(data.type) === 1) {
                // User logged in
                if (this.authService.isAuthenticated() && this.dadosCad && this.dadosCad.cpf) {
                    this.mensagem = "Senha alterada com sucesso.";
                }
                else {
                    this.mensagem = "Senha alterada com sucesso. <br/> Você será redirecionado para a tela de login.";
                    this.modalMessageOnCloseHref = '/login';
                }

                this.senhaAlteradaComSucesso = true;
                this.usuarioTentouEnviarFormulario = false;
            } else {
                this.modalMessageOnCloseHref = null;
                this.mensagem = data.message;
                this.senhaAlteradaComSucesso = false;
                this.modalMessageOnCloseHref = null;
            }
            this.formulario.reset();
            this.limparForm();

            this.isLoading = false;
            this.showDialog = true;
        }, () => {
            this.mensagem = 'Não foi possível comunicar-se com o servidor.';
            this.senhaAlteradaComSucesso = false;
            this.isLoading = false;
            this.showDialog = true;
        });
    }

    limparForm() {
        this.formulario.get('senhaAntiga').setValue('');;
        this.formulario.get('novaSenha').setValue('');
        this.formulario.get('confirmarNovaSenha').setValue('');;
    }
}
