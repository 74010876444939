import { Inject, Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { ResponseApiCdModel } from "../../models/response-api-plano-cd.model";
import { HttpClientService } from '../http-client.service';
import { ETokenApi } from "../../enums";

@Injectable()
export class TokenService extends BaseService {

    constructor(protected httpClient: HttpClient, @Inject('environment') protected environment,
        private httpClientService: HttpClientService) {
        super(httpClient, environment);
    }

    enviarTokenEmail(email: string) {
        const endpoint = `${this.endpoint}/comunicacao/enviar-token-por-email`;
        return this.httpClientService.post<ResponseApiCdModel<string>>(endpoint, { email: email }, ETokenApi.TokenApiCd);
    }

    validarToken(token: string) {
        const endpoint = `${this.endpoint}/token/valida-token`;
        return this.httpClientService.post<ResponseApiCdModel<string>>(endpoint, { token: token }, ETokenApi.TokenApiCd);
    }
}