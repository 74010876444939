import { Component, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService, Contrato, obterNomeDoMesAPartirDoNumeroDoMes } from '@fibra/fibra-shared-lib';
import { ExtratoMes } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-extrato-card',
    templateUrl: './extrato-card.component.html'
})
export class ExtratoCardComponent implements OnInit {

    extratoMes: ExtratoMes;
    erro = false;
    isLoading = true;
    mostrarExtrato = true;
    mesesSelecao: number[];
    itensExtratoMes: ExtratoMes[];
    mes: number;

    MENSAGEM_ERRO = 'Não foi possível consultar o extrato no momento. Por favor tente novamente mais tarde.';

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        setTimeout(() => {
            this.getInformacoesExtrato();
        }, 1000);
    }

    getInformacoesExtrato() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_ItensExtratoPorContrato(false).subscribe(itensExtrato => {
            if (itensExtrato && itensExtrato.length > 0) {
                this.itensExtratoMes = itensExtrato;
                this.mesesSelecao = this.setComboMeses(this.itensExtratoMes);
                this.extratoMes = this.itensExtratoMes[0];
                this.isLoading = false;
            } else { this.setLimparExtrato(); }
        }, () => this.erroCarregamento());
    }

    infoExtratoMesSelecionado() {
        const itemMes = this.itensExtratoMes.filter(itemDoMes => {
            return itemDoMes.mesAtual === Number(this.mes);
        });
        this.extratoMes = itemMes[0];
    }

    obterNomeDoMes(mes: number) {
        return obterNomeDoMesAPartirDoNumeroDoMes(mes);
    }

    private setComboMeses(extratoMes: ExtratoMes[]): number[] {
        return extratoMes.map(item => item.mesAtual);
    }

    private setLimparExtrato() {
        this.isLoading = false;
        this.mostrarExtrato = false;
    }

    private erroCarregamento() {
        this.erro = true;
        this.isLoading = false;
    }
}
