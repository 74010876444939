import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-plano-bd',
  templateUrl: './plano-bd.component.html',
  styleUrls: ["./painel-bd.component.css"]
})
export class PainelBdComponent implements OnInit {

  ngOnInit(): void {
  }

}
