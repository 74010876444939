import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertModalComponent, PessoaPoliticamenteExpostaService, DadosCadastraisService } from '@fibra/fibra-shared-lib';
import { PessoaPoliticamenteExpostaModel } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
import { CargoPessoaPoliticamenteExpostaDto, PessoaPoliticamenteExpostaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';

@Component({
    selector: 'app-pessoa-politicamente-exposta',
    templateUrl: './pessoa-politicamente-exposta.component.html',
    styleUrls: ['./pessoa-politicamente-exposta.component.css']
})
export class PessoaPoliticamenteExpostaComponent implements OnInit {
    pessoaPoliticamenteExposta: PessoaPoliticamenteExpostaModel;
    pessoaPoliticamenteExpostaForm: FormGroup;
    isLoading: boolean = false;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    urlArquivoSeSuite: string;
    msgs: any[] = [];
    isSimulado = sessionStorage.getItem('isSimulado');
    cargosPpe: Array<CargoPessoaPoliticamenteExpostaDto> = [];
    politicamenteExposta: boolean = false;
    cargoSelecionado: CargoPessoaPoliticamenteExpostaDto | null = null;

    constructor(private ppeService: PessoaPoliticamenteExpostaService,
        private dadosCadastraisService: DadosCadastraisService) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.ppeService.getCargosPssoasPoliticamenteExposta()
            .subscribe(cargos => {
                this.cargosPpe = cargos;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                console.error(error);
            });

        let dadosCad = this.dadosCadastraisService.getCadastroGeralLocal();
        if (dadosCad.periodosPoliticamenteExposto && dadosCad.periodosPoliticamenteExposto.length > 0) {
            const ppe = dadosCad.periodosPoliticamenteExposto[dadosCad.periodosPoliticamenteExposto.length - 1];
            this.politicamenteExposta = ppe.exposto;
            if (ppe.cargoModel)
                this.cargoSelecionado = ppe.cargoModel;
        }
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    ngOnDestroy(): void {
    }

    limparMensagem() {
        this.msgs = [];
    }

    enviar(cargoPpe: CargoPessoaPoliticamenteExpostaDto) {
        this.isLoading = true;
        let ppe: PessoaPoliticamenteExpostaDto = {
            cargoId: cargoPpe?.id == null ? null : cargoPpe?.id,
            exposto: this.politicamenteExposta
        };

        this.ppeService.atualizarPoliticamenteExposta(ppe).subscribe(resultado => {
            this.isLoading = false;
            this.limparMensagem();
            this.addMensagem('success', '', 'Atualizado com sucesso!');
        }, error => {
            this.isLoading = false;
            this.limparMensagem();
            this.addMensagem('error', '', 'Erro ao atualizar.');
            console.error(error);
        });
    }

    formValido() {
        if (this.politicamenteExposta && this.cargoSelecionado != null)
            return true;

        if (!this.politicamenteExposta)
            return true;

        return false;
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        this.twoFactorAuth.show();
    }

    /**
    * Lida com o sucesso da autenticação de dois fatores.
    * @param twoFactorAuthToken 2fa token.
    */
    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        // if (twoFactorAuthToken.success)
        // this.enviar(this.pessoaPoliticamenteExpostaForm.value);
    }
}
