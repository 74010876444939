import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rentabilidade',
    styleUrls: ['./rentabilidade.component.css'],
    templateUrl: './rentabilidade.component.html'
})
export class RentabilidadeComponent implements OnInit {

    isLoading = true;
	data: any;

    constructor() { 
		this.data = {
            labels: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'junho'],
            datasets: [
                {
                    label: 'Rentabilidade',
                    backgroundColor: '#9CCC65',
                    borderColor: '#7CB342',
					data: [2, 1, -2, 5, 5, 7]
                }
            ]
        }
	}

    ngOnInit() {
        this.isLoading = false;
    }

}