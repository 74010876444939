import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';



@Component({
    selector: 'app-autopatrocinio-financeiro',
    templateUrl: './autopatrocinio-financeiro.component.html',
    styleUrls: ['./autopatrocinio-financeiro.component.css']
})
export class AutopatrocinioFinanceiroComponent implements OnInit {
    valoresAutopatrocinio?: any;
    isLoading = false;
    nomeArquivo: string;

    constructor() { }

    ngOnInit() {
    }

}
