import { Component, OnInit, ViewChild } from '@angular/core';
import { FormularioService, AlertModalComponent, MESSAGE_SEVERITY, GestaoPlanoCadastroService, validFullName, scrollIntoViewToTop, DadosCadastraisService, ETipoPlano, DesignadoModel, SignDataModel, RespostaDocumentoCriadoModel } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { SuplementacaoAposentadoriaModel } from '@fibra/fibra-shared-lib';
import { BeneficiarioSupAposentadoriaModel } from '@fibra/fibra-shared-lib';
import { DesignadoSupAposentadoriaModel } from '@fibra/fibra-shared-lib';
import { TipoBeneficio } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { FileUpload } from 'primeng/fileupload';
import { ModalFormComponent } from '../../../../components/modal-form/modal-form.component';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';
import { DesignadoMockService, BeneficiarioMockService } from 'projects/FibraAutoAtendimento/src/app/mocks/mocks.module';
import { Observable } from 'rxjs';
import { BeneficiarioResponseDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { SignWidgetComponent } from 'projects/FibraAutoAtendimento/src/app/components/clicksign/sign-widget/sign-widget.component';

@Component({
    selector: 'app-suplementacao-aposentadoria',
    templateUrl: './suplementacao-aposentadoria.component.html',
    styleUrls: ['./suplementacao-aposentadoria.component.css']
})
export class SuplementacaoAposentadoriaComponent implements OnInit {
    supAposentadoria: SuplementacaoAposentadoriaModel;
    tiposBeneficio = [
        'Aposentadoria Programada',
        'Aposentadoria por Invalidez',
        'Pensão por Morte do Participante Ativo',
        'Pensão por Morte do Participante Assistido'
    ];
    documentoAssinado: boolean = false;
    mostrarSignWidget: boolean = false;
    mostrarCamposSupPensao: boolean;
    mostrarCampoRecluso: boolean;
    mostrarCamposBetm: boolean;
    mostrarCamposSupPensaoConversao: boolean;
    ptBR = LOCALE_PT_BR;
    isLoading: boolean;
    @ViewChild('signWidget') signWidget: SignWidgetComponent
    @ViewChild('alertModalComponent', { static: false }) alertModalComponent: AlertModalComponent;
    @ViewChild('modalFormComponent', { static: false }) modalFormComponent: ModalFormComponent;
    @ViewChild('twoFactorAuth', { static: false }) twoFactorAuth: TwoFactorAuthComponent;
    @ViewChild('fileUpload', { static: false }) fileUpload: FileUpload;
    urlArquivoSeSuite: string;
    msgs: any[];
    formValido: boolean = true;
    yearRange: string;
    uploadedFiles: any[] = [];
    dateMax: Date;
    aposentadoPrevOficial?: string = null;
    formSeguroVidaLink: string = 'https://ecm.fundacaoitaipu.com.br/se/generic/gn_linkshare/linkshare.php?key=3Ki2Hd0632&cdisosystem=73';
    displayFormAntecipacaoBenef: boolean = false;
    nomeParticipante: string = '';

    constructor(private formularioService: FormularioService,
        private authService: AuthService,
        private cadastroService: GestaoPlanoCadastroService,
        private dadosCadastraisService: DadosCadastraisService,
        private beneficiarioMockService: BeneficiarioMockService,
        private designadosMockService: DesignadoMockService) {
        this.yearRange = '1900:' + (new Date()).getFullYear();
        this.dateMax = new Date();
    }

    ngOnInit() {
        this.isLoading = true;
        this.supAposentadoria = {
            abonoAnual: false,
            beneficiarios: [],
            designados: [],
            dataFacelidoSupPensaoConversao: new Date(),
            dataObitoFacelidoSupPensa: new Date(),
            dataObitoFalecidoBetm: new Date(),
            nomeFacelidoBetm: '',
            nomeFacelidoSupPensao: '',
            nomeFacelidoSupPensaoConversao: '',
            opcaoSeguroVida: 0,
            recluso: '',
            tipoBeneficio: 0,
            aposentadoPrevOficial: null,
            dataVigencia: null
        }

        this.fetchBeneficiarios(true)
            .subscribe(deps => {

                if (deps != null && deps.length > 0) {
                    deps.forEach(dep => {
                        const beneficiario: BeneficiarioSupAposentadoriaModel = {
                            id: this.supAposentadoria.beneficiarios.length + 1,
                            nome: dep.nome,
                            dataNascimento: dep.dataNascimento,
                            sexo: dep.sexo ? dep.sexo.substring(0, 1) : null,
                            parentesco: dep.relacaoDependencia.descricao
                        }

                        this.supAposentadoria.beneficiarios.push(beneficiario);
                    });
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            });
        
        this.fetchDesignados(true)
            .subscribe(designados => {

                if (designados != null && designados.length > 0) {
                    designados.forEach(des => {
                        const designado: DesignadoSupAposentadoriaModel = {
                            id: this.supAposentadoria.designados.length + 1,
                            nome: des.nome,
                            percentual: des.percentual.toString(),
                            cpf: des.cpf
                        }

                        this.supAposentadoria.designados.push(designado);
                    });
                }
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            });

        this.dadosCadastraisService.getCadastroGeral()
            .subscribe(cadastroGeral => {
                this.nomeParticipante = cadastroGeral.nome;
        });

    }

    fetchBeneficiarios(useMock: boolean): Observable<BeneficiarioResponseDto[]> {
        if (!useMock) {
          return this.beneficiarioMockService.getBeneficiariosMock();
        }
        return this.cadastroService.getBeneficiarios();
    }

    fetchDesignados(useMock: boolean): Observable<DesignadoModel[]> {
        if(!useMock){
            return this.designadosMockService.getDesignadosMock();
        }
        
        return this.cadastroService.getDesignados();
    }

    ngOnDestroy(): void {
    }

    registrarDocumentoAssinado(signData: SignDataModel) {
        this.formularioService.registrarAssinaturaDocumento(signData.idDocumento)
            .subscribe(resposta => {
                this.documentoAssinado = resposta.success && resposta.data;
            }, error => {
                console.error(error);
            });
    }
    
    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    validarForm(): boolean {
        this.limparMensagem();

        this.formValido = true;

        if (this.supAposentadoria.tipoBeneficio === 0 ||
            this.supAposentadoria.opcaoSeguroVida === 0) {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Informe todos os dados!");
            this.formValido = false;
            scrollIntoViewToTop();
            return this.formValido;
        }

        if (this.supAposentadoria.aposentadoPrevOficial == null) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "É obrigatório selecionar 'Sim' ou 'Não' para 'Aposentado na Previdência Oficial'.");
        }

        switch (Number(this.supAposentadoria.tipoBeneficio)) {
            case TipoBeneficio.supPensao:
                if (this.supAposentadoria.nomeFacelidoSupPensao == '') {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Nome do Falecido' é obrigatório.");
                    this.formValido = false;
                }

                if ((this.supAposentadoria.nomeFacelidoSupPensao &&
                    this.supAposentadoria.nomeFacelidoSupPensao.trim().length < 3) ||
                    !validFullName(this.supAposentadoria.nomeFacelidoSupPensao)) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção:',
                        `O campo 'Nome Falecido' é permitido apenas letras.`);
                    this.formValido = false;
                }

                if (!this.supAposentadoria.dataObitoFacelidoSupPensa) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Data do Óbito' é obrigatório.");
                    this.formValido = false;
                }
                break;
            case TipoBeneficio.beneficioEspecialTemporarioMorte:
                if (this.supAposentadoria.nomeFacelidoBetm == '') {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Nome do Falecido' é obrigatório.");
                    this.formValido = false;
                }


                if ((this.supAposentadoria.nomeFacelidoBetm &&
                    this.supAposentadoria.nomeFacelidoBetm.trim().length < 3) ||
                    !validFullName(this.supAposentadoria.nomeFacelidoBetm)) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção:',
                        `O campo 'Nome Falecido' é permitido apenas letras.`);
                    this.formValido = false;
                }

                if (!this.supAposentadoria.dataObitoFalecidoBetm) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Data do Óbito' é obrigatório.");
                    this.formValido = false;
                }
                break;
            case TipoBeneficio.supPensaoConversao:
                if (this.supAposentadoria.nomeFacelidoSupPensaoConversao == '') {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Nome do Falecido' é obrigatório.");
                    this.formValido = false;
                }


                if ((this.supAposentadoria.nomeFacelidoSupPensaoConversao &&
                    this.supAposentadoria.nomeFacelidoSupPensaoConversao.trim().length < 3) ||
                    !validFullName(this.supAposentadoria.nomeFacelidoSupPensaoConversao)) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                        `O campo 'Nome Falecido' é permitido apenas letras.`);
                    this.formValido = false;
                }

                if (!this.supAposentadoria.dataFacelidoSupPensaoConversao) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Data do Óbito' é obrigatório.");
                    this.formValido = false;
                }
                break;
            case TipoBeneficio.auxilioReclusao:
                if (this.supAposentadoria.recluso == '') {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Recluso' é obrigatório.");
                    this.formValido = false;
                }


                if ((this.supAposentadoria.recluso &&
                    this.supAposentadoria.recluso.trim().length < 3) ||
                    !validFullName(this.supAposentadoria.recluso)) {
                    this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                        `Por favor entre com 'Recluso' válido.`);
                    this.formValido = false;
                }
                break;
        }

        if (this.supAposentadoria.opcaoSeguroVida == 0) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Opções para Folha de Benefícios' é obrigatório.");
            this.formValido = false;
        }

        if (!this.supAposentadoria.dataVigencia) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "O campo 'Data de vigência' é obrigatório.");
            this.formValido = false;
        }

        if (this.fileUpload.files.length <= 0) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "É obrigatório anexar os documentos exigidos.");
            this.formValido = false;
        }

        // É permitido gerar o formulário sem adicionar beneficiários.
        // if (this.supAposentadoria == null || this.supAposentadoria.beneficiarios == null || this.supAposentadoria.beneficiarios.length == 0) {
        //     this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ', "Informe os dados dos beneficiários.");
        //     this.formValido = false;
        // }

        if (!this.validarBeneficiarios()) {
            this.formValido = false;
        }

        if (!this.formValido)
            scrollIntoViewToTop();

        return this.formValido;
    }

    private validarBeneficiarios(): boolean {
        this.supAposentadoria.beneficiarios.forEach((benef, index) => {

            if (!benef.nome) {
                this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                    `O campo 'Nome' do ${index + 1}º beneficiário incluído é inválido.`);
                this.formValido = false;
            }

            if (!benef.dataNascimento) {
                this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                    `O campo 'Data de Nascimento' do ${index + 1}º beneficiário é inválido.`);
                this.formValido = false;
            }

            if (!benef.sexo) {
                this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                    `O campo 'Sexo' do ${index + 1}º beneficiário incluído é inválido.`);
                this.formValido = false;
            }

            if (!benef.parentesco) {
                this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                    `O campo 'Parentesco' do ${index + 1}º beneficiário incluído é inválido.`);
                this.formValido = false;
            }
        });

        return this.formValido;
    }

    enviar() {
        this.isLoading = true;

        if (this.aposentadoPrevOficial == null)
            this.supAposentadoria.aposentadoPrevOficial = null;
        else
            this.supAposentadoria.aposentadoPrevOficial = this.aposentadoPrevOficial == 'true';

        if (!this.validarForm()) {
            this.isLoading = false;
            return;
        }


        let formData = new FormData();
        formData.append('suplementacaoAposJson', JSON.stringify(this.supAposentadoria));
        console.log( JSON.stringify(this.supAposentadoria))

        if (this.fileUpload.hasFiles()) {
            this.fileUpload.files.forEach((file, index) => {
                formData.append('anexo_' + index, file);
            });
        }

        this.formularioService.gerarFormSuplementacaoAposentadoria(formData)
            .subscribe((resposta: RespostaDocumentoCriadoModel) => {
                this.isLoading = false;
                if(resposta.sucesso){
                    this.mostrarSignWidget = true;
                    setTimeout(() => {
                        this.signWidget.carregarWidget({
                            signKey: resposta.idDaRequisicaoDaAssinatura,
                            idDocumento: resposta.idDoDocumento
                        });
                    }, 500);
                }
                this.reset();
            }, () => {
                this.mostrarSignWidget = false;
                this.isLoading = false;
                this.reset();
            });
    }

    reset() {
        this.ngOnInit();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    modificarTipoBeneficio(tipoBeneficio: string) {
        this.supAposentadoria.nomeFacelidoBetm = '';
        this.supAposentadoria.dataObitoFalecidoBetm = new Date();

        this.supAposentadoria.nomeFacelidoSupPensao = '';
        this.supAposentadoria.dataObitoFacelidoSupPensa = new Date();

        this.supAposentadoria.nomeFacelidoSupPensaoConversao = '';
        this.supAposentadoria.dataFacelidoSupPensaoConversao = new Date();

        this.supAposentadoria.recluso = '';

        if (Number(tipoBeneficio) < 6) {
            this.mostrarCamposSupPensao = false;
            this.mostrarCamposBetm = false;
            this.mostrarCamposSupPensaoConversao = false;
            this.mostrarCampoRecluso = false;
            return;
        }

        switch (Number(tipoBeneficio)) {
            case TipoBeneficio.supPensao:
                this.mostrarCamposSupPensao = true;
                this.mostrarCamposBetm = false;
                this.mostrarCamposSupPensaoConversao = false;
                this.mostrarCampoRecluso = false;
                break;
            case TipoBeneficio.beneficioEspecialTemporarioMorte:
                this.mostrarCamposBetm = true;
                this.mostrarCamposSupPensao = false;
                this.mostrarCamposSupPensaoConversao = false;
                this.mostrarCampoRecluso = false;
                break;
            case TipoBeneficio.supPensaoConversao:
                this.mostrarCamposSupPensaoConversao = true;
                this.mostrarCamposSupPensao = false;
                this.mostrarCamposBetm = false;
                this.mostrarCampoRecluso = false;
                break;
            case TipoBeneficio.auxilioReclusao:
                this.mostrarCampoRecluso = true;
                this.mostrarCamposSupPensao = false;
                this.mostrarCamposBetm = false;
                this.mostrarCamposSupPensaoConversao = false;
                break;
        }
    }

    onUpload(event: any) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem('info', 'File Uploaded', 'OK');
    }

    onSubmitFormAntecipacaoBenef(condordo: boolean) {
        this.displayFormAntecipacaoBenef = false;
        this.modalFormComponent.ocultar();
        if (condordo) {
            this.enviar();
        }
    }

    verificarFormAntecipacaoBenef() {
        if (this.aposentadoPrevOficial == null)
            this.supAposentadoria.aposentadoPrevOficial = null;
        else
            this.supAposentadoria.aposentadoPrevOficial = this.aposentadoPrevOficial == 'true';

        if (!this.validarForm()) {
            return;
        }

        if (this.aposentadoPrevOficial == 'true') {
            this.enviar();
        } else {
            this.displayFormAntecipacaoBenef = true;
            this.modalFormComponent.mostrar();
        }
    }

}
