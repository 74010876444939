import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdesaoService, AuthService, DadosCadastraisService, ESituacaoParticipante, STORAGE_KEYS, SessionStorageService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
    ativo: boolean;
    papel: string[] = [];
    showEmprestimo = true;
    showSimulador = true;
    showBeneficio = true;
    showFibraNumeros = true;

    constructor(
        private authService: AuthService,
        private dadosCadastraisService: DadosCadastraisService,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        private adesaoService: AdesaoService
    ) { }

    ngOnInit() {
        this.dadosCadastraisService.getCadastroGeral(true).subscribe(dadosCad => {
            const assinarTermoAdesao = dadosCad?.informacoesAdicionais?.assinarTermoAdesao == true;
            if (assinarTermoAdesao && !this.sessionStorageService.any(STORAGE_KEYS.PULAR_TERMO_ADESAO)) {
                this.sessionStorageService.setAnyAsJsonSecure(STORAGE_KEYS.PULAR_TERMO_ADESAO, true);
                this.router.navigate(['/termo-adesao']);
            }

            let adesao = this.adesaoService.getAdesaoContexto();
            this.ativo = this.adesaoService.validarSituacao(ESituacaoParticipante.Ativo, adesao.tipoSituacao);
            this.validaSimulado();
        });
    }

    validaSimulado() {
        const isSimulado = sessionStorage.getItem('isSimulado');
        const role = sessionStorage.getItem('roleSimulado');

        if (isSimulado === 'true') {
            if (role === 'auth_simulado_menosemprestimo') {
                this.showEmprestimo = false;
                this.showSimulador = false;
            } else if (role === 'auth_simulado_emprestimo') {
                this.showBeneficio = false;
                this.showFibraNumeros = false;
                this.ativo = false;
            }
        }
    }
}
