import { Component, OnInit, Input } from '@angular/core';
import { SimuladorService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-simulador-beneficio',
    styleUrls: ['./simulador-beneficio.component.css'],
    templateUrl: './simulador-beneficio.component.html'
})
export class SimuladorBeneficioComponent implements OnInit {

    constructor() {

	}

	async ngOnInit() {
	}

}
