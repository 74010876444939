import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DesignadoModel } from '@fibra/fibra-shared-lib/public_api';

@Injectable({
  providedIn: 'root'
})
export class DesignadoMockService {
  getDesignadosMock(): Observable<DesignadoModel[]> {
    const mockBeneficiarios: DesignadoModel[] = [
        {
            id: 1,
            nome: 'João Silva',
            cpf: '123.456.789-00',
            percentual: 50,
            idDesignado: 1001,
            nomeDesignado: 'Maria Silva',
            cpfDesignado: '987.654.321-00',
            dataNascimento: new Date('1990-01-01'),
            sexo: 'Masculino',
            telefone: '99999-9999',
            email: ''
        },
        {
            id: 2,
            nome: 'Ana Santos',
            cpf: '234.567.890-11',
            percentual: 50,
            idDesignado: 1002,
            nomeDesignado: 'Carlos Santos',
            cpfDesignado: '876.543.210-11',
            dataNascimento: new Date('1985-05-15'),
            sexo: 'Feminino',
            telefone: '88888-8888',
            email: ''
        }
    ];

    return of(mockBeneficiarios);
  }
}
