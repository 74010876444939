import { Component, OnInit, ViewChild } from '@angular/core';
import {
    AdesaoParticipantePendenteAprovacaoResponse, AprovacaoDescontoFolhaRequest,
    SolicitacaoDescontoFolhaService, AlertModalComponent
} from '@fibra/fibra-shared-lib';
import { ParticipantePendenteAprovacaoRequest } from '@fibra/fibra-shared-lib';


@Component({
    selector: 'app-solicitacao-desconto-folha',
    templateUrl: './solicitacao-desconto-folha.component.html',
    styleUrls: ['./solicitacao-desconto-folha.component.css']
})
export class SolicitacaoDescontoFolhaComponent implements OnInit {
    adesoesPendenteAprovacao: Array<AdesaoParticipantePendenteAprovacaoResponse>;

    adesoesPendenteAprovacaoPermissao: Array<{
        nomeParticipante: string;
        cpfParticipante: string;
        idAdesao: number;
        idTipoAdesao: number;
        idPlano: number;

        cpf: string;
        dataNascimento: string;
        nome: string;
        dataInscricao: string;
        aprovado: boolean;
    }> = [];
    isLoading: boolean;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    existeAprovacaoPendente: boolean = false;

    constructor(private solicitacaoDescontoFolhaService: SolicitacaoDescontoFolhaService) { }

    ngOnInit() {
        this.getParticipantesPendentesAprovacao();
    }

    getParticipantesPendentesAprovacao() {
        this.isLoading = true;
        this.adesoesPendenteAprovacaoPermissao = [];
        const participantePendente: ParticipantePendenteAprovacaoRequest = {
            cpf: ''
        };

        this.solicitacaoDescontoFolhaService.getParticipantesPendentesAprovacao(participantePendente)
            .subscribe(adesoesPartPendenteAprovacao => {
                this.adesoesPendenteAprovacao = adesoesPartPendenteAprovacao

                adesoesPartPendenteAprovacao.forEach(x => {
                    x.participantesPendentesAprovacao.forEach(y => {
                        const adePermissao = {
                            nomeParticipante: x.nomeParticipante,
                            cpfParticipante: x.cpfParticipante,
                            idAdesao: x.idAdesao,
                            idTipoAdesao: x.idTipoAdesao,
                            idPlano: x.idPlano,

                            cpf: y.cpf,
                            dataNascimento: new Date(y.dataNascimento).toLocaleDateString('pt-BR'),
                            nome: y.nome,
                            dataInscricao: new Date(y.dataInscricao).toLocaleDateString('pt-BR'),
                            aprovado: false
                        }
                        this.adesoesPendenteAprovacaoPermissao.push(adePermissao);
                    });
                });

                adesoesPartPendenteAprovacao.forEach(x => {
                    x.participantesDescontoAprovado.forEach(y => {
                        const adePermissao = {
                            nomeParticipante: x.nomeParticipante,
                            cpfParticipante: x.cpfParticipante,
                            idAdesao: x.idAdesao,
                            idTipoAdesao: x.idTipoAdesao,
                            idPlano: x.idPlano,

                            cpf: y.cpf,
                            dataNascimento: new Date(y.dataNascimento).toLocaleDateString('pt-BR'),
                            nome: y.nome,
                            dataInscricao: new Date(y.dataInscricao).toLocaleDateString('pt-BR'),
                            aprovado: true
                        }
                        this.adesoesPendenteAprovacaoPermissao.push(adePermissao);
                    });
                });

                this.existeAprovacaoPendente = this.adesoesPendenteAprovacaoPermissao.length > 0;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }

    aprovarDescontoFolha(aprovacao: any) {
        const aprovacaoDescFolha: AprovacaoDescontoFolhaRequest = {
            cpf: aprovacao.cpf,
            idAdesao: aprovacao.idAdesao
        }

        this.isLoading = true;
        this.solicitacaoDescontoFolhaService.aprovarDescontoFolha(aprovacaoDescFolha)
            .subscribe(aprovacaoDesconto => {
                this.isLoading = false;
                this.alertModalComponent.show(aprovacaoDesconto.mensagem, aprovacaoDesconto.aprovado);
                this.ngOnInit();
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }

    desaprovarDescontoFolha(aprovacao: any) {
        const aprovacaoDescFolha: AprovacaoDescontoFolhaRequest = {
            cpf: aprovacao.cpf,
            idAdesao: aprovacao.idAdesao
        }

        this.isLoading = true;
        this.solicitacaoDescontoFolhaService.desaprovarDescontoFolha(aprovacaoDescFolha)
            .subscribe(aprovacaoDesconto => {
                this.isLoading = false;
                this.alertModalComponent.show(aprovacaoDesconto.mensagem, aprovacaoDesconto.aprovado);
                this.ngOnInit();
            }, error => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }
}
