export class PerguntaPerfilInvestimento {
    numero: number;
    texto: string;
    respondida: boolean | null = null;
    respostas: Array<RespostaPerfilInvestimento> = [];

    static getPerguntas?(): Array<PerguntaPerfilInvestimento> {
        return [
            {
                numero: 1,
                texto: "Qual a sua expectativa quanto à sua renda para os próximos anos?",                
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Preservar minha renda para o futuro",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Preservar a renda vislumbrando alguns aumentos ao longo da carreira",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Busco uma carreira ascendente, e vislumbro promoções em minha trajetória profissional",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Estou em fase final de carreira e almejo me aposentar apenas com a renda dos recursos investidos",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 5,
                        texto: "Sou aposentado",
                        valor: 1,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 2,
                texto: "Quando você planeja se aposentar?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Faltam mais de 10 anos para me aposentar.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Dentro dos próximos 10 anos.",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Dentro dos próximos 5 anos.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Já estou aposentado.",
                        valor: 1,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 3,
                texto: "Você diria que sua situação financeira (ou de sua  família) é:",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Sem dívidas e temos reservas financeiras e/ou investimentos.",
                        valor: 5,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Sem dívidas, porém sem reservas financeiras.",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Algumas despesas fixas, mas com espaço para guardar parte da renda.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Dívidas consomem uma parcela importante da renda e não há espaço no orçamento para reservas periódicas.",
                        valor: 1,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 4,
                texto: "Se você não estivesse trabalhando, como faria para se manter?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Pode contar com sua família / cônjuge para assumir as despesas da casa, caso perca a principal fonte de renda.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Possui economias, investimentos ou alguma atividade secundária que permitem sua família manter um razoável padrão de vida por cerca de 12 meses.",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Não possuo outras fontes de renda ou reservas financeiras.",
                        valor: 0,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Sou aposentado.",
                        valor: 1,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 5,
                texto: "Qual valor você consegue investir por mês além das contribuições para a Fibra?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não tenho realizado investimentos.",
                        valor: 0,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Até 10% do salário.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Entre 10 e 15% do salário.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Entre 15 e 20% do salário.",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 5,
                        texto: "Acima de 20% do salário.",
                        valor: 5,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 6,
                texto: "Já possui investimentos?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não.",
                        valor: 0,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Sim. Poupança.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Sim. Fundos de Renda Fixa, CDB ou títulos públicos.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Sim. Tenho aplicações diversificadas, Incluindo Renda Variável.",
                        valor: 4,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 7,
                texto: "Você possui alguma experiência com produtos do mercado financeiro, tais como Fundos de Investimento, Ações, Fundos Multimercado e CDB?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não conheço os produtos do mercado financeiro.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Sim, mas invisto meus recursos apenas em produtos tradicionais e/ou com orientação profissional.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Sim, conheço produtos do mercado financeiro e participo ativamente na seleção dos meus investimentos.",
                        valor: 4,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 8,
                texto: "Considerando que risco é a incerteza sobre o retorno, ou até mesmo a possibilidade de perda do capital investido, e que investimentos mais arriscados podem trazer retornos maiores, quanto do seu patrimônio você aplicaria em investimentos com maior risco?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Pretendo ter o menor risco possível (de 0% a 5% do patrimônio).",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Aceito riscos em uma pequena parcela do patrimônio investido para obter retorno um pouco maior (até 15 % do patrimônio).",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Aceito correr riscos controlados em busca da possibilidade de um retorno maior (até 30% do patrimônio).",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Aceito correr mais riscos em busca da possibilidade de rentabilidades ainda mais elevadas (40% ou mais do patrimônio).",
                        valor: 5,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 9,
                texto: "Qual das alternativas abaixo você acredita que descreve melhor o seu perfil de risco:",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Preservar capital. Prefiro investir meu capital em aplicações menos arriscadas, mesmo sabendo que meus ganhos podem não ser elevados.",
                        valor: 0,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Procuro alternativas de melhor rentabilidade, porém em investimentos conservadores. Só me permito entrar em investimentos que envolvem baixo risco.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Procuro alternativas buscando melhor rentabilidade, mesmo envolvendo algum nível risco, porém arrisco apenas pequena parte da minha reserva. A maior parte mantenho em investimentos conservadores.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Procuro sempre alternativas buscando melhor rentabilidade, mesmo que possuam maior risco. No entanto, não invisto todo o meu dinheiro, pois sempre deixo uma parte menor em investimentos mais conservadores.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 5,
                        texto: "Estou o tempo todo em contato com o mercado financeiro, de olho nas tendências e investindo meu dinheiro apenas em aplicações de alto retorno esperado, independente do risco.",
                        valor: 8,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 10,
                texto: "Como se sentiria se em 1 ano, em um determinado investimento, você perdesse o equivalente a 15% do seu patrimônio acumulado?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não me incomodaria muito, porque espero que no longo prazo essa perda seja revertida.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Me incomodaria um pouco e ficaria em alerta: se em mais alguns meses a rentabilidade não ficasse positiva, buscaria outra opção de investimento menos arriscado.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Contataria um especialista e pediria para trocar o investimento por uma opção mais conservadora.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Não aplicaria neste tipo de investimento.",
                        valor: 0,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 11,
                texto: "Se a perda da pergunta anterior fosse maior, cerca de 50% de suas aplicações, como você se sentiria?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não me incomodaria muito, porque espero que no longo prazo essa perda seja revertida.",
                        valor: 8,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Me incomodaria um pouco e ficaria em alerta: se em mais alguns meses a rentabilidade não ficasse positiva, buscaria outra opção de investimento menos arriscado.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Contataria um especialista e pediria para trocar o investimento por uma opção mais conservadora.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Não aplicaria neste tipo de investimento.",
                        valor: 0,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 12,
                texto: "Qual seria a sua reação ao comprar ações de alguma empresa lucrativa que, logo em seguida, perdeu significativamente o valor na bolsa de valores, sem motivo aparente?",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Não compro ações.",
                        valor: 0,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Venderia assim que começasse a cair, ou seja, ao menor sinal de perda, já passaria as ações para frente.",
                        valor: 1,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Venderia apenas se o valor caísse muito. Depois de uma perda muito grande, venderia para não correr mais risco.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Esperaria na tentativa de recuperar, um dia, o investimento feito.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 5,
                        texto: "Compraria mais ações.",
                        valor: 8,
                        selecionada: null
                    }
                ]
            },
            {
                numero: 13,
                texto: "Quando investe algum dinheiro, você:",
                respondida: null,
                respostas: [
                    {
                        numero: 1,
                        texto: "Sabe onde buscar informações sobre  investimentos e avalia o rendimento obtido nos últimos meses.",
                        valor: 4,
                        selecionada: null
                    },
                    {
                        numero: 2,
                        texto: "Tenta se informar por conta própria sobre o investimento e avalia o rendimento obtido nos últimos meses.",
                        valor: 3,
                        selecionada: null
                    },
                    {
                        numero: 3,
                        texto: "Confia na indicação dos amigos e só olha a rentabilidade atual.",
                        valor: 2,
                        selecionada: null
                    },
                    {
                        numero: 4,
                        texto: "Segue a recomendação de um especialista.",
                        valor: 1,
                        selecionada: null
                    }
                ]
            }
        ]
    }
}

export class RespostaPerfilInvestimento {
    numero: number;
    texto: string;
    valor: number;
    selecionada: boolean | null = null;
}