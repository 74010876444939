import { Component, OnInit } from '@angular/core';
import { Contrato, GestaoFinanceiraEmprestimoService, HistoricoEmprestimo } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-contrato-card',
  templateUrl: './contrato-card.component.html',
  styleUrls: ['./contrato-card.component.css']
})
export class ContratoCardComponent implements OnInit {

    mostrarContrato: boolean;
    erro: boolean;
    isLoading: boolean;

    historicoEmprestimo: HistoricoEmprestimo [] = [];

    chkCtratoEmpSeSuite: boolean;

    MENSAGEM_ERRO = 'Não foi possível consultar o contrato no momento. Por favor tente novamente mais tarde.';

  constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        this.getHistoricoEmprestimo();
    }

    public getHistoricoEmprestimo() {
        this.isLoading = true;

        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratosPainel().subscribe(histEmprestimo => {
            this.historicoEmprestimo = histEmprestimo;
            if (this.historicoEmprestimo.length !== 0) {
                this.mostrarContrato = true;
                this.chkCtratoEmpSeSuite = this.historicoEmprestimo[0].existeContrato;
            }
            this.isLoading = false;
        }, () => this.erroCarregamento());
    }

    public baixarContrato(historicoEmprestimo: HistoricoEmprestimo) {
        this.isLoading = true;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratoSESuite(
            historicoEmprestimo.matriculaParticipante, historicoEmprestimo.id,
        () => { this.isLoading = false; }, () => this.erroCarregamento());
    }

    public baixarExtrato(historicoEmprestimo: HistoricoEmprestimo) {
        this.isLoading = true;
        this.gestaoFinanceiraEmprestimoService.Emprestimo_ExtratoPdfPorNumeroContrato(
            historicoEmprestimo.id, new Date() , () => { this.isLoading = false; }, () => this.erroCarregamento());
    }

    private erroCarregamento() {
        this.erro = true;
        this.isLoading = false;
    }
}
