import { CarenciaModel } from './../models/sinqia-api/carencia-model';
import { PrevisaoModel } from './../models/sinqia-api/previsao-model';
import { BeneficioAssistido } from './../models/beneficio-assistido.model';
import { AutoPatrocinioDto } from '../models/sinqia-api/auto-patrocinio.dto';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Service } from './service';
import {
    GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem,
    GestaoPlanoBeneficioServicePercentuaisAposGetPercAposResponseItem,
    GestaoPlanoBeneficioServicePercentuaisAposPlanValoresResponse,
    GestaoPlanoBeneficioDemostrativoPagamento,
    GestaoPlanoLancamentoBeneficio,
    ServicoTotal
} from '../models';
import { HttpClientService } from './http-client.service';
import { ETokenApi } from '../enums';


@Injectable()
export class GestaoPlanoBeneficioService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment, private httpClientService: HttpClientService) {
        super(http);
        this.url = this.environment.API_GESTAO_PLANO_BENEFICIO;
    }

    Beneficios_SegundaViaDemonstrativo(dataReferencia: string, callback: Function, thisArg: any) {
        const url = `${this.url}/Beneficios/SegundaViaDemonstrativo?dataReferencia=${dataReferencia}`;
        return this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    Beneficios_SegundaViaDemonstrativoEmail(dataReferencia: string): Observable<any> {
        const url = `${this.url}/Beneficios/SegundaViaDemonstrativoEmail?dataReferencia=${dataReferencia}`;
        return this.get<any[]>(url);
    }

    Beneficios_GetInss(): Observable<ServicoTotal> {
        const url = `${this.url}/TempoDeServico/GetInss`;
        return this.httpClientService.get<ServicoTotal>(url, ETokenApi.TokenApiBd);
    }

    Beneficios_GetAutoPatrocinio(): Observable<AutoPatrocinioDto> {
        const url = `${this.url}/Beneficios/GetAutoPatrocinio`;
        return this.get<AutoPatrocinioDto>(url);
    }

    Beneficios_GetBeneficioAssistido(dataReferencia: string) {
        const url = `${this.url}/Beneficios/GetBeneficioAssistido?dataReferencia=${dataReferencia}`;
        return this.cachedGet<BeneficioAssistido>(url);
    }

    Historico_DatasBeneficio(
        ano: number
    ): Observable<GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem[]> {
        const url = `${this.url}/Historico/DatasBeneficio?ano=${ano}`;
        return this.cachedGet<GestaoPlanoBeneficioServiceHistoricoDatasBeneficioResponseItem[]>(url);
    }

    Lancamento_AnoContraCheque(informeRendimento: boolean = false): Observable<number[]> {
        const url = informeRendimento ? `${this.url}/Lancamento/AnoContraCheque?informeRendimento=${informeRendimento}` : `${this.url}/Lancamento/AnoContraCheque`;
        return this.cachedGet<number[]>(url);
    }

    Lancamento_MesContraCheque(
        ano: number
    ): Observable<number[]> {
        const url = `${this.url}/Lancamento/MesContraCheque?ano=${ano}`;
        return this.cachedGet<number[]>(url);
    }

    Lancamento_DemostrativoPagamento(ano: number = null, mes: number = null): Observable<GestaoPlanoBeneficioDemostrativoPagamento[]> {
        const url = `${this.url}/Lancamento/DemostrativoPagamento?ano=${ano}&mes=${mes}`;
        return this.post<GestaoPlanoBeneficioDemostrativoPagamento[]>(url);
    }

    Lancamento_GetBeneficio(): Observable<GestaoPlanoLancamentoBeneficio> {
        const url = `${this.url}/Lancamento/GetLancamentoBeneficio`;
        return this.cachedGet<GestaoPlanoLancamentoBeneficio>(url);
    }

    Lancamento_GetContraCheque(anoMes: string, adiantAbono: string): Observable<any> {
        const url = `${this.url}/Lancamento/GetContraCheque?anoMes=${anoMes}&adiantAbono=${adiantAbono}`;
        return this.cachedGet<any>(url);
    }

    PercentuaisApos_GetPercApos(
        grupo: number,
        consultaGerencialRegFibra?: number,
        cache: boolean = true
    ): Observable<GestaoPlanoBeneficioServicePercentuaisAposGetPercAposResponseItem[]> {
        const dataAtual = new Date();
        const url = this.getUrlPrevisaoAposentadoria(
            dataAtual.getDate(),
            dataAtual.getMonth() + 1,
            dataAtual.getFullYear(),
            false,
            false,
            null,
            2,
            grupo,
            0,
            false,
            false,
            consultaGerencialRegFibra
        );

        if (cache) {
            return this.cachedGet<GestaoPlanoBeneficioServicePercentuaisAposGetPercAposResponseItem[]>(url);
        } else {
            return this.get<GestaoPlanoBeneficioServicePercentuaisAposGetPercAposResponseItem[]>(url);
        }
    }

    PercentuaisApos_GetPrevisoesApos(idAdesao?: string): Observable<PrevisaoModel[]> {
        let url = `${this.url}/PercentuaisApos/GetPrevisoesApos`;

        if (idAdesao) {
            url = `${url}/${idAdesao}`
        }

        return this.get<PrevisaoModel[]>(url);
    }

    PercentuaisApos_GetCarenciaApos(idTipoConcessao: number, dataReferencia: string, idAdesao?: string): Observable<CarenciaModel> {
        let url = `${this.url}/PercentuaisApos/GetCarenciaApos/${idTipoConcessao}/${dataReferencia}`;

        if (idAdesao) {
            url = `${url}/${idAdesao}`
        }

        return this.get<CarenciaModel>(url);
    }

    PercentuaisApos_PlanValores(
        previsao: GestaoPlanoBeneficioServicePercentuaisAposGetPercAposResponseItem,
        consultaGerencialRegFibra?: number
    ): Observable<GestaoPlanoBeneficioServicePercentuaisAposPlanValoresResponse> {
        let url = `${this.url}/PercentuaisApos/PlanValores`;

        if (consultaGerencialRegFibra) {
            url = `${url}Gerencial?regFibra=${consultaGerencialRegFibra}`;
        }

        return this.cachedPost<GestaoPlanoBeneficioServicePercentuaisAposPlanValoresResponse>(url, previsao);
    }

    Beneficio_FichaFinanceira(ano: number, callback?: Function, thisArg?: any) {
        const url = `${this.url}/Beneficios/ObterFichaFinanceira?ano=${ano}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    Beneficio_CalculoBaseIR(ano: number, callback?: Function, thisArg?: any) {
        const url = `${this.url}/Beneficios/ObterCalculoBaseIR?ano=${ano}`;
        this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    private getUrlPrevisaoAposentadoria(
        dia: number,
        mes: number,
        ano: number,
        especial: boolean,
        comum: boolean,
        tempoAComprovar: number,
        opcao: number,
        grupo: number,
        percentualAcumulado: number,
        usaSimulacao: boolean,
        retbenefpleno: boolean,
        consultaGerencialRegFibra?: number
    ) {
        const endpointName = consultaGerencialRegFibra ? 'GetPercAposGerencial' : 'GetPercApos';

        let url =
            `${this.url}/PercentuaisApos/${endpointName}?dia=${dia}` +
            `&mes=${mes}` +
            `&ano=${ano}` +
            `&especial=${especial}` +
            `&comum=${comum}` +
            `&tempocomprovar=${tempoAComprovar || ''}` +
            `&opcao=${opcao}` +
            `&grupo=${grupo}` +
            `&percentacum=${percentualAcumulado}` +
            `&usasimulacao=${usaSimulacao}` +
            `&retbenefpleno=${retbenefpleno}`;

        if (consultaGerencialRegFibra) {
            url = `${url}&regFibra=${consultaGerencialRegFibra}`;
        }

        return url;
    }

    Beneficios_InformeRendimentos(ano: number, callback: Function, thisArg: any) {
        const url = `${this.url}/Beneficios/ObterInformeRendimento/${ano}`;
        return this.baixarArquivo(url, 'application/pdf', callback, thisArg);
    }

    /**
     * Obtém a lista de previsões de aposentadoria.
     * @returns Array de PrevisaoModel  
     */
    PercentuaisApos_GetPrevisaoAposentadoria(): Observable<Array<PrevisaoModel>> {
        let url = `${this.url}/PercentuaisApos/GetPrevisoesApos`;
        return this.get<Array<PrevisaoModel>>(url);
    }
}
