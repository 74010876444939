import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
    selector: 'app-saldos-card',
    templateUrl: './saldos-card.component.html',
    styleUrls: ['./saldos-card.component.css']
})
export class SaldosCardComponent implements OnInit {
    @Input() mostrarLinkParaExtratoCompleto = true;
    show = true;
    mensagemDeErro: string;
    bloquearContribuicaoSaldo: boolean;
    isLoading = true;
    ano: number;
    indexAnoSelecionado: number;

    constructor(
        
    ) {
    }

    ngOnInit() {
        /* Buscar DADOS CD */
        this.isLoading = false;
    }

    private informarErroDeCarregamento() {
        /* Buscar DADOS CD */
    }

    private bloquearSaldoDisponivelDeCarregamento() {
        /* Buscar DADOS CD */
    }

    baixarExtrato(ano: number) {
        /* Buscar DADOS CD */
    }

    trocaAno()
    {
       /* Buscar DADOS CD */
    }
}
