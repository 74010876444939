import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { Service } from './service';
import {
    TermoAdesao,
    DadosTelefone,
    DadosContaBancaria,
    DadosEndereco,
    Banco,
    Agencia,
    GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse
} from '../models';
import { of, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SessionInitService } from './session-init.service';
import { StorageService } from './storage.service';
import { STORAGE_KEYS } from '../constants';
import { HttpClientService } from './http-client.service';
import { ETokenApi } from '../enums';

@Injectable()
export class TermoAdesaoService extends Service {
    private url: string;
    private urlLogin: string;
    public callbackURL: string;

    constructor(http: HttpClient,
        @Inject('environment') private environment,
        private cachedSessionService: SessionInitService,
        private storageService: StorageService,
        private httpService: HttpClientService) {
        super(http);
        this.url = this.environment.API_GESTAO_PLANO_CADASTRO;
        this.urlLogin = this.environment.API_LOGIN;
        this.callbackURL = '/painel/plano-cd/inicio';
    }

    isTermAccepted(cached: boolean = true): Observable<boolean> {
        if (cached) {
            let termoAceito = this.storageService.getSecureParse<boolean>(STORAGE_KEYS.TERMO_ADESAO);
            if (termoAceito) {
                return of(true);
            }
        }

        return this.verificarTermoAdesaoAceito();
    }

    verificarTermoAdesaoAceito() {
        const url = `${this.urlLogin}/api/Account/GetUserProfile/ADERIU_TERMO_ADESAO`;

        let requestAderioTermo = this.get<boolean>(url)
            .pipe(tap((result: boolean) => {
                this.storageService.setSecure(STORAGE_KEYS.TERMO_ADESAO, result.toString());
            }));

        return requestAderioTermo as Observable<boolean>;
    }

    getTelefoneSMS(): Observable<DadosTelefone> {
        const url = `${this.url}/Telefones/GetTelefoneSMS`;
        return this.get<DadosTelefone>(url);
    }

    getDadosEndereco(): Observable<DadosEndereco> {
        const url = this.environment.API_GESTAO_PLANO_CADASTRO + '/Cadastros/GetDadosEndereco';

        return this.get(url, true).pipe(
            map(data => {
                return data;
            })
        );
    }

    consultaCEPCorreios(cep: string): Observable<DadosEndereco> {
        const url = `${this.url}/Cadastros/ConsultaCEPCorreios/${cep}`;
        return this.get<DadosEndereco>(url);
    }

    getBancos(): Observable<Banco[]> {
        const url = `${this.url}/Cadastros/GetBancos`;
        return this.get<Banco[]>(url);
    }

    getAgencias(): Observable<Agencia[]> {
        const url = `${this.url}/Cadastros/GetAgencias`;
        return this.get<Agencia[]>(url);
    }

    getDadosBancarios(): Observable<DadosContaBancaria> {
        const url = this.environment.API_GESTAO_PLANO_CADASTRO + '/Cadastros/getDadosBancarios';

        return this.get(url, true).pipe(
            map(data => {
                return data;
            })
        );
    }

    // AceitarTermoAdesao(termoAdesao: TermoAdesao) {
    //     const url = `${this.url}/Cadastros/AceitarTermoAdesao`;
    //     return this.post<TermoAdesao>(url, termoAdesao);
    // }

    aceitarTermoAdesao(body: any): Observable<any> {
        const smsURL = this.environment.API_GESTAO_PLANO_CADASTRO + '/Cadastros/AceitarTermoAdesao';

        return this.postOptions(smsURL, body, true, 'text').pipe(
            map(data => {
                return data;
            })
        );
    }

    enviarEmailTermoAdesao(termoAdesao: TermoAdesao): Observable<TermoAdesaoService> {
        const url = `${this.url}/Cadastros/EnviarEmailTermoAdesao`;
        return this.post<TermoAdesao>(url, termoAdesao);
    }

    getHtmlTermoAdesao(body: any): Observable<any> {
        const smsURL = this.environment.API_GESTAO_PLANO_CADASTRO + '/Cadastros/GetHtmlTermoAdesao';
        const headers = this.httpService.addHeadersAdesao();
        return this.httpService.post(smsURL, body, ETokenApi.TokenApiBd, headers, "text")
            .pipe(
                map(data => {
                    return data;
                })
            );
    }

    GetRegFibra(loginuser): Observable<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse> {
        const url = `${this.url}/Cadastros/GetByCPF/${loginuser}`;
        return this.get<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse>(url);
    }

    GetInfoCadastrais(): Observable<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse> {
        const url = `${this.url}/Cadastros/GetInfoCadastrais`;
        return this.get<GestaoPlanoCadastroServiceCadastrosGetUsuarioResponse>(url);
    }

    getPdfTermoAdesao(termoAdesao: TermoAdesao): Observable<DadosTelefone> {
        const url =
        this.environment.API_GESTAO_PLANO_CADASTRO +
        '/Cadastros/GetPdfTermoAdesao/' +
        termoAdesao.email +
        '/' +
        termoAdesao.dadosTelefone.ddd +
        '/' +
        termoAdesao.dadosTelefone.telefone;
        
        let options: any = {
            headers: this.getHeaders(true),
            withCredentials: true,
            responseType: 'arraybuffer'
        };
        const headers = this.httpService.addHeadersAdesao();
        return this.httpService.get<DadosTelefone>(url, ETokenApi.TokenApiBd, headers, options.responseType)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
}
