import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Service } from './service';
import { Observable } from 'rxjs';
import { ParticipantePendenteAprovacaoRequest } from '../models/participante-pendente-aprovacao-request.model';
import { AdesaoParticipantePendenteAprovacaoResponse } from '../models/adesao-participante-pendente-aprovacao-response.model';
import { AprovacaoDescontoFolhaRequest } from '../models/aprovacao-desconto-folha-request.model';
import { AprovacaoDescontoFolhaResponse } from '../models/aprovacao-desconto-folha-response.model';

@Injectable()
export class SolicitacaoDescontoFolhaService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = this.environment.API_GESTAO_PLANO_CADASTRO;
    }

    getParticipantesPendentesAprovacao(participantePendente: ParticipantePendenteAprovacaoRequest)
        : Observable<Array<AdesaoParticipantePendenteAprovacaoResponse>> {
        const url = `${this.url}/Adesao/GetParticipantesPendentesAprovacao`;
        return this.post<Array<AdesaoParticipantePendenteAprovacaoResponse>>(url, participantePendente);
    }

    aprovarDescontoFolha(aprovacao: AprovacaoDescontoFolhaRequest)
        : Observable<AprovacaoDescontoFolhaResponse> {
        const url = `${this.url}/Adesao/AprovarDescontoFolha`;
        return this.post<AprovacaoDescontoFolhaResponse>(url, aprovacao);
    }

    desaprovarDescontoFolha(aprovacao: AprovacaoDescontoFolhaRequest)
        : Observable<AprovacaoDescontoFolhaResponse> {
        const url = `${this.url}/Adesao/DesaprovarDescontoFolha`;
        return this.post<AprovacaoDescontoFolhaResponse>(url, aprovacao);
    }
}
