import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { SolicitacaoAdesaoCdModel } from "../../models/sinqia-api";
import { BaseService } from "./base.service";
import { ETipoPlano, ETokenApi } from "../../enums";
import { ResponseApiCdModel } from "../../models/response-api-plano-cd.model";
import { HttpClientService } from "../http-client.service";
import { SimulacaoBeneficioModel } from "../../models/sinqia-api/simulacao-beneficio.model";

@Injectable()
export class SimulacaoBeneficioService extends BaseService {

    constructor(protected httpClient: HttpClient, @Inject('environment') protected environment,
        private httpClientService: HttpClientService) {
        super(httpClient, environment);
    }

    obterSimulacaoBeneficio() {
        const endpoint = `${this.endpoint}/simulacao-beneficio/concessao`;
        return this.httpClientService.get<ResponseApiCdModel<SimulacaoBeneficioModel>>(endpoint, ETokenApi.TokenApiCd);
    }
}