export interface TermoAdesao {
    regFibra: number;
    cPF: string;
    nome: string;
    mobile: boolean;
    sendEmail: boolean;
    dadosTelefone: DadosTelefone;
    email: string;
    dadosEndereco: DadosEndereco;
    dadosContaBancaria: DadosContaBancaria;
}

export interface DadosTelefone {
    indice: number;
    regFibra: string;
    tipo: string;
    ordem: number;
    ddd: string;
    telefone: string;
    observacao: string;
    sms: boolean;
}

export interface DadosEndereco {
    cep: string;
    endereco: string;
    complemento: string;
    numero: string;
    cidade: string;
    estado: string;
    bairro: string;
    logradouro: Logradouro;
    pais: Pais;
}

export class Logradouro {
    cdLogradouro: string;
    descLogradouro: string;

    public constructor(
        fields?: {
            cdLogradouro?: string,
            descLogradouro?: string
        }) {
        if (fields) { Object.assign(this, fields); }
    }
}

export interface Pais {
    cdPais: string;
    descPais: string;
    sigla: string;
}

export interface DadosContaBancaria {
    contaBco: string;
    fotoDaConta: string;
    tipoCc: string;
    tipoCcDesc: string;
    banco: Banco;
    agencia: Agencia;
}

export interface Banco {
    cdBanco: string;
    nomeBanco: string;
    nroRemessa: string;
    dtRemessa: Date;
}

export interface Agencia {
    cdBanco: string;
    cdAgencia: string;
    dvAgencia: string;
    nomeAgencia: string;
    cidAgencia: string;
    stamp: string;
    dvAgenciaSal: string;
}

export const TIPO_CONTAS: any[] = [
    {cod: 1, desc: 'Conta Corrente'},
    {cod: 2, desc: 'Conta Simples'},
    {cod: 3, desc: 'Conta Jurídica'},
    {cod: 6, desc: 'Entidades Públicas'},
    {cod: 7, desc: 'Depósitos Instituições'},
    {cod: 13, desc: 'Poupança Física'},
    {cod: 22, desc: 'Poupança Jurídica'},
    {cod: 28, desc: 'Poupança Crédito Imo.'},
    {cod: 48, desc: 'Depósitos Lotéricos.'},
];
