import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SignDataModel } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-sign-widget',
    templateUrl: './sign-widget.component.html',
    styleUrls: ['./sign-widget.component.css']
})
export class SignWidgetComponent implements OnInit {
    window: any = window;
    widget: any = null;

    @Output() signKey: string = '';
    @Output() onSigned: EventEmitter<SignDataModel> = new EventEmitter<SignDataModel>();
    @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onResized: EventEmitter<number> = new EventEmitter<number>();
    endpoint: string = '';
    origin: string = ''


    constructor(@Inject('environment') private environment) {
        this.endpoint = environment.CLICK_SIGN_CONFIG.endpoint;
        this.origin = environment.CLICK_SIGN_CONFIG.origin;
    }

    ngOnInit(): void {
    }

    /**
     * Carrega o documento para assinatura
     * @param signData dados para assinatura
     */
    carregarWidget(signData: SignDataModel) {
        let self = this;

        if (this.widget)
            this.widget.unmount();

        // Carrega o widget embedded com a request_signature_key
        this.widget = new this.window.Clicksign(signData.signKey);

        // Define o endpoint https://sandbox.clicksign.com ou https://app.clicksign.com
        this.widget.endpoint = this.endpoint;

        // Define a URL de origem (parametro necessário para utilizar através de WebView)
        this.widget.origin = this.origin;

        // Monta o widget no div
        this.widget.mount('container');

        // Callback que será disparado quando o documento for carregado
        this.widget.on('loaded', (ev) => {
            self.onLoaded.emit(true);
        });

        // Callback que será disparado quando o documento for assinado
        this.widget.on('signed', (ev) => {
            self.onSigned.emit(signData);
        });

        /**
         *  Callback que será disparado nas etapas de informar dados do signatário
         *  e token, retornando a altura (height) dessas páginas para ajuste do container
         *  onde o iframe se encontra.
         */
        this.widget.on('resized', (height: number) => {
            self.onResized.emit(height);
            document.getElementById('container').style.height = height + 'px';
        });
    }
}
