import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Service } from "./service";
import { Observable } from 'rxjs';
import { Menu } from "../models/menu.model";

@Injectable()
export class MenuAppService extends Service {
    private url: string;
    constructor(http: HttpClient, @Inject('environment') private environment) {
        super(http);
        this.url = environment.API_DOCUMENTOS;
    }

    pegarMenuApp(): Observable<Menu[]> {
        const url = `${this.url}/api/Download/GetMenuApp/`;
        return this.get<Menu[]>(url);
    }
}
