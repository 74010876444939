import { Component, OnInit } from '@angular/core';
import { GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem, corrigirLinkYoutube
} from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-solicitacao-adiantamento-abono',
    templateUrl: './adiantamento-abono.component.html',
    styleUrls: ['./adiantamento-abono.component.css']
})
export class SolicitacaoAdiantamentoAbonoComponent implements OnInit {
    

    ngOnInit() {

	}
}
