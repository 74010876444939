import { Component, OnInit } from '@angular/core';
import { TvFibraService } from '@fibra/fibra-shared-lib';
import { GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem, corrigirLinkYoutube
} from '@fibra/fibra-shared-lib';
@Component({
    selector: 'app-tela-tvfibra',
    templateUrl: './tela-tvfibra.component.html',
    styleUrls: ['./tela-tvfibra.component.css']
})
export class TelaTvfibraComponent implements OnInit {
    corrigirLinkYoutube = corrigirLinkYoutube;
    iframeCarregado = false;
    isLoading: boolean;
    isVideoLoading = false;
    page = 0;
    itensPorPagina = 4;
    videos: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem[] = [];
    videoSelecionado: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem;

    constructor(private tvFibraService: TvFibraService) { }

    ngOnInit() {
       this.loadVideos();
    }

    loadVideos() {
        this.isLoading = true;
        this.tvFibraService.getVideos(this.page).subscribe(videos => {
            if (videos) {
                this.videos = videos;
            } else {
                this.iframeCarregado = false;
            }
            this.isLoading = false;
        }, () => this.iframeCarregado = false);
    }

    exibirVideo(video: GestaoRelacionamentoComunicacaoServiceTvFibraGetResponseItem) {
        this.iframeCarregado = false;
        this.isVideoLoading = true;
        this.videoSelecionado = video;
    }

    onScroll(load: any) {
       this.itensPorPagina = this.itensPorPagina + load;
       this.loadVideos();
    }

    upScroll() {
       this.itensPorPagina = 4;
       this.loadVideos();
    }
}
