import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ETipoEnvioNotificacao } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-notification-option-dialog',
    templateUrl: './notification-option-dialog.component.html',
    styleUrls: ['./notification-option-dialog.component.css']
})
export class NotificationOptionDialogComponent implements OnInit {
    isLoading = false;
    eTipoEnvioNotificacao = ETipoEnvioNotificacao;

    @Input() visible: boolean = false;
    @Input() header: string = 'Envio de notificação';
    @Input() emailBoxTitle: string = 'Receber e-mail';
    @Input() emailBoxDescription: string = 'Receber e-mail com um código.';
    @Input() smsBoxTitle: string = 'Receber SMS';
    @Input() smsBoxDescription: string = 'Receber SMS com um código.'
    @Input() extraInfo: string = '';
    @Output() onSelectType = new EventEmitter<ETipoEnvioNotificacao>();

    constructor() { }

    ngOnInit(): void {
    }

    private selectType(eTipoEnvioNotificacao: ETipoEnvioNotificacao) {
        this.onSelectType.emit(eTipoEnvioNotificacao);
    }

    show() {
        this.visible = true;
    }

    close() {
        this.visible = false;
    }
}
