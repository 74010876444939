import { EDocumentoApresentado } from "../enums/documento-apresentado.enum";

export class TempoVinculacaoModel {
    id: number;
    razaoSocial: string;
    documentoApresentado: EDocumentoApresentado | string | null;
    tipoServico: string;
    comumEspecial: string;
    dataInicio: Date;
    dataFim: Date;
}