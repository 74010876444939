import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BlockBlobClient } from "@azure/storage-blob";
import { EventEmitter, Inject, Injectable } from "@angular/core";
import { BlobResponse } from "../models/blob-response.model";
import * as _ from "lodash";

@Injectable()
export class UploadClientService {

    constructor(
        @Inject('environment') private environment,
        private httpClient: HttpClient) {
    }

    async upload(path: string, fileName: string, file: Blob, onProgress: (percentage: number) => void): Promise<BlobResponse> {
        const fileSize = file.size;
        var fileExtension = fileName.split('.').pop() || "";
        let blockClientProvider = await this.criarBlobClient(path, fileExtension);
        let blockClient = blockClientProvider.client;
        const standardOnProgress = (percentage: Number) => { };
        var result = await blockClient.uploadData(file, {
            onProgress: (progress) => {
                const percent = (progress.loadedBytes * 100) / fileSize;
                (onProgress || standardOnProgress)(percent);
            }
        });
        await blockClient.setMetadata({ "nomeOriginalDoArquivo": fileName });
        let filenameBlob = blockClientProvider.nomeDoArquivo as string;
        let paths = filenameBlob.split('/');
        let filenameBlobShort = _.last(paths) as string;
        let fileId = _.first(filenameBlobShort?.split('.')) ?? '';
        return {
            id: fileId,
            path: blockClientProvider.nomeDoArquivo,
            filename: filenameBlobShort
        }
    }

    private async criarBlobClient(caminhoDoArquivo: string, fileExtension: string): Promise<any> {
        var placeholder = await this.criarPlaceHolderParaUpload(caminhoDoArquivo, fileExtension);
        var client = new BlockBlobClient(placeholder.urlDeUpload);
        return {
            client: client,
            nomeDoArquivo: placeholder.nomeDoArquivo
        }
    }

    private async criarPlaceHolderParaUpload(path: string, fileExtension: string) {
        var accessToken = sessionStorage.getItem('accessToken');
        let httpHeader = new HttpHeaders();
        var url = `${this.environment.API_GESTAO_PLANO_CADASTRO}/perfil/iniciarupload/autoatendimento`;
        if (accessToken) {
            httpHeader = new HttpHeaders({ 'Authorization': accessToken });
        }
        var uploadPlaceHolder = await this.httpClient.post<{
            urlDeUpload: string,
            nomeDoArquivo: string
        }>(url, {
            caminhoDoArquivo: path,
            extensaoDoArquivo: fileExtension
        }, { headers: httpHeader }).toPromise();
        return uploadPlaceHolder;
    }
}
