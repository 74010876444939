import { Component, OnInit } from '@angular/core';
import { GestaoFinanceiraEmprestimoService, SaldoDevedor } from '@fibra/fibra-shared-lib';

@Component({
  selector: 'app-carencia-card',
  templateUrl: './carencia-card.component.html',
  styleUrls: ['./carencia-card.component.css']
})
export class CarenciaCardComponent implements OnInit {

    isLoading = true;
    emprestimoSaldo: SaldoDevedor;
    dataCarencia: Date = new Date();

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    ngOnInit() {
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetSaldoDevedor(new Date()).subscribe( saldo => {
            this.emprestimoSaldo = saldo;
            this.isLoading = false;
        });
    }

    obterBoletoAmortizacao() {

    }

}
