import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-zona-equilibrio',
  templateUrl: './zona-equilibrio.component.html',
  styleUrls: ['./zona-equilibrio.component.scss'],
})
export class ZonaEquilibrioComponent implements OnInit, OnChanges {

  @Input() zonaEquilibrio: number = 0;     
  @Input() zonaEquilibrioMax: number = 0;   
  @Input() zonaEquilibrioMin: number = 0; 

  tooltip: string = '';
  percentagePosition: number = 0;

  ngOnInit(): void {
  }

  atualizarPosicao(value: number): void {
    if(value){
      this.tooltip = value.toFixed(2) + '%';
      this.percentagePosition = ((value - this.zonaEquilibrioMin) / (this.zonaEquilibrioMax - this.zonaEquilibrioMin)) * 100 + 15;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.atualizarPosicao(this.zonaEquilibrio);

  }
}
