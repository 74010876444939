import { LinksIntegracao } from '@fibra/fibra-shared-lib';
import { Component } from '@angular/core';

@Component({
    selector: 'app-rodape',
    templateUrl: './rodape.component.html',
    styleUrls: ['./rodape.component.css']
})

export class RodapeComponent {

    readonly OpenExternalURL = LinksIntegracao.WHATS_APP;

    openExternalURL(link: string) {
        window.open(link, 'blank');
    }
 }
