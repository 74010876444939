import { Routes } from '@angular/router';

import {
    PainelComponent,
    TrocaPlanoComponent,
    AdesaoCdComponent,
    Passo1Component,
    Passo2Component,
    Passo3Component,
    Passo4Component,
    FinalizadoComponent,
    AdesaoSimuladorComponent,
    //plano bd saldado
    PlanoBdSaldadoAssistidoComponent,
    PlanoBdSaldadoAtivoComponent
} from '.';
import {
    AuthGuardService
} from '@fibra/fibra-shared-lib';
import { AdesaoCdGuard } from '../guards/adesao-cd.guard';
import { AuthCdGuard } from '../guards/auth-cd.guard';
import { SignWidgetComponent } from '../components/clicksign/sign-widget/sign-widget.component';
import { AdesaoPendenteGuard } from '../guards/adesao-pendente.guard';


export const DASHBOARD_ROUTES: Routes = [
    {
        path: 'painel',
        component: PainelComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: '',
                component: TrocaPlanoComponent,
                pathMatch: 'full'
            },
            {
                path: 'adesao-cd',
                component: AdesaoCdComponent,
                canActivate: [AuthCdGuard, AdesaoPendenteGuard],
                children:[
                    {
                        path: '',
                        redirectTo: 'informacoes-iniciais',
                        pathMatch: 'full'
                    },
                    {
                        path: 'informacoes-iniciais',
                        component: Passo1Component,
                    },
                    {
                        path: 'perfil-investimento',
                        component: Passo2Component,
                        canActivate: [AdesaoCdGuard]
                    },
                    {
                        path: 'conclusao',
                        component: Passo3Component,
                        canActivate: [AdesaoCdGuard]
                    },
                    {
                        path: 'resumo',
                        component: Passo4Component,
                        canActivate: [AdesaoCdGuard]
                    },
                    {
                        path: 'finalizada',
                        component: FinalizadoComponent,
                    }
                ]
            },
            {
                path: 'plano-bd-saldado-assistido',
                component: PlanoBdSaldadoAssistidoComponent,
            },
            {
                path: 'plano-bd-saldado-ativo',
                component: PlanoBdSaldadoAtivoComponent,
            },            
            {
                path: 'simulador',
                component: AdesaoSimuladorComponent,
            }
        ]
    },
    {
        path: 'clicksign-widget',
        component: SignWidgetComponent
    }
    
];
