import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AuthService,
    GraphMemberOf, GraphUserProfile, UserAd, LoginIntegradoService, CPF
} from '@fibra/fibra-shared-lib';
import { TokenParams } from '@fibra/fibra-shared-lib';
import { AdesaoLogin } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { AdesaoLoginResponse } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { AuthenticationResult, PopupRequest } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-login-integrado',
    templateUrl: './login-integrado.component.html',
    styleUrls: ['./login-integrado.component.css']
})
export class LoginIntegradoComponent implements OnInit {

    tokenParams: TokenParams;
    isIframe = false;
    loggedIn = false;
    fail = false;
    profile: GraphUserProfile;
    member: GraphMemberOf[] = [];
    userAd: UserAd;
    regFibra: string = '';
    tokenMicrosoft: string;
    adesaoLoginResp: AdesaoLoginResponse = null;
    adesaoLogin: AdesaoLogin = null;
    isLoading: boolean = false;
    cpf?: string;
    readonly CPF = CPF;


    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private msalService: MsalService,
        private loginIntegradoService: LoginIntegradoService

    ) { }

    ngOnInit() {
        this.cpf = this.route.snapshot.paramMap.get('cpf');
    }

    getLoginAdesoes() {
        this.isLoading = true;

        this.authService.GetLoginAdesoes(this.cpf).subscribe(adesoesResp => {
            this.adesaoLoginResp = adesoesResp;
            if (!adesoesResp.loginDuplo) {
                this.adesaoLogin = adesoesResp.adesoes[0];
                this.regFibra = this.adesaoLogin.registroFibra;
                this.login();
            }
            this.isLoading = false;
        }, error => {
            this.isLoading = false;
        });
    }

    selecionarAdesao(regFibra: string) {
        this.adesaoLogin = this.adesaoLoginResp.adesoes.find(ade => ade.registroFibra == regFibra);
    }

    login() {
        this.fail = false;
        let activeAccount = this.msalService.instance.getActiveAccount();
        if (activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
            this.loggedIn = true;
            this.loginIntegradoService.login(this.cpf, this.regFibra)
                .then(() => {
                }).catch(error => {
                    this.loggedIn = false;
                    this.fail = true;
                });
        } else {
            this.msalService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.msalService.instance.setActiveAccount(response.account);
                    this.loginIntegradoService.login(this.cpf, this.regFibra)
                        .then(() => {
                        }).catch(error => {
                            this.loggedIn = false;
                            this.fail = true;
                        });
                });
        }
    }

    logout() {
        this.loginIntegradoService.logout();
        this.loggedIn = true;
        this.fail = false;
        this.loggedIn = false;
    }
}
