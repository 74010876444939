import { AuthService } from '@fibra/fibra-shared-lib';
import { LinksIntegracao } from '@fibra/fibra-shared-lib';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baixar-app',
  templateUrl: './baixar-app.component.html',
  styleUrls: ['./baixar-app.component.css']
})
export class BaixarAppComponent implements OnInit {

  readonly OpenExternalURL = LinksIntegracao.PLAY_STORE;
  habilitarLink = false;
  isLoading = false;
  regFibra: number;
  openExternalUrlIOS: string;
  isModalVisible = false;
  errorMessage: string;

  constructor(private authService: AuthService) { }

    ngOnInit() {
        this.getRegistroFibra();
    }

    getRegistroFibra() {
        this.isLoading = true;
        this.authService.getRegFibra().subscribe((regFibra) => {
            this.regFibra = regFibra;
            if (this.isAppleDevice) {
                this.gerarLinkIOS();
             }
            this.isLoading = false;
        }, (error) => this.handleError(error, 'Ocorreu um erro ao carregar o registro. Por favor contate o suporte.'));
    }

    gerarLinkIOS() {
        this.isLoading = true;
        this.openExternalUrlIOS = '';
        this.authService.getLinkIos(this.regFibra).subscribe((link) => {
            this.openExternalUrlIOS = link;
            this.isLoading = false;
        }, (error) => this.handleError(error, 'Ocorreu um erro ao gerar o link. Por favor contate o suporte.'));
    }

    openExternalURL(link: string) {
        if (link) {
         window.open(link, '_blank');
        }
    }

    handleError(error: any, mensagem: string) {
        console.log(error);
        this.isLoading = false;
        this.isModalVisible = true;
        this.errorMessage = mensagem ? mensagem : 'Houve um erro em sua requisição. Por favor contate o suporte.';
    }

    get isAppleDevice() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    }
}
