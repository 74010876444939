import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AdesaoCdService, EPerfilInvestimento, ERegimeTributario, ETipoDependente, MENSAGEM_ERRO, SolicitacaoAdesaoCdModel, TermoAdesaoService, TokenService } from '@fibra/fibra-shared-lib';
import { TokenDialogComponent } from '../../../components/token-dialog/token-dialog.component';
import { TermoAdesao } from '@fibra/fibra-shared-lib/lib/models/termo-adesao.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-passo-04',
    templateUrl: './passo-04.component.html',
    styleUrls: ['./passo-04.component.css']
})
export class Passo4Component implements OnInit {
    solicitacaoAdesao: SolicitacaoAdesaoCdModel = null;
    regimeTributarioTexto: string = 'Regime tributário não selecionado.';
    perfilInvestimentoTexto: string;
    concordoTermo: boolean = false;
    btnLoading: boolean = false;
    email: string | null = null;
    displayTokenDialog: boolean = false;
    mensagemEnvioToken: string = '';
    termoFolhaPagamento: boolean = false;
    termoDataAdesao: boolean = false;
    termoSimulacao: boolean = false;
    @ViewChild('tokenDialog') tokenDialog: TokenDialogComponent;

    constructor(private router: Router,
        private termoAdesaoService: TermoAdesaoService,
        private adesaoService: AdesaoCdService,
        private messageService: MessageService,
        private sanitizer: DomSanitizer,
        private tokenService: TokenService) { }

    items: MenuItem[];
    activeIndex: number = 0;
    eTipoDependente = ETipoDependente;

    get dependentes() {
        if (this.solicitacaoAdesao?.dependentes.length > 0)
            return this.solicitacaoAdesao?.dependentes?.filter(x => x.tipoDependente == ETipoDependente.Beneficiario);
        return [];
    }

    get designados() {
        if (this.solicitacaoAdesao?.dependentes.length > 0)
            return this.solicitacaoAdesao?.dependentes.filter(x => x.tipoDependente == ETipoDependente.Designado);
        return [];
    }

    ngOnInit() {
        this.activeIndex = 2;
        this.items = [
            { label: 'Informações iniciais' },
            { label: 'Perfil de investimento' },
            { label: 'Conclusão' }
        ];

        this.solicitacaoAdesao = this.adesaoService.getSolicitacaoAdesao();
        this.email = this.solicitacaoAdesao.pessoa?.email;
        this.concordoTermo = this.solicitacaoAdesao.concordoTermo;
        // let regimeTributario = this.solicitacaoAdesao.tipoRegimeTributario;
        // if (regimeTributario == ERegimeTributario.Progressivo) {
        //     this.regimeTributarioTexto = 'Regime Progressivo (Compensável): Quanto maior a renda, maior a tributação.';
        // } else if (regimeTributario == ERegimeTributario.Regressivo) {
        //     this.regimeTributarioTexto = 'Regime Regressivo (Definitivo): Quanto maior o prazo, menor a tributação.';
        // }

        this.perfilInvestimentoTexto = 'Perfil ' + this.getPerfilInvestimentoTexto(this.solicitacaoAdesao.perfilInvestimento);
        this.carretarTermo();
    }

    onBack() {
        this.router.navigate(['/painel/adesao-cd/conclusao']);
    }

    solicitarAdesao() {
        this.isLoading = this.btnLoading = true;
        this.solicitacaoAdesao = this.adesaoService.getSolicitacaoAdesao();

        this.adesaoService.validarSolicitarAdesao(this.solicitacaoAdesao)
            .subscribe((data) => {
                if (data.success && data.data) {
                    this.adesaoService.solicitarAdesao(this.solicitacaoAdesao)
                        .subscribe(result => {
                            if (result.success) {
                                this.solicitacaoAdesao.token = null;
                                this.isLoading = this.btnLoading = false;
                                this.adesaoService.limparDadosAdesaoLocal();
                                this.router.navigate(['/painel/adesao-cd/finalizada']);
                            }
                        }, error => {
                            this.solicitacaoAdesao.token = null;
                            this.isLoading = this.btnLoading = false;
                            this.messageService.clear();
                            console.error(error);

                            // Mensagem Erro genérico
                            this.mostrarMensagemErro(MENSAGEM_ERRO.GenericoFibra);

                        });
                } else {
                    this.solicitacaoAdesao.token = null;
                    this.mostrarMensagemErro();
                }
            }, error => {
                this.solicitacaoAdesao.token = null;
                this.isLoading = this.btnLoading = false;
                this.messageService.clear();
                console.error(error);
                this.mostrarMensagemErro(error?.error?.errors?.length > 0 ? error?.error?.errors[0] : '');
            });
    }

    getPerfilInvestimentoTexto(perfilInvestimento: EPerfilInvestimento) {
        switch (perfilInvestimento) {
            case EPerfilInvestimento.Conservador:
                return 'Conservador';
            case EPerfilInvestimento.Moderado:
                return 'Moderado';
            case EPerfilInvestimento.Arrojado:
                return 'Arrojado';
            default:
                return '';
        }
    }

    modalTermoAberta = false;
    alterarVisibilidadeModalDoTermo() {
        this.modalTermoAberta = !this.modalTermoAberta;
    }

    definirAceiteTermo(concordoTermo: boolean) {
        this.solicitacaoAdesao.concordoTermo = concordoTermo;
        this.adesaoService.definirAceiteTermo(concordoTermo);
    }

    mostrarMensagemErro(mensagem: string = null) {
        if (mensagem)
            mensagem = 'Falha ao solicitar adesão: ' + mensagem;
        else
            mensagem = 'Falha ao solicitar adesão';
        this.messageService.clear();
        this.messageService.add({ severity: 'error', summary: 'Falha', detail: mensagem });
    }

    onTokenSuccess(token: string) {
        this.displayTokenDialog = false;
        this.solicitacaoAdesao.token = token;
        this.adesaoService.atualizarToken(token);
        this.solicitarAdesao();
    }

    validarToken() {
        this.tokenDialog.enviarTokenEmail(this.email);
        if (this.solicitacaoAdesao.token == null) {
            this.tokenDialog.show();
        } else {            
            this.solicitarAdesao();            
        }
    }

    podeConcluirAdesao() {
        return this.concordoTermo == true && this.termoSimulacao == true && this.termoFolhaPagamento == true;
    }

    termoHTML: any = "";
    isLoading: boolean = false;
    pdfLink: any = "";
    pdfBlob: any = {};

    carretarTermo() {
        const pessoa = this.solicitacaoAdesao.pessoa;
        let termoAdesao: TermoAdesao = {
            cPF: pessoa.cpf,
            dadosContaBancaria: null,
            dadosEndereco: null,
            dadosTelefone: {
                ddd: pessoa.telefoneCelular.split(" ")[0],
                telefone: pessoa.telefoneCelular.split(" ")[1],
                observacao: "",
                indice: 0,
                ordem: 0,
                regFibra: "",
                sms: false,
                tipo: ""
            },
            email: pessoa.email,
            mobile: false,
            nome: pessoa.nomeCompleto,
            regFibra: 0,
            sendEmail: true
        };
        this.isLoading = true;

        this.termoAdesaoService.getHtmlTermoAdesao(termoAdesao).subscribe(
            (r: string) => {
                const cleanHTML = r.replace("width: 210mm;", "");
                this.termoHTML = this.sanitizer.bypassSecurityTrustHtml(cleanHTML);
                this.termoAdesaoService.getPdfTermoAdesao(termoAdesao).subscribe(
                    (pdf: any) => {
                        this.pdfBlob = pdf;
                        const newBlob = new Blob([pdf], { type: "application/pdf" });
                        this.pdfLink = window.URL.createObjectURL(newBlob);
                        this.pdfLink = this.sanitizer.bypassSecurityTrustUrl(this.pdfLink);
                        this.isLoading = false;
                    },
                    () => {
                        this.isLoading = false;
                    }
                );
            },
            () => {
                this.isLoading = false;
            }
        );
    }
}
