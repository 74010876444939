// PrimeNG Calendar locale
export const LOCALE_PT_BR = {
    startsWith: "Começa com",
    contains: "Contém",
    notContains: "Não contém",
    endsWith: "Termina com",
    equals: "Igual",
    notEquals: "Não igual",
    noFilter: "Sem filtro",
    lt: "Menor que",
    lte: "Menor ou igual a",
    gt: "Maior que",
    gte: "Maior ou igual a",
    is: "É",
    isNot: "Não é",
    before: "Antes",
    after: "Depois",
    dateIs: "Data é",
    dateIsNot: "Data não é",
    dateBefore: "Data é anterior a",
    dateAfter: "Data é posterior a",
    clear: "Limpar",
    apply: "Aplicar",
    matchAll: "Corresponder a todos",
    matchAny: "Corresponder a qualquer um",
    addRule: "Adicionar regra",
    removeRule: "Remover regra",
    accept: "Sim",
    reject: "Não",
    choose: "Escolher",
    upload: "Enviar",
    cancel: "Cancelar",
    dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    dayNamesMin: ["Do", "Se", "Te", "Qua", "Qui", "Sex", "Sáb"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    dateFormat: "dd/MM/yy",
    firstDayOfWeek: 0,
    today: "Hoje",
    weekHeader: "Sem",
    weak: 'Fraca',
    medium: 'Média',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyMessage: 'Nenhum resultado encontrado',
    emptyFilterMessage: 'Nenhum resultado encontrado'
};