import { DadosCadastraisService, GestaoPlanoCadastroService } from '@fibra/fibra-shared-lib';
import { Component, OnInit } from '@angular/core';
import {
     Contrato, SimulacaoPercentual,
     GestaoRelacionamentoComunicacaoService, ERRO_CRITICO_DE_CARREGAMENTO
} from '@fibra/fibra-shared-lib';
import { GestaoFinanceiraEmprestimoService } from '@fibra/fibra-shared-lib';
import { DadosCadastraisSinqiaDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api/dados-cadastrais-sinqia.dto';

@Component({
  selector: 'app-alteracao-percentual-card',
  templateUrl: './alteracao-percentual-card.component.html',
  styleUrls: ['./alteracao-percentual-card.component.css']
})
export class AlteracaoPercentualCardComponent implements OnInit {
  readonly MENSAGEM_ERRO =  ERRO_CRITICO_DE_CARREGAMENTO;
  planoDeParcelamentoEscolhido: SimulacaoPercentual;
  planosDeParcelamentoDisponiveis: SimulacaoPercentual[] = [];
  isLoading = false;
  confirmarToken = false;
  contratos: Contrato[] = [];
  msgPercentual: string;
  exibirCard: boolean;
  telefone: any;
  erro: boolean;
  msgErro: string;
  cadastroSinqia: DadosCadastraisSinqiaDto;

  get valorEmprestimoSimuladoIsValid() {
    return true;
  }

  constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService,
              private gestaoRelacionamentoComunicacaoService: GestaoRelacionamentoComunicacaoService,
              private dadosCadastraisService: DadosCadastraisService) {}

  ngOnInit() {
    this.isLoading = true;
    this.hasContratos();
  }

  hasContratos() {
    this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratos().subscribe(contratos => {
        this.contratos = contratos.filter(situacao => situacao.situacao === 'Ativo');
        if (this.contratos) {
            this.getTelefoneSms();
            this.exibirCard = true;
            this.msgPercentual = this.contratos[0].percentual.toString();
        }
        this.isLoading = false;
      }, () => {
          this.isLoading = false;
          this.erro = true;
          this.msgErro = this.MENSAGEM_ERRO;
    });
  }

  getTelefoneSms() {
    this.dadosCadastraisService.getCadastroGeral(true).subscribe(cadastroInfo => {
      this.cadastroSinqia = cadastroInfo;
        this.isLoading = false;
    },(err) => {
      this.handleError(err, this.MENSAGEM_ERRO);
    });
}

  obterSimulacaoPercentual() {
    this.isLoading = true;
    this.gestaoFinanceiraEmprestimoService.Emprestimo_SimularPercentual().subscribe(percentuais => {
        this.planosDeParcelamentoDisponiveis = percentuais;
        this.isLoading = false;
    }, HttpErrorResponse  => {
        this.isLoading = false;
        this.erro = true;
        this.msgErro = HttpErrorResponse.status === 400 ?
            HttpErrorResponse.error.message.substring(5) : this.MENSAGEM_ERRO;
    });
  }

  solicitarAlteracaoPercentual() {
   this.isLoading = true;
   if (this.planoDeParcelamentoEscolhido) {
    this.gestaoRelacionamentoComunicacaoService.Enviar_SMSToken().subscribe(data => {
        this.isLoading = false;
        
        if (data && (data.descricao !== 'Ok' && data.descricao !== 'Error')) {
            this.erro = true;
            this.msgErro = data.descricao;
            return;
        }

        this.telefone = this.cadastroSinqia &&
                        this.cadastroSinqia.contato &&
                        this.cadastroSinqia.contato.celular ? this.cadastroSinqia.contato.celular : '';

        this.confirmarToken = true;
    }, () => {
        this.isLoading = false;
        this.erro = true;
        this.msgErro = this.MENSAGEM_ERRO;
    });
   }
  }

  handleError(error: any, mensagem?: string) {
    console.log(error);
    this.isLoading = false;
    this.erro = true;
    this.msgErro = mensagem ? mensagem : 'Houve um erro em sua requisição. Por favor contate o suporte.';
  }
}
