import { Component, OnInit } from '@angular/core';
import SimuladorCd from '../../functions/simulador';
import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { CRESCI_SALARIAL } from '../../functions/assets/crescimento-salarial';
import { FAIXAS_SALARIAL, ajustarFaixas } from '../../functions/assets/faixas';
import { URF } from '../../functions/assets/urf';
import { SimuladorService } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-simulador-fibra',
    styleUrls: ['./simulador.component.css'],
    templateUrl: './simulador.component.html'
})
export class SimuladorComponent implements OnInit {

    mensagemDeErro: string;
    isLoading = true;
    show = true;
    bloquear: boolean;

    //simulador
    beneficioRendaFinanceiraCd: number = 0;
    percentualRentabilidadeMinimo: string = "3.72";
    percentualRentabilidade: string = "3.72";
    idadeAposentar: number = 0;
    idadeLimite: number = 0;
    contribuicaoMensal: number = 0;
    saldoAcumulado: number = 0;
    rendaFinanceira: number = 0;
    dataAposentadoria: number = 0;
    valorPortabilidade: number = 0;
    saldoPortabilidade: number = 0;
    textoPortabilidade: string = "";
    textoSaldoPortabilidade: string = "";
    currencyMaskConfig: CurrencyMaskConfig;
    tempoRendaFinanceira: number = 0;
    contribuicaoParticipante: number = 0;

    //remuneracao
    salarioContribuicao: number = 0;

    //preencher com a info do usuário = encontrar melhor forma de fazer
    user: any = {
        //Idade Atual do Participante
        idade: 0,
        //Salário Atual do Participante
        salario: 0,
        //Saldo de Conta Atual do Participante
        saldo: 0,
        //Tempo em Anos para Aposentadoria
        carencia: 0,
        urf: URF
    }

    /* vars grafico */
    data: any;
    options: any;
    dataPortabilidade: any;
    options2: any;
    options3: any;
    dataBeneficio: any;
    dataDonnuts: any;
    optionsDanuts: any;
    simulador: SimuladorCd;

    get temPercentualRentabilidadeMinimoTemporario() {
        return parseFloat(this.percentualRentabilidadeMinimo) > 3;
    }

    constructor(private simuladorService: SimuladorService) {
        //grafico
        this.data = {
            labels: [57, 75],
            datasets: [
                {
                    label: 'Saldo Projetado',
                    data: [0, 10000],
                    fill: true,
                    borderColor: '#28a745',
                    tension: 0.4,
                    backgroundColor: 'rgba(40,167,69,0.2)'
                }
            ]
        };

        this.options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8
        };

        this.optionsDanuts = {
            responsive: false,
            maintainAspectRatio: false
            , aspectRatio: 0.8,
        }

        this.options2 = {
            maintainAspectRatio: false,
            aspectRatio: 1.5
        };
        this.options3 = {
            maintainAspectRatio: false,
            aspectRatio: 1.5
        };

        this.currencyMaskConfig = {
            align: 'left',
            allowNegative: false,
            decimal: ',',
            precision: 2,
            prefix: 'R$ ',
            suffix: '',
            thousands: '.'
        };


    }

    async ngOnInit() {
        this.isLoading = true;
        var result = await this.simuladorService.ObterDadosSimulador().toPromise();
        this.user = result;
        this.idadeAposentar = this.user.idade + this.user.carencia;
        this.salarioContribuicao = this.user.salario;
        this.beneficioRendaFinanceiraCd = result.beneficioRendaFinanceiraCd;
        
        var idadeAposentarString = this.idadeAposentar.toString();
        var salarioContribuicaoString = this.salarioContribuicao.toString();

        this.simular(idadeAposentarString, this.percentualRentabilidade, this.user.saldoPortabilidade, salarioContribuicaoString)
        this.isLoading = false;
    }

    simular(idadeAposentar: string, percentualRentabilidade: string, saldoPortabilidade: string, salarioContribuicao: string) {
        var valor = parseInt(idadeAposentar),
            rentabilidade = parseFloat(percentualRentabilidade) || 0,
            sPortabilidade = parseFloat(saldoPortabilidade) || 0,
            sContribuicao = parseFloat(salarioContribuicao) || 0,
            carencia = this.user.carencia;


        if (valor > (this.user.carencia + this.user.idade)) {
            carencia += valor = valor - (this.user.carencia + this.user.idade);
        } else if (valor < (this.user.carencia + this.user.idade)) {
            carencia -= valor = (this.user.carencia + this.user.idade) - valor;
        }

        this.rodarSimulador(carencia, this.user.salario, this.user.saldo, this.user.idade, this.user.carencia, rentabilidade, sPortabilidade, sContribuicao, this.user.urf, this.beneficioRendaFinanceiraCd)
    }

    rodarSimulador(
        carencia: number,
        salario: number,
        saldo: number,
        idade: number,
        carenciaLimite: number,
        rentabilidade: number = 0,
        saldoPortabilidade: number = 0,
        salarioContribuicao: number = 0,
        urf: number = 0,
        beneficioRendaFinanceiraCd: number = 0) {

        //pega a classe e já seta os dados do cliente (carencia, salario, saldo atual, idade)
        if (this.simulador == null)
            this.simulador = new SimuladorCd(carencia, salarioContribuicao !== 0 ? salarioContribuicao : salario, saldo, idade, carenciaLimite, beneficioRendaFinanceiraCd);
        else {
            this.simulador.setSalario(salarioContribuicao !== 0 ? salarioContribuicao : salario);
            this.simulador.setCarencia(carencia);
            this.simulador.setSaldo(saldo);
            this.simulador.setIdade(idade);
            this.simulador.setCarenciaLimite(carenciaLimite);
            this.simulador.setBeneficioRendaFinanceiraCd(beneficioRendaFinanceiraCd);
        }
        let simulador = this.simulador;

        //seta as váriaveis fibra
        //Teto FIBRA Atualizado
        //Pegar o valor URF para Teto Fibra
        ajustarFaixas(urf);
        simulador.setTetoFibra(urf);
        //Taxa Real de Juros anual
        if (rentabilidade !== 0)
            simulador.setTaxaRealAnual(rentabilidade);
        else
            simulador.setTaxaRealAnual(this.temPercentualRentabilidadeMinimoTemporario ? this.percentualRentabilidade : 3); // TODO alterar para 3.00 para simulador dentro da área restrita

        //portabilidade
        simulador.setSaldoPortabilidade(saldoPortabilidade + this.user.saldoPortabilidade);

        //Taxa Real de Juros mensal
        simulador.setTaxaRealMensal();
        //Crescimento Salarial Individual
        simulador.setTaxaSalarioCrescimentoMedio(CRESCI_SALARIAL);
        //Idade limite para recebimento da renda certa (em anos)
        // TODO: Alterar para ser o maior valor entre 85 ou idade de aposentadoria + 25
        let idadeLimiteCalculada = Math.max(85, (parseInt(this.idadeAposentar.toString()) + 25));
        simulador.setIdadeLimite(idadeLimiteCalculada);
        this.idadeLimite = idadeLimiteCalculada;

        //CN Participantes Ativos
        simulador.setFaixas(FAIXAS_SALARIAL);

        //URF
        //TODO: Lêr do serviço da Sinqia - {{baseUrl}}/api/v1/global/indicefinanceiro/porNome/URF
        simulador.setURF(urf);

        //calcula a simulacao
        simulador.calcular();

        if (simulador.erro) {
            this.handleError("Idade limite para cálculo: " + (idade + carenciaLimite));
            return;
        }
        // 219,28
        this.contribuicaoParticipante = simulador.getContribuicaoMensalParticipante();

        this.contribuicaoMensal = simulador.contrMensalParticipante;
        this.saldoAcumulado = simulador.montanteTotalAcumulado;
        this.dataAposentadoria = new Date().getFullYear() + carencia;
        var dataAponsetar = idade + carencia;
        this.rendaFinanceira = simulador.beneficioRendaFinanceira;
        this.valorPortabilidade = simulador.valorPortabilidade;

        this.tempoRendaFinanceira = simulador.tempoRendaFinanceira;

        var parcelado = simulador.valorPortabilidadeParc,
            valorPortabilidadeGrafico = this.valorPortabilidade;

        this.textoPortabilidade = 'Valor do benefício de portabilidade pago em uma única vez';
        this.textoSaldoPortabilidade = "";
        if (parcelado) {
            this.textoPortabilidade = 'Valor do benefício de portabilidade pago em 60 meses';
            //valor saldo portabilidade
            this.textoSaldoPortabilidade = 'Valor do seu saldo de portabilidade projetado em ' + this.dataAposentadoria;
        }


        this.data = {
            labels: [idade + ' anos', dataAponsetar + ' anos'],
            datasets: [
                {
                    label: 'Projetado',
                    data: [saldo, this.saldoAcumulado],
                    fill: true,
                    borderColor: '#28a745',
                    tension: 0.4,
                    backgroundColor: 'rgba(40,167,69,0.2)'
                },
                {
                    label: 'Total Projetado',
                    data: [this.saldoPortabilidade + saldo, ((simulador.valorPortabilidadeParc ? 60 : 1) * simulador.valorPortabilidade) + this.saldoAcumulado],
                    fill: true,
                    borderColor: '#ffa500',
                    tension: 0.4,
                    backgroundColor: 'rgba(255,165,0,0.2)'
                }]
        };

        this.dataPortabilidade = false;

        if (dataAponsetar > 0) {

            //beneficio programado
            let dataTotal = (dataAponsetar + 25),
                multiplo = dataTotal / 5,
                dataTotalLong = (dataTotal + 25),
                multiploL = dataTotalLong / 5,
                labels = [],
                valoresP = [],
                valoresL = [],
                valoresPortabilidade = [];
            let idadeLimiteAoFundoDeLongividade = (dataAponsetar <= 60 ? 85 : dataTotal);

            for (let x = dataAponsetar; x <= (idadeLimiteAoFundoDeLongividade); x++) {
                labels.push(x);
                valoresP.push(this.rendaFinanceira);
                valoresL.push(0);
            }
            //valores.reverse();
            //beneficio longevidade
            for (var x = idadeLimiteAoFundoDeLongividade + 1; x <= dataTotalLong; x++) {
                labels.push(x);
                valoresL.push(this.rendaFinanceira);
                valoresP.push(0);
            }

            //portabilidade
            if (parcelado) {
                for (var x = 0; x < 5; x++) {
                    valoresPortabilidade.push(this.valorPortabilidade);
                }

            }

            //labels.reverse();
            labels[labels.length - 1] = labels[labels.length - 1] + "+";
            this.dataBeneficio = {
                labels: labels,
                datasets: [
                    {
                        label: 'Benefício Programado (anos)',
                        data: valoresP,
                        fill: true,
                        borderColor: '#28a745',
                        tension: 0.4,
                        backgroundColor: 'rgba(40,167,69,0.2)'
                    },
                    {
                        label: 'Benefício Longevidade (anos)',
                        data: valoresL,
                        fill: true,
                        borderColor: '#ffa500',
                        tension: 0.4,
                        backgroundColor: 'rgba(255,165,0,0.2)'
                    },
                    {
                        label: 'Benefício Portabilidade (anos)',
                        data: valoresPortabilidade,
                        fill: true,
                        borderColor: '#c2c1c1',
                        tension: 0.4,
                        backgroundColor: 'rgba(194,193,193,0.8)'
                    }
                ]
            };

            //monta o gráfico donnuts
            /*
            
            P	50000,00
            TR	54636.35
            ------------------------
            R	4636.35
            
            20574.81
            
            TR	9760.485582342793
            FR	9760.485582342793
            -------------------------
            TC	19520.971164685586
            
            R	1053.838835314414
            
            
            Contribuicao do Participante
            9760.485582342793 = 12.97744%
            Contribuicao da Patrocinadora
            9760.485582342793 = 12.97744%
            Portabilidade
            50000.00 = 66.47%
            
            Rendimento Sobre Portabilidade
            4636.35
            Rendimento Sobre Contribuicoes
            1053.838835314414
            
            Total Rentabilidade
            5690.488835314414 = 7.566
            
            100%=75211.16
            
            
            100%=75211.16
            x=9760.485582342793
            x=12.97744%
            
            
            100%=75211.16
            x=5000000
            
            
            100%=75211.16
            x=5690.488835314414
            */
            var valorPortabilidade = simulador.portabilidade();
            valorPortabilidade = (Math.round((valorPortabilidade + Number.EPSILON) * 100) / 100)
            valorPortabilidade = simulador.valorPortabilidadeParc ? (valorPortabilidade * 60) : valorPortabilidade;

            var rentabilidadeGrafico = simulador.montanteTotalAcumulado - simulador.contrParticipanteTaxaZero - simulador.contrPatrocinadoraTaxaZero + (valorPortabilidade - this.saldoPortabilidade),
                contribuicaoParti = 0,
                contribuicaoPatro = 0,
                saldoPortabilidadeProjetado = 0;
            rentabilidadeGrafico = (Math.round((rentabilidadeGrafico + Number.EPSILON) * 100) / 100)

            var montanteTotalAcumulado = (Math.round((simulador.montanteTotalAcumulado + Number.EPSILON) * 100) / 100) + (Math.round((valorPortabilidade + Number.EPSILON) * 100) / 100)
            rentabilidadeGrafico = (rentabilidadeGrafico / montanteTotalAcumulado) * 100;
            rentabilidadeGrafico = Math.round((rentabilidadeGrafico + Number.EPSILON) * 100) / 100;
            contribuicaoParti = (simulador.contrParticipanteTaxaZero / montanteTotalAcumulado) * 100;
            contribuicaoParti = Math.round((contribuicaoParti + Number.EPSILON) * 100) / 100;
            contribuicaoPatro = (simulador.contrPatrocinadoraTaxaZero / montanteTotalAcumulado) * 100;
            contribuicaoPatro = Math.round((contribuicaoPatro + Number.EPSILON) * 100) / 100;
            saldoPortabilidadeProjetado = (this.saldoPortabilidade / montanteTotalAcumulado) * 100;

            if (parcelado) {
                saldoPortabilidadeProjetado = (this.saldoPortabilidade / montanteTotalAcumulado) * 100;
            }
            saldoPortabilidadeProjetado = Math.round((saldoPortabilidadeProjetado + Number.EPSILON) * 100) / 100;

            this.dataDonnuts = {
                labels: ['Rentabilidade (%)', 'Contribuição Participante (%)', 'Contribuição Patrocinadora (%)', 'Saldo de Portabilidade Projetado (%)'],
                datasets: [
                    {
                        data: [rentabilidadeGrafico, contribuicaoParti, contribuicaoPatro, saldoPortabilidadeProjetado],
                        backgroundColor: [
                            "#007f36",
                            "#003757",
                            "#f8c300",
                            '#c2c1c1'
                        ],
                        hoverBackgroundColor: [
                            "#007f36",
                            "#003757",
                            "#f8c300",
                            '#c2c1c1'
                        ]
                    }]
            };
        }
    }

    desbloquear() {
        this.bloquear = false;
        this.mensagemDeErro = "";
    }

    handleError(message: string) {
        this.bloquear = true;
        this.mensagemDeErro = message ? message : 'Desculpe ocorreu um erro! Contate o suporte.';
    }
}
