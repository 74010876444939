export * from './painel-cd.component';
export * from './inicio';
export * from './dados-cadastrais';
export * from './como-fazer';
export * from './identificacao-plano';
export * from './inscricoes';
export * from './beneficios-previdenciarios';
export * from './desligamento';
export * from './tela-previsao-aposentadoria';
export * from './texto-informacoes-complementares';
export * from './suspensao-contribuicao';
export * from './tela-beneficio';
export * from './demonstrativo-pagamento';
export * from './ficha-financeira';
export * from './informe-rendimento';
export * from './atuarias';
export * from './tela-fibra-numeros';
export * from './adiantamento-abono';
export * from './solicitacoes/adiantamento-abono';
export * from './prorrogacao-adiantamento-parcial';
export * from './solicitacoes/prorrogacao-adiantamento-parcial';
//export * from './historico-cotas/index.ts.depreciated';
export * from './rentabilidade';
export * from './autopatrocinio-financeiro';
export * from './beneficio-vitalicio';
export * from './perfil-investimento';
export * from './institutos';
export * from './solicitacoes/institutos';
export * from './participantes-beneficiarios';
//export * from './solicitacoes/prorrogacao-antecipacao-beneficio';
export * from './tela-contribuicao';