import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DadosParaSimulacao } from "../models/dados-para-simulacao";
import { Service } from "./service";
import { HttpClientService } from "./http-client.service";
import { ETipoPlano, ETokenApi } from "../enums";

@Injectable()
export class SimuladorService extends Service {
    private url: string;

    constructor(http: HttpClient, @Inject('environment') private environment, private httpClientService: HttpClientService ) {
        super(http);
        this.url = this.environment.BASE_URL_CONTRIBUICAO_DEFINIDA;
    }

    ObterDadosSimulador(): Observable<DadosParaSimulacao> {
        const endpoint = `${this.url}/api/v1/simulacao/obterdados`;
        return this.httpClientService.get<DadosParaSimulacao>(endpoint, ETokenApi.TokenApiCd);
    }


}
