import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { GestaoFinanceiraEmprestimoService, Contrato } from '@fibra/fibra-shared-lib';

@Component({
    selector: 'app-emprestimo-confirmado',
    styleUrls: ['./emprestimo-confirmado.component.css'],
    templateUrl: './emprestimo-confirmado.component.html'
})
export class EmprestimoConfirmadoComponent {
    @Input() exibirModal: boolean;
    @Output() exibirModalChange = new EventEmitter<boolean>();
    @Input() isPlus: boolean;
    @Input() contrato: Contrato;

    isLoading = false;

    constructor(private gestaoFinanceiraEmprestimoService: GestaoFinanceiraEmprestimoService) { }

    baixarContrato() {
        this.isLoading = true;
        // este metodo está obsoleto
        // this.gestaoFinanceiraEmprestimoService.
        // Emprestimo_GetDocumentosPorId(this.contrato.id, () => { this.isLoading = false; }, this);
        this.gestaoFinanceiraEmprestimoService.Emprestimo_GetContratoSESuite
        (this.contrato.matriculaParticipante, this.contrato.id, () => { this.isLoading = false; }, this);
    }
    fecharModal() {
        this.exibirModal = false;
        this.exibirModalChange.emit(this.exibirModal);
        window.location.reload();
    }
}
