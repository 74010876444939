import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Service } from "./service";
import { BehaviorSubject } from "rxjs";
import { ResumoAdesaoDto, SolicitacaoAdesaoCdModel } from "../models/sinqia-api";
import { DadosCadastraisService } from "./dados-cadastrais.service";
import { ESituacaoParticipante, ETipoPlano } from "../enums";
import { HeaderManagerService } from "./header-manager.service";
import { HEADERS } from "../constants/headers.const";
import { StorageService } from "./storage.service";
import { CONTEXTO_URL, STORAGE_KEYS, TipoSituacaoParticipante } from "../constants";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { SessionStorageService } from "./session-storage.service";

@Injectable()
export class AdesaoService extends Service {
    private url: string;
    fileMimeTypePdf: string;
    solicitacaoAdesao: BehaviorSubject<SolicitacaoAdesaoCdModel | null> = new BehaviorSubject<SolicitacaoAdesaoCdModel | null>(new SolicitacaoAdesaoCdModel());

    constructor(http: HttpClient, @Inject('environment') private environment,
        private dadosCadService: DadosCadastraisService,
        private headerManagerService: HeaderManagerService,
        private storageService: StorageService,
        private sessionStorage: SessionStorageService,
        private router: Router) {
        super(http);
        this.url = environment.API_GESTAO_PLANO_CADASTRO;
        this.fileMimeTypePdf = 'application/pdf';
    }

    getCertificadoInscricao(callback?: Function,
        erroCallBack?: Function, thisArg?: any) {
        const url = `${this.url}/Adesao/GetCertificadoInscricao`;
        let headers = new Array<{ name: string, value: string }>();
        this.baixarArquivo(url, this.fileMimeTypePdf, callback, erroCallBack, thisArg, headers);
    }

    getAdesao(plano: ETipoPlano) {
        let dadosCad = this.dadosCadService.getCadastroGeralLocal();
        let index = dadosCad?.adesoes.findIndex(x => x.idPlano == plano);
        if (index != null && index >= 0) {
            return dadosCad?.adesoes[index];
        }
        return null;
    }

    getAdesaoPorId(idAdesao: number) {
        let dadosCad = this.dadosCadService.getCadastroGeralLocal();
        let index = dadosCad?.adesoes.findIndex(x => x.idAdesao == idAdesao);
        if (index != null && index >= 0) {
            return dadosCad?.adesoes[index];
        }
        return null;
    }

    getInformacoesAdicionais() {
        let informacoesAdicionais = this.dadosCadService.getCadastroGeralLocal()?.informacoesAdicionais;
        return informacoesAdicionais;
    }

    existeAdesao(plano: ETipoPlano) {
        this.getAdesao(plano) != null;
    }

    addHeaderAdesao(plano: ETipoPlano, headers: Headers | null = null) {
        let adesao = this.getAdesao(plano);
        if (adesao?.idAdesao)
            this.headerManagerService.addHeader(HEADERS.ADESAO_ID, adesao?.idAdesao.toString());
    }

    /**
     * Define o contexto da adesão para utilização na aplicação.
     */
    definirAdesaoContexto(adesao: ResumoAdesaoDto) {
        this.sessionStorage.setAnyAsJsonSecure(STORAGE_KEYS.ADESAO_CONTEXTO, adesao);
    }

    /**
     * Define o contexto da adesão para utilização na aplicação.
     */
    definirAdesaoContextoPorId(idAdesao: number) {
        let adesao = this.getAdesaoPorId(idAdesao);
        this.sessionStorage.setAnyAsJsonSecure(STORAGE_KEYS.ADESAO_CONTEXTO, adesao);
    }

    /**
     * Define o contexto da adesão por plano para utilização na aplicação.
     */
    definirAdesaoContextoPorPlano(plano: ETipoPlano) {
        const adesao = this.getAdesao(plano);
        this.definirAdesaoContexto(adesao as ResumoAdesaoDto);
    }

    /**
     * Define o contexto da adesão por plano para utilização na aplicação.
     */
    resetarAdesaoContextoPorPlano() {
        this.sessionStorage.remove(STORAGE_KEYS.ADESAO_CONTEXTO);
    }

    /**
     * Retorna a adesão de contexto que foi selecionado pelo participante
     * @returns
     */
    getAdesaoContexto() {
        let adesaoContexto: ResumoAdesaoDto | null = null;
        if (this.sessionStorage.any(STORAGE_KEYS.ADESAO_CONTEXTO)) {
            adesaoContexto = this.sessionStorage.getSecureParse<ResumoAdesaoDto>(STORAGE_KEYS.ADESAO_CONTEXTO);
        }
        return adesaoContexto;
    }

    /**
     * Verifica se a adesão do contexto está condizente com o contexto da URL.
     * @returns true caso o contexto esteja correto / false caso o contexto esteja incorreto.
     */
    verificarAdesaoContextoUrl(url: string) {
        const adesao = this.getAdesaoContexto();
        const plano = this.getPlanoPorContextoUrl(url);
        if (plano && adesao) {
            return (adesao.idPlano as ETipoPlano) == plano;
        }
        return false;
    }

    /**
     * Obtém o plano com base no contexto da URL.
     * @returns
     */
    getPlanoPorContextoUrl(url: string) {
        if (url.includes(CONTEXTO_URL.PLANO_BD)) {
            return ETipoPlano.PlanoBD;
        } else if (url.includes(CONTEXTO_URL.PLANO_CD)) {
            return ETipoPlano.PlanoCD;
        }
        return null;
    }

    /**
     * Conta as adesões por plano
     * @param plano Plano de beneficio
     * @returns
     */
    contarAdesoesPorPlano(plano: ETipoPlano) {
        const dadosCad = this.dadosCadService.getCadastroGeralLocal();
        return _.size(_.filter(dadosCad?.adesoes, { idAdesao: plano }));
    }

    /**
     * Lista as adesões por plano
     * @param plano Plano de beneficio
     * @returns
     */
    listarAdesoesPorPlano(plano: ETipoPlano) {
        const dadosCad = this.dadosCadService.getCadastroGeralLocal();
        return dadosCad?.adesoes.filter(x => x.idPlano == plano);
    }

    validarSituacao(situacaoParticipante: ESituacaoParticipante, situacao: string | null | undefined) {
        ;
        switch (situacaoParticipante) {
            case ESituacaoParticipante.Ativo:
                return [
                    TipoSituacaoParticipante.ATIVO,
                    TipoSituacaoParticipante.AUTOPATR_PARCIAL,
                    TipoSituacaoParticipante.AUTOPATR_TOTAL
                ].includes(situacao as string);
            case ESituacaoParticipante.Assistido:
                return [
                    TipoSituacaoParticipante.APOSENTADO,
                    TipoSituacaoParticipante.PENSIONISTA
                ].includes(situacao as string);
            case ESituacaoParticipante.EmpregadoFibra:
                return [
                    TipoSituacaoParticipante.FIBRA
                ].includes(situacao as string);
            case ESituacaoParticipante.Pensionista:
                return [
                    TipoSituacaoParticipante.PENSIONISTA
                ].includes(situacao as string);
            default:
                return false;
        }
    }

    validarSituacaoPensionistaOuAssistido(situacaoParticipante: ESituacaoParticipante | null, situacao: string | null | undefined) {
        ;
        switch (situacaoParticipante) {
            case ESituacaoParticipante.Ativo:
                return [
                    TipoSituacaoParticipante.ATIVO,
                    TipoSituacaoParticipante.AUTOPATR_PARCIAL,
                    TipoSituacaoParticipante.AUTOPATR_TOTAL
                ].includes(situacao as string);
            case ESituacaoParticipante.Assistido:
                return [
                    TipoSituacaoParticipante.APOSENTADO
                ].includes(situacao as string);
            case ESituacaoParticipante.EmpregadoFibra:
                return [
                    TipoSituacaoParticipante.FIBRA
                ].includes(situacao as string);
            case ESituacaoParticipante.Pensionista:
                return [
                    TipoSituacaoParticipante.PENSIONISTA
                ].includes(situacao as string);
            default:
                return false;
        }
    }
}
