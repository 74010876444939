import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, of } from "rxjs";
import { CargoPessoaPoliticamenteExpostaDto, PessoaPoliticamenteExpostaDto, SolicitacaoAdesaoCdModel } from "../../models/sinqia-api";
import { BaseService } from "./base.service";
import { HttpClientService } from "../http-client.service";
import { ETokenApi } from "../../enums";


@Injectable()
export class PessoaPoliticamenteExpostaService extends BaseService {
    fileMimeTypePdf: string;
    solicitacaoAdesao: BehaviorSubject<SolicitacaoAdesaoCdModel | null> = new BehaviorSubject<SolicitacaoAdesaoCdModel | null>(new SolicitacaoAdesaoCdModel());

    constructor(protected httpClient: HttpClient, @Inject('environment') protected environment,
        private httpClientService: HttpClientService) {
        super(httpClient, environment);
    }

    getCargosPssoasPoliticamenteExposta() {
        const endpoint = `${this.endpoint}/pessoas-politicamente-expostas`;
        return this.httpClientService.get<Array<CargoPessoaPoliticamenteExpostaDto>>(endpoint, ETokenApi.TokenApiCd);
    }

    atualizarPoliticamenteExposta(ppe: PessoaPoliticamenteExpostaDto) {
        const endpoint = `${this.endpoint}/adesao/atualizar-politicamente-exposto`;
        return this.httpClientService.put<boolean>(endpoint, ppe, ETokenApi.TokenApiCd);
    }
}