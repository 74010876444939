import { Component, OnInit } from '@angular/core';
import { ArquivosService } from '@fibra/fibra-shared-lib';
import { DadosAtaColegiado } from '@fibra/fibra-shared-lib';
import { NomeArquivos } from '@fibra/fibra-shared-lib';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-extrato-atas',
    templateUrl: './extrato-atas.component.html',
    styleUrls: ['./extrato-atas.component.css']
})

export class ExtratoAtasComponent implements OnInit {

    CONSELHO_DELIBERATIVO: string = NomeArquivos.CONSELHO_DELIBERATIVO;
    CONSELHO_FISCAL: string = NomeArquivos.CONSELHO_FISCAL;
    COMITE_INVESTIMENTOS: string = NomeArquivos.COMITE_INVESTIMENTOS;
    DIRETORIA_EXECUTIVA: string = NomeArquivos.DIRETORIA_EXECUTIVA;

    PREF_CONSELHO_DELIBERATIVO: string = NomeArquivos.PREF_CONSELHO_DELIBERATIVO;
    PREF_CONSELHO_FISCAL: string = NomeArquivos.PREF_CONSELHO_FISCAL;
    PREF_COMITE_INVESTIMENTOS: string = NomeArquivos.PREF_COMITE_INVESTIMENTOS;
    PREF_DIRETORIA_EXECUTIVA: string = NomeArquivos.PREF_DIRETORIA_EXECUTIVA;

    SOLICITACAO_INSCRICAO_BENEF: string = NomeArquivos.SOLICITACAO_INSCRICAO_BENEF;

    isLoading = false;

    dadosAtaCD: DadosAtaColegiado;
    dadosAtaCF: DadosAtaColegiado;
    dadosAtaCI: DadosAtaColegiado;
    dadosAtaDE: DadosAtaColegiado;

    constructor(private arqService: ArquivosService) { }

    ngOnInit() {

        this.isLoading = true;

        forkJoin(
            this.arqService.getDadosAtaColegiado(this.PREF_CONSELHO_DELIBERATIVO),
            this.arqService.getDadosAtaColegiado(this.PREF_CONSELHO_FISCAL),
            this.arqService.getDadosAtaColegiado(this.PREF_COMITE_INVESTIMENTOS),
            this.arqService.getDadosAtaColegiado(this.PREF_DIRETORIA_EXECUTIVA)
        ).subscribe(([
            dadosAtaCD,
            dadosAtaCF,
            dadosAtaCI,
            dadosAtaDE
        ]) => {
            this.dadosAtaCD = dadosAtaCD;
            this.dadosAtaCF = dadosAtaCF;
            this.dadosAtaCI = dadosAtaCI;
            this.dadosAtaDE = dadosAtaDE;

            this.isLoading = false;
        });
    }

    DownloadArquivo(nome: string) {
        this.isLoading = true;
        this.arqService.getAtaColegiado(nome, () => { this.isLoading = false; }, this);
    }
}
