export const TipoSituacaoParticipante = {
  AUTOPATR_TOTAL: 'Autopatrocinado_Total',
  AUTOPATR_PARCIAL: 'Autopatrocinado_Parcial',
  ATIVO: 'Ativo',                 
  SUSPENSO: 'Suspenso',
  DESLIGADO: 'Desligado',
  APOSENTADO: 'Aposentado',
  PENSIONISTA: 'Pensionista',
  FIBRA: 'Fibra'
};

