import { Component, OnInit, ViewChild } from '@angular/core';
import { FormularioService, AlertModalComponent, MESSAGE_SEVERITY, validFullName, scrollIntoViewToTop } from '@fibra/fibra-shared-lib';
import { AuthService } from '@fibra/fibra-shared-lib';
import { validCPF } from '@fibra/fibra-shared-lib';
import { DependenteIr } from '@fibra/fibra-shared-lib';
import { DeclaracaoDependentesIr } from '@fibra/fibra-shared-lib';
import { RelacaoDependencia } from '@fibra/fibra-shared-lib';
import { LOCALE_PT_BR } from '@fibra/fibra-shared-lib';
import { TwoFactorAuthTokenModel } from '@fibra/fibra-shared-lib/lib/models/fibra-identity';
import { TwoFactorAuthComponent } from '../../../../components/two-factor-auth/two-factor-auth.component';

@Component({
    selector: 'app-declaracao-dependente-ir',
    templateUrl: './declaracao-dependente-ir.component.html',
    styleUrls: ['./declaracao-dependente-ir.component.css']
})
export class DeclaracaoDependenteIrComponent implements OnInit {
    relacaoDependencia: Array<RelacaoDependencia> = [];
    isLoading: boolean;
    ptBR = LOCALE_PT_BR;
    urlArquivoSeSuite: string;
    @ViewChild('alertModalComponent') alertModalComponent: AlertModalComponent;
    @ViewChild('twoFactorAuth') twoFactorAuth: TwoFactorAuthComponent;
    msgs: any[];
    declaracao: DeclaracaoDependentesIr = { dependentes: [] };
    dependenteIr: DependenteIr;
    formValido: boolean = false;
    val4: string;
    yearRange: string;
    dateMax: Date;
    isSimulado = sessionStorage.getItem('isSimulado');

    constructor(private formularioService: FormularioService, private authService: AuthService) {
        this.yearRange = '1900:' + (new Date()).getFullYear();
    }

    ngOnInit() {
        this.declaracao = { dependentes: [] };
        this.dateMax = new Date();
        this.inicializarRelecaoDependencia();
        this.inicializarDependenteIr();
        this.limparMensagem();
    }

    ngOnDestroy(): void {
    }

    inicializarRelecaoDependencia() {
        this.relacaoDependencia.push(
            { codigo: 11, descricao: 'Cônjuge ou Companheiro(a) com o(a) qual o contribuinte tenha filho ou viva há mais de 5 (cinco) anos.' },
            { codigo: 21, descricao: 'Filho(a) ou enteado(a) até 21 (vinte e um) anos, ou em qualquer idade, quando incapacitado física e/ou mentalmente para o trabalho.' },
            { codigo: 22, descricao: 'Filho(a) ou enteado(a) maior, até 24 anos, se ainda estiver cursando ensino superior ou escola técnica de segundo grau.' },
            { codigo: 24, descricao: 'Irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, de quem o contribuinte detenha a guarda judicial, até 21 (vinte e um) anos, ou em qualquer idade, quando incapacitado física ou mentalmente para o trabalho. ' },
            { codigo: 25, descricao: 'Irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, com idade de 21 (vinte e um) até 24 (vinte e quatro) anos,  se ainda estiver cursando ensino superior ou escola técnica de segundo grau, desde que o contribuinte tenha detido sua guarda judicial até os 21 (vinte e um) anos.' },
            { codigo: 31, descricao: 'Pais, avós ou bisavós, que recebem rendimentos, tributáveis ou não, até a parcela isenta para maiores de 65 (sessenta e cinco) anos.' },
            { codigo: 41, descricao: 'Menor pobre, até 21 (vinte e um) anos, que o contibuinte crie e eduque e do qual detenha a guarda judicial. ' },
            { codigo: 51, descricao: 'Pessoa absolutamente incapaz, da qual o contribuinte seja tutor ou curador.' }
        );
    }

    getDescricaoRD(codigo) {
        return this.relacaoDependencia.find(d => d.codigo == codigo).descricao.substring(0, 14) + '...';
    }

    inicializarDependenteIr() {
        this.dependenteIr = {
            id: 0,
            nome: '',
            cpf: '',
            codigoRelacaoDependencia: 0,
            dataNascimento: null
        };
    }

    adicionarDependente() {
        if (!this.validarForm()) {
            this.dependenteIr.id = this.declaracao.dependentes.length + 1;
            this.declaracao.dependentes.push(this.dependenteIr)
            this.inicializarDependenteIr();
            this.limparMensagem();
        }
    }

    removerDependente(i: number) {
        this.declaracao.dependentes.splice(i, 1);
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({ severity: severidade, summary: titulo, detail: mensagem });
    }

    limparMensagem() {
        this.msgs = [];
    }

    validarForm(): boolean {
        this.limparMensagem();

        this.formValido = this.dependenteIr.nome === '' ||
            //this.dependenteIr.cpf === '' ||
            this.dependenteIr.codigoRelacaoDependencia === 0;

        if (!this.formValido) {
            this.addMensagem(MESSAGE_SEVERITY.ERROR, 'Atenção: ', "Informe todos os dados do(s) dependente(s).");

            scrollIntoViewToTop();
            return this.formValido;
        }

        if ((this.dependenteIr.nome &&
            this.dependenteIr.nome.trim().length < 3) ||
            !validFullName(this.dependenteIr.nome)) {
            this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                `O campo 'Nome Completo' é permitido apenas letras.`);
            this.formValido = true;
        }

         /*if (!validCPF(this.dependenteIr.cpf)) {
             this.addMensagem(MESSAGE_SEVERITY.WARN, 'Atenção: ',
                 `Por favor entre com 'CPF' válido.`);
             this.formValido = true;
         }*/

        if (!this.formValido)
            scrollIntoViewToTop();

        return this.formValido;
    }


    enviar() {
        this.isLoading = true;

        this.authService.getRegFibra()
            .subscribe(regFibra => {

                this.formularioService.gerarFormDeclaracaoDependentesIR(this.declaracao)
                    .subscribe(urlArquivoSeSuite => {
                        this.isLoading = false;
                        this.urlArquivoSeSuite = urlArquivoSeSuite;
                        this.alertModalComponent.show('Solicitação enviada com sucesso!', true);
                        if (this.isSimulado === 'true') {
                            window.open(urlArquivoSeSuite, '_blank');
                        }
                        this.ngOnInit();
                    }, () => {
                        this.isLoading = false;
                        this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
                    });
            }, () => {
                this.isLoading = false;
                this.alertModalComponent.show('Desculpe ocorreu um erro! Contate o suporte.', false);
            });
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        if (!this.validarForm())
            return;
        this.twoFactorAuth.show();
    }

    /**
    * Lida com o sucesso da autenticação de dois fatores.
    * @param twoFactorAuthToken 2fa token.
    */
    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success)
            this.enviar();
    }
}
