export class PerguntasPpe {
    numero: number;
    texto: string = '';
    resposta: boolean | null = null;

    static obterPerguntas?(): Array<PerguntasPpe> {
        let perguntas: Array<PerguntasPpe> = [
            {
                numero: 1,
                texto: `Você, seus representantes ou seus familiares nos últimos 5 anos. São considerados familiares: Parentes na linha direta até o primeiro grau (pai, mãe e filhos), cônjuge, companheiro(a) e enteado(a).
                     Desempnham ou desempenharam, no Brasil ou em páis, território ou dependência estrangeira, cargo, emprego ou função píblica?`,
                resposta: null,
            },
            {
                numero: 2,
                texto: 'Exercem ou exerceram mandatos eletivos dos Poderes Executivo e Legislativo da União?',
                resposta: null,
            },
            {
                numero: 3,
                texto: 'Ocupam ou ocuparam cargo no Poder Executivo da União, tais como: de Ministro de Estado ou equiparado, de natureza especial ou equivalente, de presidente, vice-presidente e diretor de ou equivalentes, de autarquias, fundações públicas, empresas públicas ou sociedades de economia mista, do Grupo Direção e Acessoramento Superiores - DAS, nível 6 e equivalentes?',
                resposta: null,
            },
            {
                numero: 4,
                texto: 'São ou foram membros do Conselho Nacional de Justiça, do Supremo Tribunal Federal e dos Tribunais Superiores nos últimos 5 anos?',
                resposta: null,
            },
            {
                numero: 5,
                texto: 'São ou foram membros do Conselho Nacional do Mnisitério Público, Procurador-Geral da República, Vice-Procurador-Geral da República, Procurador-Geral do Trabalho, Procurador-Geral da Justiça Militar, Subprocuradores-Gerais da República e Procuradores-Gerais de Justiça dos Estados e do Distrito Federal?',
                resposta: null,
            },
            {
                numero: 6,
                texto: 'São ou foram membros do Tribunal de Contas da União e Procurador-Geral do Ministério Público junto ao Tribunal de Contas da União?',
                resposta: null,
            },
            {
                numero: 7,
                texto: 'São ou foram governadores de Estado e do Distrito Federal, presidentes de tribunal de justiça, de assembléia legislativa de Câmara Distrital, presidente de tribunal e de conselho de contas de estado, de municípios e do Distrito Federal?',
                resposta: null,
            },
            {
                numero: 8,
                texto: 'São ou foram prefeitos ou presidentes de Câmara Municipal das capitais de Estado nos últimos 5 anos?',
                resposta: null,
            }
        ];

        return perguntas;
    }

    static validarPerguntas(perguntasPpe: Array<PerguntasPpe>) {
        return perguntasPpe.length == 8 && perguntasPpe.find(x => x.resposta == null) == null;
    }
}