import { EstadoCivilSinqiaDto } from "../models/sinqia-api/estado-civil-sinqia.dto";

export const ESTADO_CIVIL: Array<EstadoCivilSinqiaDto> = [
    {
        id: 0,
        nome: "-Selecione-"
    },
    {
        id: 1,
        nome: "Solteiro(a)"
    },
    {
        id: 2,
        nome: "Viuvo(a)"
    },
    {
        id: 3,
        nome: "Desquitado(a)"
    },
    {
        id: 4,
        nome: "Casado(a)"
    },
    {
        id: 5,
        nome: "Uniao Estavel"
    },
    {
        id: 6,
        nome: "Separado(a)judicial."
    },
    {
        id: 7,
        nome: "Divorciado(a)"
    },
    {
        id: 8,
        nome: "Separado Concens."
    }
]