import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ETipoPlano } from "../enums";
import { ETokenApi } from "../enums/token-api.enum";
import { StorageService } from "./storage.service";
import { DadosCadastraisSinqiaDto } from "../models/sinqia-api/dados-cadastrais-sinqia.dto";
import { HEADERS, STORAGE_KEYS } from "../constants";
import { SessionStorageService } from "./session-storage.service";
import { Router } from "@angular/router";
import { ResumoAdesaoDto } from "../models/sinqia-api";

/**
 * Http Header para auxílio no gerenciamento dos headers.
 */
@Injectable()
export class HttpHeaderService {
    constructor(protected router: Router,
        protected storageService: StorageService,
        protected sessionStorageService: SessionStorageService) {
    }

    protected addHeaderTokenApi(api: ETokenApi, httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();

        let token: string = '';
        switch (api) {
            case ETokenApi.TokenApiBd:
                token = this.sessionStorageService.get(STORAGE_KEYS.TOKEN_API_BD) as string;
                httpHeaders = httpHeaders.append(HEADERS.AUTHORIZATION, token);
                break
            case ETokenApi.TokenApiCd:
                // Token API CD
                token = this.storageService.getSecure(STORAGE_KEYS.HANDSHAKE_TOKEN) as string;
                httpHeaders = httpHeaders.append(HEADERS.AUTHORIZATION, `Bearer ${token}`);

                // Token API BD
                const tokenBd = this.sessionStorageService.get(STORAGE_KEYS.TOKEN_API_BD) as string;
                httpHeaders = httpHeaders.append(HEADERS.AUTHORIZATION_SALDADO, tokenBd);
                break;
            default:
                break;
        }

        return httpHeaders;
    }

    protected addHeaderIdAdesao(idAdesao: number, httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();

        let adesao = this.getAdesaoPorId(idAdesao);
        if (adesao?.idAdesao) {
            if (!httpHeaders.has(HEADERS.ADESAO_ID))
                httpHeaders = httpHeaders.append(HEADERS.ADESAO_ID, adesao.idAdesao.toString());
        }

        return httpHeaders;
    }

    protected addHeaderSiglaPlano(idAdesao: number, httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();
        let siglaPlano: string | null = null;
        let adesao = this.getAdesaoPorId(idAdesao);

        switch (adesao?.idPlano) {
            case ETipoPlano.PlanoBD:
                siglaPlano = 'BD';
                break;
            case ETipoPlano.PlanoCD:
                siglaPlano = 'CD';
                break;
            default:
                siglaPlano = '';
        }

        if (siglaPlano)
            if (!httpHeaders.has(HEADERS.SIGLA_PLANO))
                httpHeaders = httpHeaders.append(HEADERS.SIGLA_PLANO, siglaPlano);

        return httpHeaders;
    }

    protected addHeaderRegistroFibra(idAdesao: number, httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();

        let adesao = this.getAdesaoPorId(idAdesao);
        if (adesao?.dadosFibra?.registroFibra) {
            let registroFibra = adesao?.dadosFibra?.registroFibra.toString();
            if (!httpHeaders.has(HEADERS.REGISTRO_FIBRA))
                httpHeaders = httpHeaders.append(HEADERS.REGISTRO_FIBRA, registroFibra);
        }

        return httpHeaders;
    }

    protected addHeaderSituacao(idAdesao: number, httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();

        let adesao = this.getAdesaoPorId(idAdesao);
        if (adesao?.tipoSituacao) {
            let situacao = adesao?.tipoSituacao;
            if (!httpHeaders.has(HEADERS.SITUACAO))
                httpHeaders = httpHeaders.append(HEADERS.SITUACAO, situacao);
        }

        return httpHeaders;
    }

    private getAdesao(plano: ETipoPlano) {
        let dadosCad = this.getCadastroGeralLocal();
        let index = dadosCad?.adesoes.findIndex(x => x.idPlano == plano);
        if (index != null && index >= 0) {
            return dadosCad?.adesoes.find(x => x.idPlano == plano);
        }
        return null;
    }

    private getAdesaoPorId(idAdesao: number) {
        let dadosCad = this.getCadastroGeralLocal();
        let index = dadosCad?.adesoes.findIndex(x => x.idAdesao == idAdesao);
        if (index != null && index >= 0) {
            return dadosCad?.adesoes.find(x => x.idAdesao == idAdesao);
        }
        return null;
    }

    private getCadastroGeralLocal() {
        let dadosCad = this.storageService.getSecureParse<DadosCadastraisSinqiaDto>(STORAGE_KEYS.DADOS_CADASTRAIS_SINQIA);
        return dadosCad;
    }

    /**
     * Retorna a adesão de contexto que foi selecionado pelo participante
     * @returns 
     */
    private getAdesaoContexto() {
        let adesaoContexto: ResumoAdesaoDto | null = null;
        if (this.sessionStorageService.any(STORAGE_KEYS.ADESAO_CONTEXTO)) {
            adesaoContexto = this.sessionStorageService.getSecureParse<ResumoAdesaoDto>(STORAGE_KEYS.ADESAO_CONTEXTO);
        }
        return adesaoContexto;
    }

    /**
     * Adiciona os cabeçalhos da adesão (id-adesao e sigla-plano)
     * @param httpHeaders 
     * @returns 
     */
    public addHeadersAdesao(httpHeaders: HttpHeaders | null = null) {
        if (!httpHeaders)
            httpHeaders = new HttpHeaders();

        let adesao = this.getAdesaoContexto();
        if (adesao) {
            httpHeaders = this.addHeaderIdAdesao(adesao.idAdesao, httpHeaders);
            httpHeaders = this.addHeaderSiglaPlano(adesao.idAdesao, httpHeaders);
            httpHeaders = this.addHeaderRegistroFibra(adesao.idAdesao, httpHeaders);
            httpHeaders = this.addHeaderSituacao(adesao.idAdesao, httpHeaders);
        }
        return httpHeaders;
    }
}