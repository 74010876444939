import { Component, OnInit, ViewChild } from "@angular/core";
import {
    DeclaracaoTempoServicoModel,
    MESSAGE_SEVERITY,
    scrollIntoViewToTop,
    TempoVinculacaoModel,
} from "@fibra/fibra-shared-lib";
import {
    FormularioService,
    AlertModalComponent,
} from "@fibra/fibra-shared-lib";
import { AuthService } from "@fibra/fibra-shared-lib";
import { LOCALE_PT_BR } from "@fibra/fibra-shared-lib";
import { EDocumentoApresentado } from "@fibra/fibra-shared-lib";
import { DocumentoApresentadoText } from "@fibra/fibra-shared-lib";
import { TwoFactorAuthTokenModel } from "@fibra/fibra-shared-lib/lib/models/fibra-identity";
import { FileUpload } from "primeng/fileupload";
import { TwoFactorAuthComponent } from "../../../../components/two-factor-auth/two-factor-auth.component";

@Component({
    selector: "app-declaracao-tempo-servico",
    templateUrl: "./declaracao-tempo-servico.component.html",
    styleUrls: ["./declaracao-tempo-servico.component.css"],
})
export class DeclaracaoTempoServicoComponent implements OnInit {
    declaracaoTempoServico: DeclaracaoTempoServicoModel = {
        aposentadoPrevidenciaSocial: false,
        dataAposentadoriaPrevidenciaSocial: new Date(),
        dataVinculoOutraEntidade: new Date(),
        funcaoVinculoOutraEntidade: "",
        observacoesInfoAdicionais: "",
        observacoesTempoVinculacao: "",
        possuiVinculoOutraEntidade: false,
        tempoVinculacao: [],
        vinculoOutraEntidade: "",
    };
    temposVinculacao: Array<TempoVinculacaoModel> = [];
    tempoServico: TempoVinculacaoModel;
    aposentadoPrevidenciaSocial: string;
    formValido: boolean;
    isLoading: boolean;
    ptBR = LOCALE_PT_BR;
    @ViewChild("alertModalComponent", { static: false })
    alertModalComponent: AlertModalComponent;
    @ViewChild("fileUpload", { static: false }) fileUpload: FileUpload;
    @ViewChild("twoFactorAuth", { static: false })
    twoFactorAuth: TwoFactorAuthComponent;
    urlArquivoSeSuite: string;
    msgs: any;
    yearRange: string;
    uploadedFiles: any[] = [];
    dateMax: Date;
    documentoApresentado: Array<{ label: string; value: string | number }> = [
        { label: "Selecione", value: "0" },
        {
            label: "Carteira de trabalho",
            value: EDocumentoApresentado.CarteiraTrabalho,
        },
        {
            label: "Guias de recolhimento previdenciário",
            value: EDocumentoApresentado.CertidaoTempoServico,
        },
        {
            label: "Comprovantes de vínculo profissional",
            value: EDocumentoApresentado.ComprovantesVinculoProfissional,
        },
        {
            label: "Guias de recolhimento como autônomo",
            value: EDocumentoApresentado.GuiasRecolhimentoAutonomo,
        },
        {
            label: "Certificado de reservista",
            value: EDocumentoApresentado.CertificadoReservista,
        },
        {
            label: "Certidão de tempo de serviço",
            value: EDocumentoApresentado.CertidaoTempoServico,
        },
        { label: "Outros", value: EDocumentoApresentado.Outros },
    ];

    tiposServico: Array<{ label: string; value: string }> = [
        { label: "Selecione", value: "0" },
        { label: "1-Tempo de Serviço Militar", value: "1" },
        { label: "2-Tempo de Serviço Público Federal", value: "2" },
        { label: "3-Regime Geral da Previdência Social", value: "3" },
        { label: "4-TSC (Tempo de Serviço a Comprovar)", value: "4" },
    ];

    isSimulado = sessionStorage.getItem("isSimulado");

    constructor(
        private formularioService: FormularioService,
        private authService: AuthService
    ) {
        this.yearRange = "1900:" + new Date().getFullYear();
        this.dateMax = new Date();
    }

    ngOnInit() {
        this.temposVinculacao = Array<TempoVinculacaoModel>();
        this.inicializarTempoVinculacao();
    }

    ngOnDestroy(): void {}

    inicializarTempoVinculacao() {
        this.tempoServico = {
            id: this.temposVinculacao.length + 1,
            razaoSocial: "",
            documentoApresentado: "0",
            tipoServico: "0",
            comumEspecial: "0",
            dataInicio: new Date(),
            dataFim: new Date(),
        };
    }

    adicionarTempoVinculacao() {
        if (this.validarVinculacao()) {
            this.tempoServico.id = this.temposVinculacao.length + 1;
            this.temposVinculacao.push(this.tempoServico);
            this.inicializarTempoVinculacao();
        }
    }

    removerTempoVinculacao(i: number) {
        this.temposVinculacao.splice(i, 1);
    }

    mudarFlagAposentadoPrevidenciaSocial(flag: string) {
        this.declaracaoTempoServico.aposentadoPrevidenciaSocial =
            flag == "true";
        if (!this.declaracaoTempoServico.aposentadoPrevidenciaSocial) {
            this.declaracaoTempoServico.dataAposentadoriaPrevidenciaSocial =
                new Date();
            this.declaracaoTempoServico.observacoesInfoAdicionais = "";
        }
    }

    mudarFlagVinculoOutraEntidade(flag: string) {
        this.declaracaoTempoServico.possuiVinculoOutraEntidade = flag == "true";
        if (!this.declaracaoTempoServico.possuiVinculoOutraEntidade) {
            this.declaracaoTempoServico.dataVinculoOutraEntidade = new Date();
            this.declaracaoTempoServico.vinculoOutraEntidade = "";
            this.declaracaoTempoServico.funcaoVinculoOutraEntidade = "";
            this.declaracaoTempoServico.dataVinculoOutraEntidade = new Date();
        }
    }

    addMensagem(severidade: string, titulo: string, mensagem: string) {
        this.msgs.push({
            severity: severidade,
            summary: titulo,
            detail: mensagem,
        });
    }

    enviar() {
        this.declaracaoTempoServico.tempoVinculacao = this.temposVinculacao;
        this.isLoading = true;

        if (!this.validarForm()) {
            this.isLoading = false;
            return;
        }

        this.limparDatas();

        let formData = new FormData();
        formData.append(
            "declaracaoTempoServicoJson",
            JSON.stringify(this.declaracaoTempoServico)
        );

        if (this.fileUpload.hasFiles()) {
            this.fileUpload.files.forEach((file, index) => {
                formData.append("anexo_" + index, file);
            });
        }

        let regFibra =

        this.formularioService
            .gerarFormDeclaracaoTempoServico(formData)
            .subscribe(
                (urlArquivoSeSuite) => {
                    this.isLoading = false;
                    this.urlArquivoSeSuite = urlArquivoSeSuite;
                    this.alertModalComponent.show(
                        "Solicitação enviada com sucesso!",
                        true
                    );
                    if (this.isSimulado === "true") {
                        window.open(urlArquivoSeSuite, "_blank");
                    }
                    this.reset();
                },
                () => {
                    this.isLoading = false;
                    this.alertModalComponent.show(
                        "Desculpe ocorreu um erro! Contate o suporte.",
                        false
                    );
                    this.reset();
                }
            );
    }

    reset() {
        this.ngOnInit();
        this.declaracaoTempoServico = new DeclaracaoTempoServicoModel();
        this.fileUpload.clear();
        this.uploadedFiles = [];
    }

    onUpload(event: any) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }

        this.addMensagem("info", "File Uploaded", "OK");
    }

    limparMensagem() {
        this.msgs = [];
    }

    limparDatas() {
        if (!this.declaracaoTempoServico.aposentadoPrevidenciaSocial) {
            this.declaracaoTempoServico.dataAposentadoriaPrevidenciaSocial =
                null;
        }

        if (!this.declaracaoTempoServico.possuiVinculoOutraEntidade) {
            this.declaracaoTempoServico.dataVinculoOutraEntidade = null;
        }
    }

    validarVinculacao() {
        this.limparMensagem();
        this.formValido = true;

        if (
            this.tempoServico.razaoSocial === "" ||
            this.tempoServico.documentoApresentado === "0" ||
            this.tempoServico.tipoServico === "0" ||
            this.tempoServico.comumEspecial === "0"
        ) {
            this.addMensagem(
                MESSAGE_SEVERITY.ERROR,
                "Atenção: ",
                "Informe todos os dados do tempo de serviço."
            );
            this.formValido = false;
        }

        if (
            this.tempoServico.razaoSocial &&
            this.tempoServico.razaoSocial.trim().length < 2
        ) {
            this.addMensagem(
                MESSAGE_SEVERITY.WARN,
                "Atenção:",
                `Informe o 'Nome da empresa' válido.`
            );
            this.formValido = false;
        }

        if (this.tempoServico.dataInicio > this.tempoServico.dataFim) {
            this.addMensagem(
                MESSAGE_SEVERITY.WARN,
                "Atenção: ",
                `Por favor 'Data Início' deve ser menor que 'Data Fim'.`
            );
            this.formValido = false;
        }

        return this.formValido;
    }

    validarForm(): boolean {
        this.limparMensagem();

        if (this.temposVinculacao.length == 0) {
            this.addMensagem(
                MESSAGE_SEVERITY.ERROR,
                "Atenção: ",
                "Informe o 'Tempo de Serviço'."
            );
            this.formValido = false;
        }

        if (!this.fileUpload.hasFiles()) {
            this.addMensagem(
                MESSAGE_SEVERITY.ERROR,
                "Atenção: ",
                "Adicione o(s) anexo(s)."
            );
            this.formValido = false;
        }

        if (
            this.declaracaoTempoServico.possuiVinculoOutraEntidade &&
            this.declaracaoTempoServico.vinculoOutraEntidade == ""
        ) {
            this.addMensagem(
                MESSAGE_SEVERITY.WARN,
                "Atenção: ",
                "Informe 'Qual vínculo?' campo obrigatório."
            );
            this.formValido = false;
        }

        if (
            this.declaracaoTempoServico.possuiVinculoOutraEntidade &&
            this.declaracaoTempoServico.funcaoVinculoOutraEntidade == ""
        ) {
            this.addMensagem(
                MESSAGE_SEVERITY.WARN,
                "Atenção: ",
                "Informe 'Qual Função ?' campo obrigatório."
            );
            this.formValido = false;
        }

        if (!this.formValido) scrollIntoViewToTop();

        return this.formValido;
    }

    getDocApresentadoDesc(docApresentado: string) {
        return DocumentoApresentadoText.get(parseInt(docApresentado));
    }

    getTipoServicoDesc(tipoServicoValue: string) {
        return this.tiposServico.find((x) => x.value == tipoServicoValue).label;
    }

    /**
     * Método de verificação de autenticação de dois fatores.
     */
    verificar2fa() {
        if (!this.validarForm()) return;
        this.twoFactorAuth.show();
    }

    /**
     * Lida com o sucesso da autenticação de dois fatores.
     * @param twoFactorAuthToken 2fa token.
     */
    on2faSuccess(twoFactorAuthToken: TwoFactorAuthTokenModel) {
        if (twoFactorAuthToken.success) this.enviar();
    }
}
