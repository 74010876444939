import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-blocker-modal',
    templateUrl: './card-blocker-modal.component.html',
    styleUrls: ['./card-blocker-modal.component.css']
})
export class CardBlockerModalComponent {
    @Input() mensagem: string;
}
